import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CompanyService } from '../../core/services/company.service';
import { KendoGridFilters, PrimaryCompany } from '../../../awsAppSync/API';
import { DataStateChangeEvent, FilterService, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { ZERO } from '@angular/cdk/keycodes';
import {
  COMPANY_OVERVIEW,
  COMPANYOVERVIEW_ELLIPSIS_WIDTH,
  CREATE_PRIMARY_COMPANY,
  EMPTY_STRING,
  ERROR_FETCHING_COMPANIES,
  FILTER_COUNTRY,
  FILTER_STATE,
  REDIRECT_CREATE_ASSET_SETUP,
  SEARCH_PLACEHOLDER_COMPANY,
  SETUP_HIERARCHY_LEVELS,
  STRING,
  COMPANY_OVERVIEW_SCREEN
} from '../../shared/constants';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetSetupHierarchy } from '../../core/store/setup-hierarchy.state';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { PermissionService } from '../../core/services/permission.service';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrl: './company-overview.component.css',
})
export class CompanyOverviewComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent;
  @ViewChild('customFilterContainer', { static: false })
  customFilter!: ElementRef;
  originalData: (PrimaryCompany | null)[] = [];
  public searchPlaceholder = SEARCH_PLACEHOLDER_COMPANY;
  public pageSize = 50; // default page size
  public currentPage = 1;
  public total = 0;
  public stateFilterValues: any[] = [];
  public countryFilterValues: any[] = [];
  public dropdownVisible = false;
  public dropdownX = ZERO;
  public dropdownY = ZERO;
  public currentDataItem: any;
  public rowId = ZERO;
  public primaryCompanyName = EMPTY_STRING;
  public primaryCompanyId = EMPTY_STRING;
  public isStickyRow = false;
  public myStickyRows: string[] = [];
  public inputScreen = COMPANY_OVERVIEW;
  ellipsisWidth = COMPANYOVERVIEW_ELLIPSIS_WIDTH;
  selectedRowData: any;
  level3Name = EMPTY_STRING;
  level4Name = EMPTY_STRING;
  public isLoading = true;
  searchText = "";
  public gridData: GridDataResult = { data: [], total: 0 };

  kendoGridFilters : KendoGridFilters =  {};
  permission = false;
 
  constructor(
    private companyService: CompanyService,
    private router: Router,
    private store: Store,
    private hierarchyFilterService: HeirarchyFilterServiceService,
    private cdr: ChangeDetectorRef,
    public permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.permission = this.permissionService.hasPermission(COMPANY_OVERVIEW_SCREEN, 'fullAccess')
    this.loadPrimaryCompanies();
  }

  async loadPrimaryCompanies(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.companyService.getAllPrimaryCompany(
        {
          pageNumber : this.currentPage,
          pageSize : this.state.take?? 50,
          gridFilters : this.kendoGridFilters,
          searchText: this.searchText
        }
      );
  
      if (response?.items) {
        this.gridData ={
          data: response.items.filter(
            (item): item is PrimaryCompany => item !== null),
            total :response?.totalCount != null ? response?.totalCount : 0
        }
       
        this.originalData = this.gridData.data;
        this.isLoading = false;
        this.getDistinctValues(FILTER_STATE);
        this.getDistinctValues(FILTER_COUNTRY);
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        
      }
    } catch (error) {
      console.error(ERROR_FETCHING_COMPANIES, error);
    }
  }

  getDistinctValues(columnName: string): void {
    const columnKey = columnName as keyof PrimaryCompany;
    const distinctValues = [
      ...new Set(
        this.gridData.data
          .map((item) => item![columnKey])
          .filter(
            (value): value is string =>
              value !== null && value !== undefined && value !== EMPTY_STRING,
          ),
      ),
    ];

    distinctValues.forEach((item) => {
      if (columnName === FILTER_STATE) {
        if (!this.stateFilterValues.some(filter => filter.text === item)) {
          this.stateFilterValues.push({ text: item, selected: false });
        }
      } 
      else if (columnName === FILTER_COUNTRY) {
        if (!this.countryFilterValues.some(filter => filter.text === item)) {
          this.countryFilterValues.push({ text: item, selected: false }); 
        }
      }
  });
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
        this.searchText = "";
        this.loadPrimaryCompanies();
    } else if (changeWord.length > 2) {
        this.searchText = changeWord;
        this.loadPrimaryCompanies();
    }
}

  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === STRING) {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }

  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
    field: string,
  ): void {
    const filters: FilterDescriptor[] = [];
    for (const value of selectedValues) {
      if (value.selected) {
        filters.push({
          field: field,
          operator: 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };

    filterService.filter(rootFilter);
  }

  async getLevelNames(id: string, name: string) {
    const level3Values: string[] = [];
    const level4Values: string[] = [];
    const response =
      await this.hierarchyFilterService.getLevelNamesByPrimaryComapany();
    if (response?.items) {
      response?.items.forEach((item) => {
        if (item?.level3Name) {
          level3Values.push(String(item.level3Name)); 
        }
        if (item?.level4Name) {
          level4Values.push(String(item.level4Name)); 
        }
      });
    }
    this.level3Name =  level3Values[0];
    this.level4Name = level4Values[0];
    this.store.dispatch(
      new SetSetupHierarchy(id, name, this.level3Name, this.level4Name, false),
    );
  }

  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.rowId = this.currentDataItem.id;
    this.primaryCompanyId = this.currentDataItem.id;
    this.primaryCompanyName = this.currentDataItem.primaryCompany;
    this.getLevelNames(this.primaryCompanyId, this.primaryCompanyName);
    const gridElement = document.querySelector('.k-grid') as HTMLElement;
    const dropdownHeight = 100;
    const dropdownWidth = 200;

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);

      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY;
      }
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );

      this.dropdownX += gridRect.left;
      this.dropdownY = Math.max(0, this.dropdownY + 150);
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }
    this.selectedRowData = dataItem;
  }

  handleDropdownOption(option: number) {
    if (option === 2) {
      return;
    }
    //option 3 -setup asset type need to redirect to asset setup screen
    else if (option === 3) {
      this.router.navigate([REDIRECT_CREATE_ASSET_SETUP], {
        queryParams: {
          id: this.primaryCompanyId,
          edit: this.currentDataItem.setupStatus > 1,
        },
      });
    } else if (option === 4) {
      if (this.currentDataItem.setupStatus >= 3) {
        this.store.dispatch(
          new SetSetupHierarchy(
            this.currentDataItem.id,
            this.currentDataItem.primaryCompany,
            EMPTY_STRING,
            EMPTY_STRING,
            true
          ),
        );
      }
      this.router.navigate([SETUP_HIERARCHY_LEVELS]);
    }
  }
  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  openCreatePrimaryCompangyPage(): void {
    this.router.navigate([CREATE_PRIMARY_COMPANY]);
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: "and" },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1; 
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.loadPrimaryCompanies();
  }
}
