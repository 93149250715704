import { Component,ElementRef,EventEmitter,Output, OnInit,Input,ChangeDetectorRef} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AssetTypeState, RemoveSelectedAssetTypeIds } from '../../core/store/filter-assettype';
import { Router } from '@angular/router';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { HeirarchyFilterServiceService } from '../service/heirarchy-filter-service/heirarchy-filter-service.service';
import { ASSET_TYPE_ID, LEVEL1_ID, LEVEL2_ID, LEVEL3_ID, SEGMENT_ID, SEGMENT_TYPE_ID, SURVEY_ROUTE_ID, TECHNICIAN_ID } from '../constants';
import { LevelNameState } from '../../core/store/filtet-levelName.state';
import { Level1State, RemoveLevel1State } from '../../core/store/filter-level1.state';
import { Level2State, RemoveLevel2State } from '../../core/store/filter-level2.state';
import { Level3State, RemoveLevel3State } from '../../core/store/filter-level3.state';
import { RemoveSegmentTypeState, SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { RemoveSelectedSegmentIds, SegmentState } from '../../core/store/filter-segments';
import { RemoveSelectedSurveyRouteIds, SurveyRouteState } from '../../core/store/filter-serveyrotes';

@Component({
  selector: 'app-heirarchy-filter-buttons',
  templateUrl: './heirarchy-filter-buttons.component.html',
  styleUrl: './heirarchy-filter-buttons.component.css',
})
export class HeirarchyFilterButtonsComponent implements OnInit  {
  //filter selected to change the color 
  IsLevel1FilterSelected = false;
  IsLevel2FilterSelected = false;
  IsLevel3FilterSelected = false;
  IsSegmentTypeFilterSelected = false;
  IsSegmentFilterSelected = false;
  IsAssetTypeFilterSelected = false;
  IsSurveyRouteFilterSelected = false;
  IsTechnicianFilterSelected = false;

  //enable/disable options
  IsLevel2FilterEnabled = false;
  IsLevel3FilterEnabled = false;
  IsSegmentTypeFilterEnabled = false;
  IsSegmentFilterEnabled = false;
  IsAssetTypeFilterEnabled = false;

  isFromMap  = false;
  @Output() isTrayCollapsed = new EventEmitter<boolean>();
  @Output() filterSearchButtonClicked = new EventEmitter<void>();
  currentFilterIdSelected = 0;
  public filterAdded: { filterId: number; filterAdded: boolean} = { filterId: 0, filterAdded: false};
  isTrayButtonVisible = false;
  @Input() isToggleFilterButtonVisible = true;
  storePrimaryCompanyID$: Observable<string | null>;
  storeLevelName$: Observable<string []>;
  selectedPrimaryCompanyId:string | null = null;
  selectedLevelName:string[] = [];
  level1Name = '';
  level2Name = '';
  level3Name = '';
  isLevel1visible = true;
  isLevel2visible = true;
  storeSelectedLevel1$: Observable<string[]>;
  selectedLevel1:string[] = [];
  storeSelectedLevel2$: Observable<string[]>;
  selectedLevel2:string[] = [];
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3:string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType:string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments:string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute:string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType:string[] = [];
  isSearchBarEnabled = false;
  isSurveyRouteSearchBarEnabled = false;
  @Input() isSurveyRouteFilterRequired = false;

  constructor(private _heirarchyFilterService : HeirarchyFilterServiceService,
              private store: Store, 
              private cd: ChangeDetectorRef,
              private router: Router){
    this.storePrimaryCompanyID$= this.store.select(AuthenticateUserState.getSyrcPrimaryCompanyId);
    this.storeLevelName$ = this.store.select(LevelNameState.getLevelName);
    this.isFromMap = this.router.url === '/maps';
    this.storeSelectedLevel1$ = this.store.select(Level1State.getSelectedLevel1Name);
    this.storeSelectedLevel2$ = this.store.select(Level2State.getSelectedLevel2Name);
    this.storeSelectedLevel3$ = this.store.select(Level3State.getSelectedLevel3Name);
    this.storeSegmentTypeSet$ = this.store.select(SegmentTypeState.getSelectedSegmentType);
    this.storeSelectedSegments$ = this.store.select(SegmentState.getSelectedSegmentIds);
    this.storeSelectedSurveyRoute$ = this.store.select(SurveyRouteState.getSelectedSurveyRouteIds);
    this.storeSelectedAssetType$ = this.store.select(AssetTypeState.getSelectedAssetTypeIds);
  }

  async ngOnInit() {
    this._heirarchyFilterService.isTrayButtonVisible.subscribe((value) => {
      this.isTrayButtonVisible = value;
    });
    this._heirarchyFilterService.isSurveyRouteFilterAdded.subscribe((value) => {
      this.updateFiltersOnSurveyRouteAdded(value);
    });
    this.storeLevelName$.subscribe(levelNames => {
      this.selectedLevelName = levelNames;
      this.generateLevelNames();
    });
    this.storeSelectedLevel1$.subscribe(level1name => {
      this.selectedLevel1 = level1name;
    });
    this.storeSelectedLevel2$.subscribe(level2name => {
      this.selectedLevel2 = level2name;
    });
    this.storeSelectedLevel3$.subscribe(level3name => {
      this.selectedLevel3 = level3name;
    });
    this.storeSegmentTypeSet$.subscribe(segmentType => {
      this.selectedSegmentType = segmentType;
    });
    this.storeSelectedSegments$.subscribe(segment => {
      this.selectedSegments = segment;
    });
    this.storeSelectedSurveyRoute$.subscribe(Surveyroute => {
      this.selectedSurveyRoute = Surveyroute;
    });
    this.storeSelectedAssetType$.subscribe(assettype => {
      this.selectedAssetType = assettype;
    });
    this._heirarchyFilterService.filterAdded.subscribe((value) => {
      this.filterAdded = value;
      this.updateValuesOnFilterChange();
    });
    this.updateButtonsOnStoreAdded();
    this.enablingSearchbar();
  }

  enablingSearchbar() {
    if((this.IsLevel1FilterSelected && this.IsLevel2FilterEnabled && this.IsLevel3FilterEnabled)){
      this.isSearchBarEnabled = true;
      this.isSurveyRouteSearchBarEnabled = false;
    }
    if(this.IsSurveyRouteFilterSelected)
    {
      this.isSurveyRouteSearchBarEnabled = true;
    }
  }

  updateFiltersOnSurveyRouteAdded(value: boolean) {
    if(value){
      this.IsLevel1FilterSelected = false;
      this.IsLevel2FilterEnabled = false;
      this.IsLevel2FilterSelected = false;
      this.IsLevel3FilterEnabled = false;
      this.IsLevel3FilterSelected = false;
      this.IsSegmentTypeFilterEnabled = false;
      this.IsSegmentTypeFilterSelected = false;
      this.IsSegmentFilterEnabled = false;
      this.IsSegmentFilterSelected = false;
      this.IsAssetTypeFilterEnabled = false;
      this.IsAssetTypeFilterSelected = false;
      this.store.dispatch(new RemoveLevel1State());
      this.store.dispatch(new RemoveLevel2State());
      this.store.dispatch(new RemoveLevel3State());
      this.store.dispatch(new RemoveSegmentTypeState());
      this.store.dispatch(new RemoveSelectedSegmentIds());
      this.store.dispatch(new RemoveSelectedAssetTypeIds());
      this.isSearchBarEnabled = false;
      this.isSurveyRouteSearchBarEnabled = true;
    }
    else{
      this.IsSurveyRouteFilterSelected = false;
      this.isSurveyRouteSearchBarEnabled = false;
      this.store.dispatch(new RemoveSelectedSurveyRouteIds);
    }
  }

  updateButtonsOnStoreAdded() {
    if(this.selectedLevel1.length > 0){
      this.IsLevel1FilterSelected = true;
      this.IsLevel2FilterEnabled = true;      
    }
    if(this.selectedLevel2.length > 0){
      this.IsLevel2FilterSelected = true;
      this.IsLevel3FilterEnabled = true;     
    }
    if(this.selectedLevel3.length > 0){
      this.IsLevel3FilterSelected = true;
      this.IsSegmentTypeFilterEnabled = true;
      this.IsSegmentFilterEnabled = true;
      this.IsAssetTypeFilterEnabled = true;    
    }
    if(this.selectedSegmentType.length > 0){
      this.IsSegmentTypeFilterSelected = true;
    }
    if(this.selectedSegments.length > 0){
      this.IsSegmentFilterSelected = true;
    }
    if(this.selectedAssetType.length > 0){
      this.IsAssetTypeFilterSelected = true;
    }
    if(this.selectedSurveyRoute.length > 0){
      this.IsSurveyRouteFilterSelected = true;
    }
  }

  generateLevelNames(){
    if(this.selectedLevelName[0]){
      this.level1Name = this.selectedLevelName[0];
      this.isLevel1visible = true;
    }
    if(this.selectedLevelName[1]){
      this.level2Name = this.selectedLevelName[1];
      this.isLevel2visible = true;
    }
    if(this.selectedLevelName[2]){
      this.level3Name = this.selectedLevelName[2];
    }
    this.cd.detectChanges();
  }

  updateValuesOnFilterChange() {
    switch (this.filterAdded.filterId) {
      case LEVEL1_ID:
        this.IsLevel1FilterSelected = this.filterAdded.filterAdded;
        this.IsLevel2FilterEnabled = this.filterAdded.filterAdded;
        this.IsLevel2FilterSelected = this.filterAdded.filterAdded ? this.selectedLevel2.length > 0 ? true : false : false;
        this.IsLevel3FilterEnabled = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsLevel3FilterSelected = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsSegmentTypeFilterEnabled = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsSegmentTypeFilterSelected = this.filterAdded.filterAdded ? this.selectedSegmentType.length > 0 ? true : false : false;
        this.IsSegmentFilterEnabled = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsSegmentFilterSelected = this.filterAdded.filterAdded ? this.selectedSegments.length > 0 ? true : false : false;
        this.IsAssetTypeFilterEnabled = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsAssetTypeFilterSelected = this.filterAdded.filterAdded ? this.selectedAssetType.length > 0 ? true : false : false;
        this.IsSurveyRouteFilterSelected = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsTechnicianFilterSelected = false;
        this.isSearchBarEnabled = this.isSearchBarEnabled ? true : false;;
        this.isSurveyRouteSearchBarEnabled = false;
      break;
      case LEVEL2_ID:
        this.IsLevel2FilterSelected = this.filterAdded.filterAdded;
        this.IsLevel3FilterEnabled = this.filterAdded.filterAdded;
        this.IsLevel3FilterSelected = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsSegmentTypeFilterEnabled = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsSegmentTypeFilterSelected = this.filterAdded.filterAdded ? this.selectedSegmentType.length > 0 ? true : false : false;
        this.IsSegmentFilterEnabled = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsSegmentFilterSelected = this.filterAdded.filterAdded ? this.selectedSegments.length > 0 ? true : false : false;
        this.IsAssetTypeFilterEnabled = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsAssetTypeFilterSelected = this.filterAdded.filterAdded ? this.selectedAssetType.length > 0 ? true : false : false;
        this.IsSurveyRouteFilterSelected = this.filterAdded.filterAdded ? this.selectedLevel3.length > 0 ? true : false : false;
        this.IsTechnicianFilterSelected = false;
        this.isSearchBarEnabled = this.isSearchBarEnabled ? true : false;
        this.isSurveyRouteSearchBarEnabled = false;
      break;
      case LEVEL3_ID:
        this.IsLevel3FilterSelected = this.filterAdded.filterAdded;
        this.IsSegmentTypeFilterEnabled = this.filterAdded.filterAdded;
        this.IsSegmentFilterEnabled = this.filterAdded.filterAdded;
        this.IsAssetTypeFilterEnabled = this.filterAdded.filterAdded;
        this.isSearchBarEnabled = true;
        this.isSurveyRouteSearchBarEnabled = false;
        if(!this.filterAdded.filterAdded)
        {
          this.IsSegmentFilterSelected = false;
          this.IsSegmentTypeFilterSelected = false;
          this.IsAssetTypeFilterSelected = false;
          this.isSearchBarEnabled = false;
        }
      break;
      case SEGMENT_TYPE_ID:
        this.IsSegmentTypeFilterSelected = this.filterAdded.filterAdded;
      break;
      case SEGMENT_ID:
        this.IsSegmentFilterSelected = this.filterAdded.filterAdded;
      break;
      case ASSET_TYPE_ID:
        this.IsAssetTypeFilterSelected = this.filterAdded.filterAdded;
      break;
      case SURVEY_ROUTE_ID:
        this.IsSurveyRouteFilterSelected = this.filterAdded.filterAdded;
        this.isSearchBarEnabled = false;
        this.isSurveyRouteSearchBarEnabled = true;
      break;
      case TECHNICIAN_ID:
        this.IsTechnicianFilterSelected = this.filterAdded.filterAdded;
      break;
      default:
        break;
    }
  }

  isAnyFilterSelected(): boolean {
    return (
      this.IsAssetTypeFilterSelected ||
      this.IsSegmentFilterSelected ||
      this.IsLevel1FilterSelected 
    );
  }

  IsSurveyRouteSelected(): boolean {
    return (
      this.IsSurveyRouteFilterSelected 
    );
  }

  OnClearFilterButtonClick() {
    if (this.isAnyFilterSelected()) {
      this._heirarchyFilterService.setIsClearFilterClick(this.isAnyFilterSelected());
    }
  }

  OnSurveyRouteClearFilterButtonClick() {
    if (this.IsSurveyRouteSelected()) {
      this._heirarchyFilterService.setIsSurveyRouteClearFilterClick(this.IsSurveyRouteSelected());
    }
  }

  onButtonClick(id: number, anchor: ElementRef): void {
    this._heirarchyFilterService.setSelectedFilter({ filterId: id, anchor: anchor });
    this.sendFilterDropdownvalue(id);
  }

  toggleFilterTray() {
    this.isTrayCollapsed.emit(true);
    this._heirarchyFilterService.setIsDropDownOpen(false);
  }

  sendFilterDropdownvalue(id:number){
    if(this.currentFilterIdSelected == id){
      this._heirarchyFilterService.setIsDropDownOpen(false);
      this.currentFilterIdSelected = 0;
    }
    else{
      this._heirarchyFilterService.setIsDropDownOpen(true);
      this.currentFilterIdSelected = id;
    }
  }

  onFilterSearchButtonClick() {
    if(this.isSearchBarEnabled){
      this.filterSearchButtonClicked.emit(); 
    }
  }

  onSurveyRouteSearchButtonClick() {
    if(this.isSurveyRouteSearchBarEnabled){
      this.filterSearchButtonClicked.emit(); 
    }
  }
}