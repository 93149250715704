import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-survey-route-edit-modal',
  templateUrl: './survey-route-edit-modal.component.html',
  styleUrl: './survey-route-edit-modal.component.css'
})
export class SurveyRouteEditModalComponent {

  Proceed():void {
    alert('');
  }

  
  constructor(
    private dialogRef: MatDialogRef<SurveyRouteEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { routeName: string }
  ) {
    // Update the label with the passed route name
    this.options[0].label = `Update Existing Survey Route - ${data.routeName}`;
  }


  options = [
    { id: 1, label: 'Update existing Survey Route - <Route Name 1>', selected: false },
    { id: 2, label: 'Save as New Survey Route', selected: false },
    { id: 3 ,label: 'Proceed without Creating Survey Route', selected: false }
  ];

  close(): void {
    // Pass selected options when dialog is closed
    const selectedOptions = this.options
      .filter(option => option.selected)
      .map(option => option.label);

    this.dialogRef.close({ selectedOptions });
  }

  proceed(): void {
    // Pass the selected option to the caller
    const selectedOption = this.options.find(option => option.selected);
    this.dialogRef.close(selectedOption);
  }

  isAnyCheckboxSelected = false;

  onCheckboxChange(selectedOption: any): void {
    // Uncheck all checkboxes except the selected one
    this.options.forEach(option => {
      option.selected = option === selectedOption;
    });

    // Update the isAnyCheckboxSelected flag
    this.isAnyCheckboxSelected = selectedOption.selected;
  }
}

