import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY_STRING, USER_PROFILE_DATA_STATE, USER_PROFILE_STATE_TYPE_REMOVE, USER_PROFILE_STATE_TYPE_SET } from "../../shared/constants";
import { Injectable } from "@angular/core";
import { PrimaryCompanyModel } from "../../../awsAppSync/API";

export interface UserProfileStateModel {
    primaryCompany: PrimaryCompanyModel | null | undefined;
    userId: string | null;
    userName: string | null;
    userEmail: string | null;
    isMTXUser: boolean | null;
    roles: (Roles | null)[] | null | undefined;
    syrcPrimaryCompanies: (PrimaryCompanyModel | null)[] | null | undefined;
  
  }
  export interface Roles{
    level1Id: string | null;
    level2Id: string | null;
    level3Id: string | null;
    level3Value: string | null;
    roleId: string | null;
    rolename: string | null;
    permissions: (Permissions | null)[] | null | undefined;
  }
  export interface Permissions{
    screenName: string | null;
    fullAccess: boolean | null;
    noAccess: boolean | null;
    readAccess: boolean | null;
  }

  export class SetUserProfileData {
  
    static readonly type = USER_PROFILE_STATE_TYPE_SET;
    constructor(
      public primaryCompany: PrimaryCompanyModel,
      public userId: string,
      public userName: string,
      public userEmail: string,
      public isMTXUser: boolean,
      public roles: (Roles | null)[] | null | undefined,
      public syrcPrimaryCompanies: (PrimaryCompanyModel | null)[] | null | undefined,
    ) {}
  }
  export class RemoveUserProfileData {
    static readonly type = USER_PROFILE_STATE_TYPE_REMOVE;
  }
  @State<UserProfileStateModel>({
    name: USER_PROFILE_DATA_STATE,
    defaults: {
      primaryCompany: null,
      userId: null,
      userName: null,
      userEmail: null,
      isMTXUser: false,
      roles: null,
      syrcPrimaryCompanies: null,
    },
  })
  @Injectable()
  export class UserProfileDataState {
    @Selector()
    static getPrimaryCompany(state: UserProfileStateModel) {
      return state.primaryCompany;
    }
  
    @Selector()
    static getUserId(state: UserProfileStateModel) {
      return state.userId;
    }
  
    @Selector()
    static getUserName(state: UserProfileStateModel) {
      return state.userName;
    }
  
    @Selector()
    static getUserEmail(state: UserProfileStateModel) {
      return state.userEmail;
    }
    @Selector()
    static getIsMTXUser(state: UserProfileStateModel) {
      return state.isMTXUser;
    }
    @Selector()
    static getRoles(state: UserProfileStateModel) {
      return state.roles;
    }
    @Selector()
    static getSyrcPrimaryCompanies(state: UserProfileStateModel) {
      return state.syrcPrimaryCompanies;
    }
  
    //actions to do operation
    @Action(SetUserProfileData)
    SetAssetData(
      { patchState }: StateContext<UserProfileStateModel>,
      {
        primaryCompany,
        userId,
        userName,
        userEmail,
        isMTXUser,
        roles,
        syrcPrimaryCompanies,
      }: SetUserProfileData,
    ): void {
      patchState({
        primaryCompany: primaryCompany,
        userId: userId,
        userName: userName,
        userEmail: userEmail,
        isMTXUser: isMTXUser,
        roles: roles,
        syrcPrimaryCompanies: syrcPrimaryCompanies,
      });
    }
    @Action(RemoveUserProfileData)
    RemoveAssetDataSetup({ setState }: StateContext<UserProfileStateModel>): void {
      setState({
        primaryCompany: null,
        userId: EMPTY_STRING,
        userName: EMPTY_STRING,
        userEmail: EMPTY_STRING,
        isMTXUser: false,
        roles: null,
        syrcPrimaryCompanies: null,
      });
    }
  }