import { Injectable } from '@angular/core';
import { User } from './models/user.module';
import { BaseAWSRequestService } from '../shared/service/base-services/base-aws-request.service';
import { AddUpdateUserResponse, CreateUserMutationVariables, GetAllUserOptionQuery, GetAllUserOptionQueryVariables, GetAllUserQuery, GetAllUserQueryVariables, GetUserByCoreViewUserIdQueryVariables, KendoGridInput, UpdateUserMutationVariables, UserConnection, UserCreateInput, UserOptionConnection, UserUpdateInput } from '../../awsAppSync/API';
import { EMPTY_STRING, GENERIC_FAILED_ERROR } from '../shared/constants';
import { getAllUser, getAllUserOption, getUserByCoreViewUserId } from '../../awsAppSync/graphql/queries';
import { createUser, updateUser } from '../../awsAppSync/graphql/mutations';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public id: string = EMPTY_STRING;

  constructor(private awsService: BaseAWSRequestService) 
  {}

  public async getAllUser( gridInput :KendoGridInput): Promise<UserConnection | null> 
  {
    try {
      const variables: GetAllUserQueryVariables = { 
        input: 
        {pageNumber: gridInput.pageNumber, pageSize: gridInput.pageSize, gridFilters: gridInput.gridFilters,searchText: gridInput.searchText}  
      
      };
      const result = await this.awsService.executeGetQuery<GetAllUserQuery>(
        getAllUser,
        variables
      );
      return result.getAllUser ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getUserByCoreViewUserId(
    coreViewUserId: string,
  ): Promise<User | null> {
    try {
      const variables: GetUserByCoreViewUserIdQueryVariables = { id: coreViewUserId };
      const query = getUserByCoreViewUserId;
      const result = await this.awsService.executeGetQuery<{
        getUserByCoreViewUserId: User; // Change here to match User type
      }>(query, variables);
      
      return result.getUserByCoreViewUserId || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllUserOption(): Promise<UserOptionConnection | null> 
  {
    try {
      const variables: GetAllUserOptionQueryVariables = { };
      const result = await this.awsService.executeGetQuery<GetAllUserOptionQuery>(
        getAllUserOption,
        variables
      );
      return result.getAllUserOption ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async createUser(
    input: UserCreateInput,
  ): Promise<AddUpdateUserResponse | null> {
    const mutation = createUser;
    const variables: CreateUserMutationVariables = { input };
    try {
      const userResult = await this.awsService.executeMutation<{
        createUser: AddUpdateUserResponse;
      }>(mutation, variables);
      const userResponse =
      userResult.createUser || null;
      this.id = userResponse?.item?.id || null;
      return userResult.createUser || null;
    } catch (error) {
      console.error("Error creating user", error);
      return null;
    }
  }

  async updateUser(
    input: UserUpdateInput,
  ): Promise<AddUpdateUserResponse | null> {
    const mutation = updateUser;
    const variables: UpdateUserMutationVariables = { input };
    try {
      const userResult = await this.awsService.executeMutation<{
        updateUser: AddUpdateUserResponse;
      }>(mutation, variables);
      const userResponse =
      userResult.updateUser || null;
      this.id = userResponse?.item?.id || null;
      return userResult.updateUser || null;
    } catch (error) {
      console.error("Error updating user", error);
      return null;
    }
  }
}