<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px;">
<div class="container-fluid">
<div class="card-body">

  <div class="row-container">
    <div class="col-auto">
      <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px;">{{ title | translate }}</h5>
    </div>
    <div class="row g-2 justify-content-end">
      <div *ngIf="permission" class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
        <button class="asset-modal-button" (click)="createSurveyRoute()">
          <div class="asset-modal-text">
            <span class="asset-modal-text">
              <span class="button-text-alignment">Create Route</span>
              <!-- <span class="button-text-alignment">{{ 'SURVEY_ROUTE.CREATE_ROUTE' | translate }}</span> -->
            </span>
          </div>
        </button>
      </div>
    </div>
    
  </div>
<div class="grid-container mt-3"  style="margin-left: -12px;">
    <kendo-grid [data]="gridData" class="grid-shadow">
      <kendo-grid-column *ngIf="permission" [resizable]="false" [width]="80" [filterable]="false" field="id" title="">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex-container" style="display: flex; align-items: center;">
                <span class="k-icon k-font-icon my-custom-icon-class"
                    (click)="openDropdownMenu($event, dataItem)"></span>
            </div>
        </ng-template>
    </kendo-grid-column>
      <kendo-grid-column field="createdDate" title="Date Created" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.createdDate | customDate}}
        </ng-template>
      </kendo-grid-column>
      
      <kendo-grid-column field="routeName" title="Route Name" [width]="150"></kendo-grid-column>
  
      <kendo-grid-column field="assets" title="Assets" [width]="450">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="isExpanded(dataItem.id)">
            {{ getAssetNames(dataItem.surveyRouteAssociatedAsset)  }}
          </span>
          <span *ngIf="!isExpanded(dataItem.id)">
            {{ getAssetNames(dataItem.surveyRouteAssociatedAsset) | slice:0:120}}
            <button *ngIf="getAssetNames(dataItem.surveyRouteAssociatedAsset).length > 120" class="see-more" (click)="toggleDescription(dataItem.id)">
              {{ 'GRIDS.SEE_MORE' | translate }}
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>
  
      <kendo-grid-column field="routeDescription" [width]="350" title="Route Description">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="isExpanded(dataItem.id)">
            {{ dataItem.routeDescription }}
          </span>
          <span *ngIf="!isExpanded(dataItem.id)">
            {{ dataItem.routeDescription | slice:0:70 }}
            <button *ngIf="dataItem.routeDescription?.length > 70" class="see-more" (click)="toggleDescription(dataItem.id)">
              {{ 'GRIDS.SEE_MORE' | translate }}
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

</div>
  <app-row-actions
    class="row-action" 
    [selectedRowData]="selectedRowData" 
    [visible]="dropdownVisible" 
    [positionX]="dropdownX" 
    [width]="ellipsisWidth"
    [positionY]="dropdownY" 
    [inputScreen]="inputScreen" 
    (visibleChange)="handleDropdownVisibilityChange($event)"
    [rowId]="rowId" 
    [isStickyRow]="false" 
    [permission] = "permission" 
    (optionSelected)="handleDropdownOption($event)"
  ></app-row-actions>
</div>
</div>