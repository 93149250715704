<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px;">
  <div class="container-fluid">
     <div class="row align-items-center justify-content-between">
        <div class="col-auto">
           <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px;">{{ 'INSPECTION_PAGE.TITLE' | translate }}</h5>
        </div>
        <div class="col-auto ml-auto">
           <div class="d-flex flex-wrap align-items-center gap-2 justify-content-end">
              <div style="width: 330px; margin-right: -10px;">
                 <app-search
                 [placeholder]="searchPlaceholder"
                 (searchInput)="handleSearchInput($event)"
                 ></app-search>
              </div>
              <div>
                 <app-template [templateTypeInput]="templateTypeInput"></app-template>
              </div>
              <div *ngIf="showFilterBtn">
                 <button
                    (click)="toggleFilterTray()"
                    class="btn mtx-btn-secondary mtx-font-weight-500"
                    >
                 <span style="vertical-align: bottom" class="material-icons">filter_alt</span>
                 {{ 'GENERIC_Buttons.FILTERS' | translate }}
                 <span style="vertical-align: bottom" class="material-icons">arrow_drop_down</span>
                 </button>
              </div>
              <div>
                 <button
                    class="btn mtx-btn-primary"
                    style="height: 38px;"
                    (click)="openDialog()"
                    >
                 <span style="vertical-align: middle" class="material-icons">download</span>
                 {{ 'GENERIC_Buttons.EXPORT' | translate }}
                 </button>
              </div>
              <div>
                 <app-columnadjustment
                 (columnPosition)="reorderColumnPosition($event)"
                 (selectedColumnsChange)="OnSelectedTemplateColumnsChange($event)"
                 [templateTypeInput]="templateTypeInput"
                 ></app-columnadjustment>
              </div>
           </div>
        </div>
     </div>
  </div>
  <div
     class="row mt-3 justify-content-end mb-3"
     style="float: right"
     *ngIf="showHierarchicalFilter"
     >
     <app-heirarchy-filter-buttons (filterSearchButtonClicked)="filterSearchButtonClicked()" 
                                   (isTrayCollapsed)="collapseFilterTray()" 
                                   [isSurveyRouteFilterRequired]="true">
     </app-heirarchy-filter-buttons>
     <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
  </div>
  <div class="mt-3">
     <kendo-grid
     #grid
     [data]="gridData"
     id="grid"
     [filterable]="'menu'"
     [reorderable]="true"
     (columnReorder)="onColumnReorder($event)"
     [sortable]="false"
     [resizable]="true"
     style="width: 100%; box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15)"
     [rowSticky]="rowSticky"
     [skip]="state.skip"
     [pageable]="true"
     [pageSize]="state.take"
     [filter]="state.filter"
     (dataStateChange)="dataStateChange($event)"

     >
     <kendo-grid-column
     [resizable]="false"
     [headerClass]="'firstColGridHeader non-reorderable'"
     [width]="60"
     [filterable]="false"
     field="id"
     title=""
     >
     <ng-template kendoGridCellTemplate let-dataItem>
        <div class="flex-container">
           <div class="icon-container">
              <span
                 class="k-icon k-font-icon my-custom-icon-class"
                 style="position: relative"
                 (click)="openDropdownMenu($event, dataItem)"
                 ></span>
              <span
              *ngIf="isSticky(dataItem.id)"
              class="k-icon k-font-icon k-i-pin pin-icon-css"
              (click)="rowActionStickyRow($event, dataItem)"
              ></span>
           </div>
        </div>
     </ng-template>
     </kendo-grid-column>
     <kendo-grid-column
     [headerClass]="'firstColGridHeader'"
     field="assetType"
     [title]="'INSPECTION_GRID_COLUMNS.ASSET_TYPE' | translate"
     [minResizableWidth]="80"
     *ngIf="IsColumnSelected('assetType')"
     [width]="120"
     >
     <ng-template
     kendoGridFilterMenuTemplate
     let-filter
     let-column="column"
     let-filterService="filterService"
     >
     <div #customFilterContainer>
        <app-custom-grid-filter
         fieldName="assetType"
         [inputElements]="assetTypeFilterValues"
         [parentDiv]="customFilter"
         [state]="customstate"
         (selectedValues)="applyFilter($event, filterService, 'assetType')">
      </app-custom-grid-filter>
     </div>
  </ng-template>
     </kendo-grid-column>
     <kendo-grid-column
     field="assetName"
     [title]="'INSPECTION_GRID_COLUMNS.ASSET_NAME' | translate"
     [minResizableWidth]="80" 
     [width]="120"
     *ngIf="IsColumnSelected('assetName')"
     ></kendo-grid-column>
     <kendo-grid-column
  field="complianceStatus"
  [title]="'INSPECTION_GRID_COLUMNS.COMPLIANCE_STATUS' | translate"
  [minResizableWidth]="80"
  [width]="135"
  *ngIf="IsColumnSelected('complianceStatus')">
  
  <ng-template kendoGridCellTemplate let-dataItem>
    <span
    [ngClass]="getStatusClass(dataItem.complianceStatus ? dataItem.complianceStatus : '')"
      style="font-weight: 700">
      <span class="dot"></span> {{ dataItem.complianceStatus ? dataItem.complianceStatus : '' }}
    </span>
  </ng-template>

  <ng-template
    kendoGridFilterMenuTemplate
    let-filter
    let-column="column"
    let-filterService="filterService">
    <div #customFilterContainer>
      <app-custom-grid-filter
         fieldName="complianceStatus"
         [inputElements]="complaiceStatusFilterValues"
         [parentDiv]="customFilter"
         [state]="customstate"
         (selectedValues)="applyFilter($event, filterService, 'complianceStatus')">
      </app-custom-grid-filter>
    </div>
  </ng-template>
</kendo-grid-column>
      <kendo-grid-column
      field="daysUntilDelinquent"
      [title]="'INSPECTION_GRID_COLUMNS.DAYS_UNTIL_DELINQUENT' | translate"
      [minResizableWidth]="80"
      [width]="120"
      *ngIf="IsColumnSelected('daysUntilDelinquent')"
      >
      <ng-template kendoGridCellTemplate let-dataItem>
         <span
         [ngClass]="getStatusClass(dataItem.complianceStatus ? dataItem.complianceStatus : '')"
           style="font-weight: 700">
            {{ dataItem.daysUntilDelinquent ? dataItem.daysUntilDelinquent : '' }}
         </span>
       </ng-template>
</kendo-grid-column>

     <kendo-grid-column
     field="inspectionTargetDate"
     *ngIf="IsColumnSelected('inspectionTargetDate')"
     [title]="'INSPECTION_GRID_COLUMNS.INSPECTION_TARGET_DATE' | translate"
     [minResizableWidth]="80"
     [width]="160"
     format="{0:dd/MM/yyyy}"
     filter="date"
     >
     <ng-template kendoGridCellTemplate let-dataItem>
        <span class="right-alignment">{{
        dataItem.inspectionTargetDate | customDate
        }}</span>
     </ng-template>
     </kendo-grid-column>
     <kendo-grid-column
     field="lastInspectionDate"
     *ngIf="IsColumnSelected('lastInspectionDate')"
     [title]="'INSPECTION_GRID_COLUMNS.LAST_INSPECTION_DATE' | translate"
     [minResizableWidth]="80"
     [width]="160"
     format="{0:dd/MM/yyyy}"
     filter="date"
     >
     <ng-template kendoGridCellTemplate let-dataItem>
        <span class="right-alignment">{{
        dataItem.lastInspectionDate | customDate
        }}</span>
     </ng-template>
     </kendo-grid-column>
     <kendo-grid-column
     field="assetDelinquencyDate"
     *ngIf="IsColumnSelected('assetDelinquencyDate')"
     [title]="'INSPECTION_GRID_COLUMNS.ASSET_DELINQUENCY_DATE' | translate"
     [minResizableWidth]="80"
     [width]="160"
     format="{0:dd/MM/yyyy}"
     filter="date"
     >
     <ng-template kendoGridCellTemplate let-dataItem>
        <span class="right-alignment">{{
        dataItem.assetDelinquencyDate | customDate
        }}</span>
     </ng-template>
     </kendo-grid-column>
     <kendo-grid-column
     #technician
     field="technician"
     [title]="'INSPECTION_GRID_COLUMNS.TECHNICIAN' | translate"
     [minResizableWidth]="80"
     [width]="135"
     *ngIf="IsColumnSelected('technician')"
     >
     <ng-template
     kendoGridFilterMenuTemplate
     let-filter
     let-column="column"
     let-filterService="filterService"
     >
     <div #customFilterContainer>
        <app-custom-grid-filter
        fieldName="technician"
        [inputElements]="technicianFilterValues"
        [parentDiv]="customFilter"
        [state]="customstate"
        (selectedValues)="applyFilter($event, filterService, 'technician')">
     </app-custom-grid-filter>
     </div>
  </ng-template>
</kendo-grid-column>
<ng-container *ngFor="let column of dynamicColumns">
   <kendo-grid-column
     [field]="column.field"
     [filterable]="false"
     [sortable]="false"
     [reorderable]="false"
     [title]="column.title"
     [width]="column.width"
   >
     <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ dataItem.dynamicValues[column.field] || '' }}</span>
     </ng-template>
   </kendo-grid-column>
 </ng-container>
   <ng-template kendoGridDetailTemplate let-dataItem>
      <div class="expanded-content">
         <kendo-grid [height]="dataItem.measurementsProcesses?.table?.length > 0 ? 200 : 110" 
             [data]="dataItem.measurementsProcesses?.table">
             <kendo-grid-column field="measurementPoint" [title]="'INSPECTION_GRID_COLUMNS.MEASUREMENT_POINT' | translate"></kendo-grid-column>
             <kendo-grid-column field="source" [title]="'INSPECTION_GRID_COLUMNS.MEASUREMENT_SOURCE' | translate"></kendo-grid-column>
             <kendo-grid-column field="measurementType" [title]="'INSPECTION_GRID_COLUMNS.MEASUREMENT_TYPE' | translate"></kendo-grid-column>
             <kendo-grid-column field="status" [title]="'INSPECTION_GRID_COLUMNS.STATUS' | translate">
                 <ng-template kendoGridCellTemplate let-dataItem>
                     <span
                         [ngClass]="getMeasurementStatusClass(dataItem.status ? dataItem.status : '')"
                         style="font-weight: 700">
                         <span class="dot"></span> {{ dataItem.status ? dataItem.status : '' }}
                     </span>
                 </ng-template>
             </kendo-grid-column>
             <kendo-grid-column field="criteria" [title]="'INSPECTION_GRID_COLUMNS.CRITERIA' | translate"></kendo-grid-column>
             <kendo-grid-column field="level4Value" [title]="'INSPECTION_GRID_COLUMNS.SEGMENT' | translate"></kendo-grid-column>
             <ng-container *ngFor="let date of dataItem.measurementsProcesses?.dates">
               <kendo-grid-column [field]="'Measurement On ' + date" [title]="'Measurement On ' + date">
                   <ng-template kendoGridCellTemplate let-dataItem>
                       {{ dataItem['Measurement On ' + date] }}
                   </ng-template>
               </kendo-grid-column>
           </ng-container>
         </kendo-grid>
     </div>
     
   </ng-template>
</kendo-grid>

     <app-row-actions
     class="row-action"
     [visible]="dropdownVisible"
     [isStickyRow]="isStickyRow"
     [positionX]="dropdownX"
     [rowId]="rowId"
     [positionY]="dropdownY"
     (optionSelected)="handleDropdownOption($event)"
     [inputScreen]="inputScreen"
     [permission] = "permission"
     (visibleChange)="handleDropdownVisibilityChange($event)"
     ></app-row-actions>
  </div>
</div>