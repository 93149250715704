<div class="modal-dialog">
    <div class="modal-content p-3">
      <div class="row">
        <!-- Profile Picture -->
        <div class="profile-circle mb-2">{{userInitials}}</div>
        
        <!-- User Information -->
        <div class="col-6" style="margin-top: 5px;">
          <h5 class="mb-0 font-weight-bold">{{userName}}</h5>
          <p class="mb-0">{{userEmail}}</p>
          <small>{{primaryCompanyName}}</small>
        </div>
      </div>
  
      <hr>
  
      <!-- Focused Regions Dropdown -->
      <div class="mb-3" *ngIf="!isMTXUser">
        <label for="regionSelect" class="form-label font-weight-bold text-primary">Focused Regions</label>
        
        <select id="regionSelect" class="form-select" [(ngModel)]="selectedLevel3Value">
          <option *ngFor="let value of level3Values" [value]="value">
            {{ value }}
          </option>
        </select>
      </div>

      <div class="mb-3" *ngIf="isMTXUser">
        <label for="regionSelect" class="form-label font-weight-bold text-primary">Focused Primary Company</label>
        
        <select id="regionSelect" class="form-select" [(ngModel)]="selectedSyrcPrimaryCompany">
          <option *ngFor="let type of syrcPrimaryCompanies" [value]="type?.name">
            {{ type?.name }}
          </option>
        </select>
      </div>
  
      <!-- Menu Items -->
      <ul class="list-unstyled mb-0">
        <li class="mb-2 d-flex align-items-center">
          <mat-icon>person</mat-icon>
          <span class="ml-2" style="margin-left: 10px;">View User Information</span>
        </li>
        <li *ngIf="isMTXUser" class="mb-2 d-flex align-items-center">
          <mat-icon>group_add</mat-icon>
          <span class="ml-2" style="margin-left: 10px;">Manage Mobiltex Member</span>
        </li>
        <li class="mb-2 d-flex align-items-center">
          <mat-icon>info</mat-icon>
          <span class="ml-2" style="margin-left: 10px;">Getting Started</span>
        </li>
        <li *ngIf="!isMTXUser" class="mb-2 d-flex align-items-center">
          <mat-icon>access_time</mat-icon>
          <span class="ml-2" style="margin-left: 10px;">Set Time Zone</span>
        </li>
        <li *ngIf="!isMTXUser" class="mb-2 d-flex align-items-center">
          <mat-icon>settings</mat-icon>
          <span class="ml-2" style="margin-left: 10px;">Set Root Cause</span>
        </li>
        <li class="mb-2 d-flex align-items-center">
          <mat-icon>lock</mat-icon>
          <span class="ml-2" style="margin-left: 10px;">Change Password</span>
        </li>
        <li class="d-flex align-items-center" (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span class="ml-2" style="margin-left: 10px;">Logout</span>
        </li>
      </ul>
    </div>
  </div>
  