import { Injectable } from '@angular/core';
import {
  AssetTypeTemplate,
  AssetTypeTemplateConnection,
  AssetTypeTemplateCreateInput,
  AssetTypeTemplateUpdateInput,
  AssetTypeTemplateResponse,
  CreateAssetTypeTemplateForPrimaryCompanyMutationVariables,
  UpdateAssetTypeTemplateForPrimaryCompanyMutationVariables,
  GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables,
} from '../../../awsAppSync/API';
import { createAssetTypeTemplateForPrimaryCompany } from '../../../awsAppSync/graphql/mutations';
import { updateAssetTypeTemplateForPrimaryCompany } from '../../../awsAppSync/graphql/mutations';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { getAssetTypeTemplateByPrimaryCompanyId } from '../../../awsAppSync/graphql/queries';
import { ERROR_FETCHING_ASSET_TYPE } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CreateAssetTypeService {
  constructor(
    private awsService: BaseAWSRequestService,
    private toastr: CustomToastrService,
  ) {}

  public async createAssetTypeAndMC(
    input: AssetTypeTemplateCreateInput,
  ): Promise<AssetTypeTemplateResponse | null> {
    const mutation = createAssetTypeTemplateForPrimaryCompany; // Ensure this is your GraphQL mutation string or document
    const variables: CreateAssetTypeTemplateForPrimaryCompanyMutationVariables =
      { input };

    const result = await this.awsService.executeMutation<{
      createAssetType: AssetTypeTemplateResponse;
    }>(mutation, variables);
    if (result.createAssetTypeTemplateForPrimaryCompany.status == 'Error') {
      console.log(
        result.createAssetTypeTemplateForPrimaryCompany.error.message,
      );
    }
    return result.createAssetTypeTemplateForPrimaryCompany || null;
  }

  public async updateAssetTypeAndMC(
    input: AssetTypeTemplateUpdateInput,
  ): Promise<AssetTypeTemplateResponse | null> {
    const mutation = updateAssetTypeTemplateForPrimaryCompany; // Ensure this is your GraphQL mutation string or document
    const variables: UpdateAssetTypeTemplateForPrimaryCompanyMutationVariables =
      { input };

    const result = await this.awsService.executeMutation<{
      createAssetType: AssetTypeTemplateResponse;
    }>(mutation, variables);
    if (result.updateAssetTypeTemplateForPrimaryCompany.status == 'Error') {
      console.log(
        result.updateAssetTypeTemplateForPrimaryCompany.error.message,
      );
    }
    return result.updateAssetTypeTemplateForPrimaryCompany || null;
  }

  public async getAssetTypeByPrimayCompanyId(
    id: string,
  ): Promise<(AssetTypeTemplate | null)[] | null> {
    const variables: GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables = {
      id,
    };
    const query = getAssetTypeTemplateByPrimaryCompanyId;
    try {
      const response = await this.awsService.executeGetQuery<{
        getAssetTypeTemplateByPrimaryCompanyId: AssetTypeTemplateConnection;
      }>(query, variables);
      console.log('getAssetTypeByPrimayCompanyId:' + JSON.stringify(response));
      return response.getAssetTypeTemplateByPrimaryCompanyId.items || null;
    } catch (error) {
      console.error(ERROR_FETCHING_ASSET_TYPE, error);
      return null;
    }
  }
}
