import { Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { PrimaryCompanyModel } from '../../../awsAppSync/API';
import { Roles, UserProfileDataState } from '../../core/store/user-profile.state';
import { TopNavigationService } from '../../core/services/top-navigation.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { RemoveAuthenticateUser } from '../../core/store/authenticate-user.state';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.css'
})
export class ProfileMenuComponent {
  primaryCompany$: Observable<PrimaryCompanyModel | null | undefined>;
  userId$: Observable<string | null>;
  userName$: Observable<string | null>;
  userEmail$: Observable<string | null>;
  isMTXUser$: Observable<boolean | null>;
  roles$: Observable<(Roles | null)[] | null | undefined>;
  syrcPrimaryCompanies$: Observable<(PrimaryCompanyModel | null)[] | null | undefined>;

  primaryCompany: PrimaryCompanyModel | null | undefined;
  userId: string | null = null;
  userName: string | null = null;
  userEmail: string | null = null;
  isMTXUser: boolean | null = null;
  roles: (Roles | null)[] | null | undefined = null;

  level3Values: string[] = [];
  selectedLevel3Value: string | null = null;
  syrcPrimaryCompanies: (PrimaryCompanyModel | null)[] | null | undefined;
  selectedSyrcPrimaryCompany= '';
  primaryCompanyName = '';
  userInitials = '';

  private readonly oidcSecurityService = inject(OidcSecurityService);
  
  
 constructor(private store: Store, private topNavigationService: TopNavigationService) {
    this.primaryCompany$ = this.store.select(UserProfileDataState.getPrimaryCompany);
    this.userId$ = this.store.select(UserProfileDataState.getUserId);
    this.userName$ = this.store.select(UserProfileDataState.getUserName);
    this.userEmail$ = this.store.select(UserProfileDataState.getUserEmail);
    this.isMTXUser$ = this.store.select(UserProfileDataState.getIsMTXUser);
    this.roles$ = this.store.select(UserProfileDataState.getRoles);
    this.syrcPrimaryCompanies$ = this.store.select(UserProfileDataState.getSyrcPrimaryCompanies);
 }

 ngOnInit(): void {

  this.isMTXUser$.subscribe((isMTXUser) => {
    this.isMTXUser = isMTXUser ?? false;
  });

  this.primaryCompany$.subscribe((primaryCompany) => {
      this.primaryCompany = primaryCompany ?? null;
      this.primaryCompanyName = this.primaryCompany?.name ?? '';
  });

  this.userId$.subscribe((userId) => {
    this.userId = userId ?? '';
  });

  this.userName$.subscribe((userName) => {
    this.userName = userName ?? '';
    this.setUserInitials();
  });

  this.userEmail$.subscribe((userEmail) => {
    this.userEmail = userEmail ?? '';
  });

  

  if(this.isMTXUser){
    this.syrcPrimaryCompanies$.subscribe((syrcPrimaryCompanies) => {
      this.syrcPrimaryCompanies = syrcPrimaryCompanies;
      if(this.syrcPrimaryCompanies){
        this.selectedSyrcPrimaryCompany = this.syrcPrimaryCompanies[0]?.name ?? '';
      }
    });

  }else{
    this.roles$.subscribe((roles) => {
      this.roles = roles ?? [];
      this.level3Values = this.roles
        .filter((role) => role?.level3Value) 
        .map((role) => role!.level3Value!);
      this.selectedLevel3Value = this.level3Values[0];
    });
  }
  

 }

 setUserInitials() {
  if (this.userName) {
    const nameParts = this.userName.split(' ');
    const initials = nameParts.map(part => part.charAt(0)).join('');
    this.userInitials = initials.toUpperCase();
  }
}

 logout(): void {
  this.oidcSecurityService.logoffAndRevokeTokens().pipe(
    tap(() => this.store.dispatch(new RemoveAuthenticateUser())),
    //tap(() => window.location.href = "https://account.corview.cloud/connect/endsession"),
  ).subscribe({
    error: (e) => console.error('Logoff error:', e),
    complete: () => console.log('Logged off successfully')
  });
}

}
