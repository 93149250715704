import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import {
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_ERROR_MSG,
  UPLOAD_HEADER,
  UPLOAD_SUBHEADER,
  VERIFY_HEADER,
  VERIFY_SUBHEADER,
  SETUP_IMPORT_SEGMENTS,
  EMPTY_STRING,
  SURE_CANCEL_HEADER,
  SURE_CANCEL_MSG,
  FAILED,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  NO,
  SETUP_COMPANY_OVERVIEW,
  RECTIFIER,
  BOND,
  TEST_POINTS,
} from '../../shared/constants';
import { Router } from '@angular/router';
import { SetupSegmentAndAssetsService } from '../../core/services/setup-segment-and-assets.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetupHierarchyState } from '../../core/store/setup-hierarchy.state';
import {
  BondColumnsDataType,
  ColumnsForFileFormat,
  RectifierColumnsDataType,
  TestPointsColumnsDataType,
} from '../../shared/enums';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { AssetType } from '../../../awsAppSync/API';

export interface FileModel {
  name: string;
  isValid: boolean;
}
export interface SegmentAssetModel {
  segmentName: string;
  assetTypeId:string;
  files: FileModel[];
}
@Component({
  selector: 'app-setup-segments-and-assets',
  templateUrl: './setup-segments-and-assets.component.html',
  styleUrl: './setup-segments-and-assets.component.css',
})


export class SetupSegmentsAndAssetsComponent implements OnInit {
  header = VERIFY_HEADER;
  subHeader = VERIFY_SUBHEADER;
  listOfSegmentsAndAssets: SegmentAssetModel[] = [];
  mandatoryColumns: string[] = [];
  columnsForFileFormat: string[] = [];
  currentStep = 4;
  isLoading = false;
  isDisabled = true;
  invalidFileURL = '';
  markBtnVisibility = false;
  uploadedFiles: {name: any; isInvalid?: boolean}[][] = [];
  level3Name$: Observable<string>;
  level4Name$: Observable<string>;
  storeLevel3Name: string = EMPTY_STRING;
  storeLevel4Name: string = EMPTY_STRING;
  fileUploaded = false;
  primaryCompanyId$: Observable<string | null>;
  storePrimaryCompanyid: string | null = EMPTY_STRING;

  constructor(
    private store: Store,
    private toastr: CustomToastrService,
    private router: Router,
    private setupSegmentAndAssetsService: SetupSegmentAndAssetsService,
    private popupDialogService: PopupDialogService,
    private hierarchyFilterService: HeirarchyFilterServiceService,
    private cdr: ChangeDetectorRef
  ) {
    this.level3Name$ = this.store.select(SetupHierarchyState.getLevel3Name);
    this.level4Name$ = this.store.select(SetupHierarchyState.getLevel4Name);
    this.primaryCompanyId$ = this.store.select(
      SetupHierarchyState.getPrimaryCompanyId,
    );
  }

  async ngOnInit(): Promise<void> {
    
    this.primaryCompanyId$.subscribe((id) => {
      this.storePrimaryCompanyid = id;
    });

    this.level3Name$.subscribe((level3Name) => {
      this.storeLevel3Name = level3Name;
    });
    this.level4Name$.subscribe((level4Name) => {
      this.storeLevel4Name = level4Name;
    });
    this.intializeMandatoryColumn();

    await this.getAllAssetTypesByPrimaryCompany(this.storePrimaryCompanyid!);

    if(!this.setupSegmentAndAssetsService.isFreshRoute){
      if(this.setupSegmentAndAssetsService.uploadedFiles.length > 0){
        if (this.setupSegmentAndAssetsService.invalidFileUrl) {
          this.listOfSegmentsAndAssets.forEach((segment) => {
            if (segment.segmentName === this.setupSegmentAndAssetsService.assetType) {
              if (this.setupSegmentAndAssetsService.uploadedFiles.length > 0) {
                this.setupSegmentAndAssetsService.uploadedFiles.forEach((uploadedFileArray, _index) => {
                  if (uploadedFileArray && uploadedFileArray.length > 0) {
                    segment.files = uploadedFileArray.map((file: FileModel) => ({
                      ...file,
                      isValid: (this.setupSegmentAndAssetsService.fileName === file.name) ? false : file.isValid, 
                    }));
                  }
                });
              }
            }
          });
          this.isDisabled = true;
          this.invalidFileURL = this.setupSegmentAndAssetsService.invalidFileUrl;
        }
        else{
          this.listOfSegmentsAndAssets.forEach((segment) => {
            if (segment.segmentName === this.setupSegmentAndAssetsService.assetType) {
              if (this.setupSegmentAndAssetsService.uploadedFiles.length > 0) {
                this.setupSegmentAndAssetsService.uploadedFiles.forEach((uploadedFileArray, _index) => {
                  if (uploadedFileArray && uploadedFileArray.length > 0) {
                    segment.files = uploadedFileArray.map((file: FileModel) => ({
                      ...file,
                      isValid: (this.setupSegmentAndAssetsService.fileName === file.name) ? true : file.isValid,
                    }));
                  }
                });
              }
            }
          });
          this.isDisabled = false;
          
        }
        this.markBtnVisibility = true;
        this.setupSegmentAndAssetsService.isFreshRoute = true;
      }
    }
  }
  
  downloadInvalidFile(): void {
    const link = document.createElement('a');
    link.href = this.invalidFileURL;
    link.download = ''; 
    link.click();
  }
  
  
  intializeMandatoryColumn() {
    this.mandatoryColumns = [
      this.storeLevel3Name,
      this.storeLevel4Name,
      this.storeLevel4Name + ' Type',
      'Asset Name',
      'Primary Seg? (Y/N)',
      'Longitude',
      'Latitude',
      'Milestone',
      'Creation Date',
    ];
  }

  async getAllAssetTypesByPrimaryCompany(id: string) {
    this.isLoading = true;
    
    try {
      const response = await this.hierarchyFilterService.getAllAssetTypesByPrimaryCompany(id);
      
      if (response && response.items) {
        // Clear the current list before adding new segments
        this.listOfSegmentsAndAssets = [];
  
        response.items.forEach((item: AssetType | null) => {
          if (item && item.assetType) {
            // Add the assetType (segment name) and initialize an empty file array
            const newSegment: SegmentAssetModel = {
              segmentName: item.assetType,
              assetTypeId: item.id, // assuming assetType is a string representing the segment name
              files: [] // Initialize empty files array for the segment
            };
            
            this.listOfSegmentsAndAssets.push(newSegment);
          }
        });
  
        // Since we no longer use uploadedFiles separately, no need to map them here
        this.isLoading = false;
      } else {
        console.error('No items found in the response');
      }
    } catch (error) {
      console.error('Error fetching asset types:', error);
      this.isLoading = false; // Ensure loading is turned off in case of an error
    }
  }

  nextBtnClick(): void {
    this.markBtnVisibility = true;
    this.header = UPLOAD_HEADER;
    this.subHeader = UPLOAD_SUBHEADER;
  }

  saveAndExitBtnClick(): void {
    this.router.navigate([SETUP_COMPANY_OVERVIEW]);
  }

  onFileSelected(event: any, index: number, value: string, id: string) {
    const fileInput = event.target;
    const files = event.target.files;
    this.setupSegmentAndAssetsService.files = files;
    this.setupSegmentAndAssetsService.currentAssetmandatoryColumns = this.mandatoryColumns;
    const allowedExtensions = ['csv', 'xlsx'];
    this.setupSegmentAndAssetsService.assetType = value;
    this.setupSegmentAndAssetsService.assetTypeId = id;
    // Initialize files array for the segment at the given index if not already
    if (!this.listOfSegmentsAndAssets[index].files) {
      this.listOfSegmentsAndAssets[index].files = [];
    }
  
    for (const file of files) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();
  
        reader.onload = (e: any) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
  
          const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const recordCount = jsonSheet.length;
  
          // Check for record limit
          if (recordCount > 10000) {
            this.toastr.showError(
              'File contains more than 10K records. Please upload a smaller file.',
              'File Upload Error',
            );
            fileInput.value = '';
            return;
          }
  
          let fileHeaders = jsonSheet[0] as string[];
          fileHeaders = fileHeaders.map((column) => column.replace('*', ''));
  
          // Check for missing mandatory columns
          const missingColumns = this.mandatoryColumns.filter(
            (column) => !fileHeaders.includes(column)
          );
  
          if (missingColumns.length > 0) {
            const missingColumnsStr = missingColumns.join(', ');
            this.toastr.showError(
              `Missing mandatory columns: ${missingColumnsStr}`,
              'File Upload Error',
            );
            fileInput.value = '';
            return;
          }
  
          // Check for missing data in mandatory columns
          const missingDataRows: number[] = [];
          jsonSheet.slice(1).forEach((row: any, rowIndex: number) => {
            const rowArray = row as any[];
            this.mandatoryColumns.forEach((column) => {
              const columnIndex = fileHeaders.indexOf(column);
              if (
                columnIndex !== -1 &&
                (!rowArray[columnIndex] ||
                  rowArray[columnIndex].toString().trim() === '')
              ) {
                missingDataRows.push(rowIndex + 2);
              }
            });
          });
  
          if (missingDataRows.length > 0) {
            const missingDataRowsStr = missingDataRows.join(', ');
            this.toastr.showError(
              `Missing data in mandatory columns at rows: ${missingDataRowsStr}`,
              'File Upload Error',
            );
            fileInput.value = '';
            return;
          }

          const primarySegColumnIndex = fileHeaders.indexOf('Primary Seg? (Y/N)');
          if (primarySegColumnIndex !== -1) {
            const invalidRows: number[] = [];
            jsonSheet.slice(1).forEach((row: any, rowIndex: number) => {
              const cellValue = row[primarySegColumnIndex];
              if (cellValue !== 'Y' && cellValue !== 'N') {
                invalidRows.push(rowIndex + 2); // +2 because we're showing 1-based row numbers (1 for header)
              }
            });
    
            if (invalidRows.length > 0) {
              const invalidRowsStr = invalidRows.join(', ');
              this.toastr.showError(
                `Invalid values in "Primary Seg? (Y/N)" column at rows: ${invalidRowsStr}. Only "Y" or "N" is allowed.`,
                'File Upload Error',
              );
              fileInput.value = '';
              return;
            }
          }
  
          // Determine column types
          const columnTypes: Record<string, string> = {};
          fileHeaders.forEach((header) => {
            columnTypes[header] = 'Unknown';
          });
  
          fileHeaders.forEach((header, colIndex) => {
            for (let i = 1; i < jsonSheet.length; i++) {
              const row = jsonSheet[i] as any[];
              const cellValue = row[colIndex];
  
              if (cellValue !== undefined && cellValue !== null) {
                const type = typeof cellValue;
  
                if (header.toLowerCase().includes('date')) {
                  columnTypes[header] = 'Date';
                } else if (type === 'string') {
                  if (this.isValidDate(cellValue)) {
                    columnTypes[header] = 'Date';
                  } else if (!isNaN(Number(cellValue))) {
                    columnTypes[header] = 'Number';
                  } else {
                    columnTypes[header] = 'String';
                  }
                } else if (type === 'number') {
                  columnTypes[header] = 'Number';
                } else if (type === 'object') {
                  columnTypes[header] = 'Object';
                } else {
                  columnTypes[header] = 'String';
                }
              }
            }
          });
  
          this.setupSegmentAndAssetsService.columnTypes = columnTypes;

          if (!this.setupSegmentAndAssetsService.uploadedFiles[index]) {
            this.setupSegmentAndAssetsService.uploadedFiles[index] = [];
          }
          
          
          // Add file to the segment's files array
          const newFile: FileModel = {
            name: file.name,
            isValid: true, // Assuming file is valid by default, set as needed
          };

          this.setupSegmentAndAssetsService.fileName = file.name;
  
          this.listOfSegmentsAndAssets[index].files.push(newFile);
          this.setupSegmentAndAssetsService.uploadedFiles[index].push(newFile);
          this.isDisabled = false;
  
          if (this.listOfSegmentsAndAssets[index].files.length > 0) {
            this.router.navigate([SETUP_IMPORT_SEGMENTS]);
          }
        };
  
        reader.onerror = () => {
          this.toastr.showError('Error reading the file.', 'File Upload Error');
        };
  
        reader.readAsBinaryString(file);
      } else {
        this.toastr.showError(FILE_UPLOAD_ERROR_MSG, FILE_UPLOAD_ERROR);
      }
    }
  }
  

  isValidDate(value: string): boolean {
    // Regular expressions to match different date formats
    const mmddyyyyRegex =
      /\b(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/(19|20)\d{2}\b/; // MM/DD/YYYY
    const ddmmyyyyRegex =
      /\b(0?[1-9]|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/(19|20)\d{2}\b/; // DD/MM/YYYY
    const yyyymmddRegex =
      /\b(19|20)\d{2}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])\b/; // YYYY-MM-DD

    // Check if the value matches any of the date formats
    const isMMDDYYYY = mmddyyyyRegex.test(value);
    const isDDMMYYYY = ddmmyyyyRegex.test(value);
    const isYYYYMMDD = yyyymmddRegex.test(value);

    return isMMDDYYYY || isDDMMYYYY || isYYYYMMDD;
  }

  removeFile(segmentIndex: number, fileIndex: number) {
    this.uploadedFiles[segmentIndex].splice(fileIndex, 1);
  }

  onCancel() {
    this.popupDialogService.openDialog(
      SURE_CANCEL_HEADER,
      SURE_CANCEL_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
      true,
      NO,
    );
  }

  downloadFileFormat(value: string) {
    this.intializeMandatoryColumn();
    this.columnsForFileFormat = this.mandatoryColumns.map(
      (column) => column + '*',
    );
    if (value == RECTIFIER) {
      Object.keys(RectifierColumnsDataType).forEach((column) => {
        if (!this.mandatoryColumns.includes(column)) {
          this.columnsForFileFormat.push(column);
        }
      });
    } else if (value == BOND) {
      Object.keys(BondColumnsDataType).forEach((column) => {
        if (!this.mandatoryColumns.includes(column)) {
          this.columnsForFileFormat.push(column);
        }
      });
    } else if (value == TEST_POINTS) {
      Object.keys(TestPointsColumnsDataType).forEach((column) => {
        if (!this.mandatoryColumns.includes(column)) {
          this.columnsForFileFormat.push(column);
        }
      });
    } else {
      Object.keys(ColumnsForFileFormat).forEach((column) => {
        if (!this.mandatoryColumns.includes(column)) {
          this.columnsForFileFormat.push(column);
        }
      });
    }
    this.downloadCSVFile(value);
    this.downloadExcelFile(value);
  }

  downloadCSVFile(value: string) {
    const csvData = this.createCSVData();
    const blob = new Blob([csvData], { type: 'text/csv' });
    this.downloadBlob(blob, value + '.csv');
  }

  createCSVData(): string {
    const headers = this.columnsForFileFormat.join(',') + '\n';
    return headers;
  }

  downloadExcelFile(value: string) {
    const worksheetData = [this.columnsForFileFormat];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, value);

    const excelData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    this.downloadBlob(blob, value + '.xlsx');
  }

  downloadBlob(blob: Blob, filename: string) {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}
