<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          {{ 'ASSET_HISTORY_PAGE.TITLE' | translate }}
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px; margin-right: -10px">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            ></app-search>
          </div>
          <div
            class="col-xs-6 col-sm-auto col-md-auto col-lg-auto"
            *ngIf="showFilterBtn"
          >
            <button
              (click)="toggleFilterTray()"
              class="btn mtx-btn-secondary mtx-font-weight-500"
            >
              <span style="vertical-align: bottom" class="material-icons"
                >filter_alt</span
              >
              {{ 'GENERIC_Buttons.FILTERS' | translate }}
              <span style="vertical-align: bottom" class="material-icons"
                >arrow_drop_down</span
              >
            </button>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <div style="width: 180px">
              <kendo-dropdownlist
                [data]="assetNameLst"
                textField="text"
                valueField="value"
                [(ngModel)]="selectedValue"
                [defaultItem]="defaultItem"
                (valueChange)="onValueChange($event)"
                [filterable]="true"
                (filterChange)="onFilterChange($event)"
              >
              </kendo-dropdownlist>
            </div>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button
              class="btn mtx-btn-primary"
              style="height: 38px"
              (click)="openDialog()"
            >
              <span style="vertical-align: bottom" class="material-icons"
                >download</span
              >
              {{ 'GENERIC_Buttons.EXPORT' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row justify-content-end mb-3"
    style="float: right"
    *ngIf="showHierarchicalFilter"
  >
    <app-heirarchy-filter-buttons
      (filterSearchButtonClicked)="filterSearchButtonClicked()"
      (isTrayCollapsed)="collapseFilterTray()"
    ></app-heirarchy-filter-buttons>
    <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
  </div>
  <div *ngIf="!primaryCompany">
    <div class="card" style="align-items: center">
      <div class="card-body-no-history">
        <h1 style="color: #6c757d">
          {{ 'ASSET_HISTORY.NO_RECORDS_AVAILABLE' | translate }}
        </h1>
      </div>
    </div>
  </div>
  <div *ngIf="primaryCompany">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="d-flex align-items-center">
            <div class="ellipsis">
              <kendo-icon
                class="k-icon k-icon-inline text-muted me-2 elipse"
                name="more-vertical"
                (click)="openDropdownMenu($event)"
              ></kendo-icon>
            </div>
            <div class="d-flex">
              <h6 class="card-title mb-0">
                {{ assetName }}
              </h6>
              <div class="d-flex align-items-center" style="padding-left: 15px">
                <span class="" [ngClass]="statusClass">{{ status }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex" style="margin-bottom: -10px">
          <span
            [ngClass]="{ 'tab-btn': isInformation }"
            style="margin-left: 10px; font-size: 17px; cursor: pointer"
            (click)="informationClicked()"
            >Information</span
          >
          <span
            [ngClass]="{ 'tab-btn': isAssetSetup }"
            style="margin-left: 20px; font-size: 17px; cursor: pointer"
            (click)="assetSetupClicked()"
            >Asset Setup</span
          >
        </div>
        <hr
          *ngIf="isInformation"
          [ngClass]="{ 'tab-btn-line': isInformation }"
        />
        <hr
          *ngIf="isAssetSetup"
          [ngClass]="{ 'tab-btn-line-asset': isAssetSetup }"
        />
        <hr style="color: #3498db; margin-top: 0px" />
        <div *ngIf="isInformation" class="row content-align">
          <div class="col-sm-12 col-md-8 col-lg-8">
            <div class="row">
              <div class="col-3 mb-4">
                <div class="">
                  <span class="text-muted">{{ level1Name }}</span
                  ><br />
                  <strong>{{ business }}</strong>
                </div>
              </div>
              <div class="col-3 mb-4">
                <div class="">
                  <span class="text-muted">{{ level2Name }}</span
                  ><br />
                  <strong>{{ company }}</strong>
                </div>
              </div>
              <div class="col-3 mb-4">
                <span class="text-muted">{{ level3Name }}</span
                ><br />
                <strong>{{ region }}</strong>
              </div>
              <div class="col-3 mb-4">
                <span class="text-muted">{{
                  'ASSET_HISTORY_PAGE.SEGMENT' | translate
                }}</span
                ><br />
                <strong>{{ segment }}</strong>
              </div>
            </div>

            <div class="row">
              <div class="col-3 mb-4">
                <span class="text-muted">{{
                  'ASSET_HISTORY_PAGE.ROUTE' | translate
                }}</span
                ><br />
                <strong>{{ route }}</strong>
              </div>
              <div class="col-3 mb-4">
                <span class="text-muted">{{
                  'ASSET_HISTORY_PAGE.ASSETTYPE' | translate
                }}</span
                ><br />
                <strong>{{ assetType }}</strong>
              </div>
              <div class="col-3 mb-4">
                <span class="text-muted">{{
                  'ASSET_HISTORY_PAGE.DATECREATED' | translate
                }}</span
                ><br />
                <strong>{{ dateCreated }}</strong>
              </div>
            </div>
            <div class="row">
              <div class="col-3 mb-4">
                <span class="text-muted">{{
                  'ASSET_HISTORY_PAGE.TECHRESPONSIBLE' | translate
                }}</span
                ><br />
                <strong>{{ technician }}</strong>
              </div>
              <div class="col-4 mb-4">
                <span class="text-muted">{{
                  'ASSET_HISTORY_PAGE.ATMOSPHERICCORROSIONGRADE' | translate
                }}</span
                ><br />
                <strong>{{ atmosphericCorrosionGrade }}</strong>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="carousel-container d-flex align-items-center">
              <div class="d-flex" style="margin-right: 10px">
                <mat-icon (click)="prevSlide()">navigate_before</mat-icon>
                <div class="oval-shape"></div>
              </div>
              <div id="carouselExample" class="carousel slide">
                <div class="carousel-inner">
                  <div
                    class="carousel-item"
                    *ngFor="let image of images; let i = index"
                    [ngClass]="{ active: i === 0 }"
                  >
                    <img
                      [src]="image"
                      class="d-block carousel-image"
                      alt="Image {{ i + 1 }}"
                    />
                    <div class="image-date">{{ imageDates[i] }}</div>
                    <div class="carousel-indicators" *ngIf="images.length >= 0">
                      <span
                        *ngFor="let img of images; let idx = index"
                        [ngClass]="{ active: idx === i }"
                        class="indicator-dot"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex" style="margin-left: 10px">
                <mat-icon (click)="nextSlide()">navigate_next</mat-icon>
                <div class="oval-shape"></div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isAssetSetup">
          <div class="box-layer">
            <div class="p-3">
              <div
                *ngIf="measurementCriteriaInputs.length > 0"
                class="row"
                style="color: #737373"
              >
                <div class="measurement-width" class="equal-column">
                  <label for="measurementSource">{{
                    'ASSET_HISTORY_PAGE.MeasurementSource' | translate
                  }}</label>
                </div>
                <div class="equal-column">
                  <label for="measurementType">{{
                    'ASSET_HISTORY_PAGE.MeasurementType' | translate
                  }}</label>
                </div>
                <div class="equal-column">
                  <label for="operator">{{
                    'ASSET_HISTORY_PAGE.Operator' | translate
                  }}</label>
                </div>
                <div class="equal-column">
                  <label for="criteria">{{
                    'ASSET_HISTORY_PAGE.Criteria' | translate
                  }}</label>
                </div>
                <div class="equal-column">
                  <label for="segment">{{
                    'ASSET_HISTORY_PAGE.Segment' | translate
                  }}</label>
                </div>
                <div class="equal-column">
                  <label class="no-wrap" for="segmentRelation">{{
                    'ASSET_HISTORY_PAGE.SegmentRelation' | translate
                  }}</label>
                </div>
                <div class="equal-column">
                  <label class="no-wrap" for="complianceMeas">{{
                    'ASSET_HISTORY_PAGE.ComplianceMeasurement' | translate
                  }}</label>
                </div>
              </div>
            </div>

            <div
              *ngFor="
                let measurement of measurementCriteriaInputs;
                let j = index
              "
              class="row p-3"
            >
              <div class="equal-column">
                <span style="color: #525252">{{
                  measurement.measurementSource
                }}</span>
              </div>
              <div class="equal-column">
                <span style="color: #525252">{{
                  measurement.measurementType
                }}</span>
              </div>
              <div class="equal-column">
                <span>{{ measurement.measurementOperator }}</span>
              </div>
              <div class="equal-column">
                <div
                  *ngIf="measurement.measurementOperator === 'between'"
                  class="d-flex"
                >
                  <div class="input-with-unit">
                    <span class="unit">{{ measurement.criteria1 }}</span>
                    <span class="unit" style="color: #525252">{{
                      measurement.unit
                    }}</span>
                  </div>
                  <div class="input-with-unit ms-1">
                    <span class="unit">{{ measurement.criteria2 }}</span>
                    <span class="unit" style="color: #525252">{{
                      measurement.unit
                    }}</span>
                  </div>
                </div>
                <div
                  *ngIf="measurement.measurementOperator !== 'between'"
                  class="input-with-unit"
                >
                  <span class="unit">{{ measurement.criteria }}</span>
                  <span class="unit" style="color: #525252">{{
                    measurement.unit
                  }}</span>
                </div>
              </div>
              <div class="equal-column">
                <span>{{ measurement.segment }}</span>
              </div>
              <div class="equal-column">
                <span>{{ measurement.segmentRelation }}</span>
              </div>
              <div class="equal-column d-flex align-items-center">
                <div class="equal-column d-flex justify-content-center">
                  <input
                    id="compliance-{{ j }}"
                    type="checkbox"
                    class="form-check-input"
                    [disabled]="true"
                    [(ngModel)]="measurement.compliance"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form [formGroup]="dateRangeForm">
        <div class="row" style="justify-content: end; margin-right: 11px">
          <div class="col-md-2">
            <div class="mb-3">
              <div class="label-container">
                <label for="startDate" class="">{{
                  'ASSET_HISTORY_PAGE.StartDate' | translate
                }}</label>
              </div>
              <kendo-datepicker
                formControlName="startDate"
                placeholder="Select Start Date"
                format="dd-MMM-yyyy"
                [(ngModel)]="startDate"
              ></kendo-datepicker>
              <div *ngIf="dateRangeForm.errors?.['dateRangeInvalid']">
                <span class="small text-danger">{{
                  'ASSET_HISTORY_PAGE.START_DATE_ERROR' | translate
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="mb-3">
              <div class="label-container">
                <label for="endDate" class="">{{
                  'ASSET_HISTORY_PAGE.EndDate' | translate
                }}</label>
              </div>
              <kendo-datepicker
                formControlName="endDate"
                placeholder="Select End Date"
                format="dd-MMM-yyyy"
                [(ngModel)]="endDate"
              ></kendo-datepicker>
            </div>
          </div>
        </div>
      </form>

      <div class="card-body-grid">
        <kendo-grid
          #grid
          [data]="gridData"
          [height]="350"
          [resizable]="true"
          [filterable]="'menu'"
          [skip]="state.skip"
          [pageable]="true"
          [pageSize]="state.take"
          [filter]="state.filter"
          (dataStateChange)="dataStateChange($event)"
          class="rounded-grid"
        >
          >
          <kendo-grid-column
            field="date"
            title="Date"
            [width]="40"
            [minResizableWidth]="40"
            filter="date"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.surveyDate | customDate }}
            </ng-template></kendo-grid-column
          >

          <kendo-grid-column
            *ngFor="let column of dynamicColumns"
            [field]="column.field"
            [title]="column.title"
            [width]="50"
            [minResizableWidth]="50"
          >
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>
                {{ dataItem.dynamicValues[column.field] || '' }}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="comment"
            title="Comments"
            [width]="160"
            [minResizableWidth]="150"
          ></kendo-grid-column>
        </kendo-grid>
      </div>
      <app-row-actions
        class="row-action"
        [visible]="dropdownVisible"
        [width]="ellipsisWidth"
        [positionX]="dropdownX"
        [positionY]="dropdownY"
        [inputScreen]="inputScreen"
        [permission] = "permission"
        (optionSelected)="handleDropdownOption($event)"
        (visibleChange)="handleDropdownVisibilityChange($event)"
      ></app-row-actions>
      <div class="card-body-graph">
        <app-asset-history-chart
          [inputStartDate]="startDate"
          [inputEndDate]="endDate"
          [reloadChart]="reloadChart"
        ></app-asset-history-chart>
      </div>
    </div>
  </div>
</div>
