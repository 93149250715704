import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { saveAs } from '@progress/kendo-file-saver';
import { ExportModalComponent } from '../export-modal/export-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommunicationService } from '../service/communication/communication.service';
import { PopupDialogService } from '../popup-dialog/popup-dialog.service';
import {
  SUCCESS,
  CONNECTION,
  FAILED,
  EXCEPTION,
  INSPECTION,
  VALIDDATAOPTION,
  FORMATANDOPTION,
  VALIDFORMAT,
  INITIATED,
  NETWORK,
  NETWORKMSG,
  FAILURE,
  FAILUREMSG,
  DATEFORTMAT1,
  DATEFORTMAT2,
  TEMPUSER,
  ASSETSETUP,
  CLOSE,
  EMPTY_STRING,
  MEASUREMENT_FREQUENCY_TEXT,
  REGION_TEXT,
  SEGMENT_TEXT,
  ASSET_NAME_EXCEPTION,
  ASSET_TYPE_EXCEPTION,
  GPS_COORDINATE,
  GPS_COORDINATE_TEXT,
  ASSETHISTORY,
  DATE_TEXT,
  COMMENTS,
  WARNING,
  INFO,
  SURVEYOVERVIEW,
  SURVEYNAME,
  SURVEYNAME_TEXT,
  TECHNICIAN,
  DATECOLLECTION,
  DATECOLLECTION_TEXT,
  SUPERVISOR,
  DATEOFCOMPLETION,
  DATEOFCOMPLETION_TEXT,
  SURVEYDESCRIPTION,
  SURVEYDESCRIPTION_TEXT,
  DUEDATE,
  DUEDATE_TEXT,
  SURVEYSTATUS,
  SURVEYSTATUS_TEXT,
  SURVEYPRIORITY,
  SURVEYPRIORITY_TEXT,
  SURVEYTYPE,
  SURVEYTYPE_TEXT,
  PDF_EXPORT_FAILED_MESG,
  GRID_DATA_CSV,
  GRID_DATA_PDF,
  CSV_TYPE,
  REPORTDETAILS,
  EXCEL_EXPORT_FAILED_MESG,
  ASSET_TYPE_TEXT,
  ASSET_NAME_TEXT,
  ASSET_TYPE,
  ASSET_NAME,
  COMPLAINCE_STATUS,
  COMPLAINCE_STATUS_TEXT,
  INSPECTION_TARGET_DATE,
  INSPECTION_TARGET_DATE_TEXT,
  LAST_INSPECTION_DATE,
  LAST_INSPECTION_DATE_TEXT,
  ASSET_DELINQUENCY_DATE,
  ASSET_DELINQUENCY_DATE_TEXT,
  TECHNICIAN_TEXT,
  DAYS_UNTIL_DELINQUENT_TEXT,
  DAYS_UNTIL_DELINQUENT,
  WORKFLOW_STATUS_TEXT,
  SPECIALIST_TEXT,
} from '../../shared/constants';
import { NotificationService } from '../notification/notification.service';
import { format, isToday } from 'date-fns';
import { AssetHistoryService } from '../../core/services/asset-history.service';
import { CustomToastrService } from '../ngx-toastr/custom-toastr.service';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { ExportService } from '../../core/services/export-service';
import { DataExportInput } from '../../../awsAppSync/API';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Store } from '@ngxs/store';
import { LevelNameState } from '../../core/store/filtet-levelName.state';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrl: './export.component.css',
})
export class ExportComponent implements OnChanges, OnInit , OnDestroy {
  @Input() gridData: any[] = [];
  @Input() allData: any[] = [];
  @Input() flag = '';
  @Input() filteredGridridData: any[] = [];
  @Input() pageSize: any;
  @Input() selectedColumns: any[] = [];
  @Input() selectedColumnsForExcel: any[] = [];
  @Input() isVisibleColumns = true;
  @Input() filters :any;
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  @ViewChild('excelExport', { static: false })
  excelExport!: ExcelExportComponent;
  selectedFormat = '';
  selectedOption = '';
  filename = '';
  filesize = 0;
  exportVisibleDataOnly = false;
  exportCount = 0;
  filteredGridData: any[] = [];
  public dynamicColumns: any[] = [];
  public arraySubject = new BehaviorSubject<any[]>([]);
  pageSizeForExport = 0;
  isVisibleDisabled = false;
  selectedButton = '';
  isFromReportDetails = false;
  storeLevelName$: Observable<string[]>;
  selectedLevelName: string[] = [];
  level1Name = '';
  level2Name = '';
  level3Name = '';
  loggedInSyrcUserId: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isVisibleColumns']) {
      this.isVisibleDisabled = this.isVisibleColumns;
    }
  }

  constructor(
    private dialogRef: MatDialogRef<ExportModalComponent>,
    private notificationService: NotificationService,
    public communicate: CommunicationService,
    private cdr: ChangeDetectorRef,
    private popupDialogService: PopupDialogService,
    private assetHistoryService: AssetHistoryService,
    private toastr: CustomToastrService,
    private allExportService : ExportService,
    private store: Store
  ) {
    this.storeLevelName$ = this.store.select(LevelNameState.getLevelName);
    this.loggedInSyrcUserId = this.store.selectSnapshot(AuthenticateUserState.getSyrcUserId) ?? "";
  }
  ngOnDestroy(): void {
    this.flag = "";
  }

  ngOnInit() {
    this.isFromReportDetails = this.flag == REPORTDETAILS ? true : false;
    if (this.isFromReportDetails) {
      this.selectedColumnsForExcel = [
        { field: 'surveyName', title: 'Survey Name' },
        { field: 'surveyType', title: 'Survey Type' },
        { field: 'surveyPriority', title: 'Survey Priority' },
        { field: 'surveyStatus', title: 'Survey Status' },
        { field: 'dueDate', title: 'Due Date' },
        { field: 'dataCollectionType', title: 'Data Collection Type' },
        { field: 'technician', title: 'Technician' },
        { field: 'supervisor', title: 'Supervisor' },
      ];
    }
    this.storeLevelName$.subscribe((level1Name) => {
      this.selectedLevelName = level1Name;
      this.generateLevelNames();
    });
  }

  generateLevelNames() {
    if (this.selectedLevelName[0]) {
      this.level1Name = this.selectedLevelName[0];
    }
    if (this.selectedLevelName[1]) {
      this.level2Name = this.selectedLevelName[1];
    }
    if (this.selectedLevelName[2]) {
      this.level3Name = this.selectedLevelName[2];
    }
    this.cdr.detectChanges();
  }

  selectButton(buttonType: string) {
    this.selectedButton = buttonType;
  }

  isFormatValid(): boolean {
    return (
      this.selectedFormat === 'pdf' ||
      this.selectedFormat === 'csv' ||
      this.selectedFormat === 'json' ||
      this.selectedFormat === 'excel'
    );
  }

  addNewNotification() {
    this.notificationService.addNotification({
      avatar: '',
      name: TEMPUSER,
      fileName: this.filename,
      fileSize: this.formatFileSize(this.filesize),
      timestamp: this.formatNotificationDate(new Date()),
      isNew: true,
    });
  }

  public async export() {
    if (
      (!this.selectedFormat || !this.selectedOption) &&
      !this.isFromReportDetails
    ) {
      this.toastr.showInfo(FORMATANDOPTION, INFO);
      return;
    }

    this.popupDialogService.openDialog(
      INITIATED,
      `The export of the Current page in ${this.selectedFormat.toUpperCase()} file is initiated. You'll be notified when it's ready to download.`,
      SUCCESS,
      CLOSE,
    );

    if (!this.checkInternetConnection()) {
      return;
    }

    if(this.selectedOption == "all"){
      const exportModel: DataExportInput = {
        gridName: this.flag,
        fileType: this.selectedFormat,  
        filters: JSON.stringify(this.filters),
        columns: this.selectedColumns.join(","),
        userId:  this.loggedInSyrcUserId,
      };
      const result = await this.allExportService.initializeDataExport(exportModel);
      if (result) {
        console.log("Data export initialized successfully:", result);
        
      } else {
        console.error("Failed to initialize data export.");
      }

    }
    
    else{

    let dataToExport = this.gridData;
    switch (this.selectedOption) {
      // case 'all':
      //   //dataToExport = this.gridData;
      //   dataToExport = this.exportVisibleDataOnly
      //     ? this.filteredGridridData
      //     : this.allData;
      //   break;
      case 'current':
        dataToExport = this.getCurrentPageData();
        break;
      case 'numberofrecords':
        dataToExport = this.exportVisibleDataOnly
          ? this.filteredGridridData.slice(0, this.exportCount)
          : this.gridData.slice(0, this.exportCount);
        break;
      default:
        if (!this.isFromReportDetails) {
          this.toastr.showWarning(VALIDDATAOPTION, WARNING);
          return;
        }
        break;
    }

    switch (this.selectedFormat) {
      case 'csv':
        if (this.flag === INSPECTION) {
          await this.onCSVExportForInspection(dataToExport);
        } else if (this.flag === EXCEPTION) {
          await this.onCSVExportForException(dataToExport);
        } else if (this.flag === ASSETSETUP) {
          await this.onCSVExportForAssetSetup(dataToExport);
        } else if (this.flag === ASSETHISTORY) {
          await this.onCSVExportForAssetHistory(dataToExport);
        } else if (this.flag === SURVEYOVERVIEW) {
          await this.onCSVExportForSurveyOverview(dataToExport);
        } else if (this.flag === REPORTDETAILS) {
          await this.onCSVExportForReportDetail(dataToExport);
        }

        break;
      case 'pdf':
        if (this.flag === INSPECTION) {
          await this.onPDFExportInspection(dataToExport);
        } else if (this.flag === EXCEPTION) {
          await this.onPDFExportException(dataToExport);
        } else if (this.flag === ASSETSETUP) {
          await this.onPDFExportAssetSetup(dataToExport);
        } else if (this.flag === ASSETHISTORY) {
          await this.onPDFExportAssetHistory(dataToExport);
        } else if (this.flag === SURVEYOVERVIEW) {
          await this.onPDFExportSurveyOverview(dataToExport);
        } else if (this.flag === REPORTDETAILS) {
          await this.onPDFExportReportDetail(dataToExport);
        }
        break;
      case 'json':
        await this.onJSONExport(dataToExport);
        break;
      case 'excel':
        if (this.flag === REPORTDETAILS) {
          await this.onExcelExportReportDetail();
        }
        break;
      default:
        this.toastr.showWarning(VALIDFORMAT, WARNING);
    }
  }
  }

  public formatNotificationDate(date: Date): string {
    if (isToday(date)) {
      return `Today at ${format(date, DATEFORTMAT2)}`;
    } else {
      return `${format(date, DATEFORTMAT1)} at ${format(date, DATEFORTMAT2)}`;
    }
  }

  public checkInternetConnection(): boolean {
    if (!navigator.onLine) {
      this.popupDialogService.openDialog(
        NETWORK,
        NETWORKMSG,
        CONNECTION,
        CLOSE,
      );
      this.popupDialogService.openDialog(FAILURE, FAILUREMSG, FAILED, CLOSE);
      return false;
    }
    return true;
  }

  public async onCSVExportForInspection(data: any[]): Promise<void> {
    const csvData = data.map((item) => {
      const mappedItem: any = {};
      console.log(data);
      const dynamicKeys = new Set<string>();

      data.forEach((item) => {
        if (item.dynamicValues) {
          Object.keys(item.dynamicValues).forEach((key) => {
            dynamicKeys.add(key);
          });
        }
      });

      // Convert Set to Array for easier use
      const uniqueDynamicKeys = Array.from(dynamicKeys);

      if (this.selectedColumns.includes(ASSET_TYPE)) {
        mappedItem[ASSET_TYPE_TEXT] =
          item.assetType == null || item.assetType == undefined
            ? EMPTY_STRING
            : item.assetType;
      }
      if (this.selectedColumns.includes(ASSET_NAME)) {
        mappedItem[ASSET_NAME_TEXT] =
          item.assetName == null || item.assetName == undefined
            ? EMPTY_STRING
            : item.assetName;
      }
      if (this.selectedColumns.includes(COMPLAINCE_STATUS)) {
        mappedItem[COMPLAINCE_STATUS_TEXT] =
          item.complianceStatus == null || item.complianceStatus == undefined
            ? EMPTY_STRING
            : item.complianceStatus;
      }
      if (this.selectedColumns.includes(DAYS_UNTIL_DELINQUENT)) {
        mappedItem[DAYS_UNTIL_DELINQUENT_TEXT] =
          item.daysUntilDelinquent == null ||
          item.daysUntilDelinquent == undefined
            ? EMPTY_STRING
            : item.daysUntilDelinquent;
      }
      if (this.selectedColumns.includes(INSPECTION_TARGET_DATE)) {
        mappedItem[INSPECTION_TARGET_DATE_TEXT] = item.inspectionTargetDate
          ? `${new Date(item.inspectionTargetDate).toLocaleDateString('en-GB')}`
          : EMPTY_STRING;
      }
      if (this.selectedColumns.includes(LAST_INSPECTION_DATE)) {
        mappedItem[LAST_INSPECTION_DATE_TEXT] = item.lastInspectionDate
          ? `${new Date(item.lastInspectionDate).toLocaleDateString('en-GB')}`
          : EMPTY_STRING;
      }
      if (this.selectedColumns.includes(ASSET_DELINQUENCY_DATE)) {
        mappedItem[ASSET_DELINQUENCY_DATE_TEXT] = item.assetDelinquencyDate
          ? `${new Date(item.assetDelinquencyDate).toLocaleDateString('en-GB')}`
          : EMPTY_STRING;
      }
      if (this.selectedColumns.includes(TECHNICIAN)) {
        mappedItem[TECHNICIAN_TEXT] =
          item.technician == null || item.technician == undefined
            ? EMPTY_STRING
            : item.technician;
      }

      uniqueDynamicKeys.forEach((key) => {
        mappedItem[key] =
          item.dynamicValues && item.dynamicValues[key]
            ? item.dynamicValues[key]
            : '';
      });
      return mappedItem;
    });
    const csvContent = this.convertToCSV(csvData);
    const blob = new Blob([csvContent], { type: CSV_TYPE });
    this.filename = GRID_DATA_CSV;
    this.filesize = blob.size;
    saveAs(blob, this.filename);
    this.addNewNotification();
  }

  public formatFileSize(bytes: number): string {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    if (mb >= 1) {
      return `${mb.toFixed(2)} MB`;
    } else {
      return `${kb.toFixed(2)} KB`;
    }
  }

  public async onCSVExportForException(data: any[]): Promise<void> {
    const csvData = data.map((item) => {
      const mappedItem: any = {};
      console.log(data);
      const dynamicKeys = new Set<string>();

      data.forEach((item) => {
        if (item.dynamicValues) {
          Object.keys(item.dynamicValues).forEach((key) => {
            dynamicKeys.add(key);
          });
        }
      });

      // Convert Set to Array for easier use
      const uniqueDynamicKeys = Array.from(dynamicKeys);

      if (this.selectedColumns.includes(ASSET_TYPE)) {
        mappedItem[ASSET_TYPE_TEXT] =
          item.assetType == null || item.assetType == undefined
            ? EMPTY_STRING
            : item.assetType;
      }
      if (this.selectedColumns.includes(ASSET_NAME)) {
        mappedItem[ASSET_NAME_TEXT] =
          item.assetName == null || item.assetName == undefined
            ? EMPTY_STRING
            : item.assetName;
      }
      if (this.selectedColumns.includes('targetRemediationDate')) {
        mappedItem['Target Remediation Date'] = item.targetRemediationDate
          ? `${new Date(item.targetRemediationDate).toLocaleDateString('en-GB')}`
          : EMPTY_STRING;
      }
      if (this.selectedColumns.includes('assetDelinquencyDate')) {
        mappedItem['Asset Delinquency Date'] = item.assetDelinquencyDate
          ? `${new Date(item.assetDelinquencyDate).toLocaleDateString('en-GB')}`
          : EMPTY_STRING;
      }
      if (this.selectedColumns.includes('workFlowStatus')) {
        mappedItem['Workflow Status'] =
          item.workFlowStatus == null || item.workFlowStatus == undefined
            ? EMPTY_STRING
            : item.workFlowStatus;
      }
      if (this.selectedColumns.includes('comments')) {
        mappedItem['Comments'] =
          item.comments == null || item.comments == undefined
            ? EMPTY_STRING
            : item.comments;
      }
      if (this.selectedColumns.includes('technician')) {
        mappedItem['Technician'] =
          item.technician == null || item.technician == undefined
            ? EMPTY_STRING
            : item.technician;
      }
      if (this.selectedColumns.includes('specialist')) {
        mappedItem['Specialist'] =
          item.specialist == null || item.specialist == undefined
            ? EMPTY_STRING
            : item.specialist;
      }
      // if (item.rootCause !== undefined && item.rootCause !== null) {
      //   const rootCauseValue = Array.isArray(item.rootCause)
      //     ? item.rootCause.join(', ')
      //     : item.rootCause;
      //   mappedItem['Root Cause'] = `"${rootCauseValue}"`;
      // }
      uniqueDynamicKeys.forEach((key) => {
        mappedItem[key] =
          item.dynamicValues && item.dynamicValues[key]
            ? item.dynamicValues[key]
            : '';
      });
      return mappedItem;
    });

    const csvContent = this.convertToCSV(csvData);
    const blob = new Blob([csvContent], { type: CSV_TYPE });
    this.filename = GRID_DATA_CSV;
    this.filesize = blob.size;
    saveAs(blob, this.filename);
    this.addNewNotification();
  }

  public async onPDFExportInspection(data: any[]): Promise<void> {
    this.communicate.data = [];
    this.communicate._selectedColumns = [];
    this.cdr.markForCheck();
    this.communicate.data = []; // Pass only the mapped data
    this.communicate._selectedColumns = [];
    if (this.pdfExport) {
      // Step 1: Gather unique dynamic column keys
      const dynamicKeys = new Set<string>();
      data.forEach((item) => {
        if (item.dynamicValues) {
          Object.keys(item.dynamicValues).forEach((key) => {
            dynamicKeys.add(key);
          });
        }
      });

      // Convert Set to Array for easier use
      const uniqueDynamicKeys = Array.from(dynamicKeys);
      const allColumns = [
        ASSET_TYPE,
        ASSET_NAME,
        COMPLAINCE_STATUS,
        DAYS_UNTIL_DELINQUENT,
        INSPECTION_TARGET_DATE,
        LAST_INSPECTION_DATE,
        ASSET_DELINQUENCY_DATE,
        TECHNICIAN,
      ];

      // Step 2: Determine which columns to export based on the exportVisibleDataOnly flag
      let columnsToExport: any[];
      if (this.exportVisibleDataOnly) {
        columnsToExport = [...this.selectedColumns, ...uniqueDynamicKeys];
      } else {
        columnsToExport = [...allColumns, ...uniqueDynamicKeys];
      }

      // Step 3: Map data to include only the selected columns or both static and dynamic

      const pdfData = data.map((item) => {
        const mappedItem: any = {};

        // Create a mapping of selected columns to their corresponding text constants
        const columnMapping: Record<string, string> = {
          assetType: ASSET_TYPE_TEXT,
          assetName: ASSET_NAME_TEXT,
          complianceStatus: COMPLAINCE_STATUS_TEXT,
          daysUntilDelinquent: DAYS_UNTIL_DELINQUENT_TEXT,
          inspectionTargetDate: INSPECTION_TARGET_DATE_TEXT,
          lastInspectionDate: LAST_INSPECTION_DATE_TEXT,
          assetDelinquencyDate: ASSET_DELINQUENCY_DATE_TEXT,
          technician: TECHNICIAN_TEXT,
        };
        // Iterate over selected columns and map them to their constants
        for (const column of columnsToExport) {
          if (columnMapping[column]) {
            mappedItem[columnMapping[column]] = item[column] ?? EMPTY_STRING;
          }
        }

        // Map dynamic keys
        uniqueDynamicKeys.forEach((key) => {
          mappedItem[key] = item.dynamicValues?.[key] ?? '';
        });

        return mappedItem;
      });

      // Step 4: Set the mapped data for export
      this.communicate.data = pdfData; // Pass only the mapped data
      this.communicate._selectedColumns = columnsToExport;
      this.communicate.flag = INSPECTION;
      this.cdr.detectChanges();
      this.filename = GRID_DATA_PDF;
      this.pdfExport.saveAs(this.filename);
      this.addNewNotification();
    } else {
      console.error(PDF_EXPORT_FAILED_MESG);
    }
  }

public async onPDFExportException(data: any[]): Promise<void> {
  this.communicate.data = [];
  this.communicate._selectedColumns =[];
  this.cdr.markForCheck();
  this.communicate.data =[]// Pass only the mapped data
  this.communicate._selectedColumns = [];
  if (this.pdfExport) {
      // Step 1: Gather unique dynamic column keys
      const dynamicKeys = new Set<string>();
      data.forEach(item => {
          if (item.dynamicValues) {
              Object.keys(item.dynamicValues).forEach(key => {
                  dynamicKeys.add(key);
              });
          }
      });

      // Convert Set to Array for easier use
      const uniqueDynamicKeys = Array.from(dynamicKeys);
      const allColumns = [
        "assetType",
        "assetName",
        "targetRemediationDate",
        "assetDelinquencyDate",
        "workFlowStatus",
        "comments",
        "technician",
        "specialist"
    ];

      // Step 2: Determine which columns to export based on the exportVisibleDataOnly flag
      let columnsToExport: any[] ;
      if(this.exportVisibleDataOnly){
        columnsToExport =  [...this.selectedColumns, ...uniqueDynamicKeys];
      }
      else{
        columnsToExport =  [...allColumns, ...uniqueDynamicKeys];
      }
     

      // Step 3: Map data to include only the selected columns or both static and dynamic
     
      const pdfData = data.map((item) => {
          const mappedItem: any = {};

          // Create a mapping of selected columns to their corresponding text constants
          const columnMapping: Record<string, string> = {
            "assetType" : ASSET_TYPE_TEXT,
            "assetName" : ASSET_NAME_TEXT,
            "targetRemediationDate" : "Target Remediation Date",
            "assetDelinquencyDate" : ASSET_DELINQUENCY_DATE_TEXT,
            "workFlowStatus" : WORKFLOW_STATUS_TEXT,
            "comments" : "Comments",
            "technician" : TECHNICIAN_TEXT,
            "specialist" : SPECIALIST_TEXT,
        };
          // Iterate over selected columns and map them to their constants
          for (const column of columnsToExport) {
              if (columnMapping[column]) {
                  mappedItem[columnMapping[column]] = item[column] ?? EMPTY_STRING;
              }
          }

          // Map dynamic keys
          uniqueDynamicKeys.forEach(key => {
              mappedItem[key] = item.dynamicValues?.[key] ?? '';
          });

          return mappedItem;
      });

      // Step 4: Set the mapped data for export
      this.communicate.data = pdfData; // Pass only the mapped data
      this.communicate._selectedColumns = columnsToExport;
      this.communicate.flag = EXCEPTION;
      this.cdr.detectChanges();
      this.filename = GRID_DATA_PDF;
      this.pdfExport.saveAs(this.filename);
      this.addNewNotification();
   
      
  } else {
      console.error(PDF_EXPORT_FAILED_MESG);
  }
}


  public async onPDFExportAssetSetup(data: any[]): Promise<void> {
    this.communicate.data = [];
    this.communicate._selectedColumns = [];
    this.cdr.markForCheck();
    this.communicate.data = []; // Pass only the mapped data
    this.communicate._selectedColumns = [];
    if (this.pdfExport) {
      const allColumns = [
        'level1Value',
        'level2Value',
        'level3Value',
        'level4Value',
        'route',
        'segmentRelation',
        'assetName',
        'assetType',
        'measurementFrequency',
        'gpsCoordinate',
        'assetDelinquencyDate',
      ];

      let columnsToExport: any[];
      if (this.exportVisibleDataOnly) {
        columnsToExport = [...this.selectedColumns];
      } else {
        columnsToExport = [...allColumns];
      }

      const pdfData = data.map((item) => {
        const mappedItem: any = {};
        const columnMapping: Record<string, string> = {
        'level1Value' : this.level1Name,
        'level2Value' : this.level2Name,
        'level3Value': this.level3Name,
        'level4Value': SEGMENT_TEXT,
        'route': 'Route',
        'segmentRelation': 'Segment Relation',
        'assetName': ASSET_NAME_TEXT,
        'assetType': ASSET_TYPE_TEXT,
        'measurementFrequency': MEASUREMENT_FREQUENCY_TEXT,
        'gpsCoordinate': GPS_COORDINATE_TEXT,
        'assetDelinquencyDate': ASSET_DELINQUENCY_DATE_TEXT,
        };
        for (const column of columnsToExport) {
          if (columnMapping[column]) {
            mappedItem[columnMapping[column]] = item[column] ?? EMPTY_STRING;
          }
        }
        return mappedItem;
      });

      console.log(pdfData);

      // Step 4: Set the mapped data for export
      this.communicate.data = pdfData; // Pass only the mapped data
      this.communicate._selectedColumns = columnsToExport;
      this.communicate.flag = ASSETSETUP;
      this.cdr.detectChanges();
      this.filename = GRID_DATA_PDF;
      this.pdfExport.saveAs(this.filename);
      this.addNewNotification();
    }
  }

  public async onCSVExportForAssetSetup(data: any[]): Promise<void> {
    const csvData = data.map((item) => {
      const mappedItem: any = {};

      if (this.selectedColumns.includes('level1Value')) {
        mappedItem['Business Unit'] =
          item.level1Value == null || item.level1Value == undefined
            ? EMPTY_STRING
            : item.level1Value;
      }
      if (this.selectedColumns.includes('level2Value')) {
        mappedItem['Asset Owner'] =
          item.level2Value == null || item.level2Value == undefined
            ? EMPTY_STRING
            : item.level2Value;
      }
      if (this.selectedColumns.includes('level3Value')) {
        mappedItem[REGION_TEXT] =
          item.level3Value == null || item.level3Value == undefined
            ? EMPTY_STRING
            : item.level3Value;
      }
      if (this.selectedColumns.includes('level4Value')) {
        mappedItem[SEGMENT_TEXT] =
          item.level4Value == null || item.level4Value == undefined
            ? EMPTY_STRING
            : item.level4Value;
      }
      if (this.selectedColumns.includes('route')) {
        mappedItem['Route'] =
          item.route == null || item.route == undefined
            ? EMPTY_STRING
            : item.route;
      }
      if (this.selectedColumns.includes('segmentRelation')) {
        mappedItem['Segment Relation'] =
          item.segmentRelation == null || item.segmentRelation == undefined
            ? EMPTY_STRING
            : item.segmentRelation;
      }
      if (this.selectedColumns.includes(ASSET_NAME_EXCEPTION)) {
        mappedItem[ASSET_NAME_TEXT] =
          item.assetName == null || item.assetName == undefined
            ? EMPTY_STRING
            : item.assetName;
      }
      if (this.selectedColumns.includes(ASSET_TYPE_EXCEPTION)) {
        mappedItem[ASSET_TYPE_TEXT] =
          item.assetType == null || item.assetType == undefined
            ? EMPTY_STRING
            : item.assetType;
      }
      if (this.selectedColumns.includes('measurementFrequency')) {
        mappedItem[MEASUREMENT_FREQUENCY_TEXT] =
          item.measurementFrequency == null ||
          item.measurementFrequency == undefined
            ? EMPTY_STRING
            : `"${item.measurementFrequency}"`;
      }
      if (this.selectedColumns.includes(GPS_COORDINATE)) {
        if (item.gpsCoordinate !== undefined && item.gpsCoordinate !== null) {
          mappedItem[GPS_COORDINATE_TEXT] =  item.gpsCoordinate;
        }
        else {
          mappedItem[GPS_COORDINATE_TEXT] = " ";
        }
      }
      if (this.selectedColumns.includes('assetDelinquencyDate')) {
        mappedItem['Asset Delinquency Date'] =
          item.assetDelinquencyDate == null ||
          item.assetDelinquencyDate == undefined
            ? EMPTY_STRING
            : item.assetDelinquencyDate;
      }
      return mappedItem;
    });

    const csvContent = this.convertToCSV(csvData);
    const blob = new Blob([csvContent], { type: CSV_TYPE });
    this.filename = GRID_DATA_CSV;
    this.filesize = blob.size;
    saveAs(blob, this.filename);
    this.addNewNotification();
  }

  public async onPDFExportAssetHistory(data: any[]): Promise<void> {
    this.communicate.data = [];
    this.communicate._selectedColumns = [];
    this.cdr.markForCheck();
    this.communicate.data = []; // Pass only the mapped data
    this.communicate._selectedColumns = [];

    if (this.pdfExport) {
      const dynamicKeys = new Set<string>();
      data.forEach((item) => {
        if (item.dynamicValues) {
          Object.keys(item.dynamicValues).forEach((key) => {
            dynamicKeys.add(key);
          });
        }
      });

      const uniqueDynamicKeys = Array.from(dynamicKeys);
      const allColumns = [DATE_TEXT, COMMENTS];
      let columnsToExport: any[];
      if (this.exportVisibleDataOnly) {
        columnsToExport = [...this.selectedColumns, ...uniqueDynamicKeys];
      } else {
        columnsToExport = [...allColumns, ...uniqueDynamicKeys];
      }
      const pdfData = data.map((item) => {
        const mappedItem: any = {};

        // Create a mapping of selected columns to their corresponding text constants
        const columnMapping: Record<string, string> = {
          Date: DATE_TEXT,
          Comments: 'Comments',
        };
        // Iterate over selected columns and map them to their constants
        for (const column of columnsToExport) {
          if (columnMapping[column]) {
            if (columnMapping[column] == DATE_TEXT) {
              const date = this.formatDate(item['surveyDate'])
              mappedItem[columnMapping[column]] =date;
            }
            if (columnMapping[column] == 'Comments') {
              mappedItem[columnMapping[column]] = item['comment'];
            }
          }
        }

        // Map dynamic keys
        uniqueDynamicKeys.forEach((key) => {
          mappedItem[key] = item.dynamicValues?.[key] ?? '';
        });

        return mappedItem;
      });
      this.communicate.data = pdfData; // Pass only the mapped data
      this.communicate._selectedColumns = columnsToExport;
      this.communicate.flag = ASSETHISTORY;
      this.cdr.detectChanges();
      this.filename = GRID_DATA_PDF;
      this.pdfExport.saveAs(this.filename);
      this.addNewNotification();
    } else {
      console.error(PDF_EXPORT_FAILED_MESG);
    }
  }

  formatDate(date: string) {
    const dateObj = new Date(date);

    // Extract year, month, and day separately and format as needed
    const year = dateObj.getUTCFullYear();
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = dateObj.getUTCDate().toString().padStart(2, '0');

    return `${day}-${month}-${year}`;
  }

  public async onCSVExportForAssetHistory(data: any[]): Promise<void> {
    const csvData = data.map((item) => {
      const mappedItem: any = {};

      const dynamicKeys = new Set<string>();

      data.forEach((item) => {
        if (item.dynamicValues) {
          Object.keys(item.dynamicValues).forEach((key) => {
            dynamicKeys.add(key);
          });
        }
      });

      const uniqueDynamicKeys = Array.from(dynamicKeys);

      if (this.selectedColumns.includes('surveyDate')) {
        item.surveyDate = this.formatDate(item.surveyDate);
        mappedItem[DATE_TEXT] =
          item.surveyDate == null || item.surveyDate == undefined
            ? EMPTY_STRING
            : item.surveyDate;
      }

      uniqueDynamicKeys.forEach((key) => {
        mappedItem[key] =
          item.dynamicValues && item.dynamicValues[key]
            ? item.dynamicValues[key]
            : '';
      });

      if (this.selectedColumns.includes(COMMENTS.toLowerCase())) {
        mappedItem[COMMENTS] =
          item.comment == null || item.comment == undefined
            ? EMPTY_STRING
            : item.comment;
      }

      return mappedItem;
    });
    const csvContent = this.convertToCSV(csvData);
    const blob = new Blob([csvContent], { type: CSV_TYPE });
    this.filename = GRID_DATA_CSV;
    this.filesize = blob.size;
    saveAs(blob, this.filename);
    this.addNewNotification();
  }

  public async onCSVExportForSurveyOverview(data: any[]): Promise<void> {
    const csvData = data.map((item) => {
      const mappedItem: any = {};

      if (this.selectedColumns.includes(SURVEYNAME)) {
        mappedItem[SURVEYNAME_TEXT] =
          item.surveyName == null || item.surveyName == undefined
            ? EMPTY_STRING
            : item.surveyName;
      }
      if (this.selectedColumns.includes(SURVEYTYPE)) {
        mappedItem[SURVEYTYPE_TEXT] =
          item.surveyType == null || item.surveyType == undefined
            ? EMPTY_STRING
            : item.surveyType;
      }
      if (this.selectedColumns.includes(SURVEYPRIORITY)) {
        mappedItem[SURVEYPRIORITY_TEXT] =
          item.surveyPriority == null || item.surveyPriority == undefined
            ? EMPTY_STRING
            : item.surveyPriority;
      }
      if (this.selectedColumns.includes(SURVEYSTATUS)) {
        mappedItem[SURVEYSTATUS_TEXT] =
          item.surveyStatus == null || item.surveyStatus == undefined
            ? EMPTY_STRING
            : item.surveyStatus;
      }
      if (this.selectedColumns.includes(DUEDATE)) {
        mappedItem[DUEDATE_TEXT] =
          item.dueDate == null || item.dueDate == undefined
            ? EMPTY_STRING
            : item.dueDate;
      }
      if (this.selectedColumns.includes(DUEDATE)) {
        mappedItem[DUEDATE_TEXT] =
          item.dueDate == null || item.dueDate == undefined
            ? EMPTY_STRING
            : item.dueDate;
      }
      if (this.selectedColumns.includes(DATECOLLECTION)) {
        mappedItem[DATECOLLECTION_TEXT] =
          item.dataCollectionType == null ||
          item.dataCollectionType == undefined
            ? EMPTY_STRING
            : item.dataCollectionType;
      }
      if (this.selectedColumns.includes(TECHNICIAN.toLocaleLowerCase())) {
        mappedItem[TECHNICIAN] =
          item.technician == null || item.technician == undefined
            ? EMPTY_STRING
            : item.technician;
      }
      if (this.selectedColumns.includes(SUPERVISOR.toLocaleLowerCase())) {
        mappedItem[SUPERVISOR] =
          item.supervisor == null || item.supervisor == undefined
            ? EMPTY_STRING
            : item.supervisor;
      }
      if (this.selectedColumns.includes(DATEOFCOMPLETION)) {
        mappedItem[DATEOFCOMPLETION_TEXT] =
          item.dateOfCompletion == null || item.dateOfCompletion == undefined
            ? EMPTY_STRING
            : `"${item.dateOfCompletion}"`;
      }
      if (this.selectedColumns.includes(SURVEYDESCRIPTION)) {
        mappedItem[SURVEYDESCRIPTION_TEXT] =
          item.surveyDescription == null || item.surveyDescription == undefined
            ? EMPTY_STRING
            : `"${item.surveyDescription}"`;
      }
      return mappedItem;
    });

    const csvContent = this.convertToCSV(csvData);
    const blob = new Blob([csvContent], { type: CSV_TYPE });
    this.filename = GRID_DATA_CSV;
    this.filesize = blob.size;
    saveAs(blob, this.filename);
    this.addNewNotification();
  }

  public async onPDFExportSurveyOverview(data: any[]): Promise<void> {
    if (this.pdfExport) {
      this.communicate.data = data;
      if (this.exportVisibleDataOnly) {
        this.communicate._selectedColumns = this.selectedColumns;
      } else {
        this.communicate._selectedColumns = this.selectedColumns;
      }
      this.communicate.flag = SURVEYOVERVIEW;
      this.cdr.detectChanges();
      this.filename = GRID_DATA_PDF;
      this.pdfExport.saveAs(this.filename);
      this.addNewNotification();
    } else {
      console.error(PDF_EXPORT_FAILED_MESG);
    }
  }

  public async onCSVExportForReportDetail(data: any[]): Promise<void> {
    const csvData = data.map((item) => {
      const mappedItem: any = {};

      if (this.selectedColumns.includes(SURVEYNAME)) {
        mappedItem[SURVEYNAME_TEXT] =
          item.surveyName == null || item.surveyName == undefined
            ? EMPTY_STRING
            : item.surveyName;
      }
      if (this.selectedColumns.includes(SURVEYTYPE)) {
        mappedItem[SURVEYTYPE_TEXT] =
          item.surveyType == null || item.surveyType == undefined
            ? EMPTY_STRING
            : item.surveyType;
      }
      if (this.selectedColumns.includes(SURVEYPRIORITY)) {
        mappedItem[SURVEYPRIORITY_TEXT] =
          item.surveyPriority == null || item.surveyPriority == undefined
            ? EMPTY_STRING
            : item.surveyPriority;
      }
      if (this.selectedColumns.includes(SURVEYSTATUS)) {
        mappedItem[SURVEYSTATUS_TEXT] =
          item.surveyStatus == null || item.surveyStatus == undefined
            ? EMPTY_STRING
            : item.surveyStatus;
      }
      if (this.selectedColumns.includes(DUEDATE)) {
        mappedItem[DUEDATE_TEXT] =
          item.dueDate == null || item.dueDate == undefined
            ? EMPTY_STRING
            : item.dueDate;
      }
      if (this.selectedColumns.includes(DUEDATE)) {
        mappedItem[DUEDATE_TEXT] =
          item.dueDate == null || item.dueDate == undefined
            ? EMPTY_STRING
            : item.dueDate;
      }
      if (this.selectedColumns.includes(TECHNICIAN.toLocaleLowerCase())) {
        mappedItem[TECHNICIAN] =
          item.technician == null || item.technician == undefined
            ? EMPTY_STRING
            : item.technician;
      }
      if (this.selectedColumns.includes(DATECOLLECTION)) {
        mappedItem[DATECOLLECTION_TEXT] =
          item.dataCollectionType == null ||
          item.dataCollectionType == undefined
            ? EMPTY_STRING
            : item.dataCollectionType;
      }
      if (this.selectedColumns.includes(SUPERVISOR.toLocaleLowerCase())) {
        mappedItem[SUPERVISOR] =
          item.supervisor == null || item.supervisor == undefined
            ? EMPTY_STRING
            : item.supervisor;
      }
      if (this.selectedColumns.includes(DATEOFCOMPLETION)) {
        mappedItem[DATEOFCOMPLETION_TEXT] =
          item.dateOfCompletion == null || item.dateOfCompletion == undefined
            ? EMPTY_STRING
            : `"${item.dateOfCompletion}"`;
      }
      if (this.selectedColumns.includes(SURVEYDESCRIPTION)) {
        mappedItem[SURVEYDESCRIPTION_TEXT] =
          item.surveyDescription == null || item.surveyDescription == undefined
            ? EMPTY_STRING
            : `"${item.surveyDescription}"`;
      }
      return mappedItem;
    });

    const csvContent = this.convertToCSV(csvData);
    const blob = new Blob([csvContent], { type: CSV_TYPE });
    this.filename = GRID_DATA_CSV;
    this.filesize = blob.size;
    this.communicate.fileName = GRID_DATA_CSV;
    saveAs(blob, this.filename);
    this.addNewNotification();
  }

  public async onPDFExportReportDetail(data: any[]): Promise<void> {
    try {
      if (this.pdfExport) {
        this.communicate.data = data;
        if (this.exportVisibleDataOnly) {
          this.communicate._selectedColumns = this.selectedColumns;
        } else {
          this.communicate._selectedColumns = this.selectedColumns;
        }
        this.communicate.flag = REPORTDETAILS;
        this.cdr.detectChanges();
        this.filename = GRID_DATA_PDF;
        this.pdfExport.saveAs(this.filename);
        this.addNewNotification();
      } else {
        console.error(PDF_EXPORT_FAILED_MESG);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async onExcelExportReportDetail(): Promise<void> {
    try {
      if (this.excelExport) {
        this.filename = 'Export.xlsx';
        this.excelExport.save();
        this.addNewNotification();
      } else {
        console.error(EXCEL_EXPORT_FAILED_MESG);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async onJSONExport(data: any[]): Promise<void> {
    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    this.filename = 'grid-data.json';
    this.filesize = blob.size;
    saveAs(blob, this.filename);
    this.addNewNotification();
  }

private convertToCSV(data: any[]): string {
    const array = [Object.keys(data[0])].concat(data);
    return array
        .map((it) => {
            return Object.values(it)
                .map(value => {
                    // Wrap values in quotes if they contain commas
                    if (typeof value === 'string' && value.includes(',')) {
                        return `"${value}"`;
                    }
                    return value;
                })
                .join(',');
        })
        .join('\n');
}


  private getCurrentPageData(): any[] {
    // Implement this method based on your pagination logic
    return this.exportVisibleDataOnly
      ? this.filteredGridridData.slice(0, this.pageSize)
      : this.gridData.slice(0, this.pageSize);
  }

  incrementExportCount() {
    if (this.exportCount < this.gridData.length) {
      this.exportCount++;
    }
  }

  decrementExportCount() {
    if (this.exportCount > 1) {
      this.exportCount--;
    }
  }

  selection(value: string) {
    this.selectedFormat = value;
  }

  close(): void {
    this.dialogRef.close();
  }
}
