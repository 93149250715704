<div class="container-fluid py-2" style="margin-top: -28px;">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px;">Reports</h5>
      </div>
      <div class="col-auto ml-auto">
        <div class="d-flex flex-wrap align-items-center gap-2 justify-content-end">
          <div style="width: 330px; margin-right: -10px;">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            ></app-search>
          </div>
          <div>
            <button class="create-report-button w-120" (click)="openAddReportPage()">
              <span class="create-report-text">{{'REPORT_MANAGEMENT.CREATE_REPORT' | translate}}</span>
            </button>
          </div>
          <div class="col-6 col-sm-1 col-md-1 custom-col-lg-1">
            <button
              class="fav-button w-140"
              (click)="toggleFavouriteFilter()"
              [ngClass]="{
                'fav-button': true,
                'fav-button-applied': isFavouriteOnly,
                'fav-button-simple': !isFavouriteOnly,
              }"
            >
              <span
                [ngClass]="{
                  'fav-icon-applyed': isFavouriteOnly,
                  'fav-icon-simple': !isFavouriteOnly,
                }"
                class="k-icon k-font-icon k-i-star fav-icon-simple"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="report-checkbox my-3 g-5">
    <kendo-label
      class="k-checkbox-label"
      [for]="AuditReport"
      [text]="'REPORT_MANAGEMENT.AUDIT' | translate"
    ></kendo-label>
    <kendo-checkbox
      #AuditReport
      [checkedState]="isAuditReport"
      class="px-3"
      [(ngModel)]="isAuditReport"
      (ngModelChange)="onReportTypeChange()"
    ></kendo-checkbox>
    <kendo-label
      class="k-checkbox-label"
      [for]="PerformanceReport"
      [text]="'REPORT_MANAGEMENT.PERFORMANCE' | translate"
    ></kendo-label>
    <kendo-checkbox
      #PerformanceReport
      [checkedState]="isPerformanceReport"
      class="px-3"
      [(ngModel)]="isPerformanceReport"
      (ngModelChange)="onReportTypeChange()"
    ></kendo-checkbox>
  </div>

  <div class="grid-container">
    <kendo-grid
      [kendoGridBinding]="gridData"
      [filterable]="'menu'"
      [pageable]="PAGINATION_ARRAY"
      (filterChange)="onFilterChange($event)"
      [rowSticky]="rowSticky"
      [pageSize]="pageSize"
      [resizable]="true"
      style="width: 100%; box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15)"
    >
      <kendo-grid-column
        [resizable]="false"
        [width]="80"
        [filterable]="false"
        field="id"
        title=""
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="flex-container"
            style="display: flex; align-items: center"
          >
            <span
              class="k-icon k-font-icon my-custom-icon-class"
              (click)="openDropdownMenu($event, dataItem)"
            ></span>
            <span
              *ngIf="isSticky(dataItem.id)"
              class="k-icon k-font-icon k-i-star k-i-bookmark"
              style="color: #3498db; margin-left: 5px"
              (click)="rowActionStickyRow($event, dataItem)"
            >
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [headerClass]="'firstColGridHeader'"
        [title]="'REPORT_MANAGEMENT.REPORT_NAME' | translate"
        field="reportName"
        [width]="200"
        [minResizableWidth]="100"
      ></kendo-grid-column>
      <kendo-grid-column
        [title]="'REPORT_MANAGEMENT.DESCRIPTION' | translate"
        field="description"
        [width]="450"
        [minResizableWidth]="200"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="isExpanded(dataItem.id)">
            {{ dataItem.description }}
          </span>
          <span *ngIf="!isExpanded(dataItem.id)">
            {{ dataItem.description | slice: 0 : 50 }}
            <button class="see-more" (click)="toggleDescription(dataItem.id)">
              {{'REPORT_MANAGEMENT.SEE_MORE_COMMENT' | translate}}
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [title]="'REPORT_MANAGEMENT.REPORT_TYPE' | translate"
        field="reportType"
        [width]="120"
        [minResizableWidth]="60"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
              fieldName="reportType"
              [inputElements]="reportTypeFilterValues"
              [parentDiv]="customFilter"
              [state]="state"
              (selectedValues)="applyFilter($event, filterService)"
            ></app-custom-grid-filter>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [title]="'REPORT_MANAGEMENT.CREATED_DATE' | translate"
        field="createdDate"
        [width]="150"
        [filter]="'date'"
        [minResizableWidth]="70"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.formattedCreatedDate }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [title]="'REPORT_MANAGEMENT.EXPORTED_DATE' | translate"
        field="exportedDate"
        [width]="150"
        [filter]="'date'"
        [minResizableWidth]="70"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.formattedExportedDate }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [title]="'REPORT_MANAGEMENT.CREATED_BY' | translate"
        field="createdBy"
        [width]="120"
        [minResizableWidth]="60"
      ></kendo-grid-column>
      <kendo-grid-column
        [title]="'REPORT_MANAGEMENT.SCHEDULE' | translate"
        field="schedule"
        [width]="80"
        [minResizableWidth]="40"
        [filterable]="false"
        [reorderable]="false"
        [resizable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- Show schedule-clock icon if schedule is true -->
          <span
            *ngIf="dataItem.schedule"
            class="k-icon k-font-icon k-i-clock schedule-clock"
          ></span>
          <!-- Show not-scheduled icon if schedule is false -->
          <span
            *ngIf="!dataItem.schedule"
            class="k-icon k-font-icon k-i-warning not-scheduled"
          ></span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <app-row-actions
      class="row-action"
      [visible]="dropdownVisible"
      [positionX]="dropdownX"
      [width]="ellipsisWidth"
      [positionY]="dropdownY"
      [inputScreen]="inputScreen"
      (visibleChange)="handleDropdownVisibilityChange($event)"
      [rowId]="rowId"
      [isStickyRow]="isStickyRow"
      (optionSelected)="handleDropdownOption($event)"
    ></app-row-actions>
  </div>
</div>
