import { Injectable } from '@angular/core';
import { Assets } from '../models/assets';
import { SurveyRoute } from '../models/survey-routes';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  SurveyRouteConnection,
  SurveyRouteGridFilterInput,
  GetAllSurveyRouteQueryVariables,
  SurveyRouteCreateInput,
  SurveyRouteResponse,
  CreateSurveyRouteMutationVariables,
  UpdateSurveyRouteMutationVariables,
  SurveyRouteUpdateInput,
} from '../../../awsAppSync/API';

import { getAllSurveyRoute } from '../../../awsAppSync/graphql/queries';

import {
  FETCHING_SURVEY_ROUTE_ERROR,
} from '../../shared/constants';
import { createSurveyRoute, updateSurveyRoute } from '../../../awsAppSync/graphql/mutations';

@Injectable({
  providedIn: 'root',
})
export class SurveyRouteService {
  selectedAsset: Assets | undefined;

  private routeData: { routeName: string; routeDescription: string } | null =
    null;
  constructor(private awsService: BaseAWSRequestService) {}

  setRouteData(data: {
    routeName: string;
    routeDescription: string;
    selectedRow?: SurveyRoute;
  }) {
    this.routeData = data;
  }
  
  getRouteData(): {
    routeName: string;
    routeDescription: string;
    selectedRow?: SurveyRoute;
  } | null {
    const data = this.routeData;
    this.clearRouteData();
    return data;
  }

  clearRouteData() {
    this.routeData = null;
  }

  public async getAllSurveyRoute(
    input: SurveyRouteGridFilterInput,
  ): Promise<SurveyRouteConnection | null> {
    const query = getAllSurveyRoute; // Ensure this is your GraphQL query string or document
    const variables: GetAllSurveyRouteQueryVariables = {
      input: input,
    };

    try {
      const result = await this.awsService.executeGetQuery<{
        getAllSurveyRoute: SurveyRouteConnection;
      }>(query, variables);
      return result.getAllSurveyRoute || null;
    } catch (error) {
      console.error(FETCHING_SURVEY_ROUTE_ERROR, error);
      return null;
    }
  }

  async createSurveyRoute(
    input: SurveyRouteCreateInput,
  ): Promise<SurveyRouteResponse | null> {
    const mutation = createSurveyRoute;
    const variables: CreateSurveyRouteMutationVariables = { input };
    try {
      const surveyRouteResult = await this.awsService.executeMutation<{
        createSurveyRoute: SurveyRouteResponse;
      }>(mutation, variables);
      return surveyRouteResult.createSurveyRoute || null;
    } catch (error) {
      console.error("Error creating Survey Route", error);
      return null;
    }
  }

  async updateSurveyRoute(
    input: SurveyRouteUpdateInput,
  ): Promise<SurveyRouteResponse | null> {
    const mutation = updateSurveyRoute;
    const variables: UpdateSurveyRouteMutationVariables = { input };
    try {
      const surveyRouteResult = await this.awsService.executeMutation<{
        updateSurveyRoute: SurveyRouteResponse;
      }>(mutation, variables);
      return surveyRouteResult.updateSurveyRoute || null;
    } catch (error) {
      console.error("Error creating Survey Route", error);
      return null;
    }
  }
}
