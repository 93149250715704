/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTemplateInput = {
  userId: string,
  corViewUserID: string,
  templateName: string,
  templateConfiguration?: string | null,
  isActive: boolean,
  templateType: templateType,
};

export enum templateType {
  inspection = "inspection",
  exception = "exception",
  asset_setup = "asset_setup",
  global = "global",
  survey_overview = "survey_overview",
}


export type Template = {
  __typename: "Template",
  id: string,
  entityType?: string | null,
  userId: string,
  corViewUserID?: string | null,
  templateName: string,
  templateConfiguration?: string | null,
  createdDate?: string | null,
  updatedDate?: string | null,
  isDeleted?: boolean | null,
  isActive?: boolean | null,
  createdBy?: string | null,
  updatedBy?: string | null,
  templateType?: string | null,
};

export type UpdateTemplateInput = {
  id: string,
  userId: string,
  templateName: string,
  templateConfiguration?: string | null,
  isActive?: boolean | null,
  templateType: templateType,
};

export type AssetCreateInput = {
  assetHierarchies?: Array< AssetHierarchyInput | null > | null,
  assetMeasurementCriteriaInputs?: Array< AssetMeasurementCriteriaInput | null > | null,
  primaryCompanyId?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  assetTypeId?: string | null,
  assetName?: string | null,
  lastInspectionDate?: string | null,
  mappingAssetId?: string | null,
  milestone?: string | null,
  creationDate?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  comments?: string | null,
  locationDescription?: string | null,
  effectiveDate?: string | null,
  assetProperties?: Array< KeyValue | null > | null,
  imageNames?: Array< string | null > | null,
};

export type AssetHierarchyInput = {
  level3ValueId?: string | null,
  level4ValueId?: string | null,
  level4Value?: string | null,
  level4Type?: string | null,
  level4Relation?: string | null,
};

export type AssetMeasurementCriteriaInput = {
  id?: string | null,
  compliance?: boolean | null,
  criteria?: string | null,
  measurementSource?: string | null,
  measurementType?: string | null,
  measurementOperator?: string | null,
  unit?: string | null,
  level4Relation?: string | null,
  level4Type?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
};

export type KeyValue = {
  key?: string | null,
  value?: string | null,
};

export type AssetResponse = {
  __typename: "AssetResponse",
  status?: string | null,
  error?: Error | null,
};

export type Error = {
  __typename: "Error",
  message?: string | null,
  details?: Array< string | null > | null,
};

export type AssetUpdateInput = {
  id: string,
  pk?: string | null,
  sk?: string | null,
  updatedBy?: string | null,
  assetHierarchies?: Array< AssetHierarchyInput | null > | null,
  assetMeasurementCriteriaUpdateInputs?: Array< AssetMeasurementCriteriaUpdateInput | null > | null,
  primaryCompanyId?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  assetTypeId?: string | null,
  assetName?: string | null,
  lastInspectionDate?: string | null,
  mappingAssetId?: string | null,
  milestone?: string | null,
  creationDate?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  comments?: string | null,
  locationDescription?: string | null,
  effectiveDate?: string | null,
  assetProperties?: Array< KeyValue | null > | null,
  imageNames?: Array< string | null > | null,
};

export type AssetMeasurementCriteriaUpdateInput = {
  id?: string | null,
  pk?: string | null,
  sk?: string | null,
  compliance?: boolean | null,
  criteria?: string | null,
  measurementSource?: string | null,
  measurementType?: string | null,
  measurementOperator?: string | null,
  unit?: string | null,
  level4Relation?: string | null,
  level4Type?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
  isDeleted?: boolean | null,
};

export type FileUploadInput = {
  filename: string,
  entityType: fileUploadEntityType,
  entityTypeId: string,
  fileUploadType: fileUploadType,
};

export enum fileUploadEntityType {
  Asset = "Asset",
  Survey = "Survey",
  PrimaryCompany = "PrimaryCompany",
}


export enum fileUploadType {
  Normal = "Normal",
  Import = "Import",
  Report = "Report",
}


export type FileUpload = {
  __typename: "FileUpload",
  contentType?: string | null,
  fileURL?: string | null,
  presignedURL?: string | null,
  status?: string | null,
  error?: Error | null,
};

export type importInput = {
  fileURL: string,
  entityType: string,
  entityValue: string,
};

export type BulkImportResponse = {
  __typename: "BulkImportResponse",
  status?: string | null,
  fileURL?: string | null,
  error?: Error | null,
};

export type PrimaryCompanyCreateInput = {
  primaryCompany?: string | null,
  imageName: string,
  phoneNumber?: string | null,
  officeNumber: string,
  country?: string | null,
  state?: string | null,
  zipCode?: string | null,
  city?: string | null,
  address?: string | null,
  timeZone: string,
  corViewCompanyId?: string | null,
};

export type PrimaryCompanyResponse = {
  __typename: "PrimaryCompanyResponse",
  item?: PrimaryCompany | null,
  status?: string | null,
  error?: Error | null,
};

export type PrimaryCompany = {
  __typename: "PrimaryCompany",
  id: string,
  entityType?: string | null,
  createdBy?: string | null,
  createdDate?: string | null,
  updatedBy?: string | null,
  updatedDate?: string | null,
  isDeleted?: boolean | null,
  primaryCompany?: string | null,
  imageURL?: string | null,
  phoneNumber?: string | null,
  officeNumber?: string | null,
  country?: string | null,
  state?: string | null,
  zipCode?: string | null,
  city?: string | null,
  address?: string | null,
  timeZoneId?: string | null,
  timeZone?: string | null,
  setupStatus?: number | null,
  totalSetup?: number | null,
  corViewCompanyId?: string | null,
};

export type PrimaryCompanyUpdateInput = {
  id?: string | null,
  imageName?: string | null,
  phoneNumber: string,
  officeNumber: string,
  country?: string | null,
  state?: string | null,
  zipCode?: string | null,
  city?: string | null,
  address?: string | null,
};

export type LevelNameCreateInput = {
  primaryCompanyId?: string | null,
  level1Name?: string | null,
  level2Name?: string | null,
  level3Name?: string | null,
  level4Name?: string | null,
};

export type LevelNameResponse = {
  __typename: "LevelNameResponse",
  item?: LevelName | null,
  status?: string | null,
  error?: Error | null,
};

export type LevelName = {
  __typename: "LevelName",
  primaryCompanyId?: string | null,
  level1Name?: string | null,
  level2Name?: string | null,
  level3Name?: string | null,
  level4Name?: string | null,
};

export type LevelNameUpdateInput = {
  primaryCompanyId?: string | null,
  level1Name?: string | null,
  level2Name?: string | null,
  level3Name?: string | null,
  level4Name?: string | null,
};

export type LevelValueCreateInput = {
  primaryCompanyId: string,
  level1Values: Array< Level1ValueInput | null >,
};

export type Level1ValueInput = {
  value: string,
  level2Values: Array< Level2ValueInput | null >,
};

export type Level2ValueInput = {
  value: string,
  level3Values: Array< Level3ValueInput | null >,
};

export type Level3ValueInput = {
  value: string,
};

export type LevelValueResponse = {
  __typename: "LevelValueResponse",
  status?: string | null,
  error?: Error | null,
};

export type AssetTypeTemplateCreateInput = {
  primaryCompanyId: string,
  assetTypeInputs: Array< AssetTypeInput >,
};

export type AssetTypeInput = {
  assetType: string,
  measurementFrequency: string,
  customFrequencyRepeatEveryNumber?: string | null,
  customFrequencyRepeatValue?: string | null,
  customFrequencyStartDate?: string | null,
  measurementCriteriaInputs: Array< MeasurementCriteriaInput >,
};

export type MeasurementCriteriaInput = {
  compliance: boolean,
  criteria: string,
  measurementSource: string,
  measurementType: string,
  measurementOperator: string,
  unit: string,
};

export type AssetTypeTemplateResponse = {
  __typename: "AssetTypeTemplateResponse",
  status?: string | null,
  error?: Error | null,
};

export type UserCreateInput = {
  firstName: string,
  lastName: string,
  active?: boolean | null,
  username: string,
  email: string,
  postalZip?: string | null,
  addr1?: string | null,
  addrCity?: string | null,
  cellPhone?: string | null,
  officePhone: string,
  country?: string | null,
  stateProvince?: string | null,
  timeZone?: string | null,
  timeZoneId?: string | null,
  password?: string | null,
  coreViewPrimaryCompany: UserPrimaryCompanyInput,
  syrcPrimaryCompany: UserPrimaryCompanyInput,
  createdBy?: string | null,
  coreViewUserId?: string | null,
  confirmPassword?: string | null,
  level3ValueIds: Array< string | null >,
};

export type UserPrimaryCompanyInput = {
  id?: string | null,
  displayName?: string | null,
};

export type AddUpdateUserResponse = {
  __typename: "AddUpdateUserResponse",
  coreViewUserId: string,
  id: string,
  status?: string | null,
  error?: Error | null,
};

export type UserUpdateInput = {
  id: string,
  coreViewUserId: string,
  level3ValueIds: Array< string | null >,
  updatedBy?: string | null,
};

export type LevelValueUpdateInput = {
  primaryCompanyId: string,
  level1Values?: Array< Level1ValueValueUpdate | null > | null,
};

export type Level1ValueValueUpdate = {
  id?: string | null,
  pk?: string | null,
  sk?: string | null,
  value?: string | null,
  entityType?: string | null,
  isDeleted?: boolean | null,
  level2Values?: Array< Level2ValueUpdateInput | null > | null,
};

export type Level2ValueUpdateInput = {
  id?: string | null,
  pk?: string | null,
  sk?: string | null,
  value?: string | null,
  isDeleted?: boolean | null,
  entityType?: string | null,
  level3Values?: Array< Level3ValueUpdateInput | null > | null,
};

export type Level3ValueUpdateInput = {
  id?: string | null,
  pk?: string | null,
  sk?: string | null,
  value?: string | null,
  entityType?: string | null,
  isDeleted?: boolean | null,
};

export type AssetTypeTemplateUpdateInput = {
  primaryCompanyId: string,
  assetTypeInputs: Array< AssetTypeUpdateInput >,
};

export type AssetTypeUpdateInput = {
  id?: string | null,
  pk?: string | null,
  sk?: string | null,
  assetType: string,
  measurementFrequency: string,
  customFrequencyRepeatEveryNumber?: string | null,
  customFrequencyRepeatValue?: string | null,
  customFrequencyStartDate?: string | null,
  isAssetConfigured?: boolean | null,
  isDeleted?: boolean | null,
  measurementCriteriaInputs?: Array< MeasurementCriteriaUpdateInput > | null,
};

export type MeasurementCriteriaUpdateInput = {
  id?: string | null,
  pk?: string | null,
  sk?: string | null,
  compliance: boolean,
  criteria: string,
  measurementSource: string,
  measurementType: string,
  measurementOperator: string,
  unit: string,
  isDeleted?: boolean | null,
};

export type UpdateExceptionInput = {
  id: string,
  pk: string,
  targetRemediationDate: string,
  updatedBy: string,
};

export type ExceptionResponse = {
  __typename: "ExceptionResponse",
  status?: string | null,
  error?: Error | null,
};

export type LevelValueDeleteInput = {
  pk?: string | null,
  sk?: string | null,
  childrenExist?: boolean | null,
};

export type DataExportInput = {
  gridName?: string | null,
  filters?: string | null,
  // JSON string for filters
  columns?: string | null,
  // Comma-separated string for columns
  userId?: string | null,
  fileType?: string | null,
};

export type DataExportResult = {
  __typename: "DataExportResult",
  exportMessage?: string | null,
  status?: string | null,
  error?: Error | null,
};

export type ExportStatusInput = {
  userId: string,
  status: string,
  exportMessage?: string | null,
};

export type ExportStatusResult = {
  __typename: "ExportStatusResult",
  userId: string,
  status: string,
  exportMessage?: string | null,
};

export type SurveyCreateInput = {
  surveyName?: string | null,
  surveyType?: string | null,
  surveyPriority?: string | null,
  dataCollectionType?: string | null,
  dueDate?: string | null,
  assignedTo?: string | null,
  troubleShooting?: string | null,
  surveyDescription?: string | null,
  primaryCompanyId: string,
  surveyAssociatedAssetInput?: Array< SurveyAssociatedAssetInput | null > | null,
};

export type SurveyAssociatedAssetInput = {
  assetId: string,
  level1ValueId: string,
  level2ValueId: string,
  level3ValueId: string,
  order?: string | null,
};

export type SurveyResponse = {
  __typename: "SurveyResponse",
  status?: string | null,
  error?: Error | null,
};

export type SurveyUpdateInput = {
  id: string,
  pk: string,
  sk: string,
  surveyName?: string | null,
  surveyType?: string | null,
  surveyPriority?: string | null,
  dataCollectionType?: string | null,
  dueDate?: string | null,
  assignedTo?: string | null,
  troubleShooting?: string | null,
  surveyDescription?: string | null,
  surveyAssociatedAssetInput?: Array< SurveyAssociatedAssetInput | null > | null,
};

export type SurveyRouteCreateInput = {
  primaryCompanyId: string,
  surveyRouteAssociatedAssetInput?: Array< SurveyRouteAssociatedAssetInput | null > | null,
  routeName?: string | null,
  routeDescription?: string | null,
};

export type SurveyRouteAssociatedAssetInput = {
  assetId?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  level3ValueId?: string | null,
  order: string,
};

export type SurveyRouteResponse = {
  __typename: "SurveyRouteResponse",
  status?: string | null,
  error?: Error | null,
};

export type SurveyRouteUpdateInput = {
  id: string,
  pk: string,
  sk: string,
  surveyRouteAssociatedAssetInput?: Array< SurveyRouteAssociatedAssetInput | null > | null,
  routeName?: string | null,
  routeDescription?: string | null,
};

export type TemplateFilterInput = {
  templateType?: TableStringFilterInput | null,
};

export type TableStringFilterInput = {
  attributeExists?: boolean | null,
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  contains?: string | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
  ne?: string | null,
  notContains?: string | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  between?: Array< number | null > | null,
  eq?: number | null,
  ge?: number | null,
  gt?: number | null,
  le?: number | null,
  lt?: number | null,
  ne?: number | null,
};

export type TemplateConnection = {
  __typename: "TemplateConnection",
  items?:  Array<Template | null > | null,
  nextToken?: string | null,
};

export type FilterInput = {
  primaryCompany?: string | null,
  level4?: Array< string | null > | null,
  assetTypes?: Array< string | null > | null,
  segmentTypes?: Array< string | null > | null,
  pageSize?: number | null,
  pageNumber?: number | null,
  lastEvaluatedKey?: string | null,
  hierarchyFilterInputs?: Array< HierarchyFilterInput | null > | null,
  surveyRouteFilterInputs?: Array< SurveyRouteFilterInput | null > | null,
  gridFilters?: KendoGridFilters | null,
  searchText?: string | null,
};

export type HierarchyFilterInput = {
  level1?: string | null,
  level2?: string | null,
  level3?: string | null,
};

export type SurveyRouteFilterInput = {
  level1?: string | null,
  level2?: string | null,
  level3?: string | null,
  assetId?: string | null,
};

export type KendoGridFilters = {
  filters?: Array< KendoFilterGroup | null > | null,
  logic?: string | null,
};

export type KendoFilterGroup = {
  filters?: Array< KendoFilter | null > | null,
  logic?: string | null,
};

export type KendoFilter = {
  field?: string | null,
  operator?: string | null,
  value?: string | null,
};

export type AssetConnection = {
  __typename: "AssetConnection",
  items?:  Array<AssetOverview | null > | null,
  lastEvaluatedKey?: string | null,
  totalCount?: number | null,
};

export type AssetOverview = {
  __typename: "AssetOverview",
  id?: string | null,
  pk?: string | null,
  sk?: string | null,
  level1Value?: string | null,
  level1ValueId?: string | null,
  level2Value?: string | null,
  level2ValueId?: string | null,
  level3Value?: string | null,
  level3ValueId?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
  route?: string | null,
  segmentRelation?: string | null,
  assetName?: string | null,
  assetType?: string | null,
  measurementFrequency?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  status?: string | null,
  assetDelinquencyDate?: string | null,
  measurementCriteria?:  Array<AssetOverviewMeasurementData | null > | null,
  isMeasurementAvailable?: boolean | null,
  surveyRouteIds?: Array< string | null > | null,
  gpsCoordinate?: string | null,
};

export type AssetOverviewMeasurementData = {
  __typename: "AssetOverviewMeasurementData",
  id?: string | null,
  measurementSource?: string | null,
  measurementType?: string | null,
  measurementOperator?: string | null,
  criteria?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
  level4Relation?: string | null,
  compliance?: boolean | null,
  unit?: string | null,
};

export type SurveyConnection = {
  __typename: "SurveyConnection",
  items?:  Array<Survey | null > | null,
  nextToken?: string | null,
};

export type Survey = {
  __typename: "Survey",
  id: string,
  pk: string,
  sk: string,
  surveyName?: string | null,
  surveyType?: string | null,
  surveyPriority?: string | null,
  dataCollectionType?: string | null,
  dueDate?: string | null,
  assignedTo?: string | null,
  troubleShooting?: string | null,
  surveyDescription?: string | null,
  surveyAssociatedAssets?:  Array<SurveyAssociatedAsset | null > | null,
  surveyStatus?: string | null,
  dateOfCompletion?: string | null,
  supervisor?: string | null,
};

export type SurveyAssociatedAsset = {
  __typename: "SurveyAssociatedAsset",
  assetId: string,
  level1ValueId: string,
  level2ValueId: string,
  level3ValueId: string,
  assetType?: string | null,
  assetName?: string | null,
  lastInspectionDate?: string | null,
  assetDelinquencyDate?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  order?: string | null,
  status?: string | null,
};

export type PrimaryCompanyConnection = {
  __typename: "PrimaryCompanyConnection",
  items?:  Array<PrimaryCompany | null > | null,
  totalCount?: number | null,
};

export type CompanyConnection = {
  __typename: "CompanyConnection",
  items?:  Array<Company | null > | null,
  nextToken?: string | null,
};

export type Company = {
  __typename: "Company",
  id: string,
  entityType?: string | null,
  company: string,
  primaryCompanyId: string,
  primaryCompany: string,
  createdDate?: string | null,
  createdBy?: string | null,
  updatedDate?: string | null,
  updatedBy?: string | null,
  isDeleted?: boolean | null,
};

export type RegionConnection = {
  __typename: "RegionConnection",
  items?:  Array<Region | null > | null,
  nextToken?: string | null,
};

export type Region = {
  __typename: "Region",
  id: string,
  entityType?: string | null,
  region: string,
  primaryCompanyId: string,
  primaryCompany: string,
  companyId: string,
  company: string,
  createdDate?: string | null,
  createdBy?: string | null,
  updatedDate?: string | null,
  updatedBy?: string | null,
  isDeleted?: boolean | null,
};

export type SegmentConnection = {
  __typename: "SegmentConnection",
  items?:  Array<Segment | null > | null,
  nextToken?: string | null,
};

export type Segment = {
  __typename: "Segment",
  id: string,
  entityType?: string | null,
  segmentName: string,
  primaryCompanyId: string,
  primaryCompany: string,
  companyId: string,
  company: string,
  regionId: string,
  region: string,
  createdDate?: string | null,
  createdBy?: string | null,
  updatedDate?: string | null,
  updatedBy: string,
  isDeleted?: boolean | null,
};

export type AssetTypeConnection = {
  __typename: "AssetTypeConnection",
  items?:  Array<AssetType | null > | null,
  nextToken?: string | null,
};

export type AssetType = {
  __typename: "AssetType",
  id: string,
  entityType?: string | null,
  assetType: string,
  measurementFrequency: string,
  customFrequencyStartDate?: string | null,
  customFrequencyRepeatEveryNumber?: number | null,
  customFrequencyRepeatValue?: string | null,
  createdDate?: string | null,
  createdBy?: string | null,
  updatedDate?: string | null,
  updatedBy?: string | null,
  isDeleted?: boolean | null,
};

export type InspectionConnection = {
  __typename: "InspectionConnection",
  items?:  Array<Inspection | null > | null,
  lastEvaluatedKey?: string | null,
  totalCount?: number | null,
};

export type Inspection = {
  __typename: "Inspection",
  id?: string | null,
  pk?: string | null,
  assetName?: string | null,
  assetType?: string | null,
  station?: string | null,
  complianceStatus?: string | null,
  lastInspectionDate?: string | null,
  inspectionTargetDate?: string | null,
  assetDelinquencyDate?: string | null,
  technician?: string | null,
  measurementPoint?: string | null,
  daysUntilDelinquent?: string | null,
  measurements?:  Array<MeasurementModel | null > | null,
};

export type MeasurementModel = {
  __typename: "MeasurementModel",
  id?: string | null,
  pk?: string | null,
  assetId?: string | null,
  surveyId?: string | null,
  status?: string | null,
  compliance?: string | null,
  technician?: string | null,
  inspectionDate?: string | null,
  surveyRouteName?: string | null,
  measurementType?: string | null,
  criteria?: string | null,
  measurementValue?: string | null,
  isCompliant?: boolean | null,
  source?: string | null,
  level4?: string | null,
  level4Relation?: string | null,
  level4Type?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
  operatorInfo?: string | null,
  specialist?: string | null,
  unit?: string | null,
  surveyDate?: string | null,
};

export type FilePreSignedUrl = {
  __typename: "FilePreSignedUrl",
  presignedURL?: string | null,
  status?: string | null,
  error?: Error | null,
};

export type LevelNameConnection = {
  __typename: "LevelNameConnection",
  items?:  Array<LevelName | null > | null,
  nextToken?: string | null,
};

export type Level1ValueConnection = {
  __typename: "Level1ValueConnection",
  items?:  Array<Level1Value | null > | null,
  nextToken?: string | null,
};

export type Level1Value = {
  __typename: "Level1Value",
  id: string,
  level1Value?: string | null,
};

export type Level2ValueConnection = {
  __typename: "Level2ValueConnection",
  items?:  Array<Level2Value | null > | null,
  nextToken?: string | null,
};

export type Level2Value = {
  __typename: "Level2Value",
  id: string,
  level1ValueId?: string | null,
  level1Value?: string | null,
  level2Value?: string | null,
};

export type Level3ValueConnection = {
  __typename: "Level3ValueConnection",
  items?:  Array<Level3Value | null > | null,
  nextToken?: string | null,
};

export type Level3Value = {
  __typename: "Level3Value",
  id: string,
  level1ValueId?: string | null,
  level1Value?: string | null,
  level2ValueId?: string | null,
  level2Value?: string | null,
  level3Value?: string | null,
};

export type Level4ValueConnection = {
  __typename: "Level4ValueConnection",
  items?:  Array<Level4Value | null > | null,
  nextToken?: string | null,
};

export type Level4Value = {
  __typename: "Level4Value",
  id: string,
  level1ValueId?: string | null,
  level1Value?: string | null,
  level2ValueId?: string | null,
  level2Value?: string | null,
  level3ValueId?: string | null,
  level3Value?: string | null,
  level4Value?: string | null,
  segmentType?: string | null,
};

export type SubFilterInput = {
  level3ValueIdList?: Array< string | null > | null,
  primaryCompanyId?: string | null,
};

export type RouteConnection = {
  __typename: "RouteConnection",
  items?:  Array<Route | null > | null,
  nextToken?: string | null,
};

export type Route = {
  __typename: "Route",
  id: string,
  entityType?: string | null,
  routeName?: string | null,
  routeDescription?: string | null,
  RouteAssociateAssetList?:  Array<RouteAssociatedAsset | null > | null,
  createdDate?: string | null,
  createdBy?: string | null,
  updatedDate?: string | null,
  updatedBy?: string | null,
  isDeleted?: boolean | null,
};

export type RouteAssociatedAsset = {
  __typename: "RouteAssociatedAsset",
  assetId?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  level3ValueIdList?: Array< string | null > | null,
};

export type SegmentTypeConnection = {
  __typename: "SegmentTypeConnection",
  items?:  Array<SegmentType | null > | null,
  nextToken?: string | null,
};

export type SegmentType = {
  __typename: "SegmentType",
  id?: string | null,
  entityType?: string | null,
  level4Type?: string | null,
  createdDate?: string | null,
  createdBy?: string | null,
  updatedDate?: string | null,
  updatedBy?: string | null,
  isDeleted?: boolean | null,
};

export type KendoGridInput = {
  pageSize: number,
  pageNumber: number,
  searchText?: string | null,
  isMTXAdmin?: boolean | null,
  gridFilters?: KendoGridFilters | null,
};

export type CompanyOptionConnection = {
  __typename: "CompanyOptionConnection",
  items?:  Array<CompanyOption | null > | null,
  nextToken?: string | null,
};

export type CompanyOption = {
  __typename: "CompanyOption",
  name?: string | null,
  corViewCompanyId?: string | null,
  timeZoneId?: string | null,
  timeZone?: string | null,
};

export type TimeZoneOptionConnection = {
  __typename: "TimeZoneOptionConnection",
  items?:  Array<TimeZoneOption | null > | null,
  nextToken?: string | null,
};

export type TimeZoneOption = {
  __typename: "TimeZoneOption",
  id: string,
  name: string,
};

export type AssetHistoryInput = {
  primaryCompany?: string | null,
  level1?: string | null,
  level2?: string | null,
  level3?: string | null,
  assetId?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  lastEvaluatedKey?: string | null,
  pageSize?: number | null,
  pageNumber?: number | null,
  gridFilters?: KendoGridFilters | null,
  searchText?: string | null,
};

export type AssetHistoryGridConnection = {
  __typename: "AssetHistoryGridConnection",
  items?:  Array<AssetHistoryGridModel | null > | null,
  lastEvaluatedKey?: string | null,
  totalCount?: number | null,
};

export type AssetHistoryGridModel = {
  __typename: "AssetHistoryGridModel",
  surveyId?: string | null,
  surveyDate?: string | null,
  comments?:  Array<AssetComments | null > | null,
  measurementModels?:  Array<MeasurementModel | null > | null,
};

export type AssetComments = {
  __typename: "AssetComments",
  pk?: string | null,
  sk?: string | null,
  comment?: string | null,
  commentDate?: string | null,
  entityType?: string | null,
  id?: string | null,
  surveyDate?: string | null,
  surveyId?: string | null,
  user?: string | null,
};

export type UserConnection = {
  __typename: "UserConnection",
  items?:  Array<User | null > | null,
  totalCount?: number | null,
};

export type User = {
  __typename: "User",
  firstName: string,
  lastName: string,
  coreViewPrimaryCompany: UserPrimaryCompany,
  syrcPrimaryCompany: UserPrimaryCompany,
  active: boolean,
  username: string,
  email: string,
  postalZip?: string | null,
  addr1?: string | null,
  addrCity?: string | null,
  cellPhone?: string | null,
  officePhone?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  timeZoneId?: string | null,
  timeZone?: string | null,
  coreViewUserId?: string | null,
  id: string,
  entityType?: string | null,
  createdBy?: string | null,
  createdDate?: string | null,
  updatedBy?: string | null,
  updatedDate?: string | null,
  isDeleted?: boolean | null,
  userRoles?:  Array<UserRole | null > | null,
  accountStatus?: string | null,
};

export type UserPrimaryCompany = {
  __typename: "UserPrimaryCompany",
  id?: string | null,
  displayName?: string | null,
};

export type UserRole = {
  __typename: "UserRole",
  roleId: string,
  name: string,
  isMTXRole: boolean,
  level3Value?: UserLevel3Value | null,
};

export type UserLevel3Value = {
  __typename: "UserLevel3Value",
  level3ValueName?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  level3ValueId?: string | null,
};

export type UserOptionConnection = {
  __typename: "UserOptionConnection",
  items?:  Array<UserOption | null > | null,
  nextToken?: string | null,
};

export type UserOption = {
  __typename: "UserOption",
  firstName?: string | null,
  lastName?: string | null,
  primaryCompany?: UserPrimaryCompany | null,
  active?: boolean | null,
  username?: string | null,
  email?: string | null,
  postalZip?: string | null,
  addr1?: string | null,
  addrCity?: string | null,
  cellPhone?: string | null,
  officePhone?: string | null,
  stateProvince?: string | null,
  country?: string | null,
  timeZoneId?: string | null,
  timeZone?: string | null,
  corViewUserId?: string | null,
};

export type AssetTypeTemplateInput = {
  primaryCompanyId: string,
  id: string,
};

export type AssetTypeTemplate = {
  __typename: "AssetTypeTemplate",
  pk: string,
  sk: string,
  id: string,
  assetType: string,
  entityType?: string | null,
  measurementFrequency?: string | null,
  customFrequencyRepeatEveryNumber?: string | null,
  customFrequencyRepeatValue?: string | null,
  customFrequencyStartDate?: string | null,
  isAssetConfigured?: boolean | null,
  isDeleted?: boolean | null,
  measurementCriterias?:  Array<MeasurementCriteria | null > | null,
};

export type MeasurementCriteria = {
  __typename: "MeasurementCriteria",
  pk: string,
  sk: string,
  id: string,
  compliance?: boolean | null,
  criteria?: string | null,
  measurementSource?: string | null,
  measurementType?: string | null,
  measurementOperator?: string | null,
  unit?: string | null,
  segment?: string | null,
  segmentRelation?: string | null,
  isDeleted?: boolean | null,
};

export type AssetTypeTemplateConnection = {
  __typename: "AssetTypeTemplateConnection",
  items?:  Array<AssetTypeTemplate | null > | null,
  nextToken?: string | null,
};

export type AssetHistoryGraphInput = {
  primaryCompany: string,
  level1: string,
  level2: string,
  level3: string,
  assetId: string,
  startDate: string,
  endDate: string,
  measurementTypes: Array< string >,
};

export type AssetGraphModelConnection = {
  __typename: "AssetGraphModelConnection",
  items?:  Array<AssetGraphModel | null > | null,
};

export type AssetGraphModel = {
  __typename: "AssetGraphModel",
  measurementType?: string | null,
  dateTime?: string | null,
  value?: string | null,
  unit?: string | null,
  level4ValueId?: string | null,
  level4Value?: string | null,
};

export type AssetMetadataInput = {
  primaryCompany: string,
  level1: string,
  level2: string,
  level3: string,
  assetId: string,
};

export type Asset = {
  __typename: "Asset",
  pk?: string | null,
  sk?: string | null,
  assetDelinquencyDate?: string | null,
  assetName?: string | null,
  assetType?: string | null,
  entityType?: string | null,
  id?: string | null,
  imageUrls?: Array< string | null > | null,
  associatedLevel4?: Array< string | null > | null,
  imagePresignedUrls?: Array< string | null > | null,
  latitude?: string | null,
  level1ValueId?: string | null,
  level1Value?: string | null,
  level2ValueId?: string | null,
  level2Value?: string | null,
  level3ValueId?: string | null,
  level3Value?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
  level4Type?: string | null,
  longitude?: string | null,
  measurementFrequency?: string | null,
  measurementStartDate?: string | null,
  nextDelinquencyDate?: string | null,
  repeatesEvery?: string | null,
  repeatesEveryInterval?: string | null,
  status?: string | null,
  atmosphericCorrosionGrade?: string | null,
  techResponsible?: Array< string | null > | null,
  surveyRoute?: string | null,
  createdDate?: string | null,
  measurementCriteria?:  Array<AssetOverviewMeasurementData | null > | null,
};

export type LevelValueOutput = {
  __typename: "LevelValueOutput",
  level1Values?:  Array<Level1ValueOutput | null > | null,
};

export type Level1ValueOutput = {
  __typename: "Level1ValueOutput",
  pk?: string | null,
  sk?: string | null,
  id?: string | null,
  value?: string | null,
  level2Values?:  Array<Level2ValueOutput | null > | null,
};

export type Level2ValueOutput = {
  __typename: "Level2ValueOutput",
  pk?: string | null,
  sk?: string | null,
  id?: string | null,
  value?: string | null,
  level3Values?:  Array<Level3ValueOutput | null > | null,
};

export type Level3ValueOutput = {
  __typename: "Level3ValueOutput",
  pk?: string | null,
  sk?: string | null,
  id?: string | null,
  value?: string | null,
  isAssetConfigured?: boolean | null,
};

export type ExceptionConnection = {
  __typename: "ExceptionConnection",
  items?:  Array<Exceptions | null > | null,
  lastEvaluatedKey?: string | null,
  totalCount: number,
};

export type Exceptions = {
  __typename: "Exceptions",
  id: string,
  pk: string,
  assetName: string,
  assetType: string,
  assetTypeId?: string | null,
  complianceStatus?: string | null,
  comments?: string | null,
  workFlowStatus?: string | null,
  targetRemediationDate?: string | null,
  specialist?: string | null,
  technician?: string | null,
  assetDelinquencyDate?: string | null,
  measurements?:  Array<MeasurementModel | null > | null,
};

export type AssetOutput = {
  __typename: "AssetOutput",
  pk?: string | null,
  sk?: string | null,
  id?: string | null,
  primaryCompanyId?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  level3ValueId?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
  level4Type?: string | null,
  assetTypeId?: string | null,
  assetName?: string | null,
  lastInspectionDate?: string | null,
  mappingAssetId?: string | null,
  milestone?: string | null,
  creationDate?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  comments?: string | null,
  locationDescription?: string | null,
  effectiveDate?: string | null,
  assetProperties?:  Array<KeyValueOutput | null > | null,
  imageUrls?: Array< string | null > | null,
  assetHierarchies?:  Array<AssetHierarchy | null > | null,
  assetMeasurementCriterias?:  Array<AssetMeasurementCriteriaOutput | null > | null,
  imagePresignedUrls?: Array< string | null > | null,
  associatedLevel4?: Array< string | null > | null,
};

export type KeyValueOutput = {
  __typename: "KeyValueOutput",
  Key?: string | null,
  Value?: string | null,
};

export type AssetHierarchy = {
  __typename: "AssetHierarchy",
  level3ValueId?: string | null,
  level4ValueId?: string | null,
  level4Value?: string | null,
  level4Type?: string | null,
  level4Relation?: string | null,
};

export type AssetMeasurementCriteriaOutput = {
  __typename: "AssetMeasurementCriteriaOutput",
  pk?: string | null,
  sk?: string | null,
  id?: string | null,
  compliance?: boolean | null,
  criteria?: string | null,
  measurementSource?: string | null,
  measurementType?: string | null,
  measurementOperator?: string | null,
  unit?: string | null,
  level4Relation?: string | null,
  level4Type?: string | null,
  level4Value?: string | null,
  level4ValueId?: string | null,
};

export type AssetFilterInput = {
  primaryCompany?: string | null,
  level4?: Array< string | null > | null,
  assetTypes?: Array< string | null > | null,
  segmentTypes?: Array< string | null > | null,
  hierarchyFilterInputs?: Array< HierarchyFilterInput | null > | null,
  surveyRouteFilterInputs?: Array< SurveyRouteFilterInput | null > | null,
};

export type AssetOptionConnection = {
  __typename: "AssetOptionConnection",
  items?:  Array<AssetOption | null > | null,
  nextToken?: string | null,
};

export type AssetOption = {
  __typename: "AssetOption",
  pk?: string | null,
  sk?: string | null,
  id?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  level3ValueId?: string | null,
  level4ValueId?: string | null,
  assetName?: string | null,
  status?: string | null,
  error?: Error | null,
};

export type SurveyInput = {
  pk: string,
  sk: string,
  id: string,
};

export type SurveyFilterInput = {
  primaryCompanyId: string,
  pageSize?: number | null,
  pageNumber?: number | null,
  lastEvaluatedKey?: string | null,
  gridFilters?: KendoGridFilters | null,
  searchText?: string | null,
};

export type UserSYRCOptionConnection = {
  __typename: "UserSYRCOptionConnection",
  items?:  Array<UserSYRCOption | null > | null,
  nextToken?: string | null,
};

export type UserSYRCOption = {
  __typename: "UserSYRCOption",
  id: string,
  firstName: string,
  lastName: string,
  username?: string | null,
};

export type SurveyRouteGridFilterInput = {
  primaryCompanyId: string,
  pageSize?: number | null,
  pageNumber?: number | null,
  lastEvaluatedKey?: string | null,
  gridFilters?: KendoGridFilters | null,
  searchText?: string | null,
};

export type SurveyRouteConnection = {
  __typename: "SurveyRouteConnection",
  items?:  Array<SurveyRoute | null > | null,
  nextToken?: string | null,
};

export type SurveyRoute = {
  __typename: "SurveyRoute",
  id: string,
  pk: string,
  sk: string,
  createdDate?: string | null,
  routeName?: string | null,
  routeDescription?: string | null,
  surveyRouteAssociatedAsset?:  Array<SurveyRouteAssociatedAsset | null > | null,
};

export type SurveyRouteAssociatedAsset = {
  __typename: "SurveyRouteAssociatedAsset",
  assetId?: string | null,
  level1ValueId?: string | null,
  level2ValueId?: string | null,
  level3ValueId?: string | null,
  assetType?: string | null,
  assetName?: string | null,
  lastInspectionDate?: string | null,
  assetDelinquencyDate?: string | null,
  latitude?: string | null,
  longitude?: string | null,
  order?: string | null,
  status?: string | null,
};

export type SurveyRouteInput = {
  pk: string,
  sk: string,
  id: string,
};

export type RolesConnection = {
  __typename: "RolesConnection",
  items?:  Array<Role | null > | null,
  nextToken?: string | null,
};

export type Role = {
  __typename: "Role",
  id: string,
  name?: string | null,
  isMTXRole?: boolean | null,
};

export type UserProfile = {
  __typename: "UserProfile",
  primaryCompany?: PrimaryCompanyModel | null,
  userId?: string | null,
  userName?: string | null,
  userEmail?: string | null,
  isMTXUser?: boolean | null,
  roles?:  Array<RolePermission | null > | null,
};

export type PrimaryCompanyModel = {
  __typename: "PrimaryCompanyModel",
  id?: string | null,
  name?: string | null,
};

export type RolePermission = {
  __typename: "RolePermission",
  level1Id?: string | null,
  level2Id?: string | null,
  level3Id?: string | null,
  level3Value?: string | null,
  roleId?: string | null,
  rolename?: string | null,
  permissions?:  Array<Permission | null > | null,
};

export type Permission = {
  __typename: "Permission",
  screenName?: string | null,
  fullAccess?: boolean | null,
  noAccess?: boolean | null,
  readAccess?: boolean | null,
};

export type PrimaryCompanyModelConnection = {
  __typename: "PrimaryCompanyModelConnection",
  items?:  Array<PrimaryCompanyModel | null > | null,
};

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput,
};

export type CreateTemplateMutation = {
  createTemplate?:  {
    __typename: "Template",
    id: string,
    entityType?: string | null,
    userId: string,
    corViewUserID?: string | null,
    templateName: string,
    templateConfiguration?: string | null,
    createdDate?: string | null,
    updatedDate?: string | null,
    isDeleted?: boolean | null,
    isActive?: boolean | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    templateType?: string | null,
  } | null,
};

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput,
};

export type UpdateTemplateMutation = {
  updateTemplate?:  {
    __typename: "Template",
    id: string,
    entityType?: string | null,
    userId: string,
    corViewUserID?: string | null,
    templateName: string,
    templateConfiguration?: string | null,
    createdDate?: string | null,
    updatedDate?: string | null,
    isDeleted?: boolean | null,
    isActive?: boolean | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    templateType?: string | null,
  } | null,
};

export type DeleteTemplateMutationVariables = {
  userId: string,
  templateName: string,
  templateType: templateType,
};

export type DeleteTemplateMutation = {
  deleteTemplate?: string | null,
};

export type CreateAssetMutationVariables = {
  input: AssetCreateInput,
};

export type CreateAssetMutation = {
  createAsset?:  {
    __typename: "AssetResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateAssetMutationVariables = {
  input: AssetUpdateInput,
};

export type UpdateAssetMutation = {
  updateAsset?:  {
    __typename: "AssetResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type InitializeFileUploadMutationVariables = {
  input: FileUploadInput,
};

export type InitializeFileUploadMutation = {
  initializeFileUpload?:  {
    __typename: "FileUpload",
    contentType?: string | null,
    fileURL?: string | null,
    presignedURL?: string | null,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type BulkImportMutationVariables = {
  input: importInput,
};

export type BulkImportMutation = {
  bulkImport?:  {
    __typename: "BulkImportResponse",
    status?: string | null,
    fileURL?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type DeleteAssetTypeMutationVariables = {
  primaryCompanyId: string,
  assetTypeId: string,
};

export type DeleteAssetTypeMutation = {
  deleteAssetType?: string | null,
};

export type DeleteMeasurementCriteriaMutationVariables = {
  primaryCompanyId: string,
  assetTypeId: string,
  measurementCriteriaId: string,
};

export type DeleteMeasurementCriteriaMutation = {
  deleteMeasurementCriteria?: string | null,
};

export type CreatePrimaryCompanyMutationVariables = {
  input: PrimaryCompanyCreateInput,
};

export type CreatePrimaryCompanyMutation = {
  createPrimaryCompany?:  {
    __typename: "PrimaryCompanyResponse",
    item?:  {
      __typename: "PrimaryCompany",
      id: string,
      entityType?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      isDeleted?: boolean | null,
      primaryCompany?: string | null,
      imageURL?: string | null,
      phoneNumber?: string | null,
      officeNumber?: string | null,
      country?: string | null,
      state?: string | null,
      zipCode?: string | null,
      city?: string | null,
      address?: string | null,
      timeZoneId?: string | null,
      timeZone?: string | null,
      setupStatus?: number | null,
      totalSetup?: number | null,
      corViewCompanyId?: string | null,
    } | null,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdatePrimaryCompanyMutationVariables = {
  input: PrimaryCompanyUpdateInput,
};

export type UpdatePrimaryCompanyMutation = {
  updatePrimaryCompany?:  {
    __typename: "PrimaryCompanyResponse",
    item?:  {
      __typename: "PrimaryCompany",
      id: string,
      entityType?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      isDeleted?: boolean | null,
      primaryCompany?: string | null,
      imageURL?: string | null,
      phoneNumber?: string | null,
      officeNumber?: string | null,
      country?: string | null,
      state?: string | null,
      zipCode?: string | null,
      city?: string | null,
      address?: string | null,
      timeZoneId?: string | null,
      timeZone?: string | null,
      setupStatus?: number | null,
      totalSetup?: number | null,
      corViewCompanyId?: string | null,
    } | null,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type CreateAllLevelNamesMutationVariables = {
  input: LevelNameCreateInput,
};

export type CreateAllLevelNamesMutation = {
  createAllLevelNames?:  {
    __typename: "LevelNameResponse",
    item?:  {
      __typename: "LevelName",
      primaryCompanyId?: string | null,
      level1Name?: string | null,
      level2Name?: string | null,
      level3Name?: string | null,
      level4Name?: string | null,
    } | null,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateAllLevelNamesMutationVariables = {
  input: LevelNameUpdateInput,
};

export type UpdateAllLevelNamesMutation = {
  updateAllLevelNames?:  {
    __typename: "LevelNameResponse",
    item?:  {
      __typename: "LevelName",
      primaryCompanyId?: string | null,
      level1Name?: string | null,
      level2Name?: string | null,
      level3Name?: string | null,
      level4Name?: string | null,
    } | null,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type CreateAllLevelValuesForPrimaryCompanyMutationVariables = {
  input: LevelValueCreateInput,
};

export type CreateAllLevelValuesForPrimaryCompanyMutation = {
  createAllLevelValuesForPrimaryCompany:  {
    __typename: "LevelValueResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  },
};

export type CreateAssetTypeTemplateForPrimaryCompanyMutationVariables = {
  input: AssetTypeTemplateCreateInput,
};

export type CreateAssetTypeTemplateForPrimaryCompanyMutation = {
  createAssetTypeTemplateForPrimaryCompany?:  {
    __typename: "AssetTypeTemplateResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: UserCreateInput,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "AddUpdateUserResponse",
    coreViewUserId: string,
    id: string,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UserUpdateInput,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "AddUpdateUserResponse",
    coreViewUserId: string,
    id: string,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateAllLevelValuesForPrimaryCompanyMutationVariables = {
  input: LevelValueUpdateInput,
};

export type UpdateAllLevelValuesForPrimaryCompanyMutation = {
  updateAllLevelValuesForPrimaryCompany:  {
    __typename: "LevelValueResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  },
};

export type UpdateAssetTypeTemplateForPrimaryCompanyMutationVariables = {
  input: AssetTypeTemplateUpdateInput,
};

export type UpdateAssetTypeTemplateForPrimaryCompanyMutation = {
  updateAssetTypeTemplateForPrimaryCompany?:  {
    __typename: "AssetTypeTemplateResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateExceptionMutationVariables = {
  input: UpdateExceptionInput,
};

export type UpdateExceptionMutation = {
  updateException?:  {
    __typename: "ExceptionResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type DeleteLevelValueForPrimaryCompanyMutationVariables = {
  input: LevelValueDeleteInput,
};

export type DeleteLevelValueForPrimaryCompanyMutation = {
  deleteLevelValueForPrimaryCompany:  {
    __typename: "LevelValueResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  },
};

export type InitializeDataExportMutationVariables = {
  input: DataExportInput,
};

export type InitializeDataExportMutation = {
  initializeDataExport?:  {
    __typename: "DataExportResult",
    exportMessage?: string | null,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateDataExportStatusMutationVariables = {
  input: ExportStatusInput,
};

export type UpdateDataExportStatusMutation = {
  updateDataExportStatus?:  {
    __typename: "ExportStatusResult",
    userId: string,
    status: string,
    exportMessage?: string | null,
  } | null,
};

export type CreateSurveyMutationVariables = {
  input: SurveyCreateInput,
};

export type CreateSurveyMutation = {
  createSurvey?:  {
    __typename: "SurveyResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateSurveyMutationVariables = {
  input: SurveyUpdateInput,
};

export type UpdateSurveyMutation = {
  updateSurvey?:  {
    __typename: "SurveyResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type CreateSurveyRouteMutationVariables = {
  input: SurveyRouteCreateInput,
};

export type CreateSurveyRouteMutation = {
  createSurveyRoute?:  {
    __typename: "SurveyRouteResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type UpdateSurveyRouteMutationVariables = {
  input: SurveyRouteUpdateInput,
};

export type UpdateSurveyRouteMutation = {
  updateSurveyRoute?:  {
    __typename: "SurveyRouteResponse",
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type GetAllTemplateByUserQueryVariables = {
  filter?: TemplateFilterInput | null,
  userId: string,
};

export type GetAllTemplateByUserQuery = {
  getAllTemplateByUser?:  {
    __typename: "TemplateConnection",
    items?:  Array< {
      __typename: "Template",
      id: string,
      entityType?: string | null,
      userId: string,
      corViewUserID?: string | null,
      templateName: string,
      templateConfiguration?: string | null,
      createdDate?: string | null,
      updatedDate?: string | null,
      isDeleted?: boolean | null,
      isActive?: boolean | null,
      createdBy?: string | null,
      updatedBy?: string | null,
      templateType?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateByNameQueryVariables = {
  userId: string,
  templateName: string,
};

export type GetTemplateByNameQuery = {
  getTemplateByName?:  {
    __typename: "Template",
    id: string,
    entityType?: string | null,
    userId: string,
    corViewUserID?: string | null,
    templateName: string,
    templateConfiguration?: string | null,
    createdDate?: string | null,
    updatedDate?: string | null,
    isDeleted?: boolean | null,
    isActive?: boolean | null,
    createdBy?: string | null,
    updatedBy?: string | null,
    templateType?: string | null,
  } | null,
};

export type GetAllAssetsQueryVariables = {
  filter?: FilterInput | null,
};

export type GetAllAssetsQuery = {
  getAllAssets?:  {
    __typename: "AssetConnection",
    items?:  Array< {
      __typename: "AssetOverview",
      id?: string | null,
      pk?: string | null,
      sk?: string | null,
      level1Value?: string | null,
      level1ValueId?: string | null,
      level2Value?: string | null,
      level2ValueId?: string | null,
      level3Value?: string | null,
      level3ValueId?: string | null,
      level4Value?: string | null,
      level4ValueId?: string | null,
      route?: string | null,
      segmentRelation?: string | null,
      assetName?: string | null,
      assetType?: string | null,
      measurementFrequency?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      status?: string | null,
      assetDelinquencyDate?: string | null,
      measurementCriteria?:  Array< {
        __typename: "AssetOverviewMeasurementData",
        id?: string | null,
        measurementSource?: string | null,
        measurementType?: string | null,
        measurementOperator?: string | null,
        criteria?: string | null,
        level4Value?: string | null,
        level4ValueId?: string | null,
        level4Relation?: string | null,
        compliance?: boolean | null,
        unit?: string | null,
      } | null > | null,
      isMeasurementAvailable?: boolean | null,
      surveyRouteIds?: Array< string | null > | null,
      gpsCoordinate?: string | null,
    } | null > | null,
    lastEvaluatedKey?: string | null,
    totalCount?: number | null,
  } | null,
};

export type GetAllSurveyWithAssetQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllSurveyWithAssetQuery = {
  getAllSurveyWithAsset?:  {
    __typename: "SurveyConnection",
    items?:  Array< {
      __typename: "Survey",
      id: string,
      pk: string,
      sk: string,
      surveyName?: string | null,
      surveyType?: string | null,
      surveyPriority?: string | null,
      dataCollectionType?: string | null,
      dueDate?: string | null,
      assignedTo?: string | null,
      troubleShooting?: string | null,
      surveyDescription?: string | null,
      surveyAssociatedAssets?:  Array< {
        __typename: "SurveyAssociatedAsset",
        assetId: string,
        level1ValueId: string,
        level2ValueId: string,
        level3ValueId: string,
        assetType?: string | null,
        assetName?: string | null,
        lastInspectionDate?: string | null,
        assetDelinquencyDate?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        order?: string | null,
        status?: string | null,
      } | null > | null,
      surveyStatus?: string | null,
      dateOfCompletion?: string | null,
      supervisor?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllPrimaryCompanyByUserQueryVariables = {
  userId: string,
};

export type GetAllPrimaryCompanyByUserQuery = {
  getAllPrimaryCompanyByUser?:  {
    __typename: "PrimaryCompanyConnection",
    items?:  Array< {
      __typename: "PrimaryCompany",
      id: string,
      entityType?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      isDeleted?: boolean | null,
      primaryCompany?: string | null,
      imageURL?: string | null,
      phoneNumber?: string | null,
      officeNumber?: string | null,
      country?: string | null,
      state?: string | null,
      zipCode?: string | null,
      city?: string | null,
      address?: string | null,
      timeZoneId?: string | null,
      timeZone?: string | null,
      setupStatus?: number | null,
      totalSetup?: number | null,
      corViewCompanyId?: string | null,
    } | null > | null,
    totalCount?: number | null,
  } | null,
};

export type GetAllCompanyByUserQueryVariables = {
  userId: string,
};

export type GetAllCompanyByUserQuery = {
  getAllCompanyByUser?:  {
    __typename: "CompanyConnection",
    items?:  Array< {
      __typename: "Company",
      id: string,
      entityType?: string | null,
      company: string,
      primaryCompanyId: string,
      primaryCompany: string,
      createdDate?: string | null,
      createdBy?: string | null,
      updatedDate?: string | null,
      updatedBy?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllRegionByUserQueryVariables = {
  userId: string,
};

export type GetAllRegionByUserQuery = {
  getAllRegionByUser?:  {
    __typename: "RegionConnection",
    items?:  Array< {
      __typename: "Region",
      id: string,
      entityType?: string | null,
      region: string,
      primaryCompanyId: string,
      primaryCompany: string,
      companyId: string,
      company: string,
      createdDate?: string | null,
      createdBy?: string | null,
      updatedDate?: string | null,
      updatedBy?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllSegmentByUserQueryVariables = {
  userId: string,
};

export type GetAllSegmentByUserQuery = {
  getAllSegmentByUser?:  {
    __typename: "SegmentConnection",
    items?:  Array< {
      __typename: "Segment",
      id: string,
      entityType?: string | null,
      segmentName: string,
      primaryCompanyId: string,
      primaryCompany: string,
      companyId: string,
      company: string,
      regionId: string,
      region: string,
      createdDate?: string | null,
      createdBy?: string | null,
      updatedDate?: string | null,
      updatedBy: string,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllAssetTypeByUserQueryVariables = {
  userId: string,
};

export type GetAllAssetTypeByUserQuery = {
  getAllAssetTypeByUser?:  {
    __typename: "AssetTypeConnection",
    items?:  Array< {
      __typename: "AssetType",
      id: string,
      entityType?: string | null,
      assetType: string,
      measurementFrequency: string,
      customFrequencyStartDate?: string | null,
      customFrequencyRepeatEveryNumber?: number | null,
      customFrequencyRepeatValue?: string | null,
      createdDate?: string | null,
      createdBy?: string | null,
      updatedDate?: string | null,
      updatedBy?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllInspectionsQueryVariables = {
  filter?: FilterInput | null,
};

export type GetAllInspectionsQuery = {
  getAllInspections?:  {
    __typename: "InspectionConnection",
    items?:  Array< {
      __typename: "Inspection",
      id?: string | null,
      pk?: string | null,
      assetName?: string | null,
      assetType?: string | null,
      station?: string | null,
      complianceStatus?: string | null,
      lastInspectionDate?: string | null,
      inspectionTargetDate?: string | null,
      assetDelinquencyDate?: string | null,
      technician?: string | null,
      measurementPoint?: string | null,
      daysUntilDelinquent?: string | null,
      measurements?:  Array< {
        __typename: "MeasurementModel",
        id?: string | null,
        pk?: string | null,
        assetId?: string | null,
        surveyId?: string | null,
        status?: string | null,
        compliance?: string | null,
        technician?: string | null,
        inspectionDate?: string | null,
        surveyRouteName?: string | null,
        measurementType?: string | null,
        criteria?: string | null,
        measurementValue?: string | null,
        isCompliant?: boolean | null,
        source?: string | null,
        level4?: string | null,
        level4Relation?: string | null,
        level4Type?: string | null,
        level4Value?: string | null,
        level4ValueId?: string | null,
        operatorInfo?: string | null,
        specialist?: string | null,
        unit?: string | null,
        surveyDate?: string | null,
      } | null > | null,
    } | null > | null,
    lastEvaluatedKey?: string | null,
    totalCount?: number | null,
  } | null,
};

export type GetPreSignedURLForUploadedFileQueryVariables = {
  fileURL: string,
};

export type GetPreSignedURLForUploadedFileQuery = {
  getPreSignedURLForUploadedFile?:  {
    __typename: "FilePreSignedUrl",
    presignedURL?: string | null,
    status?: string | null,
    error?:  {
      __typename: "Error",
      message?: string | null,
      details?: Array< string | null > | null,
    } | null,
  } | null,
};

export type GetAllLevelNameByPrimaryCompanyQueryVariables = {
  primaryCompanyId: string,
};

export type GetAllLevelNameByPrimaryCompanyQuery = {
  getAllLevelNameByPrimaryCompany?:  {
    __typename: "LevelNameConnection",
    items?:  Array< {
      __typename: "LevelName",
      primaryCompanyId?: string | null,
      level1Name?: string | null,
      level2Name?: string | null,
      level3Name?: string | null,
      level4Name?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllLevel1ValueByPrimaryCompanyQueryVariables = {
  primaryCompanyId: string,
};

export type GetAllLevel1ValueByPrimaryCompanyQuery = {
  getAllLevel1ValueByPrimaryCompany?:  {
    __typename: "Level1ValueConnection",
    items?:  Array< {
      __typename: "Level1Value",
      id: string,
      level1Value?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllLevel2ValueByPrimaryCompanyQueryVariables = {
  primaryCompanyId: string,
};

export type GetAllLevel2ValueByPrimaryCompanyQuery = {
  getAllLevel2ValueByPrimaryCompany?:  {
    __typename: "Level2ValueConnection",
    items?:  Array< {
      __typename: "Level2Value",
      id: string,
      level1ValueId?: string | null,
      level1Value?: string | null,
      level2Value?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllLevel3ValueByPrimaryCompanyQueryVariables = {
  primaryCompanyId: string,
};

export type GetAllLevel3ValueByPrimaryCompanyQuery = {
  getAllLevel3ValueByPrimaryCompany?:  {
    __typename: "Level3ValueConnection",
    items?:  Array< {
      __typename: "Level3Value",
      id: string,
      level1ValueId?: string | null,
      level1Value?: string | null,
      level2ValueId?: string | null,
      level2Value?: string | null,
      level3Value?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllLevel4ValueByPrimaryCompanyQueryVariables = {
  primaryCompanyId: string,
};

export type GetAllLevel4ValueByPrimaryCompanyQuery = {
  getAllLevel4ValueByPrimaryCompany?:  {
    __typename: "Level4ValueConnection",
    items?:  Array< {
      __typename: "Level4Value",
      id: string,
      level1ValueId?: string | null,
      level1Value?: string | null,
      level2ValueId?: string | null,
      level2Value?: string | null,
      level3ValueId?: string | null,
      level3Value?: string | null,
      level4Value?: string | null,
      segmentType?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllSurveyRouteBySubFilterInputQueryVariables = {
  filter?: SubFilterInput | null,
};

export type GetAllSurveyRouteBySubFilterInputQuery = {
  getAllSurveyRouteBySubFilterInput?:  {
    __typename: "RouteConnection",
    items?:  Array< {
      __typename: "Route",
      id: string,
      entityType?: string | null,
      routeName?: string | null,
      routeDescription?: string | null,
      RouteAssociateAssetList?:  Array< {
        __typename: "RouteAssociatedAsset",
        assetId?: string | null,
        level1ValueId?: string | null,
        level2ValueId?: string | null,
        level3ValueIdList?: Array< string | null > | null,
      } | null > | null,
      createdDate?: string | null,
      createdBy?: string | null,
      updatedDate?: string | null,
      updatedBy?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllAssetTypeByPrimaryCompanyQueryVariables = {
  primaryCompanyId: string,
};

export type GetAllAssetTypeByPrimaryCompanyQuery = {
  getAllAssetTypeByPrimaryCompany?:  {
    __typename: "AssetTypeConnection",
    items?:  Array< {
      __typename: "AssetType",
      id: string,
      entityType?: string | null,
      assetType: string,
      measurementFrequency: string,
      customFrequencyStartDate?: string | null,
      customFrequencyRepeatEveryNumber?: number | null,
      customFrequencyRepeatValue?: string | null,
      createdDate?: string | null,
      createdBy?: string | null,
      updatedDate?: string | null,
      updatedBy?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllSegmentTypeByPrimaryCompanyQueryVariables = {
  primaryCompanyId: string,
};

export type GetAllSegmentTypeByPrimaryCompanyQuery = {
  getAllSegmentTypeByPrimaryCompany?:  {
    __typename: "SegmentTypeConnection",
    items?:  Array< {
      __typename: "SegmentType",
      id?: string | null,
      entityType?: string | null,
      level4Type?: string | null,
      createdDate?: string | null,
      createdBy?: string | null,
      updatedDate?: string | null,
      updatedBy?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllPrimaryCompanyQueryVariables = {
  input?: KendoGridInput | null,
};

export type GetAllPrimaryCompanyQuery = {
  getAllPrimaryCompany?:  {
    __typename: "PrimaryCompanyConnection",
    items?:  Array< {
      __typename: "PrimaryCompany",
      id: string,
      entityType?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      isDeleted?: boolean | null,
      primaryCompany?: string | null,
      imageURL?: string | null,
      phoneNumber?: string | null,
      officeNumber?: string | null,
      country?: string | null,
      state?: string | null,
      zipCode?: string | null,
      city?: string | null,
      address?: string | null,
      timeZoneId?: string | null,
      timeZone?: string | null,
      setupStatus?: number | null,
      totalSetup?: number | null,
      corViewCompanyId?: string | null,
    } | null > | null,
    totalCount?: number | null,
  } | null,
};

export type GetAllCompanyOptionQueryVariables = {
};

export type GetAllCompanyOptionQuery = {
  getAllCompanyOption?:  {
    __typename: "CompanyOptionConnection",
    items?:  Array< {
      __typename: "CompanyOption",
      name?: string | null,
      corViewCompanyId?: string | null,
      timeZoneId?: string | null,
      timeZone?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllTimeZoneOptionQueryVariables = {
};

export type GetAllTimeZoneOptionQuery = {
  getAllTimeZoneOption?:  {
    __typename: "TimeZoneOptionConnection",
    items?:  Array< {
      __typename: "TimeZoneOption",
      id: string,
      name: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAssetHistoryQueryVariables = {
  filter?: AssetHistoryInput | null,
};

export type GetAssetHistoryQuery = {
  getAssetHistory?:  {
    __typename: "AssetHistoryGridConnection",
    items?:  Array< {
      __typename: "AssetHistoryGridModel",
      surveyId?: string | null,
      surveyDate?: string | null,
      comments?:  Array< {
        __typename: "AssetComments",
        pk?: string | null,
        sk?: string | null,
        comment?: string | null,
        commentDate?: string | null,
        entityType?: string | null,
        id?: string | null,
        surveyDate?: string | null,
        surveyId?: string | null,
        user?: string | null,
      } | null > | null,
      measurementModels?:  Array< {
        __typename: "MeasurementModel",
        id?: string | null,
        pk?: string | null,
        assetId?: string | null,
        surveyId?: string | null,
        status?: string | null,
        compliance?: string | null,
        technician?: string | null,
        inspectionDate?: string | null,
        surveyRouteName?: string | null,
        measurementType?: string | null,
        criteria?: string | null,
        measurementValue?: string | null,
        isCompliant?: boolean | null,
        source?: string | null,
        level4?: string | null,
        level4Relation?: string | null,
        level4Type?: string | null,
        level4Value?: string | null,
        level4ValueId?: string | null,
        operatorInfo?: string | null,
        specialist?: string | null,
        unit?: string | null,
        surveyDate?: string | null,
      } | null > | null,
    } | null > | null,
    lastEvaluatedKey?: string | null,
    totalCount?: number | null,
  } | null,
};

export type GetAllUserQueryVariables = {
  input?: KendoGridInput | null,
};

export type GetAllUserQuery = {
  getAllUser?:  {
    __typename: "UserConnection",
    items?:  Array< {
      __typename: "User",
      firstName: string,
      lastName: string,
      coreViewPrimaryCompany:  {
        __typename: "UserPrimaryCompany",
        id?: string | null,
        displayName?: string | null,
      },
      syrcPrimaryCompany:  {
        __typename: "UserPrimaryCompany",
        id?: string | null,
        displayName?: string | null,
      },
      active: boolean,
      username: string,
      email: string,
      postalZip?: string | null,
      addr1?: string | null,
      addrCity?: string | null,
      cellPhone?: string | null,
      officePhone?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      timeZoneId?: string | null,
      timeZone?: string | null,
      coreViewUserId?: string | null,
      id: string,
      entityType?: string | null,
      createdBy?: string | null,
      createdDate?: string | null,
      updatedBy?: string | null,
      updatedDate?: string | null,
      isDeleted?: boolean | null,
      userRoles?:  Array< {
        __typename: "UserRole",
        roleId: string,
        name: string,
        isMTXRole: boolean,
        level3Value?:  {
          __typename: "UserLevel3Value",
          level3ValueName?: string | null,
          level1ValueId?: string | null,
          level2ValueId?: string | null,
          level3ValueId?: string | null,
        } | null,
      } | null > | null,
      accountStatus?: string | null,
    } | null > | null,
    totalCount?: number | null,
  } | null,
};

export type GetPrimaryCompanyByIdQueryVariables = {
  id?: string | null,
};

export type GetPrimaryCompanyByIdQuery = {
  getPrimaryCompanyById?:  {
    __typename: "PrimaryCompany",
    id: string,
    entityType?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    isDeleted?: boolean | null,
    primaryCompany?: string | null,
    imageURL?: string | null,
    phoneNumber?: string | null,
    officeNumber?: string | null,
    country?: string | null,
    state?: string | null,
    zipCode?: string | null,
    city?: string | null,
    address?: string | null,
    timeZoneId?: string | null,
    timeZone?: string | null,
    setupStatus?: number | null,
    totalSetup?: number | null,
    corViewCompanyId?: string | null,
  } | null,
};

export type GetAllUserOptionQueryVariables = {
};

export type GetAllUserOptionQuery = {
  getAllUserOption?:  {
    __typename: "UserOptionConnection",
    items?:  Array< {
      __typename: "UserOption",
      firstName?: string | null,
      lastName?: string | null,
      primaryCompany?:  {
        __typename: "UserPrimaryCompany",
        id?: string | null,
        displayName?: string | null,
      } | null,
      active?: boolean | null,
      username?: string | null,
      email?: string | null,
      postalZip?: string | null,
      addr1?: string | null,
      addrCity?: string | null,
      cellPhone?: string | null,
      officePhone?: string | null,
      stateProvince?: string | null,
      country?: string | null,
      timeZoneId?: string | null,
      timeZone?: string | null,
      corViewUserId?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserByCoreViewUserIdQueryVariables = {
  id: string,
};

export type GetUserByCoreViewUserIdQuery = {
  getUserByCoreViewUserId?:  {
    __typename: "User",
    firstName: string,
    lastName: string,
    coreViewPrimaryCompany:  {
      __typename: "UserPrimaryCompany",
      id?: string | null,
      displayName?: string | null,
    },
    syrcPrimaryCompany:  {
      __typename: "UserPrimaryCompany",
      id?: string | null,
      displayName?: string | null,
    },
    active: boolean,
    username: string,
    email: string,
    postalZip?: string | null,
    addr1?: string | null,
    addrCity?: string | null,
    cellPhone?: string | null,
    officePhone?: string | null,
    stateProvince?: string | null,
    country?: string | null,
    timeZoneId?: string | null,
    timeZone?: string | null,
    coreViewUserId?: string | null,
    id: string,
    entityType?: string | null,
    createdBy?: string | null,
    createdDate?: string | null,
    updatedBy?: string | null,
    updatedDate?: string | null,
    isDeleted?: boolean | null,
    userRoles?:  Array< {
      __typename: "UserRole",
      roleId: string,
      name: string,
      isMTXRole: boolean,
      level3Value?:  {
        __typename: "UserLevel3Value",
        level3ValueName?: string | null,
        level1ValueId?: string | null,
        level2ValueId?: string | null,
        level3ValueId?: string | null,
      } | null,
    } | null > | null,
    accountStatus?: string | null,
  } | null,
};

export type GetAssetTypeTemplateByIdQueryVariables = {
  input?: AssetTypeTemplateInput | null,
};

export type GetAssetTypeTemplateByIdQuery = {
  getAssetTypeTemplateById?:  {
    __typename: "AssetTypeTemplate",
    pk: string,
    sk: string,
    id: string,
    assetType: string,
    entityType?: string | null,
    measurementFrequency?: string | null,
    customFrequencyRepeatEveryNumber?: string | null,
    customFrequencyRepeatValue?: string | null,
    customFrequencyStartDate?: string | null,
    isAssetConfigured?: boolean | null,
    isDeleted?: boolean | null,
    measurementCriterias?:  Array< {
      __typename: "MeasurementCriteria",
      pk: string,
      sk: string,
      id: string,
      compliance?: boolean | null,
      criteria?: string | null,
      measurementSource?: string | null,
      measurementType?: string | null,
      measurementOperator?: string | null,
      unit?: string | null,
      segment?: string | null,
      segmentRelation?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
  } | null,
};

export type GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables = {
  id: string,
};

export type GetAssetTypeTemplateByPrimaryCompanyIdQuery = {
  getAssetTypeTemplateByPrimaryCompanyId?:  {
    __typename: "AssetTypeTemplateConnection",
    items?:  Array< {
      __typename: "AssetTypeTemplate",
      pk: string,
      sk: string,
      id: string,
      assetType: string,
      entityType?: string | null,
      measurementFrequency?: string | null,
      customFrequencyRepeatEveryNumber?: string | null,
      customFrequencyRepeatValue?: string | null,
      customFrequencyStartDate?: string | null,
      isAssetConfigured?: boolean | null,
      isDeleted?: boolean | null,
      measurementCriterias?:  Array< {
        __typename: "MeasurementCriteria",
        pk: string,
        sk: string,
        id: string,
        compliance?: boolean | null,
        criteria?: string | null,
        measurementSource?: string | null,
        measurementType?: string | null,
        measurementOperator?: string | null,
        unit?: string | null,
        segment?: string | null,
        segmentRelation?: string | null,
        isDeleted?: boolean | null,
      } | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAssetHistoryGraphDataQueryVariables = {
  input?: AssetHistoryGraphInput | null,
};

export type GetAssetHistoryGraphDataQuery = {
  getAssetHistoryGraphData?:  {
    __typename: "AssetGraphModelConnection",
    items?:  Array< {
      __typename: "AssetGraphModel",
      measurementType?: string | null,
      dateTime?: string | null,
      value?: string | null,
      unit?: string | null,
      level4ValueId?: string | null,
      level4Value?: string | null,
    } | null > | null,
  } | null,
};

export type GetAssetDetailsByIdQueryVariables = {
  input: AssetMetadataInput,
};

export type GetAssetDetailsByIdQuery = {
  getAssetDetailsById?:  {
    __typename: "Asset",
    pk?: string | null,
    sk?: string | null,
    assetDelinquencyDate?: string | null,
    assetName?: string | null,
    assetType?: string | null,
    entityType?: string | null,
    id?: string | null,
    imageUrls?: Array< string | null > | null,
    associatedLevel4?: Array< string | null > | null,
    imagePresignedUrls?: Array< string | null > | null,
    latitude?: string | null,
    level1ValueId?: string | null,
    level1Value?: string | null,
    level2ValueId?: string | null,
    level2Value?: string | null,
    level3ValueId?: string | null,
    level3Value?: string | null,
    level4Value?: string | null,
    level4ValueId?: string | null,
    level4Type?: string | null,
    longitude?: string | null,
    measurementFrequency?: string | null,
    measurementStartDate?: string | null,
    nextDelinquencyDate?: string | null,
    repeatesEvery?: string | null,
    repeatesEveryInterval?: string | null,
    status?: string | null,
    atmosphericCorrosionGrade?: string | null,
    techResponsible?: Array< string | null > | null,
    surveyRoute?: string | null,
    createdDate?: string | null,
    measurementCriteria?:  Array< {
      __typename: "AssetOverviewMeasurementData",
      id?: string | null,
      measurementSource?: string | null,
      measurementType?: string | null,
      measurementOperator?: string | null,
      criteria?: string | null,
      level4Value?: string | null,
      level4ValueId?: string | null,
      level4Relation?: string | null,
      compliance?: boolean | null,
      unit?: string | null,
    } | null > | null,
  } | null,
};

export type GetLevelValueByPrimaryCompanyIdQueryVariables = {
  primaryCompanyId: string,
};

export type GetLevelValueByPrimaryCompanyIdQuery = {
  getLevelValueByPrimaryCompanyId?:  {
    __typename: "LevelValueOutput",
    level1Values?:  Array< {
      __typename: "Level1ValueOutput",
      pk?: string | null,
      sk?: string | null,
      id?: string | null,
      value?: string | null,
      level2Values?:  Array< {
        __typename: "Level2ValueOutput",
        pk?: string | null,
        sk?: string | null,
        id?: string | null,
        value?: string | null,
        level3Values?:  Array< {
          __typename: "Level3ValueOutput",
          pk?: string | null,
          sk?: string | null,
          id?: string | null,
          value?: string | null,
          isAssetConfigured?: boolean | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetAllExceptionsQueryVariables = {
  filter?: FilterInput | null,
};

export type GetAllExceptionsQuery = {
  getAllExceptions?:  {
    __typename: "ExceptionConnection",
    items?:  Array< {
      __typename: "Exceptions",
      id: string,
      pk: string,
      assetName: string,
      assetType: string,
      assetTypeId?: string | null,
      complianceStatus?: string | null,
      comments?: string | null,
      workFlowStatus?: string | null,
      targetRemediationDate?: string | null,
      specialist?: string | null,
      technician?: string | null,
      assetDelinquencyDate?: string | null,
      measurements?:  Array< {
        __typename: "MeasurementModel",
        id?: string | null,
        pk?: string | null,
        assetId?: string | null,
        surveyId?: string | null,
        status?: string | null,
        compliance?: string | null,
        technician?: string | null,
        inspectionDate?: string | null,
        surveyRouteName?: string | null,
        measurementType?: string | null,
        criteria?: string | null,
        measurementValue?: string | null,
        isCompliant?: boolean | null,
        source?: string | null,
        level4?: string | null,
        level4Relation?: string | null,
        level4Type?: string | null,
        level4Value?: string | null,
        level4ValueId?: string | null,
        operatorInfo?: string | null,
        specialist?: string | null,
        unit?: string | null,
        surveyDate?: string | null,
      } | null > | null,
    } | null > | null,
    lastEvaluatedKey?: string | null,
    totalCount: number,
  } | null,
};

export type GetAssetByIdQueryVariables = {
  input: AssetMetadataInput,
};

export type GetAssetByIdQuery = {
  getAssetById?:  {
    __typename: "AssetOutput",
    pk?: string | null,
    sk?: string | null,
    id?: string | null,
    primaryCompanyId?: string | null,
    level1ValueId?: string | null,
    level2ValueId?: string | null,
    level3ValueId?: string | null,
    level4Value?: string | null,
    level4ValueId?: string | null,
    level4Type?: string | null,
    assetTypeId?: string | null,
    assetName?: string | null,
    lastInspectionDate?: string | null,
    mappingAssetId?: string | null,
    milestone?: string | null,
    creationDate?: string | null,
    latitude?: string | null,
    longitude?: string | null,
    comments?: string | null,
    locationDescription?: string | null,
    effectiveDate?: string | null,
    assetProperties?:  Array< {
      __typename: "KeyValueOutput",
      Key?: string | null,
      Value?: string | null,
    } | null > | null,
    imageUrls?: Array< string | null > | null,
    assetHierarchies?:  Array< {
      __typename: "AssetHierarchy",
      level3ValueId?: string | null,
      level4ValueId?: string | null,
      level4Value?: string | null,
      level4Type?: string | null,
      level4Relation?: string | null,
    } | null > | null,
    assetMeasurementCriterias?:  Array< {
      __typename: "AssetMeasurementCriteriaOutput",
      pk?: string | null,
      sk?: string | null,
      id?: string | null,
      compliance?: boolean | null,
      criteria?: string | null,
      measurementSource?: string | null,
      measurementType?: string | null,
      measurementOperator?: string | null,
      unit?: string | null,
      level4Relation?: string | null,
      level4Type?: string | null,
      level4Value?: string | null,
      level4ValueId?: string | null,
    } | null > | null,
    imagePresignedUrls?: Array< string | null > | null,
    associatedLevel4?: Array< string | null > | null,
  } | null,
};

export type GetAllAssetOptionQueryVariables = {
  input: AssetFilterInput,
};

export type GetAllAssetOptionQuery = {
  getAllAssetOption?:  {
    __typename: "AssetOptionConnection",
    items?:  Array< {
      __typename: "AssetOption",
      pk?: string | null,
      sk?: string | null,
      id?: string | null,
      level1ValueId?: string | null,
      level2ValueId?: string | null,
      level3ValueId?: string | null,
      level4ValueId?: string | null,
      assetName?: string | null,
      status?: string | null,
      error?:  {
        __typename: "Error",
        message?: string | null,
        details?: Array< string | null > | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSurveyByIdQueryVariables = {
  input: SurveyInput,
};

export type GetSurveyByIdQuery = {
  getSurveyById?:  {
    __typename: "Survey",
    id: string,
    pk: string,
    sk: string,
    surveyName?: string | null,
    surveyType?: string | null,
    surveyPriority?: string | null,
    dataCollectionType?: string | null,
    dueDate?: string | null,
    assignedTo?: string | null,
    troubleShooting?: string | null,
    surveyDescription?: string | null,
    surveyAssociatedAssets?:  Array< {
      __typename: "SurveyAssociatedAsset",
      assetId: string,
      level1ValueId: string,
      level2ValueId: string,
      level3ValueId: string,
      assetType?: string | null,
      assetName?: string | null,
      lastInspectionDate?: string | null,
      assetDelinquencyDate?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      order?: string | null,
      status?: string | null,
    } | null > | null,
    surveyStatus?: string | null,
    dateOfCompletion?: string | null,
    supervisor?: string | null,
  } | null,
};

export type GetAllSurveyQueryVariables = {
  input?: SurveyFilterInput | null,
};

export type GetAllSurveyQuery = {
  getAllSurvey?:  {
    __typename: "SurveyConnection",
    items?:  Array< {
      __typename: "Survey",
      id: string,
      pk: string,
      sk: string,
      surveyName?: string | null,
      surveyType?: string | null,
      surveyPriority?: string | null,
      dataCollectionType?: string | null,
      dueDate?: string | null,
      assignedTo?: string | null,
      troubleShooting?: string | null,
      surveyDescription?: string | null,
      surveyAssociatedAssets?:  Array< {
        __typename: "SurveyAssociatedAsset",
        assetId: string,
        level1ValueId: string,
        level2ValueId: string,
        level3ValueId: string,
        assetType?: string | null,
        assetName?: string | null,
        lastInspectionDate?: string | null,
        assetDelinquencyDate?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        order?: string | null,
        status?: string | null,
      } | null > | null,
      surveyStatus?: string | null,
      dateOfCompletion?: string | null,
      supervisor?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllSYRCUserOptionQueryVariables = {
};

export type GetAllSYRCUserOptionQuery = {
  getAllSYRCUserOption?:  {
    __typename: "UserSYRCOptionConnection",
    items?:  Array< {
      __typename: "UserSYRCOption",
      id: string,
      firstName: string,
      lastName: string,
      username?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllSurveyRouteQueryVariables = {
  input?: SurveyRouteGridFilterInput | null,
};

export type GetAllSurveyRouteQuery = {
  getAllSurveyRoute?:  {
    __typename: "SurveyRouteConnection",
    items?:  Array< {
      __typename: "SurveyRoute",
      id: string,
      pk: string,
      sk: string,
      createdDate?: string | null,
      routeName?: string | null,
      routeDescription?: string | null,
      surveyRouteAssociatedAsset?:  Array< {
        __typename: "SurveyRouteAssociatedAsset",
        assetId?: string | null,
        level1ValueId?: string | null,
        level2ValueId?: string | null,
        level3ValueId?: string | null,
        assetType?: string | null,
        assetName?: string | null,
        lastInspectionDate?: string | null,
        assetDelinquencyDate?: string | null,
        latitude?: string | null,
        longitude?: string | null,
        order?: string | null,
        status?: string | null,
      } | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSurveyRouteByIdQueryVariables = {
  input: SurveyRouteInput,
};

export type GetSurveyRouteByIdQuery = {
  getSurveyRouteById?:  {
    __typename: "SurveyRoute",
    id: string,
    pk: string,
    sk: string,
    createdDate?: string | null,
    routeName?: string | null,
    routeDescription?: string | null,
    surveyRouteAssociatedAsset?:  Array< {
      __typename: "SurveyRouteAssociatedAsset",
      assetId?: string | null,
      level1ValueId?: string | null,
      level2ValueId?: string | null,
      level3ValueId?: string | null,
      assetType?: string | null,
      assetName?: string | null,
      lastInspectionDate?: string | null,
      assetDelinquencyDate?: string | null,
      latitude?: string | null,
      longitude?: string | null,
      order?: string | null,
      status?: string | null,
    } | null > | null,
  } | null,
};

export type GetAllRolesQueryVariables = {
  isMTXRole: boolean,
};

export type GetAllRolesQuery = {
  getAllRoles?:  {
    __typename: "RolesConnection",
    items?:  Array< {
      __typename: "Role",
      id: string,
      name?: string | null,
      isMTXRole?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserProfileQueryVariables = {
  userId: string,
};

export type GetUserProfileQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    primaryCompany?:  {
      __typename: "PrimaryCompanyModel",
      id?: string | null,
      name?: string | null,
    } | null,
    userId?: string | null,
    userName?: string | null,
    userEmail?: string | null,
    isMTXUser?: boolean | null,
    roles?:  Array< {
      __typename: "RolePermission",
      level1Id?: string | null,
      level2Id?: string | null,
      level3Id?: string | null,
      level3Value?: string | null,
      roleId?: string | null,
      rolename?: string | null,
      permissions?:  Array< {
        __typename: "Permission",
        screenName?: string | null,
        fullAccess?: boolean | null,
        noAccess?: boolean | null,
        readAccess?: boolean | null,
      } | null > | null,
    } | null > | null,
  } | null,
};

export type GetSyrcPrimaryCompaniesQueryVariables = {
};

export type GetSyrcPrimaryCompaniesQuery = {
  getSyrcPrimaryCompanies?:  {
    __typename: "PrimaryCompanyModelConnection",
    items?:  Array< {
      __typename: "PrimaryCompanyModel",
      id?: string | null,
      name?: string | null,
    } | null > | null,
  } | null,
};

export type OnUpdateDataExportStatusSubscriptionVariables = {
  userId: string,
};

export type OnUpdateDataExportStatusSubscription = {
  onUpdateDataExportStatus?:  {
    __typename: "ExportStatusResult",
    userId: string,
    status: string,
    exportMessage?: string | null,
  } | null,
};
