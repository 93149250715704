<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          {{ 'ASSET_SETUP_PAGE.TITLE' | translate }}
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px; margin-right: -10px">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            ></app-search>
          </div>
          <div
            class="col-xs-6 col-sm-auto col-md-auto col-lg-auto"
            *ngIf="showFilterBtn"
          >
            <button
              (click)="toggleFilterTray()"
              class="btn mtx-btn-secondary mtx-font-weight-500"
            >
              <span style="vertical-align: bottom" class="material-icons"
                >filter_alt</span
              >
              {{ 'GENERIC_Buttons.FILTERS' | translate }}
              <span style="vertical-align: bottom" class="material-icons"
                >arrow_drop_down</span
              >
            </button>
          </div>
          <div *ngIf="permission" class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button class="asset-modal-button" (click)="redirectToSingleAssetCreation()">
              <div class="asset-modal-text">
                <span class="asset-modal-text">
                  <span class="button-text-alignment">{{
                    'ASSET_SETUP.ASSET' | translate
                  }}</span>
                </span>
              </div>
            </button>
          </div>
          <div *ngIf="permission" class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button class="importasset-modal-button">
              <div class="asset-modal-text">
                <span class="asset-modal-text">
                  <span class="button-text-alignment">{{
                    'ASSET_SETUP.IMPORT' | translate
                  }}</span>
                </span>
              </div>
            </button>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button
              class="btn mtx-btn-primary"
              style="height: 38px"
              (click)="openDialog()"
            >
              <span style="vertical-align: middle" class="material-icons"
                >download</span
              >
              {{ 'GENERIC_Buttons.EXPORT' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row justify-content-end mb-3"
    style="float: right"
    *ngIf="showHierarchicalFilter"
  >
    <app-heirarchy-filter-buttons (filterSearchButtonClicked)="filterSearchButtonClicked()"  (isTrayCollapsed)="collapseFilterTray()">
    </app-heirarchy-filter-buttons>
    <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
  </div>
  <div class="mt-3">
    <kendo-grid
      #grid
      [data]="gridData"
      id="grid"
      [filterable]="'menu'"
      [skip]="state.skip"
      [pageable]="true"
      [pageSize]="state.take"
      [filter]="state.filter"
      (dataStateChange)="dataStateChange($event)"
      [reorderable]="true"
      (columnReorder)="onColumnReorder($event)"
      [sortable]="false"
      [resizable]="true"
      style="width: 100%; box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15)"
      [rowSticky]="rowSticky"
    >
      <kendo-grid-column
        [resizable]="false"
        [headerClass]="'firstColGridHeader non-reorderable'"
        [width]="60"
        [filterable]="false"
        field="id"
        title=""
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex-container">
            <div class="icon-container">
              <span
                class="k-icon k-font-icon my-custom-icon-class"
                style="position: relative"
                (click)="openDropdownMenu($event, dataItem)"
              ></span>
              <span
                *ngIf="isSticky(dataItem.id)"
                class="k-icon k-font-icon k-i-pin pin-icon-css"
                (click)="rowActionStickyRow($event, dataItem)"
              ></span>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [headerClass]="'firstColGridHeader'"
        field="level1Value"
        [title]= level1Name
        [minResizableWidth]="80"
        [width]="150"
        *ngIf="IsColumnSelected('level1Value')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="level2Value"
        [title]=level2Name
        [minResizableWidth]="80"
        [width]="190"
        *ngIf="IsColumnSelected('level2Value')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="level3Value"
        [title]=level3Name
        [minResizableWidth]="80"
        [width]="120"
        *ngIf="IsColumnSelected('level3Value')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="level4Value"
        title="Segment"
        [minResizableWidth]="80"
        [width]="200"
        *ngIf="IsColumnSelected('level4Value')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="route"
        title="Route"
        [minResizableWidth]="80"
        [width]="150"
        *ngIf="IsColumnSelected('route')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="segmentRelation"
        title="Segment Relation"
        [minResizableWidth]="80"
        [width]="150"
        *ngIf="IsColumnSelected('segmentRelation')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="assetName"
        title="Asset Name"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('assetName')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="assetType"
        [title]="'ASSET_SETUP.ASSET_TYPE' | translate"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('assetType')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="measurementFrequency"
        [title]="'ASSET_SETUP.MEASUREMENT_FREQUENCY' | translate"
        [minResizableWidth]="80"
        [width]="160"
      ></kendo-grid-column>
      <kendo-grid-column
        field="gpsCoordinate"
        [title]="'ASSET_SETUP.GPS_COORDINATE' | translate"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('gpsCoordinate')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="assetDelinquencyDate"
        title="Asset Delinquency Date"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('assetDelinquencyDate')"
        format="{0:dd/MM/yyyy}"
        filter="date"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="right-alignment">{{
            dataItem.assetDelinquencyDate | customDate
          }}</span>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridDetailTemplate let-dataItem>
        <div class="expanded-content">
          <kendo-grid [kendoGridBinding]="dataItem.measurements" [height]="dataItem.measurementsProcesses?.table?.length > 0 ? 200 : 110">
            <kendo-grid-column
              field="measurementSource"
              title="Measurement Source"
              [minResizableWidth]="50"
            ></kendo-grid-column>
            <kendo-grid-column
              field="measurementType"
              title="Measurement Type"
              [minResizableWidth]="50"
            ></kendo-grid-column>
            <kendo-grid-column
              field="measurementOperator"
              title="Measurement Operator"
              [minResizableWidth]="50"
            ></kendo-grid-column>
            <kendo-grid-column
              field="criteria"
              title="Criteria"
              [minResizableWidth]="50"
            ></kendo-grid-column>
            <kendo-grid-column
              field="level4Value"
              title="Segment"
              [minResizableWidth]="50"
            ></kendo-grid-column>
            <kendo-grid-column
              field="level4Relation"
              title="Segment Relation"
              [minResizableWidth]="50"
            ></kendo-grid-column>
            <kendo-grid-column
              field="isCompliance"
              title="Compliance Measurement?"
              [minResizableWidth]="50"
            >
            <ng-template kendoGridCellTemplate let-dataItem>
              <input type="checkbox" class="form-check-input"  [checked]="dataItem.isCompliance" [disabled]="true"/>
            </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-grid>

    <app-row-actions
      class="row-action"
      [visible]="dropdownVisible"
      [isStickyRow]="isStickyRow"
      [positionX]="dropdownX"
      [rowId]="rowId"
      [positionY]="dropdownY"
      (optionSelected)="handleDropdownOption($event)"
      [inputScreen]="inputScreen"
      [selectedRowData]="selectedRowData"
      [permission] = "permission"
      (visibleChange)="handleDropdownVisibilityChange($event)"
    ></app-row-actions>
  </div>
</div>
