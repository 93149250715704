import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AUTHENTICATE_USER_STATE,
  AUTHENTICATE_USER_STATE_TYPE_REMOVE,
  AUTHENTICATE_USER_STATE_TYPE_SET,
} from '../../shared/constants';

export interface AuthenticateUserStateModel {
  isAuthenticated: boolean;
  idToken: string | null;
  accessToken: string | null;
  corViewUserId: string | null;
  name: string | null;
  syrcPrimaryCompanyName: string | null,
  syrcPrimaryCompanyId: string | null;
  coreViewPrimaryCompanyName:string | null,
  coreViewPrimaryCompanyId: string | null;
  syrcUserId: string | null;

}

export class SetAuthenticateUser {
  static readonly type = AUTHENTICATE_USER_STATE_TYPE_SET;
  constructor(
    public isAuthenticated: boolean,
    public idToken: string,
    public accessToken: string,
    public corViewUserId: string,
    public name: string,
    public coreViewPrimaryCompanyId: string,
    public coreViewPrimaryCompanyName: string,
    public syrcPrimaryCompanyId: string,
    public syrcPrimaryCompanyName: string,
    public syrcUserId: string,
   
  ) {}
}

// Define RemoveAuthenticateUser action
export class RemoveAuthenticateUser {
  static readonly type = AUTHENTICATE_USER_STATE_TYPE_REMOVE;
}

@State<AuthenticateUserStateModel>({
  name: AUTHENTICATE_USER_STATE,
  defaults: {
    isAuthenticated: false,
    idToken: null,
    accessToken: null,
    corViewUserId: null,
    name: null,
    syrcPrimaryCompanyName: null,
    coreViewPrimaryCompanyName: null,
    syrcUserId: null,
    syrcPrimaryCompanyId : null,
    coreViewPrimaryCompanyId : null
  },
})
@Injectable()
export class AuthenticateUserState {
  //selector has logic to get data
  @Selector()
  static getIsAuthenticated(state: AuthenticateUserStateModel) {
    return state.isAuthenticated;
  }
  //get IdToken which is from STS
  @Selector()
  static getIdToken(state: AuthenticateUserStateModel) {
    return state.idToken;
  }
  //get AccessToken which is from STS
  @Selector()
  static getAccessToken(state: AuthenticateUserStateModel) {
    return state.accessToken;
  }
  //get CorViewUserId of logged in user
  @Selector()
  static getLoggedInCorViewUserId(state: AuthenticateUserStateModel) {
    return state.corViewUserId;
  }
  //get name of logged in user
  @Selector()
  static getLoggedInName(state: AuthenticateUserStateModel) {
    return state.name;
  }
  //get primary company of logged in user
  @Selector()
  static getSyrcPrimaryCompanyName(state: AuthenticateUserStateModel) {
    return state.syrcPrimaryCompanyName;
  }

  @Selector()
  static getCoreViewPrimaryCompanyName(state: AuthenticateUserStateModel) {
    return state.coreViewPrimaryCompanyName;
  }
 //get core view primary company Id of logged in user
  @Selector()
  static getCoreViewPrimaryCompanyId(state: AuthenticateUserStateModel) {
    return state.coreViewPrimaryCompanyId;
  }

   //get syrc primary company Id of logged in user
   @Selector()
   static getSyrcPrimaryCompanyId(state: AuthenticateUserStateModel) {
     return state.syrcPrimaryCompanyId;
   }

   //get syrc syrcUserId of logged in user
   @Selector()
   static getSyrcUserId(state: AuthenticateUserStateModel) {
     return state.syrcUserId;
   }

  

  //actions to do operation
  @Action(SetAuthenticateUser)
  setAuthenticateUser(
    { patchState }: StateContext<AuthenticateUserStateModel>,
    {
      isAuthenticated,
      idToken,
      accessToken,
      corViewUserId,
      name,
      syrcPrimaryCompanyName,
      coreViewPrimaryCompanyName,
      syrcPrimaryCompanyId,
      coreViewPrimaryCompanyId,
      syrcUserId
    }: SetAuthenticateUser,
  ): void {
    patchState({
      isAuthenticated: isAuthenticated,
      idToken: idToken,
      accessToken: accessToken,
      corViewUserId: corViewUserId,
      name: name,
      syrcPrimaryCompanyName: syrcPrimaryCompanyName,
      syrcPrimaryCompanyId: syrcPrimaryCompanyId,
      coreViewPrimaryCompanyId:coreViewPrimaryCompanyId,
      coreViewPrimaryCompanyName: coreViewPrimaryCompanyName,
      syrcUserId: syrcUserId
    });
  }

  @Action(RemoveAuthenticateUser)
  removeAuthenticateUser({
    setState,
  }: StateContext<AuthenticateUserStateModel>): void {
    setState({
      isAuthenticated: false,
      idToken: '',
      accessToken: '',
      corViewUserId: '',
      name: '',
      coreViewPrimaryCompanyName: '',
      syrcPrimaryCompanyName: '',
      coreViewPrimaryCompanyId: '',
      syrcPrimaryCompanyId:'',
      syrcUserId: ''
    });
  }
}
