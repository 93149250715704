import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  ColumnBase,
  ColumnComponent,
  ColumnReorderEvent,
  DataStateChangeEvent,
  FilterService,
  GridComponent,
  GridDataResult,
  RowArgs,
  RowStickyFn,
} from '@progress/kendo-angular-grid';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import {
  ASSET_SETUP_FILTER_INPUT,
  ASSETSETUP,
  CANCEL_BTN_TEXT,
  COMPANY,
  CONFIRM,
  DEFAULT,
  DEFAULTPAGESIZE,
  DELETE_ASSEST_METHOD,
  DELETE_ASSET,
  DELETE_ASSET_CONFIRMATION_MSG,
  EDIT_ASSET_ROUTE,
  FETCHING_ASSET_SETUP_ERROR,
  HELP,
  SHORT,
  ND,
  PRIMARY_COMPANY,
  RD,
  REGION,
  SEARCHASSETSETUP,
  ST,
  TH,
  ZERO,
  REDIRECT_INSPECTION,
  ASSET_OVERVIEW,
} from '../../shared/constants';
import { searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { ExceptionService } from '../exception/service/exception.service';
import { LocalStorageService } from '../../shared/service/local-storage/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { MatDialog } from '@angular/material/dialog';
import { ExportModalComponent } from '../../shared/export-modal/export-modal.component';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { AssetSetupService } from '../../core/services/asset-setup.service';
import { AssetSetupData } from '../../core/models/assets';
import {
  FilterInput,
  HierarchyFilterInput,
  KendoGridFilters,
  SurveyRouteFilterInput,
  templateType,
} from '../../../awsAppSync/API';
import { Store } from '@ngxs/store';
import { SetAsset } from '../../core/store/asset-setup.state';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Level3State } from '../../core/store/filter-level3.state';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { SegmentState } from '../../core/store/filter-segments';
import { SurveyRouteState } from '../../core/store/filter-serveyrotes';
import { AssetTypeState } from '../../core/store/filter-assettype';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { LevelNameState } from '../../core/store/filtet-levelName.state';
import { SetAssetData } from '../../core/store/asset.state';
import { PermissionService } from '../../core/services/permission.service';
@Component({
  selector: 'app-asset-setup',
  templateUrl: './asset-setup.component.html',
  styleUrl: './asset-setup.component.css',
})
export class AssetSetupComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('customFilterContainer', { static: false })
  customFilter!: ElementRef;
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  @ViewChild('grid') grid!: GridComponent;
  @ViewChildren(ColumnComponent) columns!: QueryList<ColumnComponent>;
  public templateTypeInput = templateType.asset_setup;
  public inputScreen = ASSETSETUP;
  public _selectedColumns: string[] = [
    'level1Value',
    'level2Value',
    'level3Value',
    'level4Value',
    'route',
    'segmentRelation',
    'assetName',
    'assetType',
    'measurementFreqency',
    'gpsCoordinate',
    'assetDelinquencyDate',
  ];
  public assetSetupGridData: AssetSetupData[] = [];
  public originalData: any[] = [];
  public svgSearch: SVGIcon = searchIcon;
  public addedTemplateId = ZERO;
  public message = '';
  public templateId = ZERO;
  public rowId = ZERO;
  currentPage = ZERO;
  pageChanged = false;
  dropdownVisible = false;
  dropdownX = ZERO;
  dropdownY = ZERO;
  currentDataItem: any;
  isStickyRow = false;
  filteredData: any[] = [];
  public complaiceStatusFilterValues: any[] = [];
  public searchPlaceholder = SEARCHASSETSETUP;
  public gridDataToExport: any[] = [];
  public hiddenColumns: string[] = [];
  private scrollListener: (() => void) | undefined;
  filterInput = ASSET_SETUP_FILTER_INPUT; // need to be removed after filter integration
  selectedRowData: any;
  public isLoading = true;
  filterModel: FilterInput = {};
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId: string | null = '';
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute: string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  hierarchyFilterInput: HierarchyFilterInput[] = [];
  surveyRouteFilterInput: SurveyRouteFilterInput[] = [];
  storeLevelName$: Observable<string[]>;
  selectedLevelName: string[] = [];
  level1Name = '';
  level2Name = '';
  level3Name = '';
  permission = false;

  public gridData: GridDataResult = { data: [], total: 0 };
  public searchText = '';
  kendoGridFilters: KendoGridFilters = {};
  public pageSize = DEFAULTPAGESIZE;

  constructor(
    public exceptionService: ExceptionService,
    public dialog: MatDialog,
    private toastr: CustomToastrService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private assetSetupService: AssetSetupService,
    private router: Router,
    private store: Store,
    private popupDialogService: PopupDialogService,
    public permissionService: PermissionService,
    private hirarchyFilter: HeirarchyFilterServiceService,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.storeLevelName$ = this.store.select(LevelNameState.getLevelName);
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );
  }

  ngOnInit() {
    //this.getAssetSetupGridData();
    this.permission = this.permissionService.hasPermission(ASSET_OVERVIEW, 'fullAccess');
    this.loadAssetOverviewData();
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryComapnyId = '';
      this.selectedprimaryComapnyId = id;
    });

    this.storeLevelName$.subscribe((level1Name) => {
      this.selectedLevelName = level1Name;
      this.generateLevelNames();
    });

    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
      this.hierarchyFilterInput = [];
    });

    this.storeSegmentTypeSet$.subscribe((segmentType) => {
      this.selectedSegmentType = [];
      this.selectedSegmentType = segmentType;
    });

    this.storeSelectedSegments$.subscribe((segment) => {
      this.selectedSegments = [];
      this.selectedSegments = segment;
    });

    this.storeSelectedSurveyRoute$.subscribe((Surveyroute) => {
      this.surveyRouteFilterInput = [];
      this.selectedSurveyRoute = Surveyroute;
    });

    this.storeSelectedAssetType$.subscribe((assettype) => {
      this.selectedAssetType = [];
      this.selectedAssetType = assettype;
    });

    window.addEventListener('scroll', this.onWindowScroll, true);
    this.originalData = [...this.gridData.data];
    this.route.queryParamMap.subscribe((params) => {
      const assetName = params.get('assetName') || undefined;
      if (assetName) {
        const decodedValue = decodeURIComponent(assetName);
        this.urlFilter(decodedValue);
      }
    });

    this.hirarchyFilter.isClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });

    if(this.selectedSurveyRoute.length > 0){
      this.fetchSurveyRouteValues(this.selectedprimaryComapnyId!);
    }
    else{
      this.fetchLevel3Values(this.selectedprimaryComapnyId!);
    }
  }

  onClearFilterPressed(value: boolean) {
    this.updateFilterModel(value);
  }

  ngAfterViewInit(): void {
    const gridWrapper =
      this.grid.wrapper.nativeElement.querySelector('.k-grid-content');
    this.scrollListener = this.renderer.listen(
      gridWrapper,
      'scroll',
      this.onScroll.bind(this),
    );
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onWindowScroll, true);
    if (this.scrollListener) {
      this.scrollListener();
    }
  }

  generateLevelNames() {
    if (this.selectedLevelName[0]) {
      this.level1Name = this.selectedLevelName[0];
    }
    if (this.selectedLevelName[1]) {
      this.level2Name = this.selectedLevelName[1];
    }
    if (this.selectedLevelName[2]) {
      this.level3Name = this.selectedLevelName[2];
    }
    this.cdr.detectChanges();
  }

  async getAssetSetupGridData(): Promise<void> {
    try {
      const assetConnection = await this.assetSetupService.getAssets(
        this.filterInput,
      );
      if (assetConnection) {
        this.gridData = {
          data: assetConnection.assets,
          total:
            assetConnection?.totalCount != null
              ? assetConnection?.totalCount
              : 0,
        };
        this.loadData(this.gridData.data);
        this.isLoading = false;
      }
    } catch (error) {
      console.error(FETCHING_ASSET_SETUP_ERROR, error);
    }
  }

  redirectToSingleAssetCreation() {
    this.router.navigate(['assets/asset-setup/create']);
  }

  filterSearchButtonClicked(): void {
    this.isLoading = true;
    this.loadAssetOverviewData();
    this.myStickyRows = [];
  }

  async loadAssetOverviewData(): Promise<void> {
    try {
      this.isLoading = true;
      this.updateFilterModel(false);
      const response = await this.assetSetupService.getAssets(this.filterModel);
      if (response) {
        this.gridData = {
          data: response.assets,
          total: response?.totalCount != null ? response?.totalCount : 0,
        };

        this.originalData = response.assets;
        this.cdr.markForCheck();
        this.getDistinctValues('assetType');
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  async fetchLevel3Values(primaryCompanyId: string) {
    this.isLoading = true;
    const allLevel3Values: HierarchyFilterInput[] = [];
    const level3Values =
      await this.hirarchyFilter.getAllLevel3ValueByPrimaryCompany(
        primaryCompanyId,
      );
    if (level3Values?.items) {
      level3Values.items.forEach((item) => {
        if (item) {
          if (this.selectedLevel3.includes(item.id))
            allLevel3Values.push({
              level1: item.level1ValueId || '',
              level2: item.level2ValueId || '',
              level3: item.id || '',
            });
        }
      });
    }
    this.hierarchyFilterInput = allLevel3Values;
    this.loadAssetOverviewData();
  }

  async fetchSurveyRouteValues(primaryCompanyId: string) {
    this.isLoading = true;
    const allSurveyRouteFilterValues: SurveyRouteFilterInput[] = [];
    const surveyRouteValues =
      await this.hirarchyFilter.getAllSurveyRouteBySubFilterInput({
        primaryCompanyId: primaryCompanyId,
      });
    if (surveyRouteValues?.items) {
      surveyRouteValues.items.forEach((item) => {
        if (item) {
          if (this.selectedSurveyRoute.includes(item.id)) {
            item.RouteAssociateAssetList?.forEach((level) => {
              level?.level3ValueIdList?.forEach((l3) => {
                allSurveyRouteFilterValues.push({
                  level1: level?.level1ValueId,
                  level2: level?.level2ValueId,
                  level3: l3,
                  assetId: level.assetId,
                });
              });
            });
          }
        }
      });
    }
    this.surveyRouteFilterInput = allSurveyRouteFilterValues;
    this.loadAssetOverviewData();
  }

  private updateFilterModel(value: boolean) {
    if(value){
      this.gridData = {
        data: [],
        total: 0
      };
    }
    else{
      this.filterModel = {
        primaryCompany: this.selectedprimaryComapnyId,
        level4: this.selectedSegments,
        assetTypes: this.selectedAssetType,
        segmentTypes: this.selectedSegmentType,
        surveyRouteFilterInputs: this.surveyRouteFilterInput,
        hierarchyFilterInputs: this.hierarchyFilterInput,
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        searchText: this.searchText,
        gridFilters: this.kendoGridFilters
      };
    }
  }

  @HostListener('window:scroll', ['$event'])
  onHostWindowScroll(event: Event): void {
    this.onWindowScroll(event);
  }

  onWindowScroll(_event: any): void {
    this.dropdownVisible = false;
  }

  onScroll(_event: any): void {
    this.dropdownVisible = false;
  }

  urlFilter(assetName: string) {
    const lowerCaseChangeWord = assetName.toLowerCase();
    const newData = this.originalData.filter((item: any) => {
      return this.equalsIgnoreCase(item.assetName, lowerCaseChangeWord);
    });
    this.gridData.data = newData;
  }

  equalsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase() === searchTerm.toLowerCase();
    }
    return false;
  }

  public OnSelectedTemplateColumnsChange(selectedColumns: string[]) {
    this._selectedColumns = selectedColumns;
    this._selectedColumns.forEach((columnName) => {
      this.IsColumnSelected(columnName);
    });
  }

  public IsColumnSelected(columnName: string): boolean {
    return this._selectedColumns.includes(columnName);
  }

  openDialog(): void {
    this._selectedColumns = [
      'level1Value',
      'level2Value',
      'level3Value',
      'level4Value',
      'route',
      'segmentRelation',
      'assetName',
      'assetType',
      'measurementFrequency',
      'gpsCoordinate',
      'assetDelinquencyDate',
    ];
    const currentPageData = this.gridData.data.slice(
      this.state.skip,
      this.state ? this.state.skip as number + this.pageSize :0
    );
    const dataToExport = this.pageChanged
      ? this.gridDataToExport
      : currentPageData;

    this.filteredData = dataToExport.map((item) => {
      const filteredItem: Record<string, any> = {};
      this._selectedColumns.forEach((column) => {
        filteredItem[column] = item[column];
      });
      return filteredItem;
    });

    this.dialog.open(ExportModalComponent, {
      data: {
        selectedColumnData: this.filteredData,
        selectedColumn: this._selectedColumns,
        pdfExport: this.pdfExport,
        pageSize: this.pageSize,
        gridData: dataToExport,
        allData: this.originalData,
        flag: ASSETSETUP,
        filters: this.filterModel
      },
    });

    // Reset pageChanged flag if needed
    if (this.pageChanged) {
      this.pageChanged = false;
    }
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
      this.searchText = '';
      this.loadAssetOverviewData();
    } else if (changeWord.length > 2) {
      this.searchText = changeWord;
      this.loadAssetOverviewData();
    }
  }

  // Helper function to check if a field contains the search term
  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }

  loadData(data: any[]) {
    this.originalData = data.map((item) => ({
      ...item,
      formattedNextDeliquencyDate: this.formatDate(
        new Date(item.nextDelinquencyDate),
      ),
    }));
    this.gridData.data = [...this.originalData];
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: SHORT });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  public myStickyRows: string[] = []; // Change type to string

  public rowSticky: RowStickyFn = (args: RowArgs) =>
    this.isSticky(args.dataItem.id);

  public isSticky(id: string): boolean {
    return this.myStickyRows.indexOf(id) >= 0;
  }

  public toggleStickyRow(id: string): void {
    if (this.isSticky(id)) {
      this.myStickyRows = this.myStickyRows.filter(
        (stickyId) => stickyId !== id,
      );
    } else {
      this.myStickyRows.push(id);
    }
    this.sortGridData();
  }
  private sortGridData(): void {
    // Create a copy of gridData to avoid modifying the original array in place
    const sorteddt = [...this.gridData.data];

    // Sort the copied array
    sorteddt.sort((a, b) => {
      // Check if a or b is null or undefined
      if (a === null || a === undefined) return 1;
      if (b === null || b === undefined) return -1;

      const isASticky = this.isSticky(a.id);
      const isBSticky = this.isSticky(b.id);

      if (isASticky && !isBSticky) {
        return -1;
      } else if (!isASticky && isBSticky) {
        return 1;
      } else {
        // Maintain the original order if both are non-sticky
        return a.id.localeCompare(b.id); // Use localeCompare for string comparison
      }
    });
    this.gridData.data = sorteddt;
  }

  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.assetSetupService.setCurrentDataItem(this.currentDataItem);
    this.rowId = this.currentDataItem.id;
    // Adding the data to local storage
    this.localStorageService.setItem('rowData', this.currentDataItem);

    const gridElement = document.querySelector('.k-grid') as HTMLElement; // Replace '.k-grid' with the appropriate selector for your Kendo grid
    const dropdownHeight = 150; // Set this to the height of your dropdown menu
    const dropdownWidth = 200;

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      // Calculate space available below and above the click position
      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);

      if (spaceBelow < dropdownHeight) {
        // Open upwards
        this.dropdownY = clickY - dropdownHeight;
      } else {
        // Open downwards (default)
        this.dropdownY = clickY + 10;
      }

      // Ensure the dropdown doesn't overflow horizontally
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );
      // Adjust dropdownX to account for the grid's left boundary
      this.dropdownX += gridRect.left;
      // Ensure dropdownY stays within the viewport
      this.dropdownY = Math.max(0, this.dropdownY + 150);
      // If dropdownY exceeds the viewport height, position it at the bottom
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }

    const rowId = dataItem.id;
    this.selectedRowData = dataItem;

    const primaryCompany = this.selectedRowData.pk.split('#')[1];
    this.store.dispatch(
      new SetAssetData(
        primaryCompany,
        this.selectedRowData.id!,
        this.selectedRowData.level1ValueId!,
        this.selectedRowData.level2ValueId!,
        this.selectedRowData.level3ValueId!,
        new Date(),
        new Date(),
      ),
    );

    //we need to call the isSticky function to check the cooresponding row is sticky or not
    //here we have used the order by to check either row is sticky or not
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
  }
  handleDropdownOption(option: number) {
    // Handle the option selection (e.g., call a method or service)
    // for option 1 we have to make the rows sticky and non-sticky
    if (option === 1) {
      this.toggleStickyRow(this.currentDataItem.id);
    }
    else if(option === 2){
      this.router.navigate([EDIT_ASSET_ROUTE]);
    }
    else if(option === 7){
      this.router.navigate([REDIRECT_INSPECTION]);
    }
    //for option 8 we have to make the Delete Action
    else if (option === 8) {
      // const input: DeleteAssetInput = {
      //   primaryCompanyId: '01J30BV3FPHM5MJVKCKN9GWXT0',
      //   companyId: '01J30DHX3DJG4ZFN1XJYFDPV6J',
      //   regionId: '01J30F3CMP9B772V6NS5NDGMX4',
      //   id: '01J446YP3RMTFZNRWH460VBQMZ',
      // };
      // this.deleteAsset(input);

      //Confirm
      const assetName = this.currentDataItem.assetName; //take assetName over here from currentDataItem
      const CONFIRMATION_MSG = DELETE_ASSET_CONFIRMATION_MSG.replace(
        '{0}',
        assetName,
      );
      this.popupDialogService.openDialog(
        DELETE_ASSET,
        CONFIRMATION_MSG,
        HELP,
        CONFIRM,
        undefined,
        true,
        CANCEL_BTN_TEXT,
        true,
        DELETE_ASSEST_METHOD,
      );
    }
  }

  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  rowActionStickyRow(event: MouseEvent, dataItem: any) {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    //we want to change the text from Upin to Pin of drop-down that we are making this call
    const rowId = dataItem.id;
    //we need to call the isSticky function to check the cooresponding row is sticky or not
    //here we have used the order by to check either row is sticky or not
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
    //this is used to make the row unsticky
    this.toggleStickyRow(rowId);
  }

  // Column reorder functionality Code
  public onColumnReorder(event: ColumnReorderEvent): void {
    const lockedColumns = [PRIMARY_COMPANY, COMPANY, REGION];

    const field = this.getColumnField(event.column);

    if (
      (field && lockedColumns.includes(field)) ||
      this.isNonReorderableColumn(event.column) ||
      this.isNewIndexInFirstThree(event.newIndex)
    ) {
      this.toastr.showInfo('Mandatory Columns cannot be re-ordered', 'Info');
      event.preventDefault();
    }
  }
  getColumnField(column: ColumnBase): string | null {
    if ('field' in column) {
      return (column as any).field;
    }
    return null;
  }

  private isNonReorderableColumn(column: ColumnBase): boolean {
    const headerClass = column.headerClass;

    if (typeof headerClass === 'string') {
      return headerClass.includes('non-reorderable');
    }
    return false;
  }
  private isNewIndexInFirstThree(newIndex: number): boolean {
    const rootCauseColIndex = 13;
    const manditoryColsIndexUpto = 4;
    return newIndex < manditoryColsIndexUpto || newIndex == rootCauseColIndex;
  }

  //col-reorder code
  //col-reorder code i.e this method will be invoked when there is change in drag/drop  template
  public reorderColumnPosition(columnPosition: {
    previousIndexOfCol: number;
    newIndexOfCol: number;
    columnName: string;
  }): void {
    const fieldName = columnPosition.columnName;
    const index = columnPosition.newIndexOfCol + 1;
    const column = this.columns.find((col) => col.field === fieldName);
    if (column) {
      if (columnPosition.newIndexOfCol < columnPosition.previousIndexOfCol) {
        this.grid.reorderColumn(column as ColumnBase, index, { before: true });
      } else {
        this.grid.reorderColumn(column as ColumnBase, index);
      }
      this.columns.reset([...this.columns.toArray()]);
    }
  }

  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
  ): void {
    const filters: FilterDescriptor[] = [];
    // Loop through selectedValues to log text and check if selected
    for (const value of selectedValues) {
      // Add filter based on whether the value is selected
      if (value.selected) {
        filters.push({
          field: 'ComplianceStatus',
          operator: 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };

    filterService.filter(rootFilter);
  }

  private hasFilterForField(
    filter: CompositeFilterDescriptor,
    field: string,
  ): boolean {
    return filter.filters.some(
      (f: FilterDescriptor | CompositeFilterDescriptor) => {
        if ((f as CompositeFilterDescriptor).filters) {
          return this.hasFilterForField(f as CompositeFilterDescriptor, field);
        } else {
          return (f as FilterDescriptor).field === field;
        }
      },
    );
  }
  getDistinctValues(columnName: string): void {
    const distinctValues = [
      ...new Set(this.gridData.data.map((item) => item[columnName])),
    ];
    distinctValues.forEach((item) => {
      if (!this.complaiceStatusFilterValues.some(filter => filter.text === item)) {
        this.complaiceStatusFilterValues.push({ text: item, selected: false });
      }
    });
  }
  //tray code to show and hide the filters
  height = 780; // initial height
  showHierarchicalFilter = false;
  showFilterBtn = true;
  toggleFilterTray() {
    this.showHierarchicalFilter = !this.showHierarchicalFilter;
    this.showFilterBtn = !this.showFilterBtn;
    if (this.showHierarchicalFilter) {
      this.height = 830;
    } else {
      this.height = 780;
    }
  }
  collapseFilterTray() {
    this.toggleFilterTray();
  }

  navigateToEdit(dataItem: any, fieldName: string): void {
    this.store.dispatch(
      new SetAsset(
        true,
        fieldName,
        dataItem.primaryCompany,
        dataItem.company,
        dataItem.region,
        dataItem.segment,
        dataItem.station,
        dataItem.assetType,
        dataItem.assetName,
        dataItem.assetTypeTemplate,
        dataItem.latitude,
        dataItem.longitude,
        dataItem.id,
        dataItem.createdDate,
        dataItem.isMeasurementAvailable,
      ),
    );
    this.router.navigate([EDIT_ASSET_ROUTE]);
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: "and" },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.myStickyRows = [];
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1; 
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.loadAssetOverviewData();
  }
}
