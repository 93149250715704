import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';

@Injectable({
  providedIn: 'root',
})
export class MtxMemberService {
  // static-data.ts
  STATIC_DATA = [
    {
      firstName: 'Andrew',
      lastName: 'Brewer',
      username: 'abrewer',
      roles: 'Mobiltex User',
      emailId: 'andrew_brewer@tcenergy.com',
      phoneNumber: '+91 9676786790',
      corporateNumber: '+91 9676786790',
      address: '350 Avenue Duluth Est',
      city: 'Montréal',
      stateProvince: 'Quebec',
      postalCode: 'H2W 2M3',
      country: 'Canada',
      reportTime: 'UTC−12:00 (AoE)',
      accountStatus: 'Active',
      active:true
    },
    {
      firstName: 'Matt',
      lastName: 'Duquette',
      username: 'acuren',
      roles: 'Mobiltex Admin',
      emailId: 'andrew_brewer@tcenergy.com',
      phoneNumber: '+91 9676786790',
      corporateNumber: '+91 9676786790',
      address: '350 Avenue Duluth Est',
      city: 'Montréal',
      stateProvince: 'Quebec',
      postalCode: 'H2W 2M3',
      country: 'Canada',
      reportTime: 'UTC−12:00 (AoE)',
      accountStatus: 'Deactivated',
      active:false
    },
  ];

  constructor(private awsService: BaseAWSRequestService) 
  {}

  public getAllUser(){
    return this.STATIC_DATA;
  }
}
