<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          {{ 'EXCEPTION_PAGE.TITLE' | translate }}
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px; margin-right: -10px">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            >
            </app-search>
          </div>
          <div>
            <app-template
              [templateTypeInput]="templateTypeInput"
            ></app-template>
          </div>
          <div *ngIf="showFilterBtn">
            <button
              (click)="toggleFilterTray()"
              class="btn mtx-btn-secondary mtx-font-weight-500"
            >
              <span style="vertical-align: bottom" class="material-icons"
                >filter_alt</span
              >
              {{ 'GENERIC_Buttons.FILTERS' | translate }}
              <span style="vertical-align: bottom" class="material-icons"
                >arrow_drop_down</span
              >
            </button>
          </div>
          <div>
            <button
              class="btn mtx-btn-primary"
              style="height: 38px"
              (click)="openDialog()"
            >
              <span style="vertical-align: middle" class="material-icons"
                >download</span
              >
              {{ 'GENERIC_Buttons.EXPORT' | translate }}
            </button>
          </div>
          <div>
            <app-columnadjustment
              (columnPosition)="reorderColumnPosition($event)"
              (selectedColumnsChange)="OnSelectedTemplateColumnsChange($event)"
              [templateTypeInput]="templateTypeInput"
            ></app-columnadjustment>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row justify-content-end mb-3"
    style="float: right"
    *ngIf="showHierarchicalFilter"
  >
    <app-heirarchy-filter-buttons
      (isTrayCollapsed)="collapseFilterTray()"
      (filterSearchButtonClicked)="filterSearchButtonClicked()"
    >
    </app-heirarchy-filter-buttons>
    <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
  </div>
  <div class="mt-3">
    <kendo-grid
      #grid
      [data]="gridData"
      id="grid"
      [filterable]="'menu'"
      [pageable]="PAGINATION_ARRAY"
      [reorderable]="true"
      (columnReorder)="onColumnReorder($event)"
      [sortable]="false"
      [resizable]="true"
      style="width: 100%; box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15)"
      [rowSticky]="rowSticky"
      [skip]="state.skip"
      [pageable]="true"
      [pageSize]="state.take"
      [filter]="state.filter"
      (dataStateChange)="dataStateChange($event)"

    >
      <kendo-grid-column
        [resizable]="false"
        [headerClass]="'firstColGridHeader non-reorderable'"
        [width]="60"
        [filterable]="false"
        field="id"
        title=""
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex-container">
            <div class="icon-container">
              <span
                class="k-icon k-font-icon my-custom-icon-class"
                style="position: relative"
                (click)="openDropdownMenu($event, dataItem)"
              ></span>
              <span
                *ngIf="isSticky(dataItem.id)"
                class="k-icon k-font-icon k-i-pin pin-icon-css"
                (click)="rowActionStickyRow($event, dataItem)"
              ></span>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [headerClass]="'firstColGridHeader'"
        field="assetType"
        [title]="'INSPECTION_GRID_COLUMNS.ASSET_TYPE' | translate"
        [minResizableWidth]="80"
        [width]="120"
        *ngIf="IsColumnSelected('assetType')"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="assetName"
        [title]="'INSPECTION_GRID_COLUMNS.ASSET_NAME' | translate"
        [minResizableWidth]="80"
        [width]="120"
        *ngIf="IsColumnSelected('assetName')"
      ></kendo-grid-column>

      <kendo-grid-column
        field="targetRemediationDate"
        title="Target Remediation Date"
        [minResizableWidth]="80"
        format="{0:dd/MM/yyyy}"
        filter="date"
        *ngIf="IsColumnSelected('targetRemediationDate')"
        [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="right-alignment">{{
            dataItem.targetRemediationDate | customDate
          }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="assetDelinquencyDate"
        [title]="'INSPECTION_GRID_COLUMNS.ASSET_DELINQUENCY_DATE' | translate"
        [minResizableWidth]="80"
        [width]="160"
        format="{0:dd/MM/yyyy}"
        filter="date"
        *ngIf="IsColumnSelected('assetDelinquencyDate')"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="right-alignment">{{
            dataItem.assetDelinquencyDate | customDate
          }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="workFlowStatus"
        [title]="'EXCEPTION_GRID_COLUMNS.WORKFLOW_STATUS' | translate"
        [minResizableWidth]="80"
        [width]="210"
        *ngIf="IsColumnSelected('workFlowStatus')"
      >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span
        [ngClass]="getStatusClass(dataItem.workFlowStatus ? dataItem.workFlowStatus : '')"
          style="font-weight: 700">
          <span class="dot"></span> {{ dataItem.workFlowStatus ? dataItem.workFlowStatus : '' }}
        </span>
      </ng-template>
    </kendo-grid-column>


      <kendo-grid-column
        field="comments"
        [title]="'EXCEPTION_GRID_COLUMNS.COMMENTS' | translate"
        [minResizableWidth]="150"
        [width]="300"
        *ngIf="IsColumnSelected('Comments')"
      >
      </kendo-grid-column>

      <kendo-grid-column
        #technician
        field="technician"
        [title]="'INSPECTION_GRID_COLUMNS.TECHNICIAN' | translate"
        [minResizableWidth]="80"
        [width]="135"
        *ngIf="IsColumnSelected('technician')"
      >
      </kendo-grid-column>

      <kendo-grid-column
        field="specialist"
        title="Specialist"
        [minResizableWidth]="80"
        [width]="135"
        *ngIf="IsColumnSelected('specialist')"
      >
      </kendo-grid-column>

      <ng-container *ngFor="let column of dynamicColumns">
        <kendo-grid-column
          [field]="column.field"
          [filterable]="false"
          [sortable]="false"
          [title]="column.title"
          [width]="column.width"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.dynamicValues[column.field] || '' }}</span>
          </ng-template>
        </kendo-grid-column>
      </ng-container>

      <kendo-grid-column
        [resizable]="false"
        [title]="'EXCEPTION_GRID_COLUMNS.ROOT_CAUSE' | translate"
        [width]="600"
        [filterable]="false"
        *ngIf="IsColumnSelected('rootCause')"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            class="custom-rootCause"
            *ngFor="let cause of rootCause"
            >{{ cause }}</span
          >
        </ng-template>
      </kendo-grid-column>


      <ng-template kendoGridDetailTemplate let-dataItem>
        <div class="expanded-content">
          <kendo-grid [height]="dataItem.measurementsProcesses?.table?.length > 0 ? 200 : 110" 
             [data]="dataItem.measurementsProcesses?.table">
            <kendo-grid-column
              field="measurementPoint"
              [width]="150"
              [title]="'INSPECTION_GRID_COLUMNS.MEASUREMENT_POINT' | translate"
            ></kendo-grid-column>
            <kendo-grid-column
              field="source"
              [width]="170"
              [title]="'INSPECTION_GRID_COLUMNS.MEASUREMENT_SOURCE' | translate"
            ></kendo-grid-column>
            <kendo-grid-column
              field="measurementType"
              [width]="170"
              [title]="'INSPECTION_GRID_COLUMNS.MEASUREMENT_TYPE' | translate"
            ></kendo-grid-column>
            <kendo-grid-column
              field="Status"
              [width]="150"
              title="status"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <span
                  [ngClass]="
                    getMeasurementStatusClass(
                      dataItem.status ? dataItem.status : ''
                    )
                  "
                  style="font-weight: 700"
                >
                  <span class="dot"></span>
                  {{ dataItem.status ? dataItem.status : '' }}
                </span>
              </ng-template>
            </kendo-grid-column>
            
            <kendo-grid-column
              field="criteria"
              [width]="150"
              title="Criteria"
            ></kendo-grid-column>
            
            <kendo-grid-column
              field="level4Value"
              [width]="150"
              [title]="'INSPECTION_GRID_COLUMNS.SEGMENT' | translate"
            ></kendo-grid-column>

            <ng-container
              *ngFor="let date of dataItem.measurementsProcesses?.dates"
            >
              <kendo-grid-column
                [width]="150"
                [field]="'Measurement On ' + date"
                [title]="'Measurement On ' + date"
              >
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem['Measurement On ' + date] }}
                </ng-template>
              </kendo-grid-column>
            </ng-container>
          </kendo-grid>
        </div>
      </ng-template>


    </kendo-grid>
    <kendo-dialog *ngIf="showPopup" (close)="closePopup()">
      <section class="modal-header1">
        <div class="content1">
          <span class="featured-icon1"></span>
          <div class="text-and-supporting-text1">
            <h3 class="title1">
              <span>Comments on Asset </span>
              <span class="span1"> </span>
              <b>{{ assetTypeForComment }}</b>
            </h3>
            <div class="supporting-text1">
              <span>View or add comments on Asset </span>
              <b>{{ assetTypeForComment }}</b>
            </div>
          </div>
        </div>
        <div class="button-close-x1">
          <span class="x-close-icon1" (click)="closePopup()"></span>
        </div>
        <div class="padding-bottom1"></div>
        <img class="divider-icon1" alt="" />
      </section>
      <div class="input-container1">
        <div class="comment-reply-input1">
          <div class="input-wrapper">
            <div class="placeholder-container">
              <img class="avatar-placeholder-icon" />
              <input
                [(ngModel)]="exceptionComment"
                class="reply-prompt"
                placeholder="be the first one to comment"
                type="text"
              />
              <div
                class="send-button-container1"
                (click)="saveTheFirstExceptionComment()"
              >
                <span class="content-send1"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </kendo-dialog>
    <app-row-actions
      class="row-action"
      [visible]="dropdownVisible"
      [isStickyRow]="isStickyRow"
      [positionX]="dropdownX"
      [width]="ellipsisWidth"
      [rowId]="rowId"
      [positionY]="dropdownY"
      (optionSelected)="handleDropdownOption($event)"
      [inputScreen]="inputScreen"
      [permission] = "permission"
      (visibleChange)="handleDropdownVisibilityChange($event)"
    ></app-row-actions>
  </div>
</div>
<div *ngIf="showCalendarPopup">
  <kendo-dialog>
    <kendo-dialog-titlebar>
      Select a Date
    </kendo-dialog-titlebar>
  
    <div class="calendar-content">
      <kendo-calendar [(value)]="selectedDate"></kendo-calendar>
    </div>
  
    <kendo-dialog-actions>
      <button kendoButton class="calendar-button" (click)="confirm()">OK</button>
      <button kendoButton class="calendar-button" (click)="close()">Cancel</button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>