import {
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
// import { HeirarchyFilterService } from '../../shared/service/heirarchy-filter/heirarchy-filter.service';
import {
  AC_COUPON_SIZE,
  ANODE_TYPE,
  ASSET_ADDED_FAILED,
  ASSET_ADDED_SUCCESSFULLY,
  ASSET_FAILURE_MSG,
  ASSET_SUCCESSFUL_MSG,
  ASSET_SUCCESSFUL_MSG2,
  ASSET_UPDATED_FAILED,
  ASSET_UPDATED_SUCCESSFULLY,
  BACKFILL_DIAMETER,
  BACKFILL_MASS,
  BOND,
  BOND_SHUNT_VALUE,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CLOSE,
  COMPANY_TEXT,
  CONTINUE,
  CREATE_ASSET_ERROR,
  CREATE_SINGLE_ASSET,
  CREATE_SINGLE_ASSET_SUBHEADING,
  DATE_OF_COMMISSIONING,
  DC_COUPON_SIZE,
  ELECTRODE_CHEMISTRY,
  ELECTRODE_INSTALLATION_DATE,
  ELECTRODE_MANUFACTURER,
  ELECTRODE_TYPE,
  EMPTY_STRING,
  ER_PROBES,
  ERROR,
  FAILED,
  FAILED_CREATE_ASSET_MINIMUM_ONE_ASSET,
  FAILED_CREATE_ASSET_MINIMUM_ONE_IMAGE,
  FAILED_CREATE_ASSET_MINIMUM_ONE_MEASUREMENT,
  FAILED_CREATE_ASSET_MINIMUM_ONE_PRIMARY,
  GROUNDBED_DATE_OF_COMMISSIONING,
  GROUNDBED_ORIENTATION,
  GROUNDBED_TYPE,
  HIGHLIGHT,
  INSTALL_DATE,
  LATITUDEVALIDATOR,
  LONGITUDEVALIDATOR,
  MAINTENANCE_DETAILS,
  MEASUREMENT_TYPES,
  METER_NUMBER,
  NO,
  NUMBER_OF_ANODES,
  OPERATOR_OPTIONS,
  OUTPUT_AMPERAGE,
  OUTPUT_VOLTAGE,
  PRIMARY_COMPANY_TEXT,
  PROBE_MANUFACTURER,
  PROBE_TYPE,
  RECTIFIER,
  RECTIFIER_MANUFACTURER,
  RECTIFIER_TYPE,
  REGION_TEXT,
  SEGMENT_RELATION,
  SEGMENT_TEXT,
  SETUP_ASSET_OVERVIEW,
  SHUNT_VALUES,
  SUCCESS,
  TEST_STATION,
  UPDATE_ASSET_ERROR,
} from '../../shared/constants';
import {
  AssetType,
  Company,
  PrimaryCompany,
  Region,
  Segment,
} from '../../core/models/assets';
import {
  AssetCreateInput,
  AssetMetadataInput,
  AssetOutput,
  AssetResponse,
  AssetTypeTemplateInput,
  AssetUpdateInput,
  fileUploadEntityType,
  FileUploadInput,
  fileUploadType,
} from '../../../awsAppSync/API';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { AssetSetupService } from '../../core/services/asset-setup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadService } from '../../core/services/file-upload.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { LevelNameState } from '../../core/store/filtet-levelName.state';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { CompanyService } from '../../core/services/company.service';
import {
  AssetSetupDataState,
  RemoveAssetData,
} from '../../core/store/asset.state';
@Component({
  selector: 'app-create-single-asset',
  templateUrl: './create-single-asset.component.html',
  styleUrl: './create-single-asset.component.css',
})
export class CreateSingleAssetComponent
  implements OnInit, AfterViewInit, OnDestroy {
  assetTypeData: (AssetType | null)[] = [];
  primaryCompanyData: (PrimaryCompany | null)[] = [];
  companyData: (Company | null)[] = [];
  regionData: (Region | null)[] = [];
  segmentData: (Segment | null)[] = [];
  selectedRegion: string | null = EMPTY_STRING;
  selectedSegment: string | null = EMPTY_STRING;
  selectedPrimaryCompany: string | null = EMPTY_STRING;
  selectedCompany: string | null = EMPTY_STRING;
  selectedAssetType: string | null = EMPTY_STRING;
  assetName: string | null = EMPTY_STRING;
  inspectionDate: Date | null = null;
  stationName: string | null = EMPTY_STRING;
  selectedAssetTemplate: string | null = EMPTY_STRING;
  latitude: string | null = EMPTY_STRING;
  longitude: string | null = EMPTY_STRING;
  file: File[] = [];
  uploadedFileName: string[] = [];
  uploadedFileUrl: string[] = [];
  assetTemplateData = [{ id: 'template12', name: 'Template-12' }];
  isLoading = false;
  highlightField: string | null = EMPTY_STRING;
  assetHeading = CREATE_SINGLE_ASSET;
  assetSubheading = CREATE_SINGLE_ASSET_SUBHEADING;
  level1 = PRIMARY_COMPANY_TEXT;
  level2 = COMPANY_TEXT;
  level3 = REGION_TEXT;
  level3test = 'Region';
  level4 = SEGMENT_TEXT;
  primaryCompanyId = '';
  storeLevelName$: Observable<string[]>;
  isLevel1visible = false;
  isLevel2visible = false;
  isLevel3visible = false;
  primaryCompanyName$: Observable<string | null>;
  primaryCompanyName: string | null = '';
  level1Data = [];
  level2Data = [];
  selectedLevel1 = '';
  selectedLevel2 = '';
  singleAssetInput: any;
  measurementTypes = MEASUREMENT_TYPES;
  measurementTypesdefaultItem = { text: 'Select Measurement Type', value: '' };
  operatorOptions = OPERATOR_OPTIONS;

  level1defaultItem = { text: 'Select ' + this.level1, value: '' };
  level2defaultItem = { text: 'Select ' + this.level2, value: '' };
  level3defaultItem = { text: 'Select ' + this.level3, value: '' };
  level3ValidationMsg = this.level3 + ' is required.';
  level4ValidationMsg = this.level4 + ' is required.';
  level4defaultItem = { text: 'Select ' + this.level4, value: '' };
  frequenciedefaultItem = { text: 'Measurement Frequency', value: '' };
  operatorDefaultItem = { text: 'Select Operator', value: '' };
  segmentDefaultItem = { text: 'Select Segment', value: '' };
  segmentRelationdefaultItem = { text: 'Segment Relation', value: '' };
  assetTypedefaultItem = { text: 'Select Asset Type', value: '' };
  storePrimaryCompanyID$: Observable<string | null>;
  level1Details: {
    value: string | undefined;
    text: string | null | undefined;
  }[] = [];
  level2Details: {
    value: string | undefined;
    text: string | null | undefined;
  }[] = [];
  level3Details: {
    value: string | undefined;
    text: string | null | undefined;
  }[] = [];
  level4Details: {
    value: string | undefined;
    text: string | null | undefined;
    segmentType: string | null | undefined;
  }[] = [];
  level4AllDetails: {
    value: string | undefined;
    text: string | null | undefined;
    segmentType: string | null | undefined;
  }[] = [];
  assetType: {
    value: string | undefined;
    text: string | null | undefined;
  }[] = [];
  distinctedSegmentValues: {
    value: string | undefined;
    text: string | null | undefined;
    segmentRelation: string | null | undefined;
    level4Type: string | null | undefined;
  }[] = [];

  selectedAssetTypeName = '';
  isMeasurementDisabled = true;
  segmentRelation = SEGMENT_RELATION;
  @ViewChild('fileInput') fileInput!: ElementRef;
  isEdit = false;
  isPrimarySelected = false;
  segmentprimaryIndex = -1;
  assetModel: AssetCreateInput;
  updateModel: AssetUpdateInput;
  latitudePattern = LATITUDEVALIDATOR;
  longitudePattern = LONGITUDEVALIDATOR;
  primaryCompany$: Observable<string | null>;
  level1$: Observable<string | null>;
  level2$: Observable<string | null>;
  level3$: Observable<string | null>;
  assetId$: Observable<string | null>;
  level1ValueForEdit: string = EMPTY_STRING;
  level2ValueForEdit: string = EMPTY_STRING;
  level3ValueForEdit: string = EMPTY_STRING;
  assetIdValueForEdit: string = EMPTY_STRING;
  primaryCompanyForEdit: string = EMPTY_STRING;
  isEditUrl = false;
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    private fileUploadService: FileUploadService,
    private assetSetupService: AssetSetupService,
    private popupDialogService: PopupDialogService,
    private _heirarchyFilterService: HeirarchyFilterServiceService,
    private toastr: CustomToastrService,
    private companyService: CompanyService,
  ) {
    //New
    this.storeLevelName$ = this.store.select(LevelNameState.getLevelName);
    this.primaryCompanyName$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyName,
    );
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );

    this.assetModel = {
      primaryCompanyId: '',
      level1ValueId: '',
      level2ValueId: '',
      assetTypeId: '',
      assetName: '',
      lastInspectionDate: '',
      mappingAssetId: '',
      milestone: '',
      creationDate: '',
      latitude: '',
      longitude: '',
      comments: '',
      locationDescription: '',
      effectiveDate: '',
      assetProperties: [],
      assetHierarchies: [],
      assetMeasurementCriteriaInputs: [],
      imageNames: [],
    };

    this.updateModel = {
      id: '',
      pk: '',
      sk: '',
      primaryCompanyId: '',
      level1ValueId: '',
      level2ValueId: '',
      assetTypeId: '',
      assetName: '',
      lastInspectionDate: '',
      mappingAssetId: '',
      milestone: '',
      creationDate: '',
      latitude: '',
      longitude: '',
      comments: '',
      locationDescription: '',
      effectiveDate: '',
      assetProperties: [],
      assetHierarchies: [],
      assetMeasurementCriteriaUpdateInputs: [],
      imageNames: [],
    };
    this.primaryCompany$ = this.store.select(
      AssetSetupDataState.getAssetPrimaryCompany,
    );
    this.level1$ = this.store.select(AssetSetupDataState.getAssetLevel1);
    this.level2$ = this.store.select(AssetSetupDataState.getAssetLevel2);
    this.level3$ = this.store.select(AssetSetupDataState.getAssetLevel3);
    this.assetId$ = this.store.select(AssetSetupDataState.getAssetAssetId);
  }

  ngOnInit(): void {
    this.LoadData();
    this.primaryCompanyName$.subscribe((companyName) => {
      this.primaryCompanyName = companyName;
    });
    this.storePrimaryCompanyID$.subscribe((primaryCompanyId) => {
      this.primaryCompanyId = primaryCompanyId ?? '';
    });

    this.primaryCompany$.subscribe((primaryCompany) => {
      this.primaryCompanyForEdit = primaryCompany ?? '';
    });
    this.assetId$.subscribe((assetId) => {
      this.assetIdValueForEdit = assetId ?? '';
    });
    this.level1$.subscribe((level1) => {
      this.level1ValueForEdit = level1 ?? '';
    });
    this.level2$.subscribe((level2) => {
      this.level2ValueForEdit = level2 ?? '';
    });
    this.level3$.subscribe((level3) => {
      this.level3ValueForEdit = level3 ?? '';
    });
    this.route.url.subscribe((urlSegments) => {
      // Check the segments for "create" or "edit"
      const segments = urlSegments.map((segment) => segment.path);
      if (segments.includes('create')) {
        this.isEditUrl = false;
      } else if (segments.includes('edit')) {
        this.isEditUrl = true;
      }
    });

    if (
      this.primaryCompanyForEdit &&
      this.level1ValueForEdit &&
      this.level2ValueForEdit &&
      this.level3ValueForEdit &&
      this.assetIdValueForEdit &&
      this.isEditUrl
    ) {
      this.isEdit = true;
      this.isLoading = true;
      this.getAssetByIdForEdit();
    } else {
      this.isEdit = false;
      this.isEditUrl = false;
    }

    this.initilizeModel();

    //New code
    this.storeLevelName$.subscribe((levelNames) => {
      this.generateLevelNames(levelNames);
    });
    this.bindAssetType(this.primaryCompanyId);
  }

  initilizeModel() {
    this.singleAssetInput = {
      id: '',
      pk: '',
      sk: '',
      primaryCompanyId: this.primaryCompanyId,
      assetType: '',
      assetName: '',
      level1: '',
      level2: '',
      lastInspectionDate: '',
      mappingAssetId: '',
      milestone: '',
      creationDate: '',
      effectiveDate: '',
      latitude: '',
      longitude: '',
      comments: '',
      locationDescription: '',
      assetHierarchy: [],
      measurementCriteriaInputs: [],
      rectifierType: {
        rectifierManufacturer: '',
        dateOfCommissioning: '',
        rectifierType: '',
        outputVoltage: '',
        outputAmperage: '',
        shuntValues: '',
        meterNumber: '',
        groundbedType: '',
        groundbedOrientation: '',
        anodeType: '',
        numberOfAnodes: '',
        groundbedDateOfCommissioning: '',
        backfillMass: '',
        backfillDiameter: '',
        maintenanceDetails: '',
      },
      bondType: {
        bondShuntValue: '',
      },
      testStations: {
        electrodeManufacturer: '',
        electrodeType: '',
        electrodeChemistry: '',
        electrodeInstallationDate: '',
        acCouponSize: '',
        dcCouponSize: '',
      },
      eRProbes: {
        probeManufacturer: '',
        probeType: '',
        installDate: '',
      },
    };
  }
  ngOnDestroy() {
    this.store.dispatch(new RemoveAssetData());
  }

  ngAfterViewInit(): void {
    if (this.highlightField) {
      const element = document.getElementById(this.highlightField);
      if (element) {
        this.renderer.addClass(element, HIGHLIGHT);
      }
    }
  }

  public async LoadData() {
    // const assetTypeConnection =
    //   await this.heirarchyFilterService.getAssetTypeData();
    // if (assetTypeConnection && assetTypeConnection.items) {
    //   this.assetTypeData = assetTypeConnection.items;
    // } else {
    //   this.assetTypeData = [];
    // }
    // const primaryCompanyConnection =
    //   await this.heirarchyFilterService.getPrimaryCompanyData();
    // if (primaryCompanyConnection && primaryCompanyConnection.items) {
    //   //this.primaryCompanyData = primaryCompanyConnection.items;
    // } else {
    //   this.primaryCompanyData = [];
    // }
    // const companyConnection =
    //   await this.heirarchyFilterService.getCompanyData();
    // if (companyConnection && companyConnection.items) {
    //   this.companyData = companyConnection.items;
    // } else {
    //   this.companyData = [];
    // }
    // const regionConnection = await this.heirarchyFilterService.getRegionData();
    // if (regionConnection && regionConnection.items) {
    //   this.regionData = regionConnection.items;
    // } else {
    //   this.regionData = [];
    // }
    // const segmentConnection =
    //   await this.heirarchyFilterService.getSegmentData();
    // if (segmentConnection && segmentConnection.items) {
    //   this.segmentData = segmentConnection.items;
    // } else {
    //   this.segmentData = [];
    // }
  }
  async uploadImages(_createdAsset: AssetResponse) {
    const primaryCompanyId =
      this.isEdit === true
        ? this.primaryCompanyForEdit
        : this.assetModel.primaryCompanyId ?? '';

    // Ensure uploadedFileName is an array
    for (const file of this.file) {
      const fileName = file.name.replace(/\s+/g, '_'); // Handle each file name
      const _imageInputData: FileUploadInput = {
        filename: fileName,
        entityType: fileUploadEntityType.Asset,
        entityTypeId: primaryCompanyId,
        fileUploadType: fileUploadType.Normal,
      };

      const _getPresignedURL =
        await this.assetSetupService.uploadAssetImage(_imageInputData);

      // Assuming `this.file` holds the files in the same order as uploadedFileName
      const _uploadImg = await this.fileUploadService.uploadToS3(
        String(_getPresignedURL?.presignedURL),
        file, // Access the corresponding file for each name
        String(_getPresignedURL?.contentType),
      );

      // Optionally, handle response from _uploadImg if needed
    }
  }

  renameFile(file: File, newName: string): File {
    return new File([file], newName, {
      type: file.type,
      lastModified: file.lastModified,
    });
  }

  getFormattedDate(dateValue: Date | null): string | null {
    if (dateValue instanceof Date) {
      dateValue.setHours(0, 0, 0, 0);
      return dateValue.toISOString();
    }
    return null;
  }
  resetForm() {
    this.initilizeModel();
    this.uploadedFileName = [];
    this.uploadedFileUrl = [];
    this.file = [];
    this.selectedAssetTypeName = '';
  }

  onFileSelected(event: any): void {
    let file = event.target.files[0];

    if (file.name.indexOf(' ')) {
      const fileName = file.name.replace(/\s+/g, '_');
      file = this.renameFile(file, fileName);
    }

    if (!this.uploadedFileName.includes(file.name)) {
      if (file) {
        this.file.push(file);
        this.uploadedFileName.push(file.name);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.uploadedFileUrl.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removeFile(i: number): void {
    this.isImageUploadTouched = true;
    if (this.uploadedFileName && this.uploadedFileName.length > 0) {
      this.uploadedFileName.splice(i, 1);
    }
    if (this.uploadedFileUrl && this.uploadedFileUrl.length > 0) {
      this.uploadedFileUrl.splice(i, 1);
    }
    if (this.file && this.file.length > 0) {
      this.file.splice(i, 1);
    }
    this.fileInput.nativeElement.value = '';
  }

  viewFile(i: number): void {
    if (this.uploadedFileUrl && this.uploadedFileUrl.length > 0) {
      window.open(this.uploadedFileUrl[i], '_blank');
    }
  }

  async generateLevelNames(selectedLevelName: string[]) {
    if (selectedLevelName[0]) {
      this.level1 = selectedLevelName[0];
      this.level1defaultItem.text = 'Select ' + this.level1;
      this.isLevel1visible = true;
      this.bindLevel1Values();
    }
    if (selectedLevelName[1]) {
      this.level2 = selectedLevelName[1];
      this.level2defaultItem.text = 'Select ' + this.level2;
      this.isLevel2visible = true;
      this.bindLevel2Values(EMPTY_STRING);
    }
    if (selectedLevelName[2]) {
      this.level3 = selectedLevelName[2].toString();
      this.isLevel3visible = true;
      this.level3defaultItem.text = 'Select ' + this.level3;
    }
  }
  deleteMeasurement(measurementIndex: number) {
    if (this.isEdit) {
      this.singleAssetInput.measurementCriteriaInputs[
        measurementIndex
      ].isDeleted = true;
    } else {
      this.singleAssetInput.measurementCriteriaInputs.splice(
        measurementIndex,
        1,
      );
    }
  }
  deleteAsset(measurementIndex: number) {
    this.singleAssetInput.assetHierarchy.splice(measurementIndex, 1);
  }
  allowOnlyNumbersAndSymbols(event: KeyboardEvent) {
    const allowedCharacters = /[0-9+\-.]/; // Allow digits, +, -, and .
    const inputChar = String.fromCharCode(event.keyCode || event.which);

    if (!allowedCharacters.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  }
  onTypeChange(measurement: any) {
    // Find the corresponding measurement in the existing array
    const foundMeasurement = this.measurementTypes.find(
      (m: any) => m.text === measurement.measurementType,
    );
    // If found, assign the unit
    if (foundMeasurement) {
      measurement.unit = foundMeasurement.unit; // Assign the unit from the existing measurement
    } else {
      measurement.unit = 'V'; // Default to Volts if not found
    }
  }

  async bindLevel1Values() {
    const level1Values =
      await this._heirarchyFilterService.getAllLevel1ValueByPrimaryCompany(
        this.primaryCompanyId,
      );
    if (level1Values?.items) {
      this.level1Details = level1Values?.items.map((item) => ({
        value: item?.id,
        text: item?.level1Value,
      }));
    }
  }
  async bindLevel2Values(level1ValueId: string) {
    this.level2Details = [];
    this.singleAssetInput.level2 = null;
    const level2Values =
      await this._heirarchyFilterService.getAllLevel2ValueByPrimaryCompany(
        this.primaryCompanyId,
      );

    if (level1ValueId && level2Values && level2Values.items) {
      level2Values.items = level2Values.items.filter(
        (item) => item?.level1ValueId == level1ValueId,
      );
    }

    if (level2Values?.items) {
      this.level2Details = level2Values?.items.map((item) => ({
        value: item?.id,
        text: item?.level2Value,
      }));
    }
  }

  async bindLevel3Values(level2ValueId: string) {
    const level3Values =
      await this._heirarchyFilterService.getAllLevel3ValueByPrimaryCompany(
        this.primaryCompanyId,
      );

    if (level2ValueId && level3Values && level3Values.items) {
      level3Values.items = level3Values.items.filter(
        (item) => item?.level2ValueId == level2ValueId,
      );
    }

    if (level3Values?.items) {
      this.level3Details = level3Values?.items.map((item) => ({
        value: item?.id,
        text: item?.level3Value,
      }));
    }
  }
  async bindLevel4Values(level3ValueId: string, rowIndex: number) {
    this.isLoading = true;
    const level4Values =
      await this._heirarchyFilterService.getAllLevel4ValueByPrimaryCompany(
        this.primaryCompanyId,
      );

    if (level4Values?.items) {
      this.level4AllDetails = [];
      this.level4AllDetails = level4Values?.items.map((item) => ({
        value: item?.id,
        text: item?.level4Value,
        segmentType: item?.segmentType,
      }));
    }

    if (level3ValueId && level4Values && level4Values.items) {
      level4Values.items = level4Values.items.filter(
        (item) => item?.level3ValueId == level3ValueId,
      );
    }

    if (level4Values?.items) {
      this.level4Details = [];
      this.level4Details = level4Values?.items.map((item) => ({
        value: item?.id,
        text: item?.level4Value,
        segmentType: item?.segmentType,
      }));
      this.singleAssetInput.assetHierarchy[rowIndex].level4List =
        this.level4Details;

      // Check if the selected value exists in the filtered list
      const selectedLevel4Value =
        this.singleAssetInput.assetHierarchy[rowIndex].level4;
      const valueExists = this.level4Details.some(
        (item) => item.value === selectedLevel4Value,
      );

      // If not, reset to the default value
      if (!valueExists) {
        this.singleAssetInput.assetHierarchy[rowIndex].level4 =
          this.level4defaultItem?.value || null;
        this.singleAssetInput.assetHierarchy[rowIndex].segmentType = null;
      }
      this.isLoading = false;
    }
  }

  async bindAssetType(primaryCompanyId: string) {
    const assetTypeData =
      await this._heirarchyFilterService.getAllAssetTypeByPrimaryCompany(
        primaryCompanyId,
      );
    if (assetTypeData?.items) {
      this.assetType = assetTypeData?.items.map((item) => ({
        value: item?.id,
        text: item?.assetType,
      }));
    }
  }

  addAssetDetails() {
    this.singleAssetInput.assetHierarchy.push({
      level3: '',
      level4: '',
      level4List: [],
      level4Value: '',
      segmentRelation: '',
      segmentType: '',
    });
    if (
      this.singleAssetInput.assetHierarchy &&
      this.singleAssetInput.assetHierarchy.length > 1
    ) {
      this.isMeasurementDisabled = false;
    }
    this.checkIsSegmentRelationIsPrimary();
    this.setSegmentDropDownValueToAssociate();
  }

  getDistinctSelectedSegment() {
    this.distinctedSegmentValues = [];
    this.distinctedSegmentValues = this.singleAssetInput.assetHierarchy
      .map((item: any) => ({
        value: item?.level4,
        text: item?.level4Value,
        segmentRelation: item?.segmentRelation,
        level4Type: item?.segmentType,
      }))
      .filter((item: any) => item.value?.trim() !== '') // Remove items with empty or whitespace-only values
      .filter(
        (item: any, index: number, self: any) =>
          index ===
          self.findIndex(
            (t: any) =>
              t.value?.trim().toLowerCase() ===
              item.value?.trim().toLowerCase(),
          ),
      );
  }
  addNewMeasurement() {
    this.singleAssetInput.measurementCriteriaInputs.push({
      id: '',
      pk: '',
      sk: '',
      measurementSource: '',
      measurementType: '',
      measurementOperator: '',
      criteria: '',
      criteria1: '',
      criteria2: '',
      segment: '',
      level4ValueId: '',
      level4Type: '',
      segmentRelation: '',
      compliance: false,
      unit: 'V',
      isDeleted: false,
    });
  }

  async onAssetTypeChange(selectedValue: any) {
    const input: AssetTypeTemplateInput = {
      primaryCompanyId: this.primaryCompanyId,
      id: selectedValue,
    };
    const selectedItem = this.assetType.find(
      (item) => item.value === selectedValue,
    );
    if (selectedItem) {
      this.selectedAssetTypeName = selectedItem.text ?? '';
    }
    if (selectedValue) {
      this.isLoading = true;
      await this.bindMeasurementData(input);
      this.isLoading = false;
    } else {
      this.singleAssetInput.measurementCriteriaInputs = [];
    }
  }

  async bindMeasurementData(input: AssetTypeTemplateInput) {
    const response =
      await this.assetSetupService.getAssetTypeTemplateById(input);
    // Mapping response data to the model

    if (response && response.measurementCriterias) {
      // Transforming measurementCriterias into a new array of objects
      const measurementCriteriaArray =
        response.measurementCriterias?.map((item) => {
          let criteria1 = '';
          let criteria2 = '';

          // Check if item?.criteria contains a comma
          if (item?.criteria?.includes(',')) {
            const [firstCriteria, secondCriteria] = item.criteria.split(',');
            criteria1 = firstCriteria.trim(); // Assign the first value to criteria1
            criteria2 = secondCriteria?.trim() || ''; // Assign the second value to criteria2, if available
          }

          return {
            id: item?.id,
            measurementSource: item?.measurementSource || '',
            measurementType: item?.measurementType || '',
            measurementOperator: item?.measurementOperator || '',
            criteria: item?.criteria || '',
            criteria1: criteria1, // Use the extracted criteria1
            criteria2: criteria2, // Use the extracted criteria2
            segment: item?.segment || '',
            segmentRelation: item?.segmentRelation || '',
            compliance: item?.compliance || false,
            unit: item?.unit || 'V',
            level4ValueId: '',
            level4Type: '',
          };
        }) || []; // Fallback to an empty array if measurementCriterias is undefined

      // Pushing each transformed object into measurementCriteriaInputs

      this.singleAssetInput.measurementCriteriaInputs = [];
      this.singleAssetInput.measurementCriteriaInputs.push(
        ...measurementCriteriaArray,
      );
    }
  }

  onCancel() {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_ASSET_OVERVIEW]),
      true,
      NO,
      true,
    );
  }
  async onSaveAndExit(form: any) {
    try {
      let _creatUpdateAssetResponse: AssetResponse | null;
      // Loop through all form controls and mark them as touched
      Object.keys(form.controls).forEach((field) => {
        const control = form.controls[field];
        control.markAsTouched({ onlySelf: true });
      });
      if (this.uploadedFileName && this.uploadedFileName.length === 0) {
        this.isImageUploadTouched = true;
      }

      if (!this.isPrimarySelected) {
        this.toastr.showError(FAILED_CREATE_ASSET_MINIMUM_ONE_PRIMARY, ERROR);
      } else if (
        this.singleAssetInput.measurementCriteriaInputs &&
        this.singleAssetInput.measurementCriteriaInputs.length == 0
      ) {
        this.toastr.showError(
          FAILED_CREATE_ASSET_MINIMUM_ONE_MEASUREMENT,
          ERROR,
        );
      } else if (
        this.singleAssetInput.assetHierarchy &&
        this.singleAssetInput.assetHierarchy.length == 0
      ) {
        this.toastr.showError(FAILED_CREATE_ASSET_MINIMUM_ONE_ASSET, ERROR);
      } else if (
        form.valid &&
        this.isPrimarySelected &&
        this.uploadedFileUrl &&
        this.uploadedFileUrl.length > 0
      ) {
        this.isLoading = true;
        if (this.isEdit) {
          await this.updateAssetModel();
          const input = this.updateModel;
          _creatUpdateAssetResponse =
            await this.assetSetupService.updateAsset(input);
        } else {
          await this.createAssetModel();
          const input = this.assetModel;
          _creatUpdateAssetResponse =
            await this.assetSetupService.createAsset(input);
        }

        if (_creatUpdateAssetResponse?.status === 'Success') {
          if (this.uploadedFileName && this.uploadedFileName.length > 0) {
            this.uploadImages(_creatUpdateAssetResponse);
          }
          this.popupDialogService.openDialog(
            this.isEdit == true
              ? ASSET_UPDATED_SUCCESSFULLY
              : ASSET_ADDED_SUCCESSFULLY,
            ASSET_SUCCESSFUL_MSG,
            SUCCESS,
            CONTINUE,
            () => this.router.navigate(['assets/asset-setup']),
          );
        }
        else if (_creatUpdateAssetResponse?.status === 'Error') {
          if (_creatUpdateAssetResponse.error?.message?.includes('already exists')) {
            this.popupDialogService.openDialog(
              ASSET_ADDED_FAILED,
              _creatUpdateAssetResponse.error.message,
              FAILED,
              CLOSE,
            );
          }
        }
        else {
          this.popupDialogService.openDialog(
            this.isEdit == true ? ASSET_UPDATED_FAILED : ASSET_ADDED_FAILED,
            ASSET_FAILURE_MSG,
            FAILED,
            CLOSE,
          );
        }
        this.isLoading = false;
      } else if (this.uploadedFileUrl && this.uploadedFileUrl.length === 0) {
        this.toastr.showError(FAILED_CREATE_ASSET_MINIMUM_ONE_IMAGE, ERROR);
      }
    } catch (error) {
      console.error(CREATE_ASSET_ERROR, error);
    }
  }

  async onSaveAndAddNew(form: any) {
    try {
      let _creatUpdateAssetResponse: AssetResponse | null;
      Object.keys(form.controls).forEach((field) => {
        const control = form.controls[field];
        control.markAsTouched({ onlySelf: true });
      });
      if (this.uploadedFileName && this.uploadedFileName.length === 0) {
        this.isImageUploadTouched = true;
      }
      if (!this.isPrimarySelected) {
        this.toastr.showError(FAILED_CREATE_ASSET_MINIMUM_ONE_PRIMARY, ERROR);
      } else if (
        this.singleAssetInput.measurementCriteriaInputs &&
        this.singleAssetInput.measurementCriteriaInputs.length == 0
      ) {
        this.toastr.showError(
          FAILED_CREATE_ASSET_MINIMUM_ONE_MEASUREMENT,
          ERROR,
        );
      } else if (
        this.singleAssetInput.assetHierarchy &&
        this.singleAssetInput.assetHierarchy.length == 0
      ) {
        this.toastr.showError(FAILED_CREATE_ASSET_MINIMUM_ONE_ASSET, ERROR);
      } else if (
        form.valid &&
        this.isPrimarySelected &&
        this.uploadedFileUrl &&
        this.uploadedFileUrl.length > 0
      ) {
        this.isLoading = true;
        if (this.isEdit) {
          await this.updateAssetModel();
          const input = this.updateModel;
          _creatUpdateAssetResponse =
            await this.assetSetupService.updateAsset(input);
        } else {
          await this.createAssetModel();
          const input = this.assetModel;
          _creatUpdateAssetResponse =
            await this.assetSetupService.createAsset(input);
        }
        this.isLoading = false;
        if (_creatUpdateAssetResponse?.status === 'Success') {
          if (this.uploadedFileName && this.uploadedFileName.length > 0) {
            this.uploadImages(_creatUpdateAssetResponse);
          }
          this.popupDialogService.openDialog(
            this.isEdit == true
              ? ASSET_UPDATED_SUCCESSFULLY
              : ASSET_ADDED_SUCCESSFULLY,
            ASSET_SUCCESSFUL_MSG2,
            SUCCESS,
            CONTINUE,
            () => this.router.navigate(['assets/asset-setup/create']),
          );
          form.reset();
          this.resetForm();
        }
        else if (_creatUpdateAssetResponse?.status === 'Error') {
          if (_creatUpdateAssetResponse.error?.message?.includes('already exists')) {
            this.popupDialogService.openDialog(
              ASSET_ADDED_FAILED,
              _creatUpdateAssetResponse.error.message,
              FAILED,
              CLOSE,
            );
          }
        }
        else {
          this.popupDialogService.openDialog(
            this.isEdit == true ? ASSET_UPDATED_FAILED : ASSET_ADDED_FAILED,
            ASSET_FAILURE_MSG,
            FAILED,
            CLOSE,
          );
        }
      } else if (this.uploadedFileUrl && this.uploadedFileUrl.length === 0) {
        this.toastr.showError(FAILED_CREATE_ASSET_MINIMUM_ONE_IMAGE, ERROR);
        console.log('Form is not Valid');
      }
    } catch (error) {
      console.error(
        this.isEdit == true ? UPDATE_ASSET_ERROR : CREATE_ASSET_ERROR,
        error,
      );
    }
  }
  level4DropDownValueChange(selectedValue: any, index: number) {
    const selectedObject = this.level4AllDetails.find(
      (item) => item.value === selectedValue,
    );
    this.singleAssetInput.assetHierarchy[index].level4Value =
      selectedObject?.text;
    // You can now assign it to the corresponding asset or do whatever is required
    this.updateSegmentType(selectedObject, index);
    this.getDistinctSelectedSegment();
    this.setSegmentDropDownValueToAssociate();

    this.singleAssetInput.measurementCriteriaInputs.forEach(
      (item: any, index: number) => {
        const valueExists =
          !this.distinctedSegmentValues.some((x) => x.value) ===
          item?.level4ValueId;

        if (!valueExists) {
          this.singleAssetInput.measurementCriteriaInputs[index].segment = '';
          this.singleAssetInput.measurementCriteriaInputs[index].level4ValueId =
            '';
          this.singleAssetInput.measurementCriteriaInputs[index].level4Type =
            '';
          this.singleAssetInput.measurementCriteriaInputs[
            index
          ].segmentRelation = '';
        }
      },
    );
  }

  updateSegmentType(selectedSegmentObject: any, index: number) {
    this.singleAssetInput.assetHierarchy[index].segmentType =
      selectedSegmentObject.segmentType;
  }

  checkIsSegmentRelationIsPrimary() {
    if (
      this.singleAssetInput.assetHierarchy.some(
        (item: any) => item.segmentRelation === SEGMENT_RELATION[0].text,
      )
    ) {
      this.segmentprimaryIndex = this.singleAssetInput.assetHierarchy.findIndex(
        (item: any) => item.segmentRelation === SEGMENT_RELATION[0].text,
      );
      this.isPrimarySelected = true;
    } else {
      this.isPrimarySelected = false;
      this.segmentprimaryIndex = -1;
    }
  }

  segmentDropDownChange(selectedValue: string, index: number) {
    const selectedObject = this.distinctedSegmentValues.find(
      (item) => item.value === selectedValue,
    );

    this.singleAssetInput.measurementCriteriaInputs[index].segment =
      selectedObject?.text;
    this.singleAssetInput.measurementCriteriaInputs[index].segmentRelation =
      selectedObject?.segmentRelation;
    this.singleAssetInput.measurementCriteriaInputs[index].level4ValueId =
      selectedObject?.value;
    this.singleAssetInput.measurementCriteriaInputs[index].level4Type =
      selectedObject?.level4Type;
  }

  segmentRelationDropDownValueChange(selectedValue: string, rowIndex: number) {
    this.checkIsSegmentRelationIsPrimary();
    this.setSegmentDropDownValueToAssociate();

    const correspondingSegmentName =
      this.singleAssetInput.assetHierarchy[rowIndex].level4Value;
    if (correspondingSegmentName) {
      this.updateSegmentRelation(correspondingSegmentName, selectedValue);
    }
    this.getDistinctSelectedSegment();
    this.updateMeasurementCriteriaSegmentRelation(
      correspondingSegmentName,
      selectedValue,
    );
  }

  updateMeasurementCriteriaSegmentRelation(
    correspondingSegmentName: string,
    selectedValue: string,
  ) {
    this.singleAssetInput.measurementCriteriaInputs.forEach(
      (item: any, index: number) => {
        if (item.segment === correspondingSegmentName) {
          this.singleAssetInput.measurementCriteriaInputs[
            index
          ].segmentRelation = selectedValue;
        }
      },
    );
  }

  updateSegmentRelation(segmentName: string, selectedValue: string) {
    this.singleAssetInput.assetHierarchy.forEach((item: any, index: number) => {
      const segment =
        this.singleAssetInput.assetHierarchy[index].level4Value === segmentName;
      if (segment) {
        this.singleAssetInput.assetHierarchy[index].segmentRelation =
          selectedValue;
      }
    });

    if (
      !this.singleAssetInput.assetHierarchy.some(
        (item: any) => item.segmentRelation === SEGMENT_RELATION[0].text,
      )
    ) {
      this.isPrimarySelected = false;
    }
  }
  setSegmentDropDownValueToAssociate() {
    if (this.isPrimarySelected) {
      const primarySegment = this.singleAssetInput.assetHierarchy.find(
        (item: any) => item.segmentRelation == SEGMENT_RELATION[0].text,
      );

      this.singleAssetInput.assetHierarchy.forEach(
        (item: any, index: number) => {
          // Set segmentRelation to 'Associated' based on your index logic
          if (index >= 0 && index !== this.segmentprimaryIndex) {
            if (
              primarySegment &&
              primarySegment.level4Value ==
              this.singleAssetInput.assetHierarchy[index].level4Value
            ) {
              this.singleAssetInput.assetHierarchy[index].segmentRelation =
                SEGMENT_RELATION[0].text;
            } else {
              this.singleAssetInput.assetHierarchy[index].segmentRelation =
                SEGMENT_RELATION[1].text;
            }
          }
        },
      );
    }
  }

  async updateAssetModel() {
    // Loop through all items in the measurementCriteriaInputs array
    this.singleAssetInput.measurementCriteriaInputs.forEach((item: any) => {
      // Check if both criteria1 and criteria2 have values and join them with a comma
      if (item.criteria1 && item.criteria2) {
        item.criteria = `${item.criteria1}, ${item.criteria2}`;
      }
    });

    const creationDate = this.getFormattedDate(new Date());

    this.updateModel = {
      id: this.singleAssetInput.id,
      pk: this.singleAssetInput.pk,
      sk: this.singleAssetInput.sk,
      primaryCompanyId: this.singleAssetInput.primaryCompanyId,
      level1ValueId: this.singleAssetInput.level1, // assuming level1 corresponds to level1ValueId
      level2ValueId: this.singleAssetInput.level2, // assuming level2 corresponds to level2ValueId
      assetTypeId: this.singleAssetInput.assetType, // assuming assetType maps to assetTypeId
      assetName: this.singleAssetInput.assetName,
      lastInspectionDate: this.getFormattedDate(
        this.singleAssetInput.lastInspectionDate,
      ),
      mappingAssetId: this.singleAssetInput.mappingAssetId,
      milestone: this.singleAssetInput.milestone,
      creationDate: creationDate,
      latitude: this.singleAssetInput.latitude,
      longitude: this.singleAssetInput.longitude,
      comments: this.singleAssetInput.comments,
      locationDescription: this.singleAssetInput.locationDescription,
      effectiveDate: this.getFormattedDate(this.singleAssetInput.effectiveDate), // no corresponding field in singleAssetInput, set this as needed
      assetProperties: [], // map properties as needed, singleAssetInput might not have it
      imageNames: [],
    };

    this.updateModel.assetHierarchies =
      this.singleAssetInput.assetHierarchy.map((item: any) => ({
        level3ValueId: item.level3 || null, // Mapping level3 to level3ValueId
        level4ValueId: item.level4 || null, // Mapping level4 to level4ValueId
        level4Value: item.level4Value || null, // Mapping level4Value to level4Value
        level4Type: item.segmentType || null, // Mapping segmentType to level4Type
        level4Relation: item.segmentRelation || null, // Mapping segmentRelation to level4Relation
      }));

    this.updateModel.assetMeasurementCriteriaUpdateInputs =
      this.singleAssetInput.measurementCriteriaInputs.map((item: any) => ({
        id: item.id || null,
        pk: item.pk || null,
        sk: item.sk || null,
        isDeleted: item.isDeleted || null,
        compliance: item.compliance || false, // Mapping compliance
        criteria: item.criteria || null, // Mapping criteria
        measurementSource: item.measurementSource || null, // Mapping measurementSource
        measurementType: item.measurementType || null, // Mapping measurementType
        measurementOperator: item.measurementOperator || null, // Mapping measurementOperator
        unit: item.unit || null, // Mapping unit
        level4Relation: item.segmentRelation || null, // Mapping segmentRelation to level4Relation
        level4Type: item.level4Type || null, // Assuming there is no matching field in the input, set null
        level4Value: item.segment || null, // Mapping segment to level4Value
        level4ValueId: item.level4ValueId || null, // Assuming there is no matching field in the input, set null
      }));

    const assetVal = this.assetType.find(
      (item) => item.value === this.updateModel.assetTypeId,
    );

    // Checking the assetName and binding the appropriate values
    if (assetVal?.text === RECTIFIER) {
      // Mapping rectifierType values
      Object.keys(this.singleAssetInput.rectifierType).forEach((key) => {
        let value: string | null = '';
        if (
          key === DATE_OF_COMMISSIONING ||
          key === GROUNDBED_DATE_OF_COMMISSIONING
        ) {
          value = this.getFormattedDate(
            this.singleAssetInput.rectifierType[key],
          );
        } else {
          value = this.singleAssetInput.rectifierType[key];
        }

        this.updateModel.assetProperties?.push({
          key: key,
          value: value || null,
        });
      });
    } else if (assetVal?.text === BOND) {
      // Assuming bondType is an object with its own key-value pairs
      Object.keys(this.singleAssetInput.bondType).forEach((key) => {
        this.updateModel.assetProperties?.push({
          key: key,
          value: this.singleAssetInput.bondType[key] || null,
        });
      });
    } else if (assetVal?.text === TEST_STATION) {
      // Mapping testStations values
      Object.keys(this.singleAssetInput.testStations).forEach((key) => {
        let value: string | null = '';
        if (key === ELECTRODE_INSTALLATION_DATE) {
          value = this.getFormattedDate(
            this.singleAssetInput.testStations[key],
          );
        } else {
          value = this.singleAssetInput.testStations[key];
        }

        this.updateModel.assetProperties?.push({
          key: key,
          value: value,
        });
      });
    } else if (assetVal?.text === ER_PROBES) {
      // Mapping eRProbes values
      Object.keys(this.singleAssetInput.eRProbes).forEach((key) => {
        let value: string | null = '';
        if (key === INSTALL_DATE) {
          value = this.getFormattedDate(this.singleAssetInput.eRProbes[key]);
        } else {
          value = this.singleAssetInput.eRProbes[key];
        }
        this.updateModel.assetProperties?.push({
          key: key,
          value: value || null,
        });
      });
    }
    if (this.updateModel.imageNames) {
      this.updateModel.imageNames.push(...this.uploadedFileName);
    }
  }

  async createAssetModel() {
    // Loop through all items in the measurementCriteriaInputs array
    this.singleAssetInput.measurementCriteriaInputs.forEach((item: any) => {
      // Check if both criteria1 and criteria2 have values and join them with a comma
      if (item.criteria1 && item.criteria2) {
        item.criteria = `${item.criteria1}, ${item.criteria2}`;
      }
    });

    const creationDate = this.getFormattedDate(new Date());
    this.assetModel = {
      primaryCompanyId: this.singleAssetInput.primaryCompanyId,
      level1ValueId: this.singleAssetInput.level1, // assuming level1 corresponds to level1ValueId
      level2ValueId: this.singleAssetInput.level2, // assuming level2 corresponds to level2ValueId
      assetTypeId: this.singleAssetInput.assetType, // assuming assetType maps to assetTypeId
      assetName: this.singleAssetInput.assetName,
      lastInspectionDate: this.getFormattedDate(
        this.singleAssetInput.lastInspectionDate,
      ),
      mappingAssetId: this.singleAssetInput.mappingAssetId,
      milestone: this.singleAssetInput.milestone,
      creationDate: creationDate,
      latitude: this.singleAssetInput.latitude,
      longitude: this.singleAssetInput.longitude,
      comments: this.singleAssetInput.comments,
      locationDescription: this.singleAssetInput.locationDescription,
      effectiveDate: this.getFormattedDate(this.singleAssetInput.effectiveDate), // no corresponding field in singleAssetInput, set this as needed
      assetProperties: [], // map properties as needed, singleAssetInput might not have it
      imageNames: [],
    };

    this.assetModel.assetHierarchies = this.singleAssetInput.assetHierarchy.map(
      (item: any) => ({
        level3ValueId: item.level3 || null, // Mapping level3 to level3ValueId
        level4ValueId: item.level4 || null, // Mapping level4 to level4ValueId
        level4Value: item.level4Value || null, // Mapping level4Value to level4Value
        level4Type: item.segmentType || null, // Mapping segmentType to level4Type
        level4Relation: item.segmentRelation || null, // Mapping segmentRelation to level4Relation
      }),
    );

    this.assetModel.assetMeasurementCriteriaInputs =
      this.singleAssetInput.measurementCriteriaInputs.map((item: any) => ({
        id: item.id || null,
        compliance: item.compliance || false, // Mapping compliance
        criteria: item.criteria || null, // Mapping criteria
        measurementSource: item.measurementSource || null, // Mapping measurementSource
        measurementType: item.measurementType || null, // Mapping measurementType
        measurementOperator: item.measurementOperator || null, // Mapping measurementOperator
        unit: item.unit || null, // Mapping unit
        level4Relation: item.segmentRelation || null, // Mapping segmentRelation to level4Relation
        level4Type: item.level4Type || null, // Assuming there is no matching field in the input, set null
        level4Value: item.segment || null, // Mapping segment to level4Value
        level4ValueId: item.level4ValueId || null, // Assuming there is no matching field in the input, set null
      }));

    const assetVal = this.assetType.find(
      (item) => item.value === this.assetModel.assetTypeId,
    );

    // Checking the assetName and binding the appropriate values
    if (assetVal?.text === RECTIFIER) {
      // Mapping rectifierType values
      Object.keys(this.singleAssetInput.rectifierType).forEach((key) => {
        let value: string | null = '';
        if (
          key === DATE_OF_COMMISSIONING ||
          key === GROUNDBED_DATE_OF_COMMISSIONING
        ) {
          value = this.getFormattedDate(
            this.singleAssetInput.rectifierType[key],
          );
        } else {
          value = this.singleAssetInput.rectifierType[key];
        }

        this.assetModel.assetProperties?.push({
          key: key,
          value: value || null,
        });
      });
    } else if (assetVal?.text === BOND) {
      // Assuming bondType is an object with its own key-value pairs
      Object.keys(this.singleAssetInput.bondType).forEach((key) => {
        this.assetModel.assetProperties?.push({
          key: key,
          value: this.singleAssetInput.bondType[key] || null,
        });
      });
    } else if (assetVal?.text === TEST_STATION) {
      // Mapping testStations values
      Object.keys(this.singleAssetInput.testStations).forEach((key) => {
        let value: string | null = '';
        if (key === ELECTRODE_INSTALLATION_DATE) {
          value = this.getFormattedDate(
            this.singleAssetInput.testStations[key],
          );
        } else {
          value = this.singleAssetInput.testStations[key];
        }

        this.assetModel.assetProperties?.push({
          key: key,
          value: value,
        });
      });
    } else if (assetVal?.text === ER_PROBES) {
      // Mapping eRProbes values
      Object.keys(this.singleAssetInput.eRProbes).forEach((key) => {
        let value: string | null = '';
        if (key === INSTALL_DATE) {
          value = this.getFormattedDate(this.singleAssetInput.eRProbes[key]);
        } else {
          value = this.singleAssetInput.eRProbes[key];
        }
        this.assetModel.assetProperties?.push({
          key: key,
          value: value || null,
        });
      });
    }
    if (this.assetModel.imageNames) {
      this.assetModel.imageNames.push(...this.uploadedFileName);
    }
  }

  async getAssetByIdForEdit() {
    const intput: AssetMetadataInput = {
      primaryCompany: this.primaryCompanyForEdit,
      level1: this.level1ValueForEdit,
      level2: this.level2ValueForEdit,
      level3: this.level3ValueForEdit,
      assetId: this.assetIdValueForEdit,
    };

    const response = await this.assetSetupService.getAssetById(intput);
    if (response) {
      this.transformModelForEdit(response);
    }
  }

  async transformModelForEdit(response: AssetOutput) {
    try {
      this.bindLevel3Values(EMPTY_STRING);
      const selectedItem = this.assetType.find(
        (item) => item.value === response.assetTypeId,
      );
      if (selectedItem) {
        this.selectedAssetTypeName = selectedItem.text ?? '';
      }

      this.distinctedSegmentValues =
        response.assetHierarchies?.map((item: any) => ({
          value: item?.level4ValueId,
          text: item?.level4Value,
          segmentRelation: item?.level4Relation,
          level4Type: item?.level4Type,
        })) ?? [];
      this.singleAssetInput = {
        primaryCompanyId: this.primaryCompanyId,
        id: response.id,
        pk: response.pk,
        sk: response.sk,
        level1: response.level1ValueId, // Assuming level1 maps to level1ValueId
        level2: response.level2ValueId, // Assuming level2 maps to level2ValueId
        assetType: response.assetTypeId, // Assuming assetType maps to assetTypeId
        assetName: response.assetName,
        lastInspectionDate:
          response.lastInspectionDate !== null &&
            response.lastInspectionDate !== undefined
            ? new Date(response.lastInspectionDate)
            : null,
        mappingAssetId: response.mappingAssetId,
        milestone: response.milestone,
        creationDate:
          response.creationDate !== null && response.creationDate !== undefined
            ? new Date(response.creationDate)
            : null,
        effectiveDate:
          response.effectiveDate !== null &&
            response.effectiveDate !== undefined
            ? new Date(response.effectiveDate)
            : null,
        latitude: response.latitude,
        longitude: response.longitude,
        comments: response.comments,
        locationDescription: response.locationDescription,
        assetHierarchy: response.assetHierarchies?.map(
          (item: any, index: number) => {
            this.bindLevel4Values(item.level3ValueId, index);
            return {
              level3: item.level3ValueId || null, // Mapping back level3ValueId to level3
              level4: item.level4ValueId || null, // Mapping back level4ValueId to level4
              level4Value: item.level4Value || null, // Mapping back level4Value
              segmentType: item.level4Type || null, // Mapping back level4Type to segmentType
              segmentRelation: item.level4Relation || null,
            }; // Mapping back level4Relation to segmentRelation
          },
        ),

        measurementCriteriaInputs: response.assetMeasurementCriterias?.map(
          (item: any) => {
            let criteria1 = '';
            let criteria2 = '';
            // Check if item?.criteria contains a comma
            if (item?.criteria?.includes(',')) {
              const [firstCriteria, secondCriteria] = item.criteria.split(',');
              criteria1 = firstCriteria.trim(); // Assign the first value to criteria1
              criteria2 = secondCriteria?.trim() || ''; // Assign the second value to criteria2, if available
            }
            return {
              id: item.id || null,
              pk: item.pk || null,
              sk: item.sk || null,
              compliance: item.compliance || false, // Mapping compliance
              criteria: item.criteria || null, // Mapping criteria
              criteria1: criteria1, // Use the extracted criteria1
              criteria2: criteria2, // Use the extracted criteria2
              measurementSource: item.measurementSource || null, // Mapping measurementSource
              measurementType: item.measurementType || null, // Mapping measurementType
              measurementOperator: item.measurementOperator || null, // Mapping measurementOperator
              unit: item.unit || null, // Mapping unit
              segmentRelation: item.level4Relation || null, // Mapping back level4Relation
              level4Type: item.level4Type || null, // Mapping back level4Type
              segment: item.level4Value || null, // Mapping back level4Value to segment
              level4ValueId: item.level4ValueId || null, // Mapping back level4ValueId
              isDeleted: false,
            };
          },
        ),
        rectifierType: {},
        bondType: {},
        testStations: {},
        eRProbes: {},
      };

      const assetValue = this.assetType.find(
        (item) => item.value == response.assetTypeId,
      )?.text;

      // Checking the assetType to map the appropriate fields back to rectifierType, bondType, etc.
      if (assetValue === RECTIFIER) {
        // Mapping rectifierType values back
        this.singleAssetInput.rectifierType = {};
        response.assetProperties?.forEach((property: any) => {
          if (property?.Value !== undefined && property?.Value !== '') {
            if (property?.Key === RECTIFIER_MANUFACTURER) {
              this.singleAssetInput.rectifierType.rectifierManufacturer =
                property?.Value;
            }
            if (property?.Key === DATE_OF_COMMISSIONING) {
              this.singleAssetInput.rectifierType.dateOfCommissioning =
                property?.Value;
            }
            if (property?.Key === RECTIFIER_TYPE) {
              this.singleAssetInput.rectifierType.rectifierType =
                property?.Value;
            }
            if (property?.Key === OUTPUT_VOLTAGE) {
              this.singleAssetInput.rectifierType.outputVoltage =
                property?.Value;
            }
            if (property?.Key === OUTPUT_AMPERAGE) {
              this.singleAssetInput.rectifierType.outputAmperage =
                property?.Value;
            }
            if (property?.Key === SHUNT_VALUES) {
              this.singleAssetInput.rectifierType.shuntValues = property?.Value;
            }
            if (property?.Key === METER_NUMBER) {
              this.singleAssetInput.rectifierType.meterNumber = property?.Value;
            }
            if (property?.Key === GROUNDBED_TYPE) {
              this.singleAssetInput.rectifierType.groundbedType =
                property?.Value;
            }
            if (property?.Key === GROUNDBED_ORIENTATION) {
              this.singleAssetInput.rectifierType.groundbedOrientation =
                property?.Value;
            }
            if (property?.Key === ANODE_TYPE) {
              this.singleAssetInput.rectifierType.anodeType = property?.Value;
            }
            if (property?.Key === NUMBER_OF_ANODES) {
              this.singleAssetInput.rectifierType.numberOfAnodes =
                property?.Value;
            }
            if (property?.Key === GROUNDBED_DATE_OF_COMMISSIONING) {
              this.singleAssetInput.rectifierType.groundbedDateOfCommissioning =
                property?.Value;
            }
            if (property?.Key === BACKFILL_MASS) {
              this.singleAssetInput.rectifierType.backfillMass =
                property?.Value;
            }
            if (property?.Key === BACKFILL_DIAMETER) {
              this.singleAssetInput.rectifierType.backfillDiameter =
                property?.Value;
            }
            if (property?.Key === MAINTENANCE_DETAILS) {
              this.singleAssetInput.rectifierType.maintenanceDetails =
                property?.Value;
            }
          }
        });
      } else if (assetValue === BOND) {
        // Mapping bondType values back
        this.singleAssetInput.bondType = {};
        response.assetProperties?.forEach((property: any) => {
          if (property?.Value !== undefined && property?.Value !== '') {
            if (property?.Key === BOND_SHUNT_VALUE) {
              this.singleAssetInput.bondType.bondShuntValue = property?.Value;
            }
          }
        });
      } else if (assetValue === TEST_STATION) {
        // Mapping testStations values back
        this.singleAssetInput.testStations = {};
        response.assetProperties?.forEach((property: any) => {
          if (property?.Value !== undefined && property?.Value !== '') {
            if (property?.Key === ELECTRODE_MANUFACTURER) {
              this.singleAssetInput.testStations.electrodeManufacturer =
                property?.Value;
            }
            if (property?.Key === ELECTRODE_TYPE) {
              this.singleAssetInput.testStations.electrodeType =
                property?.Value;
            }
            if (property?.Key === ELECTRODE_CHEMISTRY) {
              this.singleAssetInput.testStations.electrodeChemistry =
                property?.Value;
            }
            if (property?.Key === ELECTRODE_INSTALLATION_DATE) {
              this.singleAssetInput.testStations.electrodeInstallationDate =
                property?.Value;
            }
            if (property?.Key === AC_COUPON_SIZE) {
              this.singleAssetInput.testStations.acCouponSize = property?.Value;
            }
            if (property?.Key === DC_COUPON_SIZE) {
              this.singleAssetInput.testStations.dcCouponSize = property?.Value;
            }
          }
        });
      } else if (assetValue === ER_PROBES) {
        // Mapping eRProbes values back
        this.singleAssetInput.eRProbes = {};
        response.assetProperties?.forEach((property: any) => {
          if (property?.Key === PROBE_MANUFACTURER) {
            this.singleAssetInput.eRProbes.probeManufacturer = property?.Value;
          }
          if (property?.Key === PROBE_TYPE) {
            this.singleAssetInput.eRProbes.probeType = property?.Value;
          }
          if (property?.Key === INSTALL_DATE) {
            this.singleAssetInput.eRProbes.installDate = property?.Value;
          }
        });
      }

      // Assuming the uploaded file names were stored in imageNames array
      this.singleAssetInput.uploadedFileName = response.imageUrls?.[0] || null;

      if (response.imageUrls) {
        const presignedURLPromises = response.imageUrls.map(
          async (imageURL) => {
            return (
              (await this.getPreSignedURLForUploadedFile(imageURL ?? '')) ?? ''
            );
          },
        );
        this.uploadedFileUrl = await Promise.all(presignedURLPromises);

        response.imageUrls.forEach((item) => {
          const fileName = this.getFileNameFromUrl(item ?? '');
          if (!this.uploadedFileName.includes(fileName)) {
            this.uploadedFileName.push(fileName);
          }
        });
      }
    } finally {
      this.isLoading = false;
      this.isPrimarySelected = true;
    }
  }

  getFileNameFromUrl(url: string): string {
    const lastSlashIndex = url.lastIndexOf('/');
    return lastSlashIndex !== -1 ? url.substring(lastSlashIndex + 1) : '';
  }

  async getPreSignedURLForUploadedFile(imageURL: string) {
    const response =
      await this.companyService.getPreSignedURLForUploadedFile(imageURL);
    return response?.presignedURL;
  }

  isImageUploadTouched = false;
  onFileInputChange(event: Event) {
    // Check if files were selected
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.isImageUploadTouched = true; // Set to true if a file is selected
    }
  }

  level1DropDownChangeEvent(selectedValue: any) {
    this.bindLevel2Values(selectedValue);
    this.singleAssetInput.assetHierarchy = [];
    this.distinctedSegmentValues = [];
    this.removeMeasurementTypesAndCriteriaSegmentData(EMPTY_STRING);
  }
  level2DropDownChangeEvent(selectedValue: any) {
    this.bindLevel3Values(selectedValue);
    this.singleAssetInput.assetHierarchy = [];
    this.distinctedSegmentValues = [];
    this.removeMeasurementTypesAndCriteriaSegmentData(EMPTY_STRING);
  }
  level3DropDownChangeEvent(selectedValue: any, rowIndex: number) {
    this.bindLevel4Values(selectedValue, rowIndex);
    this.getDistinctSelectedSegment();
    const relatedSegmentId =
      this.singleAssetInput.assetHierarchy[rowIndex].level4;
    if (relatedSegmentId) {
      this.removeMeasurementTypesAndCriteriaSegmentData(relatedSegmentId);
    }
  }

  removeDistinctedSegmentValues() {
    this.distinctedSegmentValues = [];
  }

  removeMeasurementTypesAndCriteriaSegmentData(relatedSegmentId: string) {
    this.singleAssetInput.measurementCriteriaInputs.forEach(
      (item: any, index: number) => {
        if (relatedSegmentId === item?.level4ValueId) {
          this.singleAssetInput.measurementCriteriaInputs[index].segment = '';
          this.singleAssetInput.measurementCriteriaInputs[index].level4ValueId =
            '';
          this.singleAssetInput.measurementCriteriaInputs[index].level4Type =
            '';
          this.singleAssetInput.measurementCriteriaInputs[
            index
          ].segmentRelation = '';
        }
      },
    );
  }

  async loadAssetHierarchy(response: any) {
    // Check if assetHierarchies exists and is an array
    const assetHierarchyPromises = response.assetHierarchies?.map(
      async (item: any, index: number) => {
        // Call bindLevel4Values and wait for it to complete
        await this.bindLevel4Values(item.level3ValueId, index);

        // Return the mapped object with default values
        return {
          level3: item.level3ValueId || null, // Mapping back level3ValueId to level3
          level4: item.level4ValueId || null, // Mapping back level4ValueId to level4
          level4Value: item.level4Value || null, // Mapping back level4Value
          segmentType: item.level4Type || null, // Mapping back level4Type to segmentType
          segmentRelation: item.level4Relation || null, // Mapping back level4Relation to segmentRelation
        };
      },
    );

    // Wait for all promises to resolve and return the asset hierarchy
    const assetHierarchy = await Promise.all(assetHierarchyPromises);

    return assetHierarchy;
  }
}
