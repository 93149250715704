import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { ExportMapModalComponent } from '../../shared/export-map-modal/export-map-modal.component';
import { ANODE, BOND, CASING, COMPLIANT_AH, CTS, DELINQUENT_AH, EMPTY_STRING, ER_PROBE, EXCEPTION_AH, ISOLATIONJOINT, RECTIFIER, TEST_POINT } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private mapElement!: HTMLElement;
  private mapInstance: any = null;
  private center: { lat: number; lng: number } | undefined;
  constructor(public dialog: MatDialog) { 

  }

  initialize(mapElement: HTMLElement, mapInstance: any) {
    this.mapElement = mapElement;
    this.mapInstance = mapInstance;
  }

assetData : any[] = []; 
  getData() {
    this.setMapCenter();
    return this.assetData;
  }

  openExportMapModal(): void {
    const dialogRef = this.dialog.open(ExportMapModalComponent);
    dialogRef.afterClosed().subscribe((result: { fileType: string; includeLegendsMarkers: boolean } | undefined) => {
      if (result) {
        this.exportMapAsImage(result.fileType, result.includeLegendsMarkers);
      } else {
        console.log('No file type selected or dialog closed without selection.');
      }
    });
  }

  pickMarkerColor(assetType: string): string {
    switch (assetType) {
      case COMPLIANT_AH:
        return '#22C55E';
      case EXCEPTION_AH:
        return '#E5AB09';
      case DELINQUENT_AH:
        return '#E77A3C';
      default:
        return EMPTY_STRING;
    }
  }

  pickMarkerInnerText(assetType: string): string {
    let markertext = 'M';
    if (assetType == RECTIFIER) {
      markertext = 'R';
    } else if (assetType == TEST_POINT) {
      markertext = 'T';
    } else if (assetType == BOND) {
      markertext = 'B';
    } else if (assetType == ANODE) {
      markertext = 'A';
    } else if (assetType == CASING) {
      markertext = 'CS';  
    } else if (assetType == CTS) {
      markertext = 'CP';
    } else if (assetType == ER_PROBE) {
      markertext = 'ER';
    } else if (assetType == ISOLATIONJOINT) {
      markertext = 'IJ';
    } else {
      const firstLetter = assetType.charAt(0).toUpperCase();
      markertext = firstLetter;
    }
    return markertext;
  }

  getMarkerIcon(text: string, markerColor: string, shoText: boolean, type: string): string {
    const numberElements = shoText
      ? `
        <circle cx="38" cy="11" r="10" fill="#3498DB" stroke="white" stroke-width="2"/>
        <text x="38" y="12" font-family="Arial" font-size="14" fill="#FFF" text-anchor="middle" alignment-baseline="middle">${text}</text>
      `
      : '';
  
    const svgIcon = `
      <svg width="98" height="110" viewBox="0 0 49 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <g filter="url(#filter0_d)">
            <!-- Marker path with border -->
            <path d="M24.15 10.0244C16.3616 10.0244 10.0625 16.3235 10.0625 24.1119C10.0625 34.6775 24.15 50.2744 24.15 50.2744C24.15 50.2744 38.2375 34.6775 38.2375 24.1119C38.2375 16.3235 31.9384 10.0244 24.15 10.0244Z" fill="${markerColor}" stroke="white" stroke-width="2"/>
            <text x="24.15" y="27" font-family="Arial" font-size="16" fill="white" text-anchor="middle" alignment-baseline="middle">${type}</text>
          </g>
        </g>
        ${numberElements}
        <defs>
          <filter id="filter0_d" x="1.0625" y="5.02441" width="46.1748" height="59.2334" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="3.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <clipPath id="clip0">
            <rect y="6" width="60.3" height="60.3" rx="2.24651" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    `;
  
    // Return the SVG as a data URL
    return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgIcon);
  }
  exportMapAsImage(fileType: string, includeLegendsMarkers: boolean): void {
   
    if (!fileType || !this.mapElement || !this.mapInstance) {
      console.error('Required data is missing');
      return;
    }
    const mapContainer = this.mapElement.closest('.map-container');

    if (mapContainer) {
      // Hide zoom and map type controls
      this.mapInstance.setOptions({
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      });

      const legendContainer = mapContainer.querySelector('.legend-container') as HTMLElement;
      let originalDisplayStyle = '';

      if (legendContainer) {
        originalDisplayStyle = window.getComputedStyle(legendContainer).display;
        if (!includeLegendsMarkers) {
          legendContainer.style.display = 'none';
        }
      }

      // Function to handle the image capture
      const captureImage = () => {
        const captureElement = (includeLegendsMarkers ? mapContainer : this.mapElement ) as HTMLElement;
        html2canvas(captureElement, {
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          scale: 2
        }).then(canvas => {
          const image = canvas.toDataURL(`image/${fileType}`);
          const link = document.createElement('a');
          link.href = image;
          link.download = `map.${fileType}`;
          link.click();
        }).catch(error => {
          console.error('Error capturing map image:', error);
        }).finally(() => {
          // Restore original map options
          this.mapInstance.setOptions({
            zoomControl: true,
            mapTypeControl: true,
            fullscreenControl: true
          });

          if (legendContainer) {
            legendContainer.style.display = originalDisplayStyle;
          }
        });
      };
      setTimeout(captureImage, 100);

    } 
    
    else {
      console.error('Map element or container is not found.');
    }
  }

  private setMapCenter(): void {
    if (this.assetData.length > 0) {
      const latitudes = this.assetData.map(data => parseFloat(data.latitude));
      const longitudes = this.assetData.map(data => parseFloat(data.longitude));

      const avgLatitude = latitudes[0];
      const avgLongitude = longitudes[0];

      this.center = { lat: avgLatitude, lng: avgLongitude };
    }else if(this.assetData.length == 0){
      this.center = { lat: 22, lng: 90 }; // need to change the default coordinates 
    } 
    else {
      console.error('No asset data available to calculate center.');
    }
  }

  getMapCenter(): { lat: number; lng: number } | undefined {
    this.setMapCenter();
    return this.center;
  }
}
