import { Injectable } from '@angular/core';
import { SurveyFields } from '../models/create-survey.module';
import { FETCHING_SYRC_USERS_ERROR } from '../../shared/constants';
import {
  GetAllSYRCUserOptionQuery,
  UserSYRCOptionConnection,
} from '../../../awsAppSync/API';
import { getAllSYRCUserOption } from '../../../awsAppSync/graphql/queries';
import { GetSurveyByIdQuery, GetSurveyByIdQueryVariables, SurveyInput } from '../../../awsAppSync/API';
import { getSurveyById } from '../../../awsAppSync/graphql/queries';
import { GENERIC_FAILED_ERROR } from '../../shared/constants';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';

@Injectable({
  providedIn: 'root',
})
export class CreateSurveyService {
  surveyType: SurveyFields[] = [];
  surveyPriority: SurveyFields[] = [];
  surveyCollectionType: SurveyFields[] = [];
  surveyTroubleshooting: SurveyFields[] = [];
  assignTo: SurveyFields[] = [];

  constructor(private awsService: BaseAWSRequestService) {}

  getSurveyType() {
    this.surveyType = [
      { id: '1', value: 'Direct' },
      { id: '2', value: 'Stationary' },
      { id: '3', value: 'CIS' },
      { id: '4', value: 'DCVG' },
      { id: '5', value: 'ACVG' },
    ];
    return this.surveyType;
  }
  getSurveyPriority() {
    this.surveyPriority = [
      { id: '1', value: 'High' },
      { id: '2', value: 'Medium' },
      { id: '3', value: 'Low' },
    ];
    return this.surveyPriority;
  }

  getSurveyCollectionType() {
    this.surveyCollectionType = [
      { id: '1', value: 'Compliance' },
      { id: '2', value: 'Troubleshooting' },
    ];
    return this.surveyCollectionType;
  }
  getsurveyTroubleshooting() {
    this.surveyTroubleshooting = [
      { id: '1', value: 'Yes' },
      { id: '2', value: 'No' },
    ];
    return this.surveyTroubleshooting;
  }
  clearLocalStorageKey(key: string): void {
    localStorage.removeItem(key);
  }

  async getAllSYRCUsers(): Promise<UserSYRCOptionConnection | null> {
    try {
      const response =
        await this.awsService.executeGetQuery<GetAllSYRCUserOptionQuery>(
          getAllSYRCUserOption,
        );
      return response.getAllSYRCUserOption ?? null;
    } catch (error) {
      console.error(FETCHING_SYRC_USERS_ERROR, error);
      return null;
    }
  }

  async getSurveyById(input: SurveyInput): Promise<GetSurveyByIdQuery['getSurveyById'] | null> {
    const query = getSurveyById;
    const variables: GetSurveyByIdQueryVariables = { input };
    
    try {
      const result = await this.awsService.executeGetQuery<GetSurveyByIdQuery>(query, variables);
      return result.getSurveyById || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
}
