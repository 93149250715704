import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { API_ERROR_MSG, CANCEL_BTN_TEXT, CLOSE, CONTINUE, ERROR, FAILED, REDIRECT_ROUTE_MANAGEMENT, REDIRECT_SURVEY_CREATE_ROUTE, REDIRECT_SURVEY_EDIT_ROUTE, SAVE_TEMPLATE_HEADING, SAVE_TEMPLATE_MSG, SUCCESS } from '../../shared/constants';
import { SurveyRouteService } from '../../core/services/survey-route.service';
import { SurveyRoute } from '../../core/models/survey-routes';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { SurveyRouteUpdateInput } from '../../../awsAppSync/API';
import { Assets } from '../../core/models/assets';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { Store } from '@ngxs/store';
import { RemoveSurveyRouteData } from '../../core/store/survey-route.state';

@Component({
  selector: 'app-add-survey-route-modal',
  templateUrl: './add-survey-route-modal.component.html',
  styleUrl: './add-survey-route-modal.component.css'
})
export class AddSurveyRouteModalComponent {

  createRouteForm: FormGroup;
  isEditMode = false;
  selectedRowItem: SurveyRoute | undefined;
  allowNavigation = true;
  isLoading = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private store: Store,
    private popupDialogService: PopupDialogService,
    private toastr: CustomToastrService,
    private surveyRouteService: SurveyRouteService,
    private dialogRef: MatDialogRef<AddSurveyRouteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any 
  ) {
    this.createRouteForm = this.fb.group({
      routeName: ['', Validators.required],
      routeDescription: ['']
    });

    if (data && data.selectedRowItem) {
      this.selectedRowItem = data.selectedRowItem;
      this.createRouteForm.patchValue({
        routeName: data.selectedRowItem.routeName,
        routeDescription: data.selectedRowItem.routeDescription
      });
      this.isEditMode = data.isEdit;
    }

    if(this.data){
       this.allowNavigation = this.data.allowNavigation as boolean;
    }
  }
  close(): void {
    this.dialogRef.close();
  }
  cancel(): void {
    this.dialogRef.close();
  }

  saveAndContinue(): void {
    if (this.createRouteForm.valid){
      const routeName = this.createRouteForm.get('routeName')?.value;
      const routeDescription = this.createRouteForm.get('routeDescription')?.value;
      
      if (this.isEditMode) {
        const dialogRef = this.popupDialogService.openDialog(
          SAVE_TEMPLATE_HEADING,
          SAVE_TEMPLATE_MSG,
          FAILED,
          CONTINUE,
          () => this.router.navigate([]),
          true,
          CANCEL_BTN_TEXT,
          true,
        );
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result === CANCEL_BTN_TEXT) {
            return;
          } else {
             this.isLoading = true;
            if (this.selectedRowItem) {
              const routeId = this.selectedRowItem?.id;
              if (routeId) {
                const input: SurveyRouteUpdateInput = {
                  id: this.selectedRowItem.id,
                  pk: this.selectedRowItem.pk,
                  sk: this.selectedRowItem.sk,
                  routeDescription: routeDescription,
                  routeName: routeName,
                  surveyRouteAssociatedAssetInput: [
                    ...this.data.assets.map((asset : Assets) => ({
                      assetId: asset.id,
                      level1ValueId: asset.level1Id ?? null,
                      level2ValueId: asset.level2Id ?? null,
                      level3ValueId: asset.level3Id ?? null,
                      order: asset.order,
                    })),
                  ],
                };

                const response =
                  await this.surveyRouteService.updateSurveyRoute(input);
                 this.isLoading = false;
                if (response!.status === 'Success') {
                  this.store.dispatch(new RemoveSurveyRouteData());
                  this.popupDialogService.openDialog(
                    'Survey Route Updated Successfully!',
                    "You'll be redirected to Survey Route Overview list page",
                    SUCCESS,
                    CLOSE,
                    () => this.router.navigate([REDIRECT_ROUTE_MANAGEMENT])
                  );
                } else {
                  this.popupDialogService.openDialog(
                    'Updating Survey Route Failed!',
                    'An unexpected error occurred. Please try again.',
                    FAILED,
                    CLOSE,
                  );
                  this.toastr.showError(
                    response?.error?.message ?? API_ERROR_MSG,
                    ERROR,
                  );
                  this.isLoading = false;
                }
              }
            }
          }
        });
  
      } else {
        this.surveyRouteService.setRouteData({ routeName, routeDescription });
      }

      if(this.allowNavigation){
        this.router.navigate([REDIRECT_SURVEY_CREATE_ROUTE])
      }
     
      if(this.isEditMode){
        this.router.navigate([REDIRECT_SURVEY_EDIT_ROUTE])
      }else{
        if(this.allowNavigation){
        this.router.navigate([REDIRECT_SURVEY_CREATE_ROUTE])
        }
      }
      this.dialogRef.close();
      
    }
  }
}
