import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SVGIcon, searchIcon } from '@progress/kendo-svg-icons';
import { Observable, of, Subscription } from 'rxjs';
import { CustomToastrService } from '../ngx-toastr/custom-toastr.service';
import { TemplateService } from '../service/template/template.service';
import {
  ASSET_DELINQUENCY_DATE,
  ASSET_DELINQUENCY_DATE_TEXT,
  ASSET_NAME,
  ASSET_NAME_EXCEPTION,
  ASSET_NAME_TEXT,
  ASSET_TYPE,
  ASSET_TYPE_EXCEPTION,
  ASSET_TYPE_TEXT,
  COMMENTS,
  COMPLAINCE_STATUS,
  COMPLAINCE_STATUS_TEXT,
  DATECOLLECTION,
  DATECOLLECTION_TEXT,
  DATEOFCOMPLETION,
  DATEOFCOMPLETION_TEXT,
  DAYS_UNTIL_DELINQUENT,
  DAYS_UNTIL_DELINQUENT_TEXT,
  DUEDATE,
  DUEDATE_TEXT,
  EMPTY_STRING,
  GLOBAL_TEMPLATE_ID,
  GPS_COORDINATE,
  GPS_COORDINATE_TEXT,
  INSPECTION_TARGET_DATE,
  INSPECTION_TARGET_DATE_TEXT,
  LAST_INSPECTION_DATE,
  LAST_INSPECTION_DATE_TEXT,
  MEASUREMENT_FREQUENCY_TEXT,
  ROOT_CAUSE,
  ROOT_CAUSE_TEXT,
  SEGMENT_TEXT,
  SPECIALIST,
  SUPERVISOR,
  SURVEYDESCRIPTION,
  SURVEYDESCRIPTION_TEXT,
  SURVEYNAME,
  SURVEYNAME_TEXT,
  SURVEYPRIORITY,
  SURVEYPRIORITY_TEXT,
  SURVEYSTATUS,
  SURVEYSTATUS_TEXT,
  SURVEYTYPE,
  SURVEYTYPE_TEXT,
  TECHNICIAN,
  TECHNICIAN_TEXT,
  UPDATE_TEMPLATE,
  WORKFLOW_STATUS,
  WORKFLOW_STATUS_TEXT,
} from '../constants';
import { templateType, UpdateTemplateInput } from '../../../awsAppSync/API';
import {
  TemplateBase,
  TemplateBaseItem,
  TemplateFixedItems,
  TemplateItem,
  TemplateItems,
} from '../../core/models/template';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { LevelNameState } from '../../core/store/filtet-levelName.state';

@Component({
  selector: 'app-columnadjustment',
  templateUrl: './columnadjustment.component.html',
  styleUrl: './columnadjustment.component.css',
})
export class ColumnadjustmentComponent implements OnDestroy, OnChanges {
  @ViewChild('columnadjustmentbutton', { static: false })
  columnadjustmentbutton!: ElementRef<HTMLButtonElement>;
  @Output() selectedColumnsChange = new EventEmitter<string[]>();
  @Output() columnPosition = new EventEmitter<{
    previousIndexOfCol: number;
    newIndexOfCol: number;
    columnName: string;
  }>();
  @Output() previousIndexOfCol = 0;
  private subscription: Subscription;
  public showColumnAdjustmentPopup = false;
  public svgSearch: SVGIcon = searchIcon;
  public restrictedIndexForReorder = 3;
  public addedTemplateId = '';
  public deletedTemplateId = '';
  public searchText = '';
  public templateType: templateType | undefined;
  public isNewTemplate = false;
  public savedTemplateId = GLOBAL_TEMPLATE_ID;
  public selectedTemplateId = GLOBAL_TEMPLATE_ID;
  public currentSelectedTemplateId = EMPTY_STRING;
  public templates: TemplateBase[] = [];
  public baseItems: TemplateBaseItem[] = [];
  public templateItems!: Record<string, { id: number; checked: boolean }[]>;
  public itemsForSelectedTemplate: TemplateItems[] = [];
  public filteredItems: TemplateItems[] = [];
  private _selectedColumns: string[] = [];
  @Input() templateTypeInput: templateType | undefined;
  fixedTemplateValues: TemplateFixedItems[] = [];

  private userId = "" ;
  private userId$: Observable<string | null> = of(null);
  private subscriptions: Subscription = new Subscription();
  
  storeLevelName$: Observable<string[]>;
  selectedLevelName: string[] = [];
  level1Name = '';
  level2Name = '';
  level3Name = '';

  get selectedColumns(): string[] {
    return this._selectedColumns;
  }
  set selectedColumns(value: string[]) {
    this._selectedColumns = value;
    this.selectedColumnsChange.emit(this._selectedColumns); // Emit the change
  }

  constructor(
    private templateService: TemplateService,
    private toastr: CustomToastrService,
    private store: Store
  ) {
    this.initilizeUserObservable();
    this.templateService.templates$.subscribe((templates) => {
      this.templates = templates;
    });
    this.templateService.templatesItems$.subscribe((templateItems) => {
      this.templateItems = templateItems;
      this.updateItemsForSelectedTemplate(this.selectedTemplateId);
    });
    this.templateService.getAddedTemplateId.subscribe(
      (id) => (this.addedTemplateId = id),
    );
    this.templateService.getDeletedTemplateId.subscribe(
      (id) => (this.deletedTemplateId = id),
    );
    this.templateService.getIsNewTemplate.subscribe(
      (value) => (this.isNewTemplate = value),
    );
    this.subscription = this.templateService.openTemplateSettings$.subscribe(
      () => {
        this.showColumnAdjustmentPopup = false;
        this.triggerColumnAdjustmentButtonButton();
        this.templateService.setIsNewTemplate(false);
      },
    );
    this.templateService.getIsTemplateDeleted$.subscribe(() => {
      this.selectedTemplateId =
        this.savedTemplateId == this.deletedTemplateId
          ? GLOBAL_TEMPLATE_ID
          : this.selectedTemplateId;
      this.savedTemplateId = this.selectedTemplateId;
      this.updateItemsForSelectedTemplate(this.selectedTemplateId);
    });

    this.storeLevelName$ = this.store.select(LevelNameState.getLevelName);
  }

  ngOnInit() {
    this.storeLevelName$.subscribe((level1Name) => {
      this.selectedLevelName = level1Name;
      this.generateLevelNames();
    });
  }

  generateLevelNames() {
    if (this.selectedLevelName[0]) {
      this.level1Name = this.selectedLevelName[0];
    }
    if (this.selectedLevelName[1]) {
      this.level2Name = this.selectedLevelName[1];
    }
    if (this.selectedLevelName[2]) {
      this.level3Name = this.selectedLevelName[2];
    }
  }

  initilizeUserObservable(){
    this.userId$ = this.store.select(AuthenticateUserState.getSyrcUserId);
    this.subscriptions.add(
      this.userId$.subscribe((userId) => {
        this.userId = userId?? "";
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['templateTypeInput']) {
      this.templateType = this.templateTypeInput;
      this.baseItems = this.prepareBaseItemsForTemplate(this.templateType!);
      this.fixedTemplateValues = this.prepareFixedItemsForTemplate(
        this.templateType!,
      );
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.showColumnAdjustmentPopup && !this.isClickInsidePopup(event)) {
      this.showColumnAdjustmentPopup = false;
      this.selectedTemplateId = this.savedTemplateId;
      this.updateItemsForSelectedTemplate(this.selectedTemplateId);
    }
  }

  private isClickInsidePopup(_event: MouseEvent): boolean {
    const popupElement = document.querySelector('.columnadjustmentpopup');
    return popupElement ? false : true;
  }

  public ColumnAdjustmentButtonClicked(event: MouseEvent) {
    event.stopPropagation();
    this.showColumnAdjustmentPopup = !this.showColumnAdjustmentPopup;
  }

  drop(event: CdkDragDrop<any[]>) {
    if (
      event.previousIndex < this.restrictedIndexForReorder ||
      event.currentIndex < this.restrictedIndexForReorder
    ) {
      this.toastr.showInfo(
        'Mandatory Columns cannot be re-ordered or unchecked',
        'Info',
      );
      return; // Prevent dragging first three items
    }
    moveItemInArray(
      this.filteredItems,
      event.previousIndex,
      event.currentIndex,
    );
    moveItemInArray(
      this.itemsForSelectedTemplate,
      event.previousIndex,
      event.currentIndex,
    );
    const colPositions = {
      previousIndexOfCol: event.previousIndex,
      newIndexOfCol: event.currentIndex,
      columnName: this.itemsForSelectedTemplate[event.currentIndex].field,
    };
    this.columnPosition.emit(colPositions);
  }

  //Prepare the base items for template based on templateType - input screen
  prepareBaseItemsForTemplate = (templateTypeInput: string): TemplateItem[] => {
    let templateItems: TemplateItem[];
    if (templateTypeInput == templateType.inspection) {
      templateItems = [
        
        {
          field: ASSET_TYPE,
          id: 1,
          text: ASSET_TYPE_TEXT,
          isPermanentColumn: true,
        },
        {
          field: ASSET_NAME,
          id: 2,
          text: ASSET_NAME_TEXT,
          isPermanentColumn: true,
        },
        {
          field: COMPLAINCE_STATUS,
          id: 3,
          text: COMPLAINCE_STATUS_TEXT,
          isPermanentColumn: false,
        },

        {
          field: DAYS_UNTIL_DELINQUENT,
          id: 4,
          text: DAYS_UNTIL_DELINQUENT_TEXT,
          isPermanentColumn: false,
        },

        {
          field: INSPECTION_TARGET_DATE,
          id: 5,
          text: INSPECTION_TARGET_DATE_TEXT,
          isPermanentColumn: false,
        },
      
        {
          field: LAST_INSPECTION_DATE,
          id: 6,
          text: LAST_INSPECTION_DATE_TEXT,
          isPermanentColumn: false,
        },

        {
          field: ASSET_DELINQUENCY_DATE,
          id: 7,
          text: ASSET_DELINQUENCY_DATE_TEXT,
          isPermanentColumn: false,
        },

        {
          field: TECHNICIAN,
          id: 8,
          text: TECHNICIAN_TEXT,
          isPermanentColumn: false,
        },
        
      ];
    } else if (templateTypeInput == templateType.exception) {
      templateItems = [
        {
          field: ASSET_TYPE_EXCEPTION,
          id: 1,
          text: ASSET_TYPE_TEXT,
          isPermanentColumn: true,
        },
        {
          field: ASSET_NAME_EXCEPTION,
          id: 2,
          text: ASSET_NAME_TEXT,
          isPermanentColumn: true,
        },
        {
          field: "targetRemediationDate",
          id: 3,
          text: "targetRemediationDate",
          isPermanentColumn: true,
        },
        {
          field: ASSET_DELINQUENCY_DATE,
          id: 4,
          text: ASSET_DELINQUENCY_DATE_TEXT,
          isPermanentColumn: false,
        },
        {
          field: WORKFLOW_STATUS,
          id: 5,
          text: WORKFLOW_STATUS_TEXT,
          isPermanentColumn: false,
        },
        {
          field: COMMENTS,
          id: 6,
          text: COMMENTS,
          isPermanentColumn: false,
        },
        {
          field: TECHNICIAN,
          id: 7,
          text: TECHNICIAN_TEXT,
          isPermanentColumn: false,
        },
        {
          field: SPECIALIST,
          id: 8,
          text: SPECIALIST,
          isPermanentColumn: false,
        },
        {
          field: ROOT_CAUSE,
          id: 9,
          text: ROOT_CAUSE_TEXT,
          isPermanentColumn: false,
        }
      ];
    } else if (templateTypeInput == templateType.asset_setup) {
      templateItems = [
        {
          field: 'level1Value',
          id: 1,
          text: this.level1Name,
          isPermanentColumn: true,
        },
        {
          field: 'level2Value',
          id: 2,
          text: this.level2Name,
          isPermanentColumn: true,
        },
        {
          field: 'level3Value',
          id: 3,
          text: this.level3Name,
          isPermanentColumn: true,
        },
        {
          field: 'level4Value',
          id: 4,
          text: SEGMENT_TEXT,
          isPermanentColumn: false,
        },
        {
          field: 'route',
          id: 5,
          text: 'Route',
          isPermanentColumn: false,
        },
        {
          field: 'segmentRelation',
          id: 6,
          text: 'Segment Relation',
          isPermanentColumn: false,
        },
        {
          field: ASSET_NAME_EXCEPTION,
          id: 7,
          text: ASSET_NAME_TEXT,
          isPermanentColumn: false,
        },
        {
          field: ASSET_TYPE_EXCEPTION,
          id: 8,
          text: ASSET_TYPE_TEXT,
          isPermanentColumn: false,
        },
        {
          field: 'measurementFrequency',
          id: 9,
          text: MEASUREMENT_FREQUENCY_TEXT,
          isPermanentColumn: false,
        },
        {
          field: GPS_COORDINATE,
          id: 10,
          text: GPS_COORDINATE_TEXT,
          isPermanentColumn: false,
        },
        {
          field: 'assetDelinquencyDate',
          id: 11,
          text: 'Asset Delinquency Date',
          isPermanentColumn: false,
        },
      ];
    }else if (templateTypeInput == templateType.survey_overview) {
      templateItems = [
        {
          field: SURVEYNAME,
          id: 1,
          text: SURVEYNAME_TEXT,
          isPermanentColumn: true,
        },
        {
          field: SURVEYTYPE,
          id: 2,
          text: SURVEYTYPE_TEXT,
          isPermanentColumn: true,
        },
        {
          field: SURVEYPRIORITY,
          id: 3,
          text: SURVEYPRIORITY_TEXT,
          isPermanentColumn: true,
        },
        {
          field: SURVEYSTATUS,
          id: 4,
          text: SURVEYSTATUS_TEXT,
          isPermanentColumn: false,
        },
        {
          field: DUEDATE,
          id: 5,
          text: DUEDATE_TEXT,
          isPermanentColumn: false,
        },
        {
          field: DATECOLLECTION,
          id: 6,
          text: DATECOLLECTION_TEXT,
          isPermanentColumn: false,
        },
        {
          field: TECHNICIAN.toLocaleLowerCase(),
          id: 7,
          text: TECHNICIAN,
          isPermanentColumn: false,
        },
        {
          field: SUPERVISOR.toLocaleLowerCase(),
          id: 8,
          text: SUPERVISOR,
          isPermanentColumn: false,
        },
        {
          field: DATEOFCOMPLETION,
          id: 9,
          text: DATEOFCOMPLETION_TEXT,
          isPermanentColumn: false,
        },
        {
          field: SURVEYDESCRIPTION,
          id: 10,
          text: SURVEYDESCRIPTION_TEXT,
          isPermanentColumn: false,
        },
      ];
    }

    return templateItems!;
  };

  //Prepare fixed template items
  prepareFixedItemsForTemplate = (
    templateTypeInput: string,
  ): TemplateFixedItems[] => {
    let templateFixedItems: TemplateFixedItems[];
    if (templateTypeInput == templateType.inspection) {
      {
        templateFixedItems = [
          {
            field: ASSET_TYPE,
            id: 1,
            text: ASSET_TYPE_TEXT,
            checked: true,
            isPermanentColumn: true,
            isDragDisabled: true,
          },
          {
            field: ASSET_NAME,
            id: 2,
            text: ASSET_NAME_TEXT,
            checked: true,
            isPermanentColumn: true,
            isDragDisabled: true,
          },
        ];
      }
    } else if (templateTypeInput == templateType.exception) {
      templateFixedItems = [
        {
          field: ASSET_TYPE_EXCEPTION,
          id: 1,
          text: ASSET_TYPE_TEXT,
          checked: true,
          isPermanentColumn: true,
          isDragDisabled: true,
        },
        {
          field: ASSET_NAME_EXCEPTION,
          id: 2,
          text: ASSET_NAME_TEXT,
          checked: true,
          isPermanentColumn: true,
          isDragDisabled: true,
        }
      ];
    }
    //  else if (templateTypeInput == templateType.asset_setup) {
    //   templateFixedItems = [
    //     {
    //       field: PRIMARY_COMPANY,
    //       id: 1,
    //       text: PRIMARY_COMPANY_TEXT,
    //       checked: true,
    //       isPermanentColumn: true,
    //       isDragDisabled: true,
    //     },
    //     {
    //       field: COMPANY,
    //       id: 2,
    //       text: COMPANY_TEXT,
    //       checked: true,
    //       isPermanentColumn: true,
    //       isDragDisabled: true,
    //     },
    //     {
    //       field: REGION,
    //       id: 3,
    //       text: REGION_TEXT,
    //       checked: true,
    //       isPermanentColumn: true,
    //       isDragDisabled: true,
    //     },
    //   ];
    // }
    else if (templateTypeInput == templateType.survey_overview) {
      templateFixedItems = [
        {
          field: SURVEYNAME,
          id: 1,
          text: SURVEYNAME_TEXT,
          checked: true,
          isPermanentColumn: true,
          isDragDisabled: true,
        },
        {
          field: SURVEYTYPE,
          id: 2,
          text: SURVEYTYPE_TEXT,
          checked: true,
          isPermanentColumn: true,
          isDragDisabled: true,
        },
        {
          field: SURVEYPRIORITY,
          id: 3,
          text: SURVEYPRIORITY_TEXT,
          checked: true,
          isPermanentColumn: true,
          isDragDisabled: true,
        },
      ];
    }
    return templateFixedItems!;
  };

  //Adjust the column adjustment popup
  triggerColumnAdjustmentButtonButton() {
    if (this.columnadjustmentbutton) {
      this.columnadjustmentbutton.nativeElement.click();
      this.selectedTemplateId = this.addedTemplateId;
      this.updateItemsForSelectedTemplate(this.selectedTemplateId);
    }
  }

  //Update items for selected template - check uncheck the template configuration
  updateItemsForSelectedTemplate(event: any | null): void {
    this.selectedColumns = [];
    this.selectedTemplateId = event;
    this.currentSelectedTemplateId = this.selectedTemplateId;
    const templateConfig = this.templateItems[this.selectedTemplateId] || [];
    const selectedTemplateConfig = this.templateItems[this.savedTemplateId] || [];
    
    const template = this.templates.find(template => template.id === this.selectedTemplateId);
    let isGlobal = false;
    if(template){
      if(template.isGlobal){
        isGlobal = true;
      }
    }
    
    this.itemsForSelectedTemplate = this.baseItems.map((baseItem) => {
    const config = templateConfig.find((item) => item.id === baseItem.id);
    const savedconfig = selectedTemplateConfig.find(
        (item) => item.id === baseItem.id,
      );
      if (savedconfig?.checked == true) {
        this.selectedColumns.push(baseItem.field);
      }
      return {
        ...baseItem,
        checked: this.isNewTemplate ? true : config ? config.checked : baseItem.isPermanentColumn ? true : false,
        isPermanentColumn: baseItem.isPermanentColumn ? true : isGlobal ? true : false
      };
    });
    this.updateFilteredItems();
  }

  //Update the filterd items
  updateFilteredItems() {
    const searchTextLower = this.searchText.toLowerCase();
    this.filteredItems = this.itemsForSelectedTemplate.filter((item) =>
      item.text.toLowerCase().includes(searchTextLower),
    );
    // Function to check if an item already exists in filteredItems
    const itemExists = (items: TemplateItems[], newItem: any) => {
      return items.some((item: TemplateItems) => item.field === newItem.field);
    };

    // Concatenate topItems with filteredItems, avoiding duplicates
    this.filteredItems = [
      ...this.fixedTemplateValues.filter(
        (item) => !itemExists(this.filteredItems, item),
      ),
      ...this.filteredItems,
    ];
  }

  //Reset the template configuration
  public reset() {
    if (this.isNewTemplate) {
      this.filteredItems.forEach((element) => {
        if (!element.isPermanentColumn) {
          element.checked = false;
        }
      });
    }

    if (this.currentSelectedTemplateId) {
      this.updateItemsForSelectedTemplate(this.currentSelectedTemplateId);
    }
  }
  //Save the template configuration
  public save() {
    this.savedTemplateId = this.selectedTemplateId;
    this.templateItems[this.selectedTemplateId] =
      this.itemsForSelectedTemplate.map((item) => ({
        id: item.id,
        checked: item.checked,
      }));
    this.selectedColumns = [];
    this.itemsForSelectedTemplate.forEach((x) => {
      if (x.checked === true) {
        this.selectedColumns.push(x.field);
      }
    });
    this.showColumnAdjustmentPopup = false;
    this.updateItemsForSelectedTemplate(this.selectedTemplateId);
    this.updateTemplate();
  }

  //Update the template
  async updateTemplate() {
    const templateName = this.templates.find(
      (template) => template.id == this.savedTemplateId,
    )!.templateName;
    const templateConfigurationString = JSON.stringify(
      this.templateItems[this.selectedTemplateId],
    );
    try {
      const input: UpdateTemplateInput = {
        id: this.savedTemplateId,
        userId: this.userId,
        templateName: templateName,
        templateConfiguration: templateConfigurationString,
        isActive: true,
        templateType: this.templateType!,
      };
      await this.templateService.updateTemplate(input, this.templateType!);
    } catch (error) {
      console.error(UPDATE_TEMPLATE, error);
    }
  }
}
