import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../../core/services/permission.service';
import { 
  ASSET_OVERVIEW, 
  SURVEY_OVERVIEW_SCREEN, 
  SURVEY_ROUTE_SCREEN, 
  COMPANY_OVERVIEW_SCREEN, 
  USER_OVERVIEW_SCREEN,
  INSPECTION_SCREEN,
  EXCEPTION_SCREEN,
  ASSET_HISTORY_SCREEN, 
} from '../../constants';

interface NestedSubMenuItem {
  label: string;
  route?: string;
  active: boolean;
}

interface SubMenuItem {
  label: string;
  icon?: string;
  route?: string;
  active: boolean;
  submenu: NestedSubMenuItem[] | null | undefined;
}

interface MenuItem {
  label: string;
  icon: string;
  active: boolean;
  submenu: SubMenuItem[] | null | undefined;
  route?: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  isShrunk = false;
  assetOverviewNoAccessPermission = false;
  assetHistoryNoAccessPermission = false;
  surveyOverviewNoAccessPermission = false;
  surveyRouteNoAccessPermission = false;
  companyOverviewNoAccessPermission = false;
  userOverviewNoAccessPermission = false;
  inspectionNoAccessPermission = false;
  exceptionsNoAccessPermission = false;
  
  @Output() shrink = new EventEmitter<boolean>();

  menuItems: MenuItem[] = [
    {
      label: 'SIDE_NAV_MAIN_MENU.COMPLIANCE',
      icon: 'complianceIcon',
      active: false,
      submenu: [
        { label: 'SIDE_NAV_SUB_MENU.INSPECTION', route: '/compliance/inspection', active: false, submenu: null },
        { label: 'SIDE_NAV_SUB_MENU.EXCEPTION', route: '/compliance/exception', active: false, submenu: null },
      ],
    },
    {
      label: 'SIDE_NAV_MAIN_MENU.REPORTS',
      icon: 'reportsIcon',
      active: false,
      submenu: [
        {
          label: 'SIDE_NAV_SUB_MENU.REPORT_MANAGEMENT',
          route: '/reports/report-management',
          active: false, submenu: null
        },
        {
          label: 'SIDE_NAV_SUB_MENU.REPORT_TEMPLATES_LIBRARY',
          route: '/reports/report-templates-library',
          active: false, submenu: null
        },
      ],
      route: '/reports',
    },
    {
      label: 'SIDE_NAV_MAIN_MENU.ASSETS',
      icon: 'assetsIcon',
      active: false,
      submenu: [
        {
          label: 'SIDE_NAV_SUB_MENU.ASSET_HISTORY',
          route: '/assets/asset-history',
          active: false, submenu: null
        },
        { label: 'Asset Setup',
          route: '/assets/asset-setup',
          active: false, submenu: null 
        },
      ],
    },
    {
      label: 'SIDE_NAV_MAIN_MENU.SURVEYS',
      icon: 'surveyIcon',
      active: false,
      submenu: [
        {
          label: 'SIDE_NAV_SUB_MENU.SURVEY_OVERVIEW',
          route: '/survey/survey-overview',
          active: false, submenu: null
        },
        {
          label: 'SIDE_NAV_SUB_MENU.FIELD_DATA_REVIEW',
          route: '/survey/field-data-review',
          active: false, submenu: null
        },
        {
          label: 'SIDE_NAV_SUB_MENU.ROUTE_MANAGEMENT',
          route: '/survey/route-management',
          active: false, submenu: null
        },
        {
          label: 'SIDE_NAV_SUB_MENU.DATA_PROCESSING',
          route: '/survey/data-processing',
          active: false, submenu: null
        },
      ],
    },
  ];

  setup: MenuItem = {
    label: 'SIDE_NAV_MAIN_MENU.SETUP',
    icon: 'setupIcon',
    active: false,
    submenu: [
      { 
        label: 'SIDE_NAV_SUB_MENU.COMPANY', 
        icon: 'setupIcon',
        active: false,
        submenu: [
          {
            label:'SIDE_NAV_CHILD_SUB_MENU.COMPANY_OVERVIEW',
            route: '/setup/company-overview', 
            active: false,
          },
          {
            label:'SIDE_NAV_CHILD_SUB_MENU.ASSET_OVERVIEW',
            route: '/setup/asset-overview', 
            active: false,
          },
       ]
      },
      { 
        label: 'SIDE_NAV_SUB_MENU.USERS', 
        route: 'setup/users', active: false, submenu: null
      },
      { label: 'SIDE_NAV_SUB_MENU.DEVICES', route: '/devices', active: false, submenu: null },
    ],
  };
  
  constructor(
    private router: Router,
    private translate: TranslateService,
    public permissionService: PermissionService,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateActiveStates();
      });
  }

  ngOnInit() {
    //need to uncoment this code once BA permits for now it is hard-coded to ENGLISH
    // const savedLanguage = localStorage.getItem('selectedLanguage') ?? 'en';
    // const savedLanguage = 'en';
    //this.translate.use(savedLanguage);
    this.assetOverviewNoAccessPermission = this.permissionService.hasPermission(ASSET_OVERVIEW, 'noAccess');
    this.surveyOverviewNoAccessPermission = this.permissionService.hasPermission(SURVEY_OVERVIEW_SCREEN, 'noAccess');
    this.surveyRouteNoAccessPermission = this.permissionService.hasPermission(SURVEY_ROUTE_SCREEN, 'noAccess');
    this.companyOverviewNoAccessPermission = this.permissionService.hasPermission(COMPANY_OVERVIEW_SCREEN, 'noAccess');
    this.userOverviewNoAccessPermission = this.permissionService.hasPermission(USER_OVERVIEW_SCREEN, 'noAccess');
    this.inspectionNoAccessPermission = this.permissionService.hasPermission(INSPECTION_SCREEN, 'noAccess');
    this.exceptionsNoAccessPermission = this.permissionService.hasPermission(EXCEPTION_SCREEN, 'noAccess');
    this.assetHistoryNoAccessPermission = this.permissionService.hasPermission(ASSET_HISTORY_SCREEN, 'noAccess');

    this.setup.submenu?.forEach((item) => {
      if (item.label === 'SIDE_NAV_SUB_MENU.COMPANY') {
        item.submenu = item.submenu?.filter(subItem => {
          if (subItem.label === 'SIDE_NAV_CHILD_SUB_MENU.ASSET_OVERVIEW') {
            return !this.assetOverviewNoAccessPermission;
          }
          if (subItem.label === 'SIDE_NAV_CHILD_SUB_MENU.COMPANY_OVERVIEW') {
            return !this.companyOverviewNoAccessPermission;
          }
          return true;
        });
      }
    });

    this.menuItems?.forEach((item) => {

      if(item.label === 'SIDE_NAV_MAIN_MENU.COMPLIANCE'){
        item.submenu = item.submenu?.filter(subItem => {
          if(subItem.label === 'SIDE_NAV_SUB_MENU.INSPECTION'){
            return !this.inspectionNoAccessPermission;
          }
          if(subItem.label === 'SIDE_NAV_SUB_MENU.EXCEPTION'){
            return !this.exceptionsNoAccessPermission;
          }
          return true;
        })
      }

      if(item.label === 'SIDE_NAV_MAIN_MENU.ASSETS'){
        item.submenu = item.submenu?.filter(subItem => {
          if(subItem.label === 'Asset Setup'){
            return !this.assetOverviewNoAccessPermission;
          }
          if(subItem.label === 'SIDE_NAV_SUB_MENU.ASSET_HISTORY'){
            return !this.assetHistoryNoAccessPermission;
          }
          return true;
        })
      }
      if(item.label === 'SIDE_NAV_MAIN_MENU.SURVEYS'){
        item.submenu = item.submenu?.filter(subItem => {
          if(subItem.label === 'SIDE_NAV_SUB_MENU.SURVEY_OVERVIEW'){
            return !this.surveyOverviewNoAccessPermission;
          }
          if(subItem.label === 'SIDE_NAV_SUB_MENU.ROUTE_MANAGEMENT'){
            return !this.surveyRouteNoAccessPermission;
          }
          return true;
        })
      }

    } )
    this.updateActiveStates();
  }

  toggleSidebar() {
    this.isShrunk = !this.isShrunk;
    this.shrink.emit(this.isShrunk);
  }

  toggleSubmenu(item: MenuItem) {
    // If sidebar is shrunk, expand it
    if (this.isShrunk) {
      this.isShrunk = false;
      this.shrink.emit(this.isShrunk);
    }

    // If the clicked item is already active, collapse it
    if (item.active) {
      item.active = false;
      if (item.submenu) {
        item.submenu.forEach((sub) => (sub.active = false));
      }
      return; // Exit method as we don't need to deactivate other menus
    }

    // Deactivate all main menu items and their submenus
    this.menuItems.forEach((menu) => {
      menu.active = false;
      if (menu.submenu) {
        menu.submenu.forEach((sub) => (sub.active = false));
      }
    });

    // Deactivate setup menu if it is active
    this.setup.active = false;

    // Activate the selected menu item's submenu
    item.active = true;
    if (item.submenu) {
      item.submenu.forEach((sub) => (sub.active = this.isSubItemActive(sub)));
    }
  }

  toggleSetupSubmenu() {
    if (this.isShrunk) {
      this.isShrunk = false;
      this.shrink.emit(this.isShrunk);
    }

    // Deactivate all main menu items and their submenus
    this.menuItems.forEach((menu) => {
      menu.active = false;
      if (menu.submenu) {
        menu.submenu.forEach((sub) => (sub.active = false));
      }
    });

    // Toggle the setup menu's submenu
    this.setup.active = !this.setup.active;
    if (this.setup.submenu) {
      this.setup.submenu.forEach(
        (sub) => (sub.active = this.isSubItemActive(sub)),
      );
    }
  }

  activateLink(item: MenuItem) {
    // Deactivate all main menu items and submenus
    this.menuItems.forEach((menu) => {
      menu.active = item === menu;
      if (menu.submenu) {
        menu.submenu.forEach((sub) => (sub.active = this.isSubItemActive(sub)));
      }
    });

    // Deactivate setup menu if it is not the selected item
    if (item !== this.setup) {
      this.setup.active = false;
    }
  }

  // Method to activate first-level subitems and collapse nested subitems when toggling
activateSubLink(subItem: any, setup: any) {
  // Deactivate all other subitems and their nested submenus
  setup.submenu.forEach((item: any) => {
    if (item !== subItem) {
      item.active = false;
      // Deactivate nested subitems
      if (item.submenu) {
        item.submenu.forEach((nested: any) => nested.active = false);
      }
    }
  });

  // Toggle the clicked subitem's active state
  subItem.active = !subItem.active;

  // If the subitem is deactivated, collapse its nested subitems
  if (!subItem.active && subItem.submenu) {
    subItem.submenu.forEach((nested: any) => nested.active = false);
  }
}

  // Method to activate nested subitems
activateNestedSubLink(nestedSubItem: any, subItem: any) {
  // Deactivate all other nested subitems of the current subItem
  if (subItem.submenu) {
    subItem.submenu.forEach((item: any) => {
      if (item !== nestedSubItem) {
        item.active = false;
      }
    });
  }

  // Toggle the clicked nested subitem's active state
  nestedSubItem.active = !nestedSubItem.active;
}


  isActive(item: MenuItem): boolean {
    if (item.route) {
      return this.router.url === item.route;
    }
    if (item.submenu) {
      return item.submenu.some((sub) => sub.route &&  this.router.url.includes(sub.route));
    }
    return false;
  }

  isSubmenuActive(item: MenuItem): boolean {
    if (item.submenu) {
      return item.submenu.some((sub) => sub.route && this.router.url.includes(sub.route));
    }
    return false;
  }

  isSubItemActive(subItem: SubMenuItem): boolean {
    if(subItem.route){
      return this.router.url.includes(subItem.route);
    }
    return false;
  }

  isSetupActive(): boolean {
    return (
      this.setup.submenu?.some((sub) => this.router.url === sub.route) || false
    );
  }

  private updateActiveStates() {
    this.menuItems.forEach((item) => {
      item.active = this.isActive(item);
      if (item.submenu) {
        item.submenu.forEach((sub) => (sub.active = this.isSubItemActive(sub)));
      }
    });
    this.setup.active = this.isSetupActive();
    if (this.setup.submenu) {
      this.setup.submenu.forEach(
        (sub) => (sub.active = this.isSubItemActive(sub)),
      );
    }
  }
}
