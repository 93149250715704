import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import {
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  FAILED,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  NO,
  SETUP_COMPANY_OVERVIEW,
  SUCCESS_CREATE_ASSET_SETUP,
  SETUP_HIERARCHY_LEVELS,
  FAILED_CREATE_ASSET_SETUP,
  ERROR_CREATE_ASSET_TYPE,
  ERROR,
  FREQUENCIES,
  MEASUREMENT_TYPES,
  OPERATOR_OPTIONS,
  TOASTER_SUCCESS,
  EMPTY_STRING,
  ERROR_FETCHING_ASSET_TYPE,
} from '../../shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { CreateAssetTypeService } from '../../core/services/create-asset-type.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetupHierarchyState } from '../../core/store/setup-hierarchy.state';
import { DatePipe } from '@angular/common';
import {
  AssetTypeTemplateCreateInput,
  AssetTypeTemplateUpdateInput,
  AssetTypeTemplateResponse,
} from '../../../awsAppSync/API';

@Component({
  selector: 'app-create-asset-type',
  templateUrl: './create-asset-type.component.html',
  styleUrl: './create-asset-type.component.css',
})
export class CreateAssetTypeComponent implements OnInit, OnDestroy {
  assets: any;
  currentStep = 2;
  createAssetTypeMC: AssetTypeTemplateCreateInput;
  updateAssetTypeMC: AssetTypeTemplateUpdateInput;
  isLoader = false;
  public frequencies: { text: string; value: string }[] = [];
  measurementTypes = MEASUREMENT_TYPES;
  // Add this in your component
  operatorOptions = OPERATOR_OPTIONS;
  frequenciedefaultItem = { text: 'Measurement Frequency', value: '' };
  operatorDefaultItem = { text: 'Select Operator', value: '' };
  measurementTypesdefaultItem = { text: 'Select Measurement Type', value: '' };
  isSaveClicked = false;
  isDialogOpen = false;
  primaryCompanyId!: string;
  primaryCompanyId$: Observable<string | null>;
  isEdit = false;
  customFrequencyStartDateISOFormat = EMPTY_STRING;
  ngOnInit() {
    this.isEdit = false;
    this.route.queryParams.subscribe((params) => {
      this.primaryCompanyId = params['id'];
      if (params['edit'] && params['edit'].toLocaleLowerCase() == 'true') {
        this.isEdit = true;
        this.getAssetTypeTemplate();
      }
    });
    this.assets = {
      primaryCompanyId: this.primaryCompanyId,
      assetTypeInputs: [
        {
          pk: '',
          sk: '',
          id: '',
          assetType: '',
          customFrequencyRepeatEveryNumber: '',
          customFrequencyRepeatValue: '',
          customFrequencyStartDate: '',
          customFrequencyString: 'Custom',
          customFrequencyBtnFlag: false,
          measurementFrequency: '',
          isDeleted: false,
          activeMeasurementCriteriasCount: 0,
          measurementCriteriaInputs: [
            {
              pk: '',
              sk: '',
              id: '',
              compliance: false,
              criteria: '',
              criteria1: '',
              criteria2: '',
              measurementOperator: '',
              measurementSource: '',
              measurementType: '',
              unit: 'V', // Default to Volts
              isDeleted: false,
            },
          ],
        },
      ],
    };

    this.frequencies = FREQUENCIES;
  }
  constructor(
    private popupDialogService: PopupDialogService,
    private router: Router,
    private toastr: CustomToastrService,
    private route: ActivatedRoute,
    private assetTypeService: CreateAssetTypeService,
    private store: Store,
    private datePipe: DatePipe,
  ) {
    this.primaryCompanyId$ = this.store.select(
      SetupHierarchyState.getPrimaryCompanyId,
    );
    this.updateAssetTypeMC = {
      primaryCompanyId: this.primaryCompanyId,
      assetTypeInputs: [
        {
          pk: '',
          sk: '',
          id: '',
          assetType: '',
          customFrequencyRepeatEveryNumber: '',
          customFrequencyRepeatValue: '',
          customFrequencyStartDate: '',
          measurementFrequency: '',
          isDeleted: false,
          measurementCriteriaInputs: [
            {
              pk: '',
              sk: '',
              id: '',
              compliance: false,
              criteria: '',
              measurementOperator: '',
              measurementSource: '',
              measurementType: '',
              unit: 'V', // Default to Volts
              isDeleted: false,
            },
          ],
        },
      ],
    };

    this.createAssetTypeMC = {
      primaryCompanyId: this.primaryCompanyId,
      assetTypeInputs: [
        {
          assetType: '',
          customFrequencyRepeatEveryNumber: '',
          customFrequencyRepeatValue: '',
          customFrequencyStartDate: '',
          measurementFrequency: '',
          measurementCriteriaInputs: [
            {
              compliance: false,
              criteria: '',
              measurementOperator: '',
              measurementSource: '',
              measurementType: '',
              unit: 'V', // Default to Volts
            },
          ],
        },
      ],
    };
  }
  ngOnDestroy(): void {
    this.isEdit = false;
  }

  /**
   * This function displays the existing asset type templates
   * for the current primary company
   */
  async getAssetTypeTemplate() {
    try {
      this.isLoader = true;
      const response =
        await this.assetTypeService.getAssetTypeByPrimayCompanyId(
          this.primaryCompanyId!,
        );
      if (response && response.length > 0) {
        this.assets.assetTypeInputs = [];

        for (const item of response) {
          const measurementCriterias = [];

          if (item?.measurementCriterias) {
            for (const measurement of item.measurementCriterias) {
              let criteria1 = '';
              let criteria2 = '';

              // Check if the measurement operator is "between"
              if (
                measurement?.measurementOperator === 'between' &&
                measurement?.criteria
              ) {
                const [first, second] = measurement.criteria.split(',');
                criteria1 = first?.trim() || ''; // Assign first value to criteria1
                criteria2 = second?.trim() || ''; // Assign second value to criteria2
              }

              measurementCriterias.push({
                pk: measurement?.pk || '',
                sk: measurement?.sk || '',
                id: measurement?.id || '',
                compliance: measurement?.compliance || false,
                criteria: measurement?.criteria || '',
                criteria1: criteria1,
                criteria2: criteria2,
                measurementOperator: measurement?.measurementOperator || '',
                measurementSource: measurement?.measurementSource || '',
                measurementType: measurement?.measurementType || '',
                unit: measurement?.unit || 'V', // Default to Volts
                isDeleted: measurement?.isDeleted,
              });
            }
          }

          const customFrequencyString = this.getCustomFrequencyString(
            item?.customFrequencyStartDate,
            item?.customFrequencyRepeatValue,
            item?.customFrequencyRepeatEveryNumber,
          );

          const assetType = {
            pk: item?.pk || '',
            sk: item?.sk || '',
            id: item?.id || '',
            assetType: item?.assetType,
            customFrequencyRepeatEveryNumber:
              item?.customFrequencyRepeatEveryNumber || '',
            customFrequencyRepeatValue: item?.customFrequencyRepeatValue || '',
            customFrequencyStartDate: item?.customFrequencyStartDate || '',
            customFrequencyString: customFrequencyString,
            customFrequencyBtnFlag: customFrequencyString !== 'Custom',
            measurementFrequency: item?.measurementFrequency || '',
            isAssetConfigured: item?.isAssetConfigured || false,
            measurementCriteriaInputs: measurementCriterias,
            isDeleted: item?.isDeleted,
            activeMeasurementCriteriasCount:
              this.getActiveMeasurementCriteriasCount(measurementCriterias),
          };

          this.assets.assetTypeInputs.push(assetType);
        }
      }
      this.isLoader = false;
    } catch (error) {
      this.isLoader = false;
      console.error(ERROR_FETCHING_ASSET_TYPE, error);
    }
  }

  /**
   * This function return the custom meaurement frequency string
   * @param startDate start date for measurement frequency
   * @param repeatInterval repeat interval for measurement frequency
   * @param selectedRepeatOption repeat option for measurement frequency (Days, Weeks, Months Or Years)
   * @returns
   */
  getCustomFrequencyString(
    startDate: string | null | undefined,
    repeatInterval: string | null | undefined,
    selectedRepeatOption: string | null | undefined,
  ) {
    if (!startDate && !repeatInterval && !selectedRepeatOption) {
      return 'Custom';
    }

    const date = new Date(startDate!);

    // Format the date as dd-MM-yyyy using DatePipe
    const formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    return `Occurs every ${repeatInterval} ${selectedRepeatOption!.toLowerCase()} starting from ${formattedDate}`;
  }

  addAssetType() {
    this.assets.assetTypeInputs.push({
      assetType: '',
      customFrequencyRepeatEveryNumber: '',
      customFrequencyRepeatValue: '',
      customFrequencyStartDate: '',
      customFrequencyString: 'Custom',
      customFrequencyBtnFlag: false,
      measurementFrequency: '',
      measurementCriteriaInputs: [
        {
          compliance: false,
          criteria: '',
          criteria1: '',
          criteria2: '',
          measurementOperator: '',
          measurementSource: '',
          measurementType: '',
          unit: 'V', // Default to Volts
        },
      ],
    });
  }

  addMeasurement(assetIndex: number) {
    this.assets.assetTypeInputs[assetIndex].measurementCriteriaInputs.push({
      compliance: false,
      criteria: '',
      criteria1: '',
      criteria2: '',
      measurementOperator: '',
      measurementSource: '',
      measurementType: '',
      unit: 'V', // Default to Volts
    });
    this.assets.assetTypeInputs[assetIndex].activeMeasurementCriteriasCount =
      this.getActiveMeasurementCriteriasCount(
        this.assets.assetTypeInputs[assetIndex].measurementCriteriaInputs,
      );
  }

  deleteMeasurement(assetIndex: number, measurementIndex: number) {
    this.assets.assetTypeInputs[assetIndex].measurementCriteriaInputs[
      measurementIndex
    ].isDeleted = true;
    if (
      !this.assets.assetTypeInputs[assetIndex].measurementCriteriaInputs[
        measurementIndex
      ].pk
    ) {
      this.assets.assetTypeInputs[assetIndex].measurementCriteriaInputs.splice(
        measurementIndex,
        1,
      );
    }
    this.assets.assetTypeInputs[assetIndex].activeMeasurementCriteriasCount =
      this.getActiveMeasurementCriteriasCount(
        this.assets.assetTypeInputs[assetIndex].measurementCriteriaInputs,
      );
  }

  // Update activeMeasurementCriteriasCount based on actual counts
  getActiveMeasurementCriteriasCount(measurementCriteriaInputs: any) {
    const activeItemsCount = measurementCriteriaInputs.filter(
      (item: { isDeleted: any }) => !item.isDeleted,
    ).length;
    return activeItemsCount;
  }

  cancel() {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
      true,
      NO,
      true,
    );
  }

  async saveAndExit(form: any) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
    if (form.valid) {
      const isValid = await this.saveAssetSetup();
      if (isValid) {
        this.router.navigate([SETUP_COMPANY_OVERVIEW]);
      }
    }
  }

  async saveAssetSetup() {
    const isValid = this.checkValidation();
    let assetTypeTemplateResp: AssetTypeTemplateResponse | null = null;

    if (isValid) {
      this.isLoader = true; // Enable the loader before the API call
      try {
        if (this.isEdit) {
          this.buildUpdateInput();
          console.log(
            'updateAssetTypeMC---->: ' + JSON.stringify(this.updateAssetTypeMC),
          );
          const updateAssetTypeMCObject = JSON.parse(
            JSON.stringify(this.updateAssetTypeMC),
          );
          // Make the API call and wait for the response
          assetTypeTemplateResp =
            await this.assetTypeService.updateAssetTypeAndMC(
              updateAssetTypeMCObject,
            );
        } else {
          this.buildCreateInput();
          console.log(
            'createAssetTypeMC----> ' + JSON.stringify(this.createAssetTypeMC),
          );
          const createAssetTypeMCObject = JSON.parse(
            JSON.stringify(this.createAssetTypeMC),
          );
          // Make the API call and wait for the response
          assetTypeTemplateResp =
            await this.assetTypeService.createAssetTypeAndMC(
              createAssetTypeMCObject,
            );
        }

        // Handle the response based on the result
        if (assetTypeTemplateResp?.status?.toLocaleLowerCase() === 'success') {
          this.toastr.showSuccess(SUCCESS_CREATE_ASSET_SETUP, TOASTER_SUCCESS);
        } else {
          this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
        }
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error('Error saving asset setup:', error);
        this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
      } finally {
        // Disable the loader after the API call (whether it succeeds or fails)
        this.isLoader = false;
      }
    }
    return (
      isValid &&
      assetTypeTemplateResp?.status?.toLocaleLowerCase() === 'success'
    );
  }
  async saveAndNext(form: any) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
    if (form.valid) {
      const isValid = await this.saveAssetSetup();
      if (isValid) {
        this.router.navigate([SETUP_HIERARCHY_LEVELS], {
          queryParams: { id: this.primaryCompanyId },
        });
      }
    }
  }

  // Handle measurement frequency change
  onMeasurementFrequencyChange(frequency: any) {
    const index = this.assets.assetTypeInputs.length - 1;
    // this.assets.measurementFrequency = frequency;
    if (frequency === 'custom') {
      this.isDialogOpen = true;
    } else {
      this.isDialogOpen = false;
      this.assets.customFrequencyBtnFlag[index] = false;
    }
  }
  setCustomFrequency(event: {
    formattedDate: string | null;
    repeatValue: string;
    repeatNumber: number;
    customFrequencyString: string;
  }) {
    if (event.formattedDate) {
      const dateString = event.formattedDate;
      const [day, month, year] = dateString.split('-').map(Number);
      const date = new Date(Date.UTC(year, month - 1, day));
      this.customFrequencyStartDateISOFormat = date.toISOString();
    }
    const index = this.assets.assetTypeInputs.length - 1;
    this.assets.assetTypeInputs[index].customFrequencyRepeatEveryNumber =
      event.repeatNumber;
    this.assets.assetTypeInputs[index].customFrequencyRepeatValue =
      event.repeatValue;
    this.assets.assetTypeInputs[index].customFrequencyStartDate =
      this.customFrequencyStartDateISOFormat;
    this.assets.assetTypeInputs[index].customFrequencyString =
      event.customFrequencyString;
    this.assets.assetTypeInputs[index].customFrequencyString =
      event.customFrequencyString;
    this.assets.assetTypeInputs[index].customFrequencyBtnFlag = true;
    this.updateCustomFrequencyString();
    this.isDialogOpen = false;
  }

  //Set isDeleted field to true of assetType and its all the measurement criteria
  deleteAssetType(index: number): void {
    this.assets.assetTypeInputs[index].isDeleted = true;
    this.assets.assetTypeInputs[index].measurementCriteriaInputs.forEach(
      (criteria: { isDeleted: boolean }) => (criteria.isDeleted = true),
    );

    if (
      !this.assets.assetTypeInputs[index].pk &&
      this.assets.assetTypeInputs.length > 1
    ) {
      this.assets.assetTypeInputs.splice(index, 1);
    }
  }

  onTypeChange(measurement: any) {
    // Find the corresponding measurement in the existing array
    const foundMeasurement = this.measurementTypes.find(
      (m: any) => m.text === measurement.measurementType,
    );
    // If found, assign the unit
    if (foundMeasurement) {
      measurement.unit = foundMeasurement.unit; // Assign the unit from the existing measurement
    } else {
      measurement.unit = 'V'; // Default to Volts if not found
    }
  }

  checkValidation() {
    let isValid = true;
    this.assets.assetTypeInputs.forEach((asset: any) => {
      // Check if the asset type is empty
      if (!asset.assetType || asset.assetType.trim() === '') {
        this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
        isValid = false;
      } else if (
        !asset.measurementFrequency ||
        asset.measurementFrequency.trim() === ''
      ) {
        this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
        isValid = false;
      }

      if (isValid == true) {
        // Loop through measurement criteria inputs for each asset
        asset.measurementCriteriaInputs.forEach((measurement: any) => {
          // Validate measurement source
          if (
            !measurement.measurementSource ||
            measurement.measurementSource.trim() === ''
          ) {
            this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
            isValid = false;
          }

          // Validate measurement type
          else if (
            !measurement.measurementType ||
            measurement.measurementType.trim() === ''
          ) {
            this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
            isValid = false;
          }

          // Validate operator
          else if (
            !measurement.measurementOperator ||
            measurement.measurementOperator.trim() === ''
          ) {
            this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
            isValid = false;
          }

          // Validate criteria based on operator
          else if (measurement.measurementOperator === 'between') {
            if (!measurement.criteria1 || !measurement.criteria2) {
              this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
              isValid = false;
            }
          } else if (
            !measurement.criteria ||
            measurement.criteria.trim() === ''
          ) {
            this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
            isValid = false;
          }
        });
      }
    });
    return isValid;
  }

  allowOnlyNumbersAndSymbols(event: KeyboardEvent) {
    const allowedCharacters = /[0-9+\-.]/; // Allow digits, +, -, and .
    const inputChar = String.fromCharCode(event.keyCode || event.which);

    if (!allowedCharacters.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  }

  onCustomFrequencyClick() {
    this.isDialogOpen = true;
  }

  // Update the custom frequency string dynamically
  updateCustomFrequencyString() {
    this.frequencies = this.frequencies.map((item) => {
      if (item.value === 'custom') {
        return { ...item, text: this.assets.customFrequencyString };
      }
      return item;
    });
  }

  // build the AssetTypeTemplateUpdateInput
  buildUpdateInput() {
    this.updateAssetTypeMC.primaryCompanyId = this.primaryCompanyId;
    this.updateAssetTypeMC.assetTypeInputs = [];

    for (const item of this.assets.assetTypeInputs) {
      const measurementCriterias = [];
      if (item?.measurementCriteriaInputs) {
        for (const measurement of item.measurementCriteriaInputs) {
          measurementCriterias.push({
            pk: measurement?.pk || '',
            sk: measurement?.sk || '',
            id: measurement?.id || '',
            compliance: measurement?.compliance || false,
            criteria:
              measurement.measurementOperator === 'between'
                ? `${measurement.criteria1},${measurement.criteria2}`
                : measurement.criteria,
            measurementOperator: measurement?.measurementOperator || '',
            measurementSource: measurement?.measurementSource || '',
            measurementType: measurement?.measurementType || '',
            unit: measurement?.unit || 'V', // Default to Volts
            isDeleted: measurement?.isDeleted,
          });
        }
      }

      const assetType = {
        pk: item?.pk || '',
        sk: item?.sk || '',
        id: item?.id || '',
        assetType: item?.assetType,
        customFrequencyRepeatEveryNumber:
          item?.customFrequencyRepeatEveryNumber || '',
        customFrequencyRepeatValue: item?.customFrequencyRepeatValue || '',
        customFrequencyStartDate: item?.customFrequencyStartDate || '',
        measurementFrequency: item?.measurementFrequency || '',
        isAssetConfigured: item?.isAssetConfigured || false,
        measurementCriteriaInputs: measurementCriterias,
        isDeleted: item?.isDeleted,
      };

      this.updateAssetTypeMC.assetTypeInputs.push(assetType);
    }
  }

  // build the AssetTypeTemplateCreateInput
  buildCreateInput() {
    this.createAssetTypeMC.primaryCompanyId = this.primaryCompanyId;
    this.createAssetTypeMC.assetTypeInputs = [];

    for (const item of this.assets.assetTypeInputs) {
      const measurementCriterias = [];

      if (item?.measurementCriteriaInputs) {
        for (const measurement of item.measurementCriteriaInputs) {
          measurementCriterias.push({
            compliance: measurement?.compliance || false,
            criteria:
              measurement.measurementOperator === 'between'
                ? `${measurement.criteria1},${measurement.criteria2}`
                : measurement.criteria,
            measurementOperator: measurement?.measurementOperator || '',
            measurementSource: measurement?.measurementSource || '',
            measurementType: measurement?.measurementType || '',
            unit: measurement?.unit || 'V', // Default to Volts
          });
        }
      }

      const assetType = {
        assetType: item?.assetType,
        customFrequencyRepeatEveryNumber:
          item?.customFrequencyRepeatEveryNumber || '',
        customFrequencyRepeatValue: item?.customFrequencyRepeatValue || '',
        customFrequencyStartDate: item?.customFrequencyStartDate || '',
        measurementFrequency: item?.measurementFrequency || '',
        measurementCriteriaInputs: measurementCriterias,
      };

      this.createAssetTypeMC.assetTypeInputs.push(assetType);
    }
  }
}
