import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from './core/store/authenticate-user.state';
import { Store } from '@ngxs/store';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { PageTitleService } from './core/services/page-title.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  title = 'SYRC';
  isShrunk = false;
  defaultLoad = true;
  isAuthenticated$: Observable<boolean | null | undefined> | undefined;
  pageTitle = '';

  toggleShrink(isShrunk: boolean) {
    this.isShrunk = isShrunk;
  }

  constructor(private store: Store, private router: Router, private activatedRoute: ActivatedRoute,private pageTitleService: PageTitleService ) {}

  ngOnInit(): void {
    //Fetch the isAutheticated from store
    const isAuthenticated = this.store.selectSnapshot(AuthenticateUserState.getIsAuthenticated);
    this.isAuthenticated$ = this.store.select(AuthenticateUserState.getIsAuthenticated);
    if (isAuthenticated){
      this.defaultLoad = false;
    }

    this.pageTitleService.pageTitle$.subscribe(title => {
      this.pageTitle = title;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(data => {
      const title = data['title'];
      this.pageTitleService.setPageTitle(title);
    });
  }
}
