import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SetAuthenticateUser } from '../../core/store/authenticate-user.state';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { forkJoin } from 'rxjs';
import { UserService } from '../../core/user.service';
import { User } from '../../../awsAppSync/API';

@Component({
  selector: 'app-auth-return',
  templateUrl: './auth-return.component.html',
  styleUrls: ['./auth-return.component.css']
})
export class AuthReturnComponent implements OnInit {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly router = inject(Router);

  configuration$ = this.oidcSecurityService.getConfiguration();
  userData$ = this.oidcSecurityService.userData$;
  checkSessionChanged$ = this.oidcSecurityService.checkSessionChanged$;

  constructor(private store: Store,private _hierarchicalFilter : HeirarchyFilterServiceService,private userService: UserService){}

  ngOnInit(): void {
    
    // First, ensure the authentication state is checked and updated.
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, idToken, accessToken, userData }) => {
      if (isAuthenticated) {
        const corViewUserId = userData.sub;
        const loggedInName = userData.given_name;
        this.store.dispatch(new SetAuthenticateUser(
          true, 
          idToken, accessToken, 
          corViewUserId, loggedInName,"","","","",""
        ));

        this.getLoginUserDetails(corViewUserId)
        .then(user => {
          if (user) {
            this.store.dispatch(new SetAuthenticateUser(
              true, 
              idToken, accessToken, 
              corViewUserId, loggedInName,
              user.coreViewPrimaryCompany.id ?? "",
              user.coreViewPrimaryCompany.displayName?? "",
              user.syrcPrimaryCompany.id ?? "",
              user.syrcPrimaryCompany.displayName?? "",
              user.id
            ));

          } else {
            console.log('User not found');
          }
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
        });
        
        
        this.router.navigate(['/dashboard']);
      }
      this.intializeLevelNames();
    });
    

    //subscribing to isAuthenticated$ to listen for authentication status changes when token is refresh
    this.oidcSecurityService.isAuthenticated$.subscribe(isAuthenticatedResult => {
      console.log("isAuthenticatedResult--->",isAuthenticatedResult);
      if (isAuthenticatedResult.isAuthenticated) {
        try {
          this.handleTokenRenewal();
        }catch(err){
          console.warn(err);
        }
      }else if(isAuthenticatedResult.allConfigsAuthenticated.length>0){
        console.log("Refresh token request failed, redirecting to authorize...");
        //this.oidcSecurityService.authorize();
      }
    });

  }
  async intializeLevelNames() {
    await this._hierarchicalFilter.initialize();
  }

  async getLoginUserDetails(coreViewUserId: string): Promise<User | null> {
    try {
      return await this.userService.getUserByCoreViewUserId(coreViewUserId);
      
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null; // Return null in case of an error
    }
  }
  
  handleTokenRenewal() {
    // Subscribe to the idToken, accessToken and userData observables to get the actual values
    forkJoin({
      idToken: this.oidcSecurityService.getIdToken(),
      accessToken: this.oidcSecurityService.getAccessToken(),
      userData: this.oidcSecurityService.getUserData()
    }).subscribe(({ idToken, accessToken, userData }) => {
      if (idToken && accessToken && userData) {
        const corViewUserId = userData?.sub;
        const loggedInName = userData?.given_name;
        this.store.dispatch(new SetAuthenticateUser(
          true, 
          idToken, accessToken, 
          corViewUserId, loggedInName,"","","","",""
        ));


        this.getLoginUserDetails(corViewUserId)
        .then(user => {
          if (user) {
            this.store.dispatch(new SetAuthenticateUser(
              true, 
              idToken, accessToken, 
              corViewUserId, loggedInName,
              user.coreViewPrimaryCompany.id ?? "",
              user.coreViewPrimaryCompany.displayName?? "",
              user.syrcPrimaryCompany.id ?? "",
              user.syrcPrimaryCompany.displayName?? "",
              user.id
            ));

          } else {
            console.log('User not found');
          }

          this.router.navigate(['/dashboard']);
        })
        .catch(error => {
          console.error('Error fetching user details:', error);
        });
      }
    });
  }

}

