/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAllTemplateByUser = /* GraphQL */ `query GetAllTemplateByUser($filter: TemplateFilterInput, $userId: String!) {
  getAllTemplateByUser(filter: $filter, userId: $userId) {
    items {
      id
      entityType
      userId
      corViewUserID
      templateName
      templateConfiguration
      createdDate
      updatedDate
      isDeleted
      isActive
      createdBy
      updatedBy
      templateType
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllTemplateByUserQueryVariables,
  APITypes.GetAllTemplateByUserQuery
>;
export const getTemplateByName = /* GraphQL */ `query GetTemplateByName($userId: String!, $templateName: String!) {
  getTemplateByName(userId: $userId, templateName: $templateName) {
    id
    entityType
    userId
    corViewUserID
    templateName
    templateConfiguration
    createdDate
    updatedDate
    isDeleted
    isActive
    createdBy
    updatedBy
    templateType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTemplateByNameQueryVariables,
  APITypes.GetTemplateByNameQuery
>;
export const getAllAssets = /* GraphQL */ `query GetAllAssets($filter: FilterInput) {
  getAllAssets(filter: $filter) {
    items {
      id
      pk
      sk
      level1Value
      level1ValueId
      level2Value
      level2ValueId
      level3Value
      level3ValueId
      level4Value
      level4ValueId
      route
      segmentRelation
      assetName
      assetType
      measurementFrequency
      latitude
      longitude
      status
      assetDelinquencyDate
      measurementCriteria {
        id
        measurementSource
        measurementType
        measurementOperator
        criteria
        level4Value
        level4ValueId
        level4Relation
        compliance
        unit
        __typename
      }
      isMeasurementAvailable
      surveyRouteIds
      gpsCoordinate
      __typename
    }
    lastEvaluatedKey
    totalCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAssetsQueryVariables,
  APITypes.GetAllAssetsQuery
>;
export const getAllSurveyWithAsset = /* GraphQL */ `query GetAllSurveyWithAsset(
  $filter: FilterInput
  $limit: Int
  $nextToken: String
) {
  getAllSurveyWithAsset(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pk
      sk
      surveyName
      surveyType
      surveyPriority
      dataCollectionType
      dueDate
      assignedTo
      troubleShooting
      surveyDescription
      surveyAssociatedAssets {
        assetId
        level1ValueId
        level2ValueId
        level3ValueId
        assetType
        assetName
        lastInspectionDate
        assetDelinquencyDate
        latitude
        longitude
        order
        status
        __typename
      }
      surveyStatus
      dateOfCompletion
      supervisor
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSurveyWithAssetQueryVariables,
  APITypes.GetAllSurveyWithAssetQuery
>;
export const getAllPrimaryCompanyByUser = /* GraphQL */ `query GetAllPrimaryCompanyByUser($userId: String!) {
  getAllPrimaryCompanyByUser(userId: $userId) {
    items {
      id
      entityType
      createdBy
      createdDate
      updatedBy
      updatedDate
      isDeleted
      primaryCompany
      imageURL
      phoneNumber
      officeNumber
      country
      state
      zipCode
      city
      address
      timeZoneId
      timeZone
      setupStatus
      totalSetup
      corViewCompanyId
      __typename
    }
    totalCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllPrimaryCompanyByUserQueryVariables,
  APITypes.GetAllPrimaryCompanyByUserQuery
>;
export const getAllCompanyByUser = /* GraphQL */ `query GetAllCompanyByUser($userId: String!) {
  getAllCompanyByUser(userId: $userId) {
    items {
      id
      entityType
      company
      primaryCompanyId
      primaryCompany
      createdDate
      createdBy
      updatedDate
      updatedBy
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllCompanyByUserQueryVariables,
  APITypes.GetAllCompanyByUserQuery
>;
export const getAllRegionByUser = /* GraphQL */ `query GetAllRegionByUser($userId: String!) {
  getAllRegionByUser(userId: $userId) {
    items {
      id
      entityType
      region
      primaryCompanyId
      primaryCompany
      companyId
      company
      createdDate
      createdBy
      updatedDate
      updatedBy
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllRegionByUserQueryVariables,
  APITypes.GetAllRegionByUserQuery
>;
export const getAllSegmentByUser = /* GraphQL */ `query GetAllSegmentByUser($userId: String!) {
  getAllSegmentByUser(userId: $userId) {
    items {
      id
      entityType
      segmentName
      primaryCompanyId
      primaryCompany
      companyId
      company
      regionId
      region
      createdDate
      createdBy
      updatedDate
      updatedBy
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSegmentByUserQueryVariables,
  APITypes.GetAllSegmentByUserQuery
>;
export const getAllAssetTypeByUser = /* GraphQL */ `query GetAllAssetTypeByUser($userId: String!) {
  getAllAssetTypeByUser(userId: $userId) {
    items {
      id
      entityType
      assetType
      measurementFrequency
      customFrequencyStartDate
      customFrequencyRepeatEveryNumber
      customFrequencyRepeatValue
      createdDate
      createdBy
      updatedDate
      updatedBy
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAssetTypeByUserQueryVariables,
  APITypes.GetAllAssetTypeByUserQuery
>;
export const getAllInspections = /* GraphQL */ `query GetAllInspections($filter: FilterInput) {
  getAllInspections(filter: $filter) {
    items {
      id
      pk
      assetName
      assetType
      station
      complianceStatus
      lastInspectionDate
      inspectionTargetDate
      assetDelinquencyDate
      technician
      measurementPoint
      daysUntilDelinquent
      measurements {
        id
        pk
        assetId
        surveyId
        status
        compliance
        technician
        inspectionDate
        surveyRouteName
        measurementType
        criteria
        measurementValue
        isCompliant
        source
        level4
        level4Relation
        level4Type
        level4Value
        level4ValueId
        operatorInfo
        specialist
        unit
        surveyDate
        __typename
      }
      __typename
    }
    lastEvaluatedKey
    totalCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllInspectionsQueryVariables,
  APITypes.GetAllInspectionsQuery
>;
export const getPreSignedURLForUploadedFile = /* GraphQL */ `query GetPreSignedURLForUploadedFile($fileURL: String!) {
  getPreSignedURLForUploadedFile(fileURL: $fileURL) {
    presignedURL
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPreSignedURLForUploadedFileQueryVariables,
  APITypes.GetPreSignedURLForUploadedFileQuery
>;
export const getAllLevelNameByPrimaryCompany = /* GraphQL */ `query GetAllLevelNameByPrimaryCompany($primaryCompanyId: ID!) {
  getAllLevelNameByPrimaryCompany(primaryCompanyId: $primaryCompanyId) {
    items {
      primaryCompanyId
      level1Name
      level2Name
      level3Name
      level4Name
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllLevelNameByPrimaryCompanyQueryVariables,
  APITypes.GetAllLevelNameByPrimaryCompanyQuery
>;
export const getAllLevel1ValueByPrimaryCompany = /* GraphQL */ `query GetAllLevel1ValueByPrimaryCompany($primaryCompanyId: ID!) {
  getAllLevel1ValueByPrimaryCompany(primaryCompanyId: $primaryCompanyId) {
    items {
      id
      level1Value
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllLevel1ValueByPrimaryCompanyQueryVariables,
  APITypes.GetAllLevel1ValueByPrimaryCompanyQuery
>;
export const getAllLevel2ValueByPrimaryCompany = /* GraphQL */ `query GetAllLevel2ValueByPrimaryCompany($primaryCompanyId: ID!) {
  getAllLevel2ValueByPrimaryCompany(primaryCompanyId: $primaryCompanyId) {
    items {
      id
      level1ValueId
      level1Value
      level2Value
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllLevel2ValueByPrimaryCompanyQueryVariables,
  APITypes.GetAllLevel2ValueByPrimaryCompanyQuery
>;
export const getAllLevel3ValueByPrimaryCompany = /* GraphQL */ `query GetAllLevel3ValueByPrimaryCompany($primaryCompanyId: ID!) {
  getAllLevel3ValueByPrimaryCompany(primaryCompanyId: $primaryCompanyId) {
    items {
      id
      level1ValueId
      level1Value
      level2ValueId
      level2Value
      level3Value
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllLevel3ValueByPrimaryCompanyQueryVariables,
  APITypes.GetAllLevel3ValueByPrimaryCompanyQuery
>;
export const getAllLevel4ValueByPrimaryCompany = /* GraphQL */ `query GetAllLevel4ValueByPrimaryCompany($primaryCompanyId: ID!) {
  getAllLevel4ValueByPrimaryCompany(primaryCompanyId: $primaryCompanyId) {
    items {
      id
      level1ValueId
      level1Value
      level2ValueId
      level2Value
      level3ValueId
      level3Value
      level4Value
      segmentType
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllLevel4ValueByPrimaryCompanyQueryVariables,
  APITypes.GetAllLevel4ValueByPrimaryCompanyQuery
>;
export const getAllSurveyRouteBySubFilterInput = /* GraphQL */ `query GetAllSurveyRouteBySubFilterInput($filter: SubFilterInput) {
  getAllSurveyRouteBySubFilterInput(filter: $filter) {
    items {
      id
      entityType
      routeName
      routeDescription
      RouteAssociateAssetList {
        assetId
        level1ValueId
        level2ValueId
        level3ValueIdList
        __typename
      }
      createdDate
      createdBy
      updatedDate
      updatedBy
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSurveyRouteBySubFilterInputQueryVariables,
  APITypes.GetAllSurveyRouteBySubFilterInputQuery
>;
export const getAllAssetTypeByPrimaryCompany = /* GraphQL */ `query GetAllAssetTypeByPrimaryCompany($primaryCompanyId: ID!) {
  getAllAssetTypeByPrimaryCompany(primaryCompanyId: $primaryCompanyId) {
    items {
      id
      entityType
      assetType
      measurementFrequency
      customFrequencyStartDate
      customFrequencyRepeatEveryNumber
      customFrequencyRepeatValue
      createdDate
      createdBy
      updatedDate
      updatedBy
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAssetTypeByPrimaryCompanyQueryVariables,
  APITypes.GetAllAssetTypeByPrimaryCompanyQuery
>;
export const getAllSegmentTypeByPrimaryCompany = /* GraphQL */ `query GetAllSegmentTypeByPrimaryCompany($primaryCompanyId: ID!) {
  getAllSegmentTypeByPrimaryCompany(primaryCompanyId: $primaryCompanyId) {
    items {
      id
      entityType
      level4Type
      createdDate
      createdBy
      updatedDate
      updatedBy
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSegmentTypeByPrimaryCompanyQueryVariables,
  APITypes.GetAllSegmentTypeByPrimaryCompanyQuery
>;
export const getAllPrimaryCompany = /* GraphQL */ `query GetAllPrimaryCompany($input: KendoGridInput) {
  getAllPrimaryCompany(input: $input) {
    items {
      id
      entityType
      createdBy
      createdDate
      updatedBy
      updatedDate
      isDeleted
      primaryCompany
      imageURL
      phoneNumber
      officeNumber
      country
      state
      zipCode
      city
      address
      timeZoneId
      timeZone
      setupStatus
      totalSetup
      corViewCompanyId
      __typename
    }
    totalCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllPrimaryCompanyQueryVariables,
  APITypes.GetAllPrimaryCompanyQuery
>;
export const getAllCompanyOption = /* GraphQL */ `query GetAllCompanyOption {
  getAllCompanyOption {
    items {
      name
      corViewCompanyId
      timeZoneId
      timeZone
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllCompanyOptionQueryVariables,
  APITypes.GetAllCompanyOptionQuery
>;
export const getAllTimeZoneOption = /* GraphQL */ `query GetAllTimeZoneOption {
  getAllTimeZoneOption {
    items {
      id
      name
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllTimeZoneOptionQueryVariables,
  APITypes.GetAllTimeZoneOptionQuery
>;
export const getAssetHistory = /* GraphQL */ `query GetAssetHistory($filter: AssetHistoryInput) {
  getAssetHistory(filter: $filter) {
    items {
      surveyId
      surveyDate
      comments {
        pk
        sk
        comment
        commentDate
        entityType
        id
        surveyDate
        surveyId
        user
        __typename
      }
      measurementModels {
        id
        pk
        assetId
        surveyId
        status
        compliance
        technician
        inspectionDate
        surveyRouteName
        measurementType
        criteria
        measurementValue
        isCompliant
        source
        level4
        level4Relation
        level4Type
        level4Value
        level4ValueId
        operatorInfo
        specialist
        unit
        surveyDate
        __typename
      }
      __typename
    }
    lastEvaluatedKey
    totalCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetHistoryQueryVariables,
  APITypes.GetAssetHistoryQuery
>;
export const getAllUser = /* GraphQL */ `query GetAllUser($input: KendoGridInput) {
  getAllUser(input: $input) {
    items {
      firstName
      lastName
      coreViewPrimaryCompany {
        id
        displayName
        __typename
      }
      syrcPrimaryCompany {
        id
        displayName
        __typename
      }
      active
      username
      email
      postalZip
      addr1
      addrCity
      cellPhone
      officePhone
      stateProvince
      country
      timeZoneId
      timeZone
      coreViewUserId
      id
      entityType
      createdBy
      createdDate
      updatedBy
      updatedDate
      isDeleted
      userRoles {
        roleId
        name
        isMTXRole
        level3Value {
          level3ValueName
          level1ValueId
          level2ValueId
          level3ValueId
          __typename
        }
        __typename
      }
      accountStatus
      __typename
    }
    totalCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllUserQueryVariables,
  APITypes.GetAllUserQuery
>;
export const getPrimaryCompanyById = /* GraphQL */ `query GetPrimaryCompanyById($id: ID) {
  getPrimaryCompanyById(id: $id) {
    id
    entityType
    createdBy
    createdDate
    updatedBy
    updatedDate
    isDeleted
    primaryCompany
    imageURL
    phoneNumber
    officeNumber
    country
    state
    zipCode
    city
    address
    timeZoneId
    timeZone
    setupStatus
    totalSetup
    corViewCompanyId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPrimaryCompanyByIdQueryVariables,
  APITypes.GetPrimaryCompanyByIdQuery
>;
export const getAllUserOption = /* GraphQL */ `query GetAllUserOption {
  getAllUserOption {
    items {
      firstName
      lastName
      primaryCompany {
        id
        displayName
        __typename
      }
      active
      username
      email
      postalZip
      addr1
      addrCity
      cellPhone
      officePhone
      stateProvince
      country
      timeZoneId
      timeZone
      corViewUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllUserOptionQueryVariables,
  APITypes.GetAllUserOptionQuery
>;
export const getUserByCoreViewUserId = /* GraphQL */ `query GetUserByCoreViewUserId($id: String!) {
  getUserByCoreViewUserId(id: $id) {
    firstName
    lastName
    coreViewPrimaryCompany {
      id
      displayName
      __typename
    }
    syrcPrimaryCompany {
      id
      displayName
      __typename
    }
    active
    username
    email
    postalZip
    addr1
    addrCity
    cellPhone
    officePhone
    stateProvince
    country
    timeZoneId
    timeZone
    coreViewUserId
    id
    entityType
    createdBy
    createdDate
    updatedBy
    updatedDate
    isDeleted
    userRoles {
      roleId
      name
      isMTXRole
      level3Value {
        level3ValueName
        level1ValueId
        level2ValueId
        level3ValueId
        __typename
      }
      __typename
    }
    accountStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByCoreViewUserIdQueryVariables,
  APITypes.GetUserByCoreViewUserIdQuery
>;
export const getAssetTypeTemplateById = /* GraphQL */ `query GetAssetTypeTemplateById($input: AssetTypeTemplateInput) {
  getAssetTypeTemplateById(input: $input) {
    pk
    sk
    id
    assetType
    entityType
    measurementFrequency
    customFrequencyRepeatEveryNumber
    customFrequencyRepeatValue
    customFrequencyStartDate
    isAssetConfigured
    isDeleted
    measurementCriterias {
      pk
      sk
      id
      compliance
      criteria
      measurementSource
      measurementType
      measurementOperator
      unit
      segment
      segmentRelation
      isDeleted
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetTypeTemplateByIdQueryVariables,
  APITypes.GetAssetTypeTemplateByIdQuery
>;
export const getAssetTypeTemplateByPrimaryCompanyId = /* GraphQL */ `query GetAssetTypeTemplateByPrimaryCompanyId($id: ID!) {
  getAssetTypeTemplateByPrimaryCompanyId(id: $id) {
    items {
      pk
      sk
      id
      assetType
      entityType
      measurementFrequency
      customFrequencyRepeatEveryNumber
      customFrequencyRepeatValue
      customFrequencyStartDate
      isAssetConfigured
      isDeleted
      measurementCriterias {
        pk
        sk
        id
        compliance
        criteria
        measurementSource
        measurementType
        measurementOperator
        unit
        segment
        segmentRelation
        isDeleted
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables,
  APITypes.GetAssetTypeTemplateByPrimaryCompanyIdQuery
>;
export const getAssetHistoryGraphData = /* GraphQL */ `query GetAssetHistoryGraphData($input: AssetHistoryGraphInput) {
  getAssetHistoryGraphData(input: $input) {
    items {
      measurementType
      dateTime
      value
      unit
      level4ValueId
      level4Value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetHistoryGraphDataQueryVariables,
  APITypes.GetAssetHistoryGraphDataQuery
>;
export const getAssetDetailsById = /* GraphQL */ `query GetAssetDetailsById($input: AssetMetadataInput!) {
  getAssetDetailsById(input: $input) {
    pk
    sk
    assetDelinquencyDate
    assetName
    assetType
    entityType
    id
    imageUrls
    associatedLevel4
    imagePresignedUrls
    latitude
    level1ValueId
    level1Value
    level2ValueId
    level2Value
    level3ValueId
    level3Value
    level4Value
    level4ValueId
    level4Type
    longitude
    measurementFrequency
    measurementStartDate
    nextDelinquencyDate
    repeatesEvery
    repeatesEveryInterval
    status
    atmosphericCorrosionGrade
    techResponsible
    surveyRoute
    createdDate
    measurementCriteria {
      id
      measurementSource
      measurementType
      measurementOperator
      criteria
      level4Value
      level4ValueId
      level4Relation
      compliance
      unit
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetDetailsByIdQueryVariables,
  APITypes.GetAssetDetailsByIdQuery
>;
export const getLevelValueByPrimaryCompanyId = /* GraphQL */ `query GetLevelValueByPrimaryCompanyId($primaryCompanyId: ID!) {
  getLevelValueByPrimaryCompanyId(primaryCompanyId: $primaryCompanyId) {
    level1Values {
      pk
      sk
      id
      value
      level2Values {
        pk
        sk
        id
        value
        level3Values {
          pk
          sk
          id
          value
          isAssetConfigured
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLevelValueByPrimaryCompanyIdQueryVariables,
  APITypes.GetLevelValueByPrimaryCompanyIdQuery
>;
export const getAllExceptions = /* GraphQL */ `query GetAllExceptions($filter: FilterInput) {
  getAllExceptions(filter: $filter) {
    items {
      id
      pk
      assetName
      assetType
      assetTypeId
      complianceStatus
      comments
      workFlowStatus
      targetRemediationDate
      specialist
      technician
      assetDelinquencyDate
      measurements {
        id
        pk
        assetId
        surveyId
        status
        compliance
        technician
        inspectionDate
        surveyRouteName
        measurementType
        criteria
        measurementValue
        isCompliant
        source
        level4
        level4Relation
        level4Type
        level4Value
        level4ValueId
        operatorInfo
        specialist
        unit
        surveyDate
        __typename
      }
      __typename
    }
    lastEvaluatedKey
    totalCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllExceptionsQueryVariables,
  APITypes.GetAllExceptionsQuery
>;
export const getAssetById = /* GraphQL */ `query GetAssetById($input: AssetMetadataInput!) {
  getAssetById(input: $input) {
    pk
    sk
    id
    primaryCompanyId
    level1ValueId
    level2ValueId
    level3ValueId
    level4Value
    level4ValueId
    level4Type
    assetTypeId
    assetName
    lastInspectionDate
    mappingAssetId
    milestone
    creationDate
    latitude
    longitude
    comments
    locationDescription
    effectiveDate
    assetProperties {
      Key
      Value
      __typename
    }
    imageUrls
    assetHierarchies {
      level3ValueId
      level4ValueId
      level4Value
      level4Type
      level4Relation
      __typename
    }
    assetMeasurementCriterias {
      pk
      sk
      id
      compliance
      criteria
      measurementSource
      measurementType
      measurementOperator
      unit
      level4Relation
      level4Type
      level4Value
      level4ValueId
      __typename
    }
    imagePresignedUrls
    associatedLevel4
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetByIdQueryVariables,
  APITypes.GetAssetByIdQuery
>;
export const getAllAssetOption = /* GraphQL */ `query GetAllAssetOption($input: AssetFilterInput!) {
  getAllAssetOption(input: $input) {
    items {
      pk
      sk
      id
      level1ValueId
      level2ValueId
      level3ValueId
      level4ValueId
      assetName
      status
      error {
        message
        details
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAssetOptionQueryVariables,
  APITypes.GetAllAssetOptionQuery
>;
export const getSurveyById = /* GraphQL */ `query GetSurveyById($input: SurveyInput!) {
  getSurveyById(input: $input) {
    id
    pk
    sk
    surveyName
    surveyType
    surveyPriority
    dataCollectionType
    dueDate
    assignedTo
    troubleShooting
    surveyDescription
    surveyAssociatedAssets {
      assetId
      level1ValueId
      level2ValueId
      level3ValueId
      assetType
      assetName
      lastInspectionDate
      assetDelinquencyDate
      latitude
      longitude
      order
      status
      __typename
    }
    surveyStatus
    dateOfCompletion
    supervisor
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveyByIdQueryVariables,
  APITypes.GetSurveyByIdQuery
>;
export const getAllSurvey = /* GraphQL */ `query GetAllSurvey($input: SurveyFilterInput) {
  getAllSurvey(input: $input) {
    items {
      id
      pk
      sk
      surveyName
      surveyType
      surveyPriority
      dataCollectionType
      dueDate
      assignedTo
      troubleShooting
      surveyDescription
      surveyAssociatedAssets {
        assetId
        level1ValueId
        level2ValueId
        level3ValueId
        assetType
        assetName
        lastInspectionDate
        assetDelinquencyDate
        latitude
        longitude
        order
        status
        __typename
      }
      surveyStatus
      dateOfCompletion
      supervisor
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSurveyQueryVariables,
  APITypes.GetAllSurveyQuery
>;
export const getAllSYRCUserOption = /* GraphQL */ `query GetAllSYRCUserOption {
  getAllSYRCUserOption {
    items {
      id
      firstName
      lastName
      username
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSYRCUserOptionQueryVariables,
  APITypes.GetAllSYRCUserOptionQuery
>;
export const getAllSurveyRoute = /* GraphQL */ `query GetAllSurveyRoute($input: SurveyRouteGridFilterInput) {
  getAllSurveyRoute(input: $input) {
    items {
      id
      pk
      sk
      createdDate
      routeName
      routeDescription
      surveyRouteAssociatedAsset {
        assetId
        level1ValueId
        level2ValueId
        level3ValueId
        assetType
        assetName
        lastInspectionDate
        assetDelinquencyDate
        latitude
        longitude
        order
        status
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSurveyRouteQueryVariables,
  APITypes.GetAllSurveyRouteQuery
>;
export const getSurveyRouteById = /* GraphQL */ `query GetSurveyRouteById($input: SurveyRouteInput!) {
  getSurveyRouteById(input: $input) {
    id
    pk
    sk
    createdDate
    routeName
    routeDescription
    surveyRouteAssociatedAsset {
      assetId
      level1ValueId
      level2ValueId
      level3ValueId
      assetType
      assetName
      lastInspectionDate
      assetDelinquencyDate
      latitude
      longitude
      order
      status
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSurveyRouteByIdQueryVariables,
  APITypes.GetSurveyRouteByIdQuery
>;
export const getAllRoles = /* GraphQL */ `query GetAllRoles($isMTXRole: Boolean!) {
  getAllRoles(isMTXRole: $isMTXRole) {
    items {
      id
      name
      isMTXRole
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllRolesQueryVariables,
  APITypes.GetAllRolesQuery
>;
export const getUserProfile = /* GraphQL */ `query GetUserProfile($userId: String!) {
  getUserProfile(userId: $userId) {
    primaryCompany {
      id
      name
      __typename
    }
    userId
    userName
    userEmail
    isMTXUser
    roles {
      level1Id
      level2Id
      level3Id
      level3Value
      roleId
      rolename
      permissions {
        screenName
        fullAccess
        noAccess
        readAccess
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileQueryVariables,
  APITypes.GetUserProfileQuery
>;
export const getSyrcPrimaryCompanies = /* GraphQL */ `query GetSyrcPrimaryCompanies {
  getSyrcPrimaryCompanies {
    items {
      id
      name
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSyrcPrimaryCompaniesQueryVariables,
  APITypes.GetSyrcPrimaryCompaniesQuery
>;
