import { Injectable } from '@angular/core';
import { from, map, Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators'; // Add tap here
import { Assets } from '../models/assets';
import {
  ASSET_NOT_FOUND_ERROR,
  GENERIC_FAILED_ERROR,
} from '../../shared/constants';
import {
  FilterInput,
  AssetConnection,
  GetAllAssetsQueryVariables,
  GetAllAssetsQuery,
  AssetMetadataInput,
  AssetOutput,
  GetAssetByIdQueryVariables,
  GetAssetByIdQuery,
} from '../../../awsAppSync/API';
import { getAllAssets, getAssetById } from '../../../awsAppSync/graphql/queries';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private awsService: BaseAWSRequestService) {}

  public assets: Assets[] = [];

  async getAssets(filter: FilterInput): Promise<Observable<Assets[]>> {
    return from(this.getAssetSetupGridData(filter)).pipe(
      map((response) => {
        const assetsArray = response?.items ?? []; 
        return assetsArray.map((asset) => ({
          id: asset?.id,
          pk: asset?.pk,
          sk: asset?.sk,
          level1Id: asset?.level1ValueId,
          level2Id: asset?.level2ValueId,
          level3Id: asset?.level3ValueId,
          name: asset?.assetName,
          latitude: parseFloat(asset?.latitude ?? ''),
          longitude: parseFloat(asset?.longitude ?? ''),
          type: asset?.assetType,
          selected: false,
          lastInspectionDate: null,
          assetDelinquencyDate: asset?.assetDelinquencyDate,
          order: '',
          status: asset?.status,
        }));
      }),
      tap((processedAssets) => {
        this.assets.push(...processedAssets);
      }),
    );
  }

  getRandomFraction(): number {
    return parseFloat(Math.random().toFixed(8));
  }
  
  async getAssetSetupGridData(
    filter: FilterInput,
  ): Promise<AssetConnection | null> {
    try {
      const variables: GetAllAssetsQueryVariables = { filter };
      const result = await this.awsService.executeGetQuery<GetAllAssetsQuery>(
        getAllAssets,
        variables,
      );
      return result.getAllAssets ?? null; // Using nullish coalescing operator
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async getAssetByIdAPI(input: AssetMetadataInput): Promise<AssetOutput | null> {
    try {
      const variables: GetAssetByIdQueryVariables = { input };
      const response = await this.awsService.executeGetQuery<GetAssetByIdQuery>(
        getAssetById,
        variables,
      );
      return response.getAssetById ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  getAssetById(id: string): Observable<Assets | undefined> {
    const asset = this.assets.find((a) => a.id === id);
    if (asset) {
      return of(asset);
    } else {
      console.error(ASSET_NOT_FOUND_ERROR(id));
      return throwError(() => new Error(ASSET_NOT_FOUND_ERROR(id)));
    }
  }

  updateAsset(updatedAsset: Assets): Observable<Assets> {
    const index = this.assets.findIndex((a) => a.id === updatedAsset.id);
    if (index !== -1) {
      this.assets[index] = updatedAsset;
      return of(updatedAsset);
    } else {
      console.error(ASSET_NOT_FOUND_ERROR(updatedAsset.id ?? ''));
      return throwError(
        () => new Error(ASSET_NOT_FOUND_ERROR(updatedAsset.id ?? '')),
      );
    }
  }
}
