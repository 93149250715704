<div *ngIf="isLoader">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    @if (!isEdit) {
    {{ 'CREATE_ASSET_SETUP.TITLE' | translate }}
    } @else {
    {{
    'CREATE_ASSET_SETUP.EDIT_ASSET_TYPE_MEASUREMENT_CRITERIA' | translate
    }}
    }
  </h5>
</div>

<div class="card card-shadow mt-4">
  <form #createAssetForm="ngForm">
    <div class="card-body">
      <div class="row" style="margin-top: -20px;">
        <div class="col-md-2">
          <div class="progress-container mb-4 bs-stepper">
            <div class="step-container">
              <span style="color: #737373;">Step {{currentStep}}/4</span>
              <div class="progress-bars">
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 1, 'inactive-bar': currentStep < 1}"
                  role="progressbar">
                </div>
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 2, 'inactive-bar': currentStep < 2}"
                  role="progressbar">
                </div>
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 3, 'inactive-bar': currentStep < 3}"
                  role="progressbar">
                </div>
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 4, 'inactive-bar': currentStep < 4}"
                  role="progressbar">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mtx-card-bg-primary mtx-card-info">
        <div class="mtx-padding-bottom-0" style="padding-left: 10px">
          <div class="row mt-2">
            <div class="col-sm-12 col-md-8 d-flex">
              <span class="mtx-font-20 mtx-font-weight-500" style="margin-right: 5px">{{
                'CREATE_ASSET_SETUP.FORM_HEADER_HEADING' | translate }}</span>
            </div>
            <div
              class="col-sm-12 col-md-4 pull-right mtx-text-end mtx-padding-right-0 mtx-font-15 d-flex justify-content-end">
              <a class="mtx-theme-primary-color" style="margin-right: 10px">{{ 'CREATE_ASSET_SETUP.VIEW_DOCUMENTATION' |
                translate }}
                <span class="material-icons mtx-theme-primary-color icon-css">open_in_new</span></a>
            </div>
            <div *ngIf="!isSaveClicked" class="col-12">
              <span class="mtx-sub-text-primary mtx-font-14">{{ 'CREATE_ASSET_SETUP.FORM_HEADER_SUBHEADING' | translate
                }}
              </span>
            </div>
          </div>
          <div *ngIf="isSaveClicked" class="row">
            <div class="col">
              <ul>
                <li class="mtx-sub-text-primary mtx-font-14">
                  {{
                  'CREATE_ASSET_SETUP.FORM_HEADER_SUBHEADING_Click1' | translate
                  }}
                </li>
                <li [ngClass]="{ 'mtx-font-weight-500': !isSaveClicked }" class="mtx-sub-text-primary mtx-font-14"
                  style="font-weight: 600">
                  {{
                  'CREATE_ASSET_SETUP.FORM_HEADER_SUBHEADING_Click2' | translate
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <p class="asset-headline my-4">Setup Asset Type Template(s)</p>

      <!-- Asset Type and Measurement Frequency -->
      <div *ngFor="let asset of assets.assetTypeInputs; let i = index" class="mb-4">
        <div *ngIf="!asset.isDeleted" class="row mb-3">
          <div class="col-md-2">
            <input type="text" name="assetType-{{i}}" class="form-control" placeholder="Asset Type"
              [(ngModel)]="asset.assetType" required #assetTypeVal="ngModel" />
            <span *ngIf="assetTypeVal.invalid && assetTypeVal.touched" class="text-danger">
              Asset Type is required.
            </span>
          </div>
          <div [ngClass]="{
            'col-md-5': asset.measurementFrequency === 'Custom',
            'col-md-4': asset.measurementFrequency !== 'Custom',
          }">
            <kendo-dropdownlist [data]="frequencies" [defaultItem]="frequenciedefaultItem"
              name="measurementFrequency-{{i}}" [(ngModel)]="asset.measurementFrequency" textField="text"
              valueField="value" [valuePrimitive]="true" (valueChange)="onMeasurementFrequencyChange($event)" required
              #measurementFrequencyVal="ngModel">
              <!-- Template for the top bar (selected value) -->
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem.value !== 'custom' && asset.measurementFrequency === ''"
                  [ngClass]="{ 'label-css': dataItem.value==='' }">
                  {{ dataItem.text }}
                </span>
                <span *ngIf="dataItem.value !== 'custom' && asset.measurementFrequency"
                  [ngClass]="{ 'label-css': dataItem.value==='' }" style="color: black; margin-left: 5px">
                  {{ asset.measurementFrequency }}
                </span>
                <span *ngIf="dataItem.value === 'custom'">
                  {{ asset.customFrequencyString }}
                </span>
              </ng-template>

              <!-- Template for the dropdown items -->
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span *ngIf="dataItem.value !== 'custom'">
                  {{ dataItem.text }}
                </span>
                <span *ngIf="dataItem.value === 'custom'">
                  {{ 'Custom' }}
                  <span *ngIf="asset.customFrequencyBtnFlag" class="k-icon k-font-icon k-i-pencil"
                    style="color: black; margin-left: 5px" (click)="onCustomFrequencyClick()">
                  </span>
                </span>
              </ng-template>
            </kendo-dropdownlist>
            <span *ngIf="measurementFrequencyVal.invalid && measurementFrequencyVal.touched" class="text-danger">
              Measurement Frequency is required.
            </span>
          </div>

          <!-- Conditional Delete Button -->
          <div class="col-md-3">
            <button *ngIf="!asset.isAssetConfigured && assets.assetTypeInputs.length > 1"
              class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn"
              (click)="deleteAssetType(i)"></button>
          </div>
        </div>

        <!-- Labels for Measurement Fields (only rendered once) -->
        <div *ngIf="!asset.isDeleted" class="row mx-5" style="color: #737373">
          <div class="col-md-3">
            <label>Measurement Source</label>
          </div>
          <div class="col-md-3">
            <label>Measurement Type</label>
          </div>
          <div class="col-md-2">
            <label>Operator</label>
          </div>
          <div class="col-md-2">
            <label>Criteria</label>
          </div>
          <div class="col-md-2">
            <label class="no-wrap">Compliance Measurement?</label>
          </div>
        </div>

        <!-- Dynamic Measurement Rows -->
        <div *ngFor="
          let measurement of asset.measurementCriteriaInputs;
          let j = index
        " class="row mx-5 my-3">
          <div *ngIf="!measurement.isDeleted" class="col-md-3">
            <input id="source-{{ j }}" type="text" class="form-control" placeholder="Enter Measurement Source"
              name="measurementSource-{{j}}" [(ngModel)]="measurement.measurementSource" required
              #measurementSourceVal="ngModel" />
            <span *ngIf="measurementSourceVal.invalid && measurementSourceVal.touched" class="text-danger">
              Measurement Source is required.
            </span>
          </div>
          <div *ngIf="!measurement.isDeleted" class="col-md-3">
            <kendo-dropdownlist [data]="measurementTypes" [(ngModel)]="measurement.measurementType"
              name="measurementType-{{j}}" [valuePrimitive]="true" [defaultItem]="measurementTypesdefaultItem"
              (valueChange)="onTypeChange(measurement)" textField="text" valueField="value" required
              #measurementTypesVal="ngModel">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="{ 'label-css': dataItem.value === '' }">{{
                  dataItem.text
                  }}</span>
              </ng-template>
            </kendo-dropdownlist>
            <span *ngIf="measurementTypesVal.invalid && measurementTypesVal.touched" class="text-danger">
              Measurement Type is required.
            </span>
          </div>
          <div *ngIf="!measurement.isDeleted" class="col-md-2">
            <kendo-dropdownlist [data]="operatorOptions" [(ngModel)]="measurement.measurementOperator"
              name="measurementOperator-{{j}}" [valuePrimitive]="true" [defaultItem]="operatorDefaultItem"
              textField="text" valueField="value" required #measurementOperatorVal="ngModel">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="{ 'label-css': dataItem.value==='' }">{{dataItem.text}}</span>
              </ng-template>
            </kendo-dropdownlist>
            <span *ngIf="measurementOperatorVal.invalid && measurementOperatorVal.touched" class="text-danger">
              Measurement Operator is required.
            </span>
          </div>
          <div *ngIf="!measurement.isDeleted" class="col-md-2">
            <div *ngIf="measurement.measurementOperator === 'between'" class="d-flex">
              <div class="input-with-unit">
                <input id="criteria-1-{{ j }}" type="text" class="form-control" placeholder="Enter"  name="measurementCriteria1-{{j}}"
                  name="measurementCriteria-{{j}}" [(ngModel)]="measurement.criteria1"
                  (keypress)="allowOnlyNumbersAndSymbols($event)" required #criteria1Val="ngModel" />
                <span class="unit" [ngClass]="{ 'unit-position': criteria2Val.invalid && criteria2Val.touched || criteria1Val.invalid && criteria1Val.touched }">{{
                  measurement.unit }}</span>
                <span *ngIf="criteria1Val.invalid && criteria1Val.touched" class="text-danger no-wrap">
                  Required.
                </span>
              </div>
              <div class="input-with-unit ms-1">
                <input id="criteria-2-{{ j }}" type="text" class="form-control" placeholder="Enter" name="measurementCriteria2-{{j}}"
                  [(ngModel)]="measurement.criteria2" (keypress)="allowOnlyNumbersAndSymbols($event)" required
                  #criteria2Val="ngModel" />
                <span class="unit" [ngClass]="{ 'unit-position': criteria2Val.invalid && criteria2Val.touched || criteria1Val.invalid && criteria1Val.touched }">{{
                  measurement.unit }}</span>
                <span *ngIf="criteria2Val.invalid && criteria2Val.touched" class="text-danger no-wrap">
                  Required.
                </span>
              </div>
            </div>
            <div *ngIf="measurement.measurementOperator !== 'between'" class="input-with-unit">
              <input id="criteria-{{ j }}" type="text" class="form-control" placeholder="Enter Criteria"
                name="measurementCriteria-{{j}}" [(ngModel)]="measurement.criteria"
                (keypress)="allowOnlyNumbersAndSymbols($event)" required #criteriaVal="ngModel" />
              <span class="unit" [ngClass]="{ 'unit-position': criteriaVal.invalid && criteriaVal.touched }">{{
                measurement.unit }}</span>
              <span *ngIf="criteriaVal.invalid && criteriaVal.touched" class="text-danger">
                Criteria is required.
              </span>
            </div>

          </div>
          <div *ngIf="!measurement.isDeleted" class="col-md-2 d-flex justify-content-between align-items-center">
            <div class="col-md-6 d-flex justify-content-center">
              <input id="compliance-{{ j }}" type="checkbox" class="form-check-input"
                [(ngModel)]="measurement.compliance" />
            </div>
            <div class="col-md-6 d-flex justify-content-end">
              <button *ngIf="asset.activeMeasurementCriteriasCount > 1"
                class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn"
                (click)="deleteMeasurement(i, j)"></button>
            </div>
          </div>

        </div>

        <!-- Add New Measurement Button -->
        <div *ngIf="!asset.isDeleted" class="col-md-3 mx-5" style="padding: 0.375rem 0.75rem">
          <button class="btn mtx-btn-secondary fontWeight" (click)="addMeasurement(i)">
            + Add New Measurement
          </button>
        </div>
      </div>
      <!-- Create New Asset Type Button -->
      <div>
        <button class="btn mtx-btn-secondary mt-5 fontWeight" (click)="addAssetType()">
          Create New Asset Type
        </button>
      </div>
    </div>
  </form>
  <!-- Divider line before buttons -->
  <hr class="button-divider mt-3" />
  <!-- Cancel, Save & Exit, and Save & Next Buttons -->
  <div class="mt-2 mb-3 d-flex justify-content-end">
    <button class="btn mtx-btn-secondary me-4 fontWeight" style="width: 170px;height: 40px;" (click)="cancel()">
      Cancel
    </button>
    <button class="btn mtx-btn-secondary me-4 fontWeight" style="width: 170px;height: 40px;"
      (click)="saveAndExit(createAssetForm)">
      Save & Exit
    </button>
    <button *ngIf="!isEdit" class="btn mtx-btn-primary me-4 fontWeight" style="width: 170px;height: 40px;"
      (click)="saveAndNext(createAssetForm)">
      Save & Next
    </button>
  </div>
</div>
<app-custom-frequency [OpenDialog]="isDialogOpen" (customFrequency)="setCustomFrequency($event)"></app-custom-frequency>