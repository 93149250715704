<div class="topbar">
    <div class="left-section">
        <span class="logo-icon"></span>
        <span class="logo-seperator"></span>
        <kendo-label class="primarycompany"><b>{{ primaryCompanyName$ | async }}</b></kendo-label>
      <div class="nav-links">
        <a href="#"  [ngClass]="{ 'nav-link-active': currentUrl === '/' || currentUrl==='/dashboard' }" class="nav-link">{{ 'TOP_NAV.DASHBOARD' | translate }}</a>
        <a href="maps" [ngClass]="{ 'nav-link-active': currentUrl === '/maps' }" class="nav-link map-link">{{ 'TOP_NAV.MAP' | translate }}</a>
      </div>
    </div>
    <div class="right-section">
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          {{ selectedName }}
        </button> 
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li *ngFor="let country of countries" (click)="changeLanguage(country)">
            <a class="dropdown-item">
               {{ country.name }}
            </a>
          </li>
        </ul> 
      </div>
      <button class="notification-btn" (click)="openNotificationPopup($event)"><kendo-icon class="notiticationIcon" name="bell" size="large"></kendo-icon></button>
      <app-notification class="notification-dropdown" (click)="$event.stopPropagation()" *ngIf="isNotificationPopupOpen"></app-notification>
      <button mat-icon-button class="settings-btn"><mat-icon class="right-section-icon msgIcon"></mat-icon></button>
      <button mat-icon-button class="settings-btn"><mat-icon class="right-section-icon questionMarkIcon"></mat-icon></button>
      <div class="user-info">
        <span class="avatar"></span>
        <div class="user-details">
          <p class="greeting">Hello</p>
          <!-- <p class="username"><b>John</b></p> -->
          <p class="username"><b>{{ loggedInName$ | async }}</b></p>
        </div>
      </div>
      <button kendoButton #anchor class="usersettings-btn" (click)="userSettingClicked()"></button>
      <app-profile-menu class="profile-menu" (click)="$event.stopPropagation()" *ngIf="isUserSettingPopUpOpen"></app-profile-menu>
      <!-- <kendo-popup
        #popup
        [anchor]="anchor.element"
        (anchorViewportLeave)="isUserSettingPopUpOpen = false"
        *ngIf="isUserSettingPopUpOpen"
        class="kendo-popup userSettingpopup">
        <mat-list>
          <mat-list-item *ngFor="let item of userSettingList">
            <div style="display: flex;  cursor: pointer;">
              <mat-icon class="matListIcon">{{ item.icon }}</mat-icon>
              <h4 style="font-size: 14px; font-weight: inherit; margin-top: 2px;" (click)="userSettingOptionClicked(item)">{{ item.name }}</h4>
            </div>
          </mat-list-item>
        </mat-list>        
      </kendo-popup> -->
    </div>
</div>
  