import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DEFAULTPAGESIZE,
  SEARCHUSERS,
  SURVEYOVERVIEW_ELLIPSIS_WIDTH,
  USEROVERVIEW,
  ZERO,
  USER_OVERVIEW_SCREEN
} from '../../shared/constants';
import { UserService } from '../../core/user.service';
import {
  ColumnBase,
  ColumnComponent,
  DataStateChangeEvent,
  FilterService,
  GridComponent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { User } from '../../core/models/user.module';
import { Observable } from 'rxjs';
import { LevelNameState } from '../../core/store/filtet-levelName.state';
import { Store } from '@ngxs/store';
import { SetSelectedUser } from '../../core/store/selectedUser.state';
import { KendoGridFilters } from '../../../awsAppSync/API';
import { PermissionService } from '../../core/services/permission.service';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrl: './user-overview.component.css',
})
export class UserOverviewComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent;
  @ViewChildren(ColumnComponent) columns!: QueryList<ColumnComponent>;
  @ViewChild('customFilterContainer', { static: true })
  public gridData: GridDataResult = { data: [], total: 0 };
  public searchText = "";
  kendoGridFilters : KendoGridFilters =  {};
  public pageSize = DEFAULTPAGESIZE;
  public searchPlaceholder = SEARCHUSERS;
  public originalData: any[] = [];
  primaryCompanyFilterValues: any[] = [];
  level3FilterValues: any[] = [];
  cityFilterValues: any[] = [];
  stateProvinceFilterValues: any[] = [];
  countryFilterValues: any[] = [];
  accountStatusFilterValues: any[] = [];
  roleNameFilterValues: any[] = [];
  public rowId = ZERO;
  public inputScreen = USEROVERVIEW;
  ellipsisWidth = SURVEYOVERVIEW_ELLIPSIS_WIDTH;
  customFilter!: ElementRef;
  public _selectedColumns: string[] = [
    'primaryCompany',
    'firstName',
    'lastName',
    'userName',
    'emailId',
    'address',
    'city',
    'stateProvince',
    'postalZipCode',
    'country',
    'reportTimeZone',
    'accountStatus',
  ];
  currentDataItem: any;
  dropdownVisible = false;
  dropdownX = ZERO;
  dropdownY = ZERO;
  selectedRowData: any;
  isStickyRow = false;
  public myStickyRows: string[] = [];
  currentPage = ZERO;
  public gridDataToExport: any[] = [];
  public isLoading = true;
  level3name = '';
  storeLevelName$: Observable<string []>;
  selectedLevelName:string[] = [];
  permission = false;
  
  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    private router: Router,
    private store: Store, 
    private cdr: ChangeDetectorRef,
    public permissionService: PermissionService,
  ) 
  {
    this.storeLevelName$ = this.store.select(LevelNameState.getLevelName);
  }

  ngOnInit() {
    this.permission = this.permissionService.hasPermission(USER_OVERVIEW_SCREEN, 'fullAccess')
    this.getUSerGridData();
    this.storeLevelName$.subscribe(levelNames => {
      this.selectedLevelName = levelNames;
      this.level3name = this.selectedLevelName?.[2] ?? '';
    });
  }

  async getUSerGridData(): Promise<void> {
    try {
      this.isLoading = true;
      const userData = await this.userService.getAllUser(
        {
          pageNumber : this.currentPage,
          pageSize : this.state.take?? 50,
          gridFilters : this.kendoGridFilters,
          searchText: this.searchText
        }

      );
      if (userData?.items) {
        const items = userData.items.filter((item): item is User => item !== null);
        const userModels: User[] = this.mapToUserModules(items);
        this.gridData ={
          data: userModels,
          total : userData?.totalCount != null ? userData?.totalCount : 0
        }
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        this.originalData = this.gridData.data;
        this.getDistinctValues('primaryCompanyName');
        this.getDistinctValues('level3DisplayNames');
        this.getDistinctValues('addrCity');
        this.getDistinctValues('addrCity');
        this.getDistinctValues('stateProvince');
        this.getDistinctValues('country');
        this.getDistinctValues('accountStatus');
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        this.isLoading = false;
      }
      
    } catch (error) {
      console.error("Error fetching User data", error);
    }
  }
  
  mapToUserModules(userList: User[]): User[] {
    return userList.map((user) => ({
      __typename: "User", 
      id: user.id ?? '',
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      syrcPrimaryCompany: {
        __typename: "UserPrimaryCompany",
        id: user.syrcPrimaryCompany?.id ?? null, 
        displayName: user.syrcPrimaryCompany?.displayName ?? null,
      },  
      coreViewPrimaryCompany: {
        __typename: "UserPrimaryCompany",
        id: user.coreViewPrimaryCompany?.id ?? null, 
        displayName: user.coreViewPrimaryCompany?.displayName ?? null,
      },                
      active: user.active,
      username: user.username ?? '',
      email: user.email ?? '',
      postalZip: user.postalZip ?? null,
      addr1: user.addr1 ?? null,
      addrCity: user.addrCity ?? null,
      cellPhone: user.cellPhone ?? null,
      officePhone: user.officePhone,
      stateProvince: user.stateProvince ?? null,
      country: user.country ?? null,
      timeZoneId: user.timeZoneId ?? null,
      timeZone: user.timeZone ?? null,
      coreViewUserId: user.coreViewUserId ?? null,
      entityType: user.entityType ?? '',
      createdBy: user.createdBy ?? null,
      createdDate: user.createdDate ?? null,
      updatedBy: user.updatedBy ?? null,
      updatedDate: user.updatedDate ?? null,
      isDeleted: user.isDeleted,
      // level3Values: user.level3Values?.map((level3) => ({
      //   __typename: "UserLevel3Value",
      //   id: level3?.id ?? '',
      //   displayName: level3?.displayName ?? '',
      // })) ?? [],
      //level3Values: user.userRoles?.map((x) => x?.level3Value?.level3ValueName?),
      //level3DisplayNames: this.getLevel3DisplayNames(Array.isArray(user.) ? user.level3Values : []),
      //level3DisplayNames : user.userRoles?.map((x) => x?.level3Value?.level3ValueName),
      level3DisplayNames: user.userRoles?.map(x => x?.level3Value?.level3ValueName).filter(name => name != null).join(', ') ?? '',
      userRoleDisplayNames:user.userRoles?.map(x => x?.name).filter(name => name != null).join(', ') ?? '',
      accountStatus: user.accountStatus ?? '',
      primaryCompanyName : user.syrcPrimaryCompany?.displayName ?? null,
      userRoleNames: this.getUserRoleNames(Array.isArray(user.userRoles) ? user.userRoles : [])
    }));
  }

  public getLevel3DisplayNames(level3Values: any[]): string {
    return level3Values?.map(level3 => level3.displayName).join(', ') || '';
  }

  public getUserRoleNames(userRoles: any[]): string {
    return userRoles?.map(role => role.name).filter(name => name !== undefined).join(', ') || "";
  }
  
  createUser() {
    this.router.navigate(['/setup/users/add']);
  }

  getPriorityClass(priority: boolean): string {
    switch (priority) {
      case true:
        return 'high-priority';
      case false:
        return 'medium-priority';
      default:
        return '';
    }
  }

  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.rowId = this.currentDataItem.id;
    const gridElement = document.querySelector('.k-grid') as HTMLElement;
    const dropdownHeight = 100;
    const dropdownWidth = 200;

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);

      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY;
      }
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );

      this.dropdownX += gridRect.left;
      this.dropdownY = Math.max(0, this.dropdownY + 150);
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }
    this.selectedRowData = dataItem;
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
        this.searchText = "";
        this.getUSerGridData();
    } else if (changeWord.length > 2) {
        this.searchText = changeWord;
        this.getUSerGridData();
    }
}

  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }

  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  getColumnField(column: ColumnBase): string | null {
    if ('field' in column) {
      return (column as any).field;
    }
    return null;
  }


  getDistinctValues(columnName: keyof User): void {
    // Create a Set to store distinct non-null values for the specified column
    const distinctValues = new Set<string>(
      this.gridData.data
        .map((item) => item![columnName])
        .filter(
          (value): value is string => value !== null && value !== undefined && value !="",
        ),
    );

    // Create a new filter array for the column if it doesn't exist
    const filterValuesMap: Record<
      string,
      { text: string; selected: boolean }[]
    > = {
      primaryCompanyName: this.primaryCompanyFilterValues,
      level3DisplayNames: this.level3FilterValues,
      addrCity: this.cityFilterValues,
      stateProvince: this.stateProvinceFilterValues,
      country: this.countryFilterValues,
      accountStatus: this.accountStatusFilterValues,
      userRoleNames: this.roleNameFilterValues
    };

    // Add distinct values to the respective filter array based on the column name
    distinctValues.forEach((item) => {
      const filterItem = { text: item, selected: false };
      const filterArray = filterValuesMap[columnName];
      if (filterArray) {
        // Check if the item already exists to prevent duplicates
        if (!filterArray.some((f) => f.text === item)) {
          filterArray.push(filterItem);
        }
      }
    });
  }

  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
    field:string
  ): void {
    const filters: FilterDescriptor[] = [];
    // Loop through selectedValues to log text and check if selected
    for (const value of selectedValues) {
      // Add filter based on whether the value is selected
      if (value.selected) {
        filters.push({
          field:field,
          operator: 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };

    filterService.filter(rootFilter);
  }

  handleDropdownOption(option: number) {
    if (option === 2) {
      this.store.dispatch(new SetSelectedUser(true,this.selectedRowData.coreViewUserId));
      this.router.navigate(['/setup/users/add']);
    }
  }

  // This code is for Grid Filters and Pagination
    
  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: "and" },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1; 
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.getUSerGridData();
  }
}