import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import { BulkImportMutation, BulkImportMutationVariables, BulkImportResponse, FileUpload, FileUploadInput, importInput, InitializeFileUploadMutationVariables } from '../../../awsAppSync/API';
import { bulkImport, initializeFileUpload } from '../../../awsAppSync/graphql/mutations';


@Injectable({
  providedIn: 'root'
})
export class ImportService {
  
  constructor(private awsService: BaseAWSRequestService) {}

  async bulkImport(input: importInput): Promise<BulkImportResponse | null> {
    try {
      const variables: BulkImportMutationVariables = { input };

      let result = await this.awsService.executeMutation<BulkImportMutation>(bulkImport, variables);

      result = result?.bulkImport || null;

      if (result) {
        return result;  
      }
      return null;  
    } catch (error) {
      console.error('Error during bulk import', error);
      return null;
    }
  }

  async uploadExcelFile(input: FileUploadInput): Promise<FileUpload | null> {
    try {
      const variables: InitializeFileUploadMutationVariables = { input };

      let uploadedResult = await this.awsService.executeMutation<FileUpload>(
        initializeFileUpload,
        variables,
      );
      uploadedResult = uploadedResult.initializeFileUpload;
      if (uploadedResult && uploadedResult.presignedURL) {
        return uploadedResult;
      }

      return null;
    } catch (error) {
      console.error('Error during file upload', error);
      return null;
    }
  }
}
