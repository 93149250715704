<div class="row mb-2">
  <div class="col-12 d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center flex-grow-1 me-3">
      <select id="chartType" class="form-select">
        <option value="line">Time Series Plot</option>
      </select>
    </div>
    <button kendoButton (click)="ShowPopUp()" class="filterbutton" #Button>
      Select Measurement
    </button>

    <kendo-popup *ngIf="isFilterOpen" [anchor]="Button" class="list-container">
      <div *ngFor="let measurement of measurements" class="item-container">
        <input
          type="checkbox"
          [checked]="measurement.selected"
          (change)="onMeasurementChange(measurement)"
        />
        <label class="popuplabel">{{ measurement.name }}</label>
      </div>
    </kendo-popup>
  </div>
</div>

<div *ngIf="selectedMeasurements.length > 0" class="d-flex">
  <div
    *ngFor="let type of selectedMeasurements; let i = index"
    class="selected-option"
  >
    <span
      [ngStyle]="{ 'background-color': getDotColor(i) }"
      class="dot"
      style="margin-left: 20px"
    ></span>
    <span>{{ type.name }}</span>
  </div>
</div>

<!-- Chart Container -->
<div class="row">
  <div class="col-12 d-flex">
    <div class="time-series-chart" id="chart"></div>
  </div>
</div>
