import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LEVEL_NAME_STATE, LEVEL_NAME_STATE_TYPE_SET, LEVEL_NAME_STATE_TYPE_SET_REMOVE } from '../../shared/constants';
export interface LevelNameStateModel {
  levelName: string[];
}
export class SetLevelName {
  static readonly type = LEVEL_NAME_STATE_TYPE_SET;
  constructor(public levelName: string[]) {}
}
export class RemoveLevelName {
  static readonly type = LEVEL_NAME_STATE_TYPE_SET_REMOVE;
}
@State<LevelNameStateModel>({
  name: LEVEL_NAME_STATE,
  defaults: {
    levelName: [],
  },
})
@Injectable()
export class LevelNameState {
  //selector has logic to get data

  @Selector()
  static getLevelName(state: LevelNameStateModel) {
    return state.levelName;
  }

  //actions to do operation
  @Action(SetLevelName)
  SetLevelName(
    { patchState }: StateContext<LevelNameStateModel>,
    { levelName }: SetLevelName,
  ): void {
    patchState({
      levelName: levelName,
    });
  }
  // @Action(RemoveLevelName)
  // removeLevelName({ setState }: StateContext<LevelNameStateModel>): void {
  //   setState({
  //     levelName: [],
  //   });
  // }
}
