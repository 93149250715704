import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import {
  ASSET_HISTORY_ELLIPSIS_WIDTH,
  ASSET_NAME_EXCEPTION,
  ASSETHISTORY,
  CARD_BODY,
  COMPLIANT_AH,
  COMPLIANT_CLASS,
  DEFAULT,
  DEFAULTPAGESIZE,
  DELINQUENT_AH,
  DELINQUENT_CLASS,
  EDIT_ASSET_ROUTE,
  EMPTY_STRING,
  EXCEPTION_AH,
  EXCEPTION_CLASS,
  ND,
  PAGEHEIGHT,
  RD,
  REDIRECT_EXCEPTION,
  REDIRECT_INSPECTION,
  SEARCHASSETHISTORY,
  SHORT,
  ST,
  TH,
  ZERO,
  ASSET_HISTORY_SCREEN,
} from '../../shared/constants';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DataStateChangeEvent,
  FilterService,
  GridComponent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import { AssetHistoryService } from '../../core/services/asset-history.service';
import { MatDialog } from '@angular/material/dialog';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import {
  AssetHistoryGraphInput,
  AssetHistoryGridModel,
  AssetHistoryInput,
  AssetMetadataInput,
  FilterInput,
  HierarchyFilterInput,
  KendoGridFilters,
  SurveyRouteFilterInput,
} from '../../../awsAppSync/API';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { AssetHistory } from '../../core/models/assets';
import { Column } from '../../core/models/inspection.module';
import {
  AssetSetupDataState,
  RemoveAssetData,
  SetAssetData,
} from '../../core/store/asset.state';
import { ExportModalComponent } from '../../shared/export-modal/export-modal.component';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Level3State } from '../../core/store/filter-level3.state';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { SegmentState } from '../../core/store/filter-segments';
import { AssetTypeState } from '../../core/store/filter-assettype';
import { SurveyRouteState } from '../../core/store/filter-serveyrotes';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { LevelNameState } from '../../core/store/filtet-levelName.state';
import { PermissionService } from '../../core/services/permission.service';
declare let bootstrap: any;
@Component({
  selector: 'app-asset-history',
  templateUrl: './asset-history.component.html',
  styleUrl: './asset-history.component.css',
})
export class AssetHistoryComponent implements OnInit, OnDestroy {
  dateRangeForm: FormGroup;
  selectedAssetData: any;
  isLoading = false;
  startDate: Date | null = new Date();
  endDate: Date | null = new Date();
  assetHistoryStartDate: string | null = EMPTY_STRING;
  assetHistoryEndDate: string | null = EMPTY_STRING;
  assetMetadataInput: AssetMetadataInput = {
    primaryCompany: EMPTY_STRING,
    level1: EMPTY_STRING,
    level2: EMPTY_STRING,
    level3: EMPTY_STRING,
    assetId: EMPTY_STRING,
  };
  assetHistoryGraphInput: AssetHistoryGraphInput = {
    primaryCompany: EMPTY_STRING,
    level1: EMPTY_STRING,
    level2: EMPTY_STRING,
    level3: EMPTY_STRING,
    assetId: EMPTY_STRING,
    startDate: EMPTY_STRING,
    endDate: EMPTY_STRING,
    measurementTypes: [],
  };
  measurementTypeArray: string[] = [];
  measurementTypeArrayWithSegment: string[] = [];
  measurementCriteriaInputs: any[] = [];
  assetHistory: AssetHistoryInput = {};
  public dynamicColumns: any[] = [];
  filteredData: any[] = [];
  dropdownVisible = false;
  isInformation = true;
  isAssetSetup = false;
  public inputScreen = ASSETHISTORY;
  ellipsisWidth = ASSET_HISTORY_ELLIPSIS_WIDTH;
  dropdownX = ZERO;
  dropdownY = ZERO;
  region: string | null | undefined = EMPTY_STRING;
  segment: string | null = EMPTY_STRING;
  primaryCompany: string | null = EMPTY_STRING;
  company: string | null | undefined = EMPTY_STRING;
  business: string | null | undefined = EMPTY_STRING;
  assetType: string | null | undefined = EMPTY_STRING;
  assetName: string | null | undefined = EMPTY_STRING;
  stationName: string | null = EMPTY_STRING;
  latitude: string | null = EMPTY_STRING;
  longitude: string | null = EMPTY_STRING;
  route: string | null | undefined = EMPTY_STRING;
  status: string | null | undefined = EMPTY_STRING;
  statusClass = EMPTY_STRING;
  technician: string | null = EMPTY_STRING;
  comments: string | null = EMPTY_STRING;
  atmosphericCorrosionGrade: string | null | undefined = EMPTY_STRING;
  dateCreated: string | null | undefined = EMPTY_STRING;

  primaryCompany$: Observable<string | null>;
  level1$: Observable<string | null>;
  level2$: Observable<string | null>;
  level3$: Observable<string | null>;
  assetId$: Observable<string | null>;
  startDate$: Observable<Date | null>;
  endDate$: Observable<Date | null>;

  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId: string | null = '';
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute: string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  filterModel: FilterInput = {};
  hierarchyFilterInput: HierarchyFilterInput[] = [];
  surveyRouteFilterInput: SurveyRouteFilterInput[] = [];
  isEditAsset = false;

  public pageSize = DEFAULTPAGESIZE;
  public height = PAGEHEIGHT; // initial height
  pageChanged = false;

  public gridData: GridDataResult = { data: [], total: 0 };
  public searchText = '';
  public currentPage = 1;
  kendoGridFilters: KendoGridFilters = {};
  public gridDataToExport: any[] = [];
  public originalData: (AssetHistory | null)[] = [];
  public getFilterDropdownValue = false;
  public showHierarchicalFilter = false;
  public showFilterBtn = true;
  public searchPlaceholder = SEARCHASSETHISTORY;
  public complaiceStatusFilterValues: any[] = [];
  public _selectedColumns: string[] = ['surveyDate', 'comments'];
  public assetNameLst:
    | {
        text: any;
        value: any;
        primaryCompany: string;
        level1: string;
        level2: string;
        level3: string;
      }[]
    | undefined = [];
  public selectedValue = 0;
  public defaultItem: { text: string; value: number } = {
    text: 'Select Asset',
    value: 0,
  };
  images: string[] = [];
  imageDates: any[] = [];
  level1: string | null = EMPTY_STRING;
  level2: string | null = EMPTY_STRING;
  level3: string | null = EMPTY_STRING;
  assetId: string | null = EMPTY_STRING;
  level1Name: string | null = EMPTY_STRING;
  level2Name: string | null = EMPTY_STRING;
  level3Name: string | null = EMPTY_STRING;
  storeLevelName$: Observable<string[]>;
  selectedLevelName: string[] = [];
  reloadChart = false;
  private originalAssetNameLst: any[] = [];

  carouselInstance: any;
  @ViewChild('grid') grid!: GridComponent;
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  @ViewChild('customFilterContainer', { static: false })
  customFilter!: ElementRef;
  permission = false;
  constructor(
    private store: Store,
    private router: ActivatedRoute,
    private navRoute: Router,
    private cdr: ChangeDetectorRef,
    private assetHistoryService: AssetHistoryService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private hirarchyFilter: HeirarchyFilterServiceService,
    public permissionService: PermissionService,
  ) {
    const currentYear = new Date().getFullYear();
    this.startDate = new Date(currentYear, 0, 1); // January 1st
    this.endDate = new Date(currentYear, 11, 31); // December 31st
    this.dateRangeForm = this.fb.group(
      {
        startDate: [this.startDate],
        endDate: [this.endDate],
      },
      { validator: this.dateRangeValidator },
    );
    this.primaryCompany$ = this.store.select(
      AssetSetupDataState.getAssetPrimaryCompany,
    );
    this.level1$ = this.store.select(AssetSetupDataState.getAssetLevel1);
    this.level2$ = this.store.select(AssetSetupDataState.getAssetLevel2);
    this.level3$ = this.store.select(AssetSetupDataState.getAssetLevel3);
    this.assetId$ = this.store.select(AssetSetupDataState.getAssetAssetId);
    this.startDate$ = this.store.select(AssetSetupDataState.getAssetStartDate);
    this.endDate$ = this.store.select(AssetSetupDataState.getAssetEndDate);

    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );
    this.storeLevelName$ = this.store.select(LevelNameState.getLevelName);
  }

  ngOnInit(): void {
    this.permission = this.permissionService.hasPermission(ASSET_HISTORY_SCREEN, 'fullAccess');
    this.selectedAssetData = this.assetHistoryService.selectedRowData;
    this.storeLevelName$.subscribe((levelNames) => {
      this.selectedLevelName = levelNames;
    });
    this.level1Name = this.selectedLevelName[0].toUpperCase();
    this.level2Name = this.selectedLevelName[1].toUpperCase();
    this.level3Name = this.selectedLevelName[2].toUpperCase();
    this.primaryCompany$.subscribe((primaryCompany) => {
      this.primaryCompany = primaryCompany;
    });
    this.assetId$.subscribe((assetId) => {
      this.assetId = assetId;
    });
    this.level1$.subscribe((level1) => {
      this.level1 = level1;
    });
    this.level2$.subscribe((level2) => {
      this.level2 = level2;
    });
    this.level3$.subscribe((level3) => {
      this.level3 = level3;
    });
    this.startDate$.subscribe((startDate) => {
      this.startDate = startDate;
    });
    this.endDate$.subscribe((endDate) => {
      this.endDate = endDate;
    });

    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryComapnyId = '';
      this.selectedprimaryComapnyId = id;
    });

    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
      this.hierarchyFilterInput = [];
      if (this.selectedprimaryComapnyId && this.selectedLevel3.length > 0) {
        this.fetchLevel3Values(this.selectedprimaryComapnyId);
      }
    });

    this.storeSegmentTypeSet$.subscribe((segmentType) => {
      this.selectedSegmentType = [];
      this.selectedSegmentType = segmentType;
    });

    this.storeSelectedSegments$.subscribe((segment) => {
      this.selectedSegments = [];
      this.selectedSegments = segment;
    });

    this.storeSelectedSurveyRoute$.subscribe((Surveyroute) => {
      this.surveyRouteFilterInput = [];
      this.selectedSurveyRoute = Surveyroute;
      if (
        this.selectedSurveyRoute.length > 0 &&
        this.selectedprimaryComapnyId
      ) {
        this.fetchSurveyRouteValues(this.selectedprimaryComapnyId);
      }
    });

    this.storeSelectedAssetType$.subscribe((assettype) => {
      this.selectedAssetType = [];
      this.selectedAssetType = assettype;
    });

    if (!this.selectedAssetData) {
      const startDate = this.startDate ? new Date(this.startDate) : null;
      const endDate = this.endDate ? new Date(this.endDate) : null;

      if (startDate) {
        this.dateRangeForm.get('startDate')?.setValue(startDate);
      }

      if (endDate) {
        this.dateRangeForm.get('endDate')?.setValue(endDate);
      }
    }

    if (this.selectedAssetData) {
      const currentYear = new Date().getFullYear();
      this.startDate = new Date(currentYear, 0, 1); // January 1st
      this.endDate = new Date(currentYear, 11, 31); // December 31st
    }

    this.dateRangeForm.get('startDate')?.valueChanges.subscribe((value) => {
      if (!this.dateRangeForm.errors?.['dateRangeInvalid']) {
        this.startDate = value;
        this.assetHistoryStartDate = this.formatToISO(this.startDate);
        this.callMethods();
        this.assetHistoryService.startDate = this.startDate!;
        this.disptachDataToStore();
      }
    });

    this.dateRangeForm.get('endDate')?.valueChanges.subscribe((value) => {
      if (!this.dateRangeForm.errors?.['dateRangeInvalid']) {
        this.endDate = value;
        this.assetHistoryEndDate = this.formatToISO(this.endDate);
        this.callMethods();
        this.assetHistoryService.endDate = this.endDate!;
        this.disptachDataToStore();
      }
    });
    if (this.selectedAssetData) {
      const primaryCompany = this.selectedAssetData.pk.split('#')[1];
      this.primaryCompany = primaryCompany;
      this.level1 = this.selectedAssetData.level1ValueId;
      this.level2 = this.selectedAssetData.level2ValueId;
      this.level3 = this.selectedAssetData.level3ValueId;
      this.assetId = this.selectedAssetData.id;
    }
    this.assetHistoryStartDate = this.formatToISO(this.startDate);
    this.assetHistoryEndDate = this.formatToISO(this.endDate);
    this.assetHistoryService.startDate = this.startDate!;
    this.assetHistoryService.endDate = this.endDate!;
    this.disptachDataToStore();
    this.mapAssetHistoryMetaDataInput();
    this.mapAssetHistoryGridInput();
    this.isLoading = true;
    this.getAssetDetailsById();
    this.loadAssetHistoryGridData();
    this.loadAssetOption();
    this.originalData = [...this.gridData.data];
  }

  onValueChange(selectedValue: { text: string; value: number }): void {
    this.reloadChart = true;
    const selectedAssetItem =
      this.assetNameLst?.find((item) => item.value === selectedValue.value) ||
      null;
    this.assetId = selectedAssetItem?.value;
    this.primaryCompany = selectedAssetItem!.primaryCompany!;
    this.level1 = selectedAssetItem!.level1!;
    this.level2 = selectedAssetItem!.level2!;
    this.level3 = selectedAssetItem!.level3!;
    const currentYear = new Date().getFullYear();
    this.startDate = new Date(currentYear, 0, 1); // January 1st
    this.endDate = new Date(currentYear, 11, 31); // December 31st
    this.assetHistoryStartDate = this.formatToISO(this.startDate);
    this.assetHistoryEndDate = this.formatToISO(this.endDate);
    this.mapAssetHistoryMetaDataInput();
    this.mapAssetHistoryGridInput();
    this.getAssetDetailsById();
    this.loadAssetHistoryGridData();
  }

  formatToISO(date: Date | null): string | null {
    return date ? new Date(date).toISOString() : null;
  }

  filterSearchButtonClicked(): void {
    this.loadAssetOption();
  }

  disptachDataToStore() {
    this.store.dispatch(
      new SetAssetData(
        this.primaryCompany!,
        this.assetId!,
        this.level1!,
        this.level2!,
        this.level3!,
        this.startDate!,
        this.endDate!,
      ),
    );
  }

  callMethods() {
    this.isLoading = true;
    this.mapAssetHistoryGridInput();
    this.mapAssetHistoryGraphInput();
    this.loadAssetHistoryGridData();
    this.loadAssetGraphData();
  }

  mapAssetHistoryMetaDataInput() {
    this.assetMetadataInput = {
      primaryCompany: this.primaryCompany!,
      level1: this.level1!,
      level2: this.level2!,
      level3: this.level3!,
      assetId: this.assetId!,
    };
  }

  mapAssetHistoryGridInput() {
    this.assetHistory = {
      primaryCompany: this.primaryCompany!,
      level1: this.level1,
      level2: this.level2,
      level3: this.level3,
      assetId: this.assetId,
      startDate: this.assetHistoryStartDate,
      endDate: this.assetHistoryEndDate,
      pageSize: 50,
    };
  }

  mapAssetHistoryGraphInput() {
    this.assetHistoryGraphInput = {
      primaryCompany: this.primaryCompany!,
      level1: this.level1!,
      level2: this.level2!,
      level3: this.level3!,
      assetId: this.assetId!,
      startDate: this.assetHistoryStartDate!,
      endDate: this.assetHistoryEndDate!,
      measurementTypes: this.measurementTypeArray,
    };
  }

  updateFilterModel() {
    this.filterModel = {
      primaryCompany: this.selectedprimaryComapnyId,
      level4: this.selectedSegments,
      assetTypes: this.selectedAssetType,
      segmentTypes: this.selectedSegmentType,
      surveyRouteFilterInputs: this.surveyRouteFilterInput,
      hierarchyFilterInputs: this.hierarchyFilterInput,
    };
  }

  async getAssetDetailsById() {
    this.isLoading = true;
    const response = await this.assetHistoryService.getAssetDetailsById(
      this.assetMetadataInput,
    );
    this.images =
      response?.getAssetDetailsById?.imagePresignedUrls?.filter(
        (url): url is string => url !== null,
      ) || [];
    this.assetName = response?.getAssetDetailsById?.assetName;
    this.route = response?.getAssetDetailsById?.surveyRoute;
    this.region = response?.getAssetDetailsById?.level3Value;
    this.status = response?.getAssetDetailsById?.status;
    this.statusClass = this.mapCriteriaToClass(this.status);
    this.atmosphericCorrosionGrade =
      response?.getAssetDetailsById?.atmosphericCorrosionGrade;
    this.technician = (response?.getAssetDetailsById?.techResponsible || [])
      .filter((item) => item !== null)
      .join(', ');
    this.segment = [
      ...(response?.getAssetDetailsById?.associatedLevel4 || []).filter(
        (item) => item !== null,
      ),
      response?.getAssetDetailsById?.level4Value,
    ]
      .filter((item) => item)
      .join(', ');

    this.assetType = response?.getAssetDetailsById?.assetType;
    const dateString = response?.getAssetDetailsById?.createdDate;
    const date = new Date(dateString ?? '');
    if (!isNaN(date.getTime())) {
      this.dateCreated = this.formatDate(date);
      this.imageDates.push(this.dateCreated);
    }
    this.business = response?.getAssetDetailsById?.level1Value;
    //this.level1label =
    this.company = response?.getAssetDetailsById?.level2Value;
    if (response && response.getAssetDetailsById?.measurementCriteria) {
      const measurementCriteriaArray =
        response.getAssetDetailsById?.measurementCriteria.map((item) => ({
          measurementSource: item?.measurementSource || EMPTY_STRING,
          measurementType: item?.measurementType || EMPTY_STRING,
          measurementOperator: item?.measurementOperator || EMPTY_STRING,
          criteria: item?.criteria || EMPTY_STRING,
          criteria1: EMPTY_STRING,
          criteria2: EMPTY_STRING,
          segment: item?.level4Value || EMPTY_STRING,
          segmentRelation: item?.level4Relation || EMPTY_STRING,
          compliance: item?.compliance || false,
          unit: item?.unit || EMPTY_STRING,
        })) || [];
      this.measurementCriteriaInputs.push(...measurementCriteriaArray);
      this.measurementTypeArray =
        response.getAssetDetailsById?.measurementCriteria.map(
          (item) => item?.measurementType || EMPTY_STRING,
        );
      this.measurementTypeArrayWithSegment =
        response.getAssetDetailsById?.measurementCriteria.map((item) => {
          const measurementType = item?.measurementType || EMPTY_STRING;
          const level4Value = item?.level4Value || EMPTY_STRING;
          return `${measurementType} - ${level4Value}`.trim();
        });
    }
    this.assetHistoryService.measurementTypeArray =
      this.measurementTypeArrayWithSegment;
    this.mapAssetHistoryGraphInput();
    this.loadAssetGraphData();
  }

  async loadAssetGraphData() {
    const response = await this.assetHistoryService.getAssetHistoryGraph(
      this.assetHistoryGraphInput,
    );
    this.assetHistoryService.graphData =
      response?.getAssetHistoryGraphData?.items;
  }

  async loadAssetOption() {
    this.isLoading = true;
    this.updateFilterModel();
    const response = await this.assetHistoryService.getAllAssetOption(
      this.filterModel,
    );
    this.assetNameLst = response?.getAllAssetOption?.items?.map(
      (item: any) => ({
        text: item.assetName,
        value: item.id,
        primaryCompany: item.pk.split('#')[1],
        level1: item.level1ValueId,
        level2: item.level2ValueId,
        level3: item.level3ValueId,
      }),
    );
    this.originalAssetNameLst = [...(this.assetNameLst ?? [])];
    this.isLoading = false;
  }

  onFilterChange(filterText: string): void {
    if (filterText) {
      this.assetNameLst = this.originalAssetNameLst.filter((item: any) =>
        item.text.toLowerCase().includes(filterText.toLowerCase())
      );
    } else {
      this.assetNameLst = [...this.originalAssetNameLst];
    }
  }

  async loadAssetHistoryGridData() {
    const response = await this.assetHistoryService.getAssetHistory(
      this.assetHistory,
    );
    if (response?.items) {
      const items = response.items.filter(
        (item): item is AssetHistoryGridModel => item !== null,
      );

      const assetHistoryModels: AssetHistory[] =
        this.mapAssetHistoryToModels(items);
      this.dynamicColumns = this.generateDynamicColumns(assetHistoryModels);
      this.assetHistoryService.assetHistorydynamicColumns = this.dynamicColumns;

      this.gridData = {
        data: assetHistoryModels,
        total: response?.totalCount != null ? response?.totalCount : 0,
      };

      const surveyDates = response.items
        .map((item) =>
          item?.surveyDate ? new Date(item.surveyDate).getTime() : null,
        )
        .filter((date) => date !== null);

      const minDate = new Date(Math.min(...(surveyDates as number[])));
      const maxDate = new Date(Math.max(...(surveyDates as number[])));
      this.assetHistoryService.minDateForGridData = minDate;
      this.assetHistoryService.maxDateForGridData = maxDate;
    } else {
      this.dynamicColumns = [];
      this.assetHistoryService.assetHistorydynamicColumns = this.dynamicColumns;
      this.gridData = {
        data: [],
        total: 0,
      };
    }
  }

  handleDropdownOption(option: number) {
    if (option === 2) {
      this.isEditAsset = true;
      this.disptachDataToStore();
      this.navRoute.navigate([EDIT_ASSET_ROUTE]);
    }
    if (option === 3) {
      this.navRoute.navigate([REDIRECT_EXCEPTION]);
    }
    if (option === 5) {
      this.navRoute.navigate([REDIRECT_INSPECTION]);
    }
  }

  mapAssetHistoryToModels(
    assetHistory: AssetHistoryGridModel[],
  ): AssetHistory[] {
    return assetHistory.map((history) => {
      const lastComment =
        history.comments && history.comments.length > 0
          ? history.comments[history.comments.length - 1]?.comment ?? null
          : null;

      return {
        __typename: 'AssetHistory',
        surveyId: history.surveyId ?? null,
        surveyDate: history.surveyDate ?? null,
        comments: history.comments ?? null,
        comment: lastComment,
        measurementModels: history.measurementModels ?? null,
        dynamicValues: {}, // Initialize as empty object
      };
    });
  }

  generateDynamicColumns(data: AssetHistory[] | null): Column[] {
    const columns: Column[] = [];
    data?.forEach((item) => {
      item.dynamicValues = item.dynamicValues || {};
      if (
        Array.isArray(item.measurementModels) &&
        item.measurementModels.length > 0
      ) {
        item.measurementModels.forEach((measurement) => {
          if (measurement) {
            const columnName = measurement.measurementType!;
            const value = `${measurement.measurementValue} ${measurement.unit}`;
            if (!columns.find((col) => col.field === columnName)) {
              columns.push({
                field: columnName,
                title: columnName,
                width: 120,
              });
            }
            item.dynamicValues[columnName] = value;
          }
        });
      }

      columns.forEach((col) => {
        if (!item.dynamicValues[col.field]) {
          item.dynamicValues[col.field] = '';
        }
      });
    });

    return columns;
  }

  ngAfterViewInit(): void {
    const carouselElement = document.getElementById('carouselExample');
    this.carouselInstance = new bootstrap.Carousel(carouselElement);
  }

  dateRangeValidator(
    formGroup: AbstractControl,
  ): Record<string, boolean> | null {
    const startDate = formGroup.get('startDate')?.value;
    const endDate = formGroup.get('endDate')?.value;

    if (startDate && endDate && startDate > endDate) {
      return { dateRangeInvalid: true };
    }
    return null;
  }

  informationClicked() {
    this.isAssetSetup = false;
    this.isInformation = true;
  }

  assetSetupClicked() {
    this.isAssetSetup = true;
    this.isInformation = false;
  }

  prevSlide() {
    this.carouselInstance.prev();
  }

  nextSlide() {
    this.carouselInstance.next();
  }

  ngOnDestroy() {
    if (!this.isEditAsset) {
      this.store.dispatch(new RemoveAssetData());
    }
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: SHORT });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  formatDateString(dateString: string | null): string | null {
    if (dateString !== null) {
      const date = new Date(dateString);

      const day = date.getDate();
      const daySuffix = this.getDaySuffix(day);

      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        year: 'numeric',
      };
      const formattedDate = date.toLocaleDateString('en-US', options);

      return `${day}${daySuffix} ${formattedDate}`;
    }
    return null;
  }

  getQueryParams(): void {
    this.router.queryParamMap.subscribe((params) => {
      const assetName = params.get(ASSET_NAME_EXCEPTION) || undefined;
      if (assetName) {
        const decodedValue = decodeURIComponent(assetName);
        this.urlFilter(decodedValue);
      }
    });
  }

  urlFilter(assetName: string) {
    const lowerCaseChangeWord = assetName.toLowerCase();
    const newData = this.gridData.data.filter((item: any) => {
      return this.equalsIgnoreCase(item.assetName, lowerCaseChangeWord);
    });
    this.gridData.data = newData;
  }

  equalsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase() === searchTerm.toLowerCase();
    }
    return false;
  }

  mapCriteriaToClass(status: string | null | undefined): string {
    // Map criteria values to CSS classes
    switch (status) {
      case COMPLIANT_AH:
        return COMPLIANT_CLASS;
      case EXCEPTION_AH:
        return EXCEPTION_CLASS;
      case DELINQUENT_AH:
        return DELINQUENT_CLASS;
      default:
        return EMPTY_STRING;
    }
  }

  // transformGridData() {
  //   //Matches the value from measurement based on isCompliant flag
  //   this.gridData.forEach((item) => {
  //     const compliantMeasurement = item.measurement.find(
  //       (measure: { isCompliant: any }) => measure.isCompliant,
  //     );
  //     if (compliantMeasurement) {
  //       item.criteria = compliantMeasurement.criteria;
  //     }
  //   });
  // }

  openDropdownMenu(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownVisible = true;
    const gridElement = document.querySelector(CARD_BODY) as HTMLElement;
    const dropdownHeight = 150;
    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const scrollTop = gridElement.scrollTop;
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + scrollTop;

      const spaceBelow = window.innerHeight - clickY;
      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY + 10;
      }
      this.dropdownX = Math.max(0, clickX + 15);
    }
  }

  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
      this.searchText = '';
      this.assetHistory.searchText = this.searchText;
      this.loadAssetHistoryGridData();
    } else if (changeWord.length > 2) {
      this.searchText = changeWord;
      this.assetHistory.searchText = this.searchText;
      this.loadAssetHistoryGridData();
    }
  }

  // Helper function to check if a field contains the search term
  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }
  //tray code to show and hide the filters

  toggleFilterTray() {
    this.showHierarchicalFilter = !this.showHierarchicalFilter;
    this.showFilterBtn = !this.showFilterBtn;
    if (this.showHierarchicalFilter) {
      this.height = 830;
    } else {
      this.height = PAGEHEIGHT;
    }
  }
  collapseFilterTray() {
    this.toggleFilterTray();
  }

  openDialog(): void {
    const currentPageData = this.gridData.data.slice(
      this.state.skip,
      this.state ? (this.state.skip as number) + this.pageSize : 0,
    );
    const dataToExport = this.pageChanged
      ? this.gridDataToExport
      : currentPageData;
    this.filteredData = this.gridData.data.map((item) => {
      const filteredItem: Record<string, any> = {};
      this._selectedColumns.forEach((column) => {
        filteredItem[column] = (item as any)[column];
      });
      filteredItem['dynamicValues'] = item.dynamicValues;
      return filteredItem;
    });
    this.dialog.open(ExportModalComponent, {
      data: {
        selectedColumnData: this.filteredData,
        selectedColumn: this._selectedColumns,
        pdfExport: this.pdfExport,
        pageSize: this.pageSize,
        gridData: dataToExport,
        allData: this.originalData,
        flag: ASSETHISTORY,
        isVisibleColumns: false,
        filters: this.assetHistory
      },
    });
    this.pageChanged = false;
  }

  //custom-filter for complaice status column in the grid
  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
  ): void {
    const filters: FilterDescriptor[] = [];
    // Loop through selectedValues to log text and check if selected
    for (const value of selectedValues) {
      // Add filter based on whether the value is selected
      if (value.selected) {
        filters.push({
          field: 'status',
          operator: 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };
    filterService.filter(rootFilter);
  }

  async fetchLevel3Values(primaryCompanyId: string) {
    const allLevel3Values: HierarchyFilterInput[] = [];
    const level3Values =
      await this.hirarchyFilter.getAllLevel3ValueByPrimaryCompany(
        primaryCompanyId,
      );
    if (level3Values?.items) {
      level3Values.items.forEach((item) => {
        if (item) {
          if (this.selectedLevel3.includes(item.id))
            allLevel3Values.push({
              level1: item.level1ValueId || '',
              level2: item.level2ValueId || '',
              level3: item.id || '',
            });
        }
      });
    }
    this.hierarchyFilterInput = allLevel3Values;
  }

  async fetchSurveyRouteValues(primaryCompanyId: string) {
    const allSurveyRouteFilterValues: SurveyRouteFilterInput[] = [];
    const surveyRouteValues =
      await this.hirarchyFilter.getAllSurveyRouteBySubFilterInput({
        primaryCompanyId: primaryCompanyId,
      });
    if (surveyRouteValues?.items) {
      surveyRouteValues.items.forEach((item) => {
        if (item) {
          if (this.selectedSurveyRoute.includes(item.id)) {
            item.RouteAssociateAssetList?.forEach((level) => {
              level?.level3ValueIdList?.forEach((l3) => {
                allSurveyRouteFilterValues.push({
                  level1: level?.level1ValueId,
                  level2: level?.level2ValueId,
                  level3: l3,
                  assetId: level.assetId,
                });
              });
            });
          }
        }
      });
    }

    this.surveyRouteFilterInput = allSurveyRouteFilterValues;
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1;
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.assetHistory.pageNumber = this.currentPage;
    this.assetHistory.gridFilters = this.kendoGridFilters;
    this.assetHistory.pageSize = this.pageSize;
    this.loadAssetHistoryGridData();
  }
}
