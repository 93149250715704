<mat-dialog-content class="add-route-dialog">
  <div class="dialog-header">
    <div class="route-header-icon"></div>
    <div class="top-close-button col-2" (click)="close()"></div>
  </div>

  <h2 class="text-alignment">Update Existing Survey Route or Create New</h2>
  <span class="export-modal-text02">
    <span>It looks like you want to manage your routes. Choose an option below:</span>
  </span>

  <div class="checkbox-group">
    <div *ngFor="let option of options" class="checkbox-row"
         [ngClass]="{'selected-row': option.selected}">
      <span class="checkbox-label">{{ option.label }}</span>
      <kendo-checkbox
        [(ngModel)]="option.selected"
        (change)="onCheckboxChange(option)"
        class="circle-checkbox">
      </kendo-checkbox>
    </div>
  </div>

  <div class="action-buttons">
    <button class="action-button cancel" (click)="close()">Back</button>
    <button class="action-button export" (click)="proceed()" [disabled]="!isAnyCheckboxSelected">Proceed</button>
  </div>
</mat-dialog-content>
