<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    <ng-container *ngIf="!isEdit; else editTemplate">
      Create Survey
    </ng-container>
    <ng-template #editTemplate>
      Edit Survey
    </ng-template>
  </h5>
</div>

<div class="custom-form card" >
  <form [formGroup]="surveyForm">
    <div class="progress-container mb-4 bs-stepper">
      <div class="step-container">
        <span style="color: #737373">Step {{ currentStep }}/3</span>
        <div class="progress-bars">
          <div
            class="progress-bar"
            [ngClass]="{
              'active-bar': currentStep >= 1,
              'inactive-bar': currentStep < 1,
            }"
            role="progressbar"
          ></div>
          <div
            class="progress-bar"
            [ngClass]="{
              'active-bar': currentStep >= 2,
              'inactive-bar': currentStep < 2,
            }"
            role="progressbar"
          ></div>
          <div
            class="progress-bar"
            [ngClass]="{
              'active-bar': currentStep === 3,
              'inactive-bar': currentStep < 3,
            }"
            role="progressbar"
          ></div>
        </div>
      </div>
    </div>
    <div class="bs-stepper-content">
      <div
        id="step-1"
        class="content"
        role="tabpanel"
        aria-labelledby="step-1-trigger"
        *ngIf="currentStep !== 2"
        [attr.aria-selected]="currentStep === 2"
        [ngClass]="{ active: currentStep === 2 }"
      >
        <div class="header-container">
          <div class="line"></div>
          <div>
            <h5 style="font-size: 20px">
              {{ 'CREATE_SURVEY.FORM_HEADER' | translate }}
            </h5>
            <p style="font-size: 15px">
              <ng-container *ngIf="!isEdit; else editTemplate">
                {{ reportSubheading }}
              </ng-container>
              <ng-template #editTemplate>
                to edit {{surveyNameVal}}
              </ng-template>
            </p>


          </div>
        </div>
        <div class="card-body-align">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <div class="label-container">
                  <label for="surveyName" class="form-label txt-color"
                    >Survey Name <span class="asterisk">*</span></label
                  >
                  <div
                    *ngIf="
                      surveyForm.get('surveyName')?.hasError('required') &&
                      surveyForm.get('surveyName')?.touched
                    "
                  >
                    <small class="text-danger">Survey Name is required</small>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Survey Name"
                  maxlength="80"
                  formControlName="surveyName"
                  id="surveyName"
                  [(ngModel)]="surveyNameVal"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <div class="label-container">
                  <label for="surveyType" class="form-label txt-color"
                    >Survey Type <span class="asterisk">*</span></label
                  >
                  <div
                    *ngIf="
                      surveyForm.get('surveyType')?.hasError('required') &&
                      surveyForm.get('surveyType')?.touched
                    "
                  >
                    <small class="text-danger">Survey Type is required</small>
                  </div>
                </div>
                <select
                  formControlName="surveyType"
                  id="surveyType"
                  class="form-select"
                  [(ngModel)]="surveyTypeVal"
                  [ngStyle]="{ color: surveyTypeVal ? 'black' : '#8E8E8E' }"
                >
                  <option value="">Select Survey Type</option>
                  <option *ngFor="let type of surveyTypes" [value]="type.value">
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <div class="label-container">
                  <label for="surveyPriority" class="form-label txt-color"
                    >Survey Priority <span class="asterisk">*</span></label
                  >
                  <div
                    *ngIf="
                      surveyForm.get('surveyPriority')?.hasError('required') &&
                      surveyForm.get('surveyPriority')?.touched
                    "
                  >
                    <small class="text-danger"
                      >Survey Priority is required</small
                    >
                  </div>
                </div>
                <select
                  formControlName="surveyPriority"
                  id="surveyPriority"
                  class="form-select"
                  [(ngModel)]="surveyPriorityVal"
                  [ngStyle]="{ color: surveyPriorityVal ? 'black' : '#8E8E8E' }"
                >
                  <option value="">Select Survey Priority</option>
                  <option *ngFor="let type of surveyPriority" [value]="type.value">
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <div class="label-container">
                  <label for="surveyCollectionType" class="form-label txt-color"
                    >Data Collection Type <span class="asterisk">*</span></label
                  >
                  <div
                    *ngIf="
                      surveyForm
                        .get('surveyCollectionType')
                        ?.hasError('required') &&
                      surveyForm.get('surveyCollectionType')?.touched
                    "
                  >
                    <small class="text-danger"
                      >Data Collection Type is required</small
                    >
                  </div>
                </div>
                <select
                  formControlName="surveyCollectionType"
                  id="surveyCollectionType"
                  class="form-select"
                  [(ngModel)]="surveyCollectionTypeVal"
                  [ngStyle]="{ color: surveyCollectionTypeVal ? 'black' : '#8E8E8E' }"
                >
                  <option value="">Select Data Collection Type</option>
                  <option
                    *ngFor="let type of surveyCollectionType"
                    [value]="type.value"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row"> 
            <div class="col-md-6">
              <div class="mb-3">
                <div class="label-container">
                  <label for="dueDate" class="form-label txt-color"
                    >Due Date <span class="asterisk">*</span></label
                  >
                  <div
                    *ngIf="
                      surveyForm.get('dueDate')?.hasError('required') &&
                      surveyForm.get('dueDate')?.touched
                    "
                  >
                    <small class="text-danger">Due Date is required</small>
                  </div>
                </div>
                <!-- <input type="date" class="form-select" id="dateOfInspection" name="dateOfInspection" /> -->
                <kendo-datepicker
                  formControlName="dueDate"
                  id="dueDate"
                  placeholder="Choose a Due Date"
                  format="dd-MMM-yyyy"
                  [(ngModel)]="dueDateVal"
                ></kendo-datepicker>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <div class="label-container">
                  <label for="assignedTo" class="form-label txt-color"
                    >Assigned to <span class="asterisk">*</span></label
                  >
                  <div
                    *ngIf="
                      surveyForm.get('assignedTo')?.hasError('required') &&
                      surveyForm.get('assignedTo')?.touched
                    "
                  >
                    <small class="text-danger">Assigned To is required</small>
                  </div>
                </div>
                <select
                  formControlName="assignedTo"
                  id="assignedTo"
                  class="form-select"
                  [(ngModel)]="assignedToVal"
                  [ngStyle]="{ color: assignedToVal ? 'black' : '#8E8E8E' }"
                >
                  <option value="">Select Assigned To</option>
                  <option *ngFor="let type of assignTo" [value]="type.id">
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <div class="label-container">
                  <label for="troubleshooting" class="form-label"
                    >Troubleshooting <span class="asterisk">*</span></label
                  >
                  <div
                    *ngIf="
                      surveyForm.get('troubleshooting')?.hasError('required') &&
                      surveyForm.get('troubleshooting')?.touched
                    "
                  >
                    <small class="text-danger"
                      >Troubleshooting is required</small
                    >
                  </div>
                </div>
                <select
                  formControlName="troubleshooting"
                  id="troubleshooting"
                  class="form-select"
                  [(ngModel)]="troubleshootingVal"
                >
                  <option value="">Select Troubleshooting</option>
                  <option
                    *ngFor="let type of surveyTroubleshooting"
                    [value]="type.value"
                  >
                    {{ type.value }}
                  </option>
                </select>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <div class="label-container">
                  <label for="surveyDescription" class="form-label txt-color"
                    >Survey Description</label
                  >
                </div>
                <textarea
                  class="form-control"
                  id="surveyDescription"
                  rows="3"
                  placeholder="Enter Survey Description"
                  formControlName="surveyDescription"
                  [(ngModel)]="surveyDescriptionVal"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-3">
                <button
                  class="btn btn-secondary mtx-btn-secondary mtx-btn-custom"
                  (click)="cancel()"
                  type="button"
                >
                  Cancel
                </button>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-primary mtx-btn-custom"
                  style="background-color: #3498DB !important;"
                  type="submit"
                  (click)="onSaveAndContinue()"
                >
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
