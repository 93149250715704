<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="container-fluid py-2" style="margin-top: -28px;">
  <div class="container-fluid">

<div class="card-body"> 
  <div class="row-container">
    <div class="col-auto">
      <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px;">{{ title | translate }}</h5>
    </div>
  <div *ngIf="showHierarchicalFilter" style="margin-left: 45px; margin-top: 20px;">
    <!-- <app-heirarchy-filter-buttons [isToggleFilterButtonVisible] = "false" [isSurveyRouteFilterRequired]="true"> </app-heirarchy-filter-buttons>
    <app-heirarchy-filter-popup></app-heirarchy-filter-popup> -->
    <app-heirarchy-filter-buttons (filterSearchButtonClicked)="filterSearchButtonClicked()" 
                                  [isToggleFilterButtonVisible] = "false" 
                                  [isSurveyRouteFilterRequired]="true">
     </app-heirarchy-filter-buttons>
     <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
  </div>
</div>

<div class="row" style="margin-top: -10px;">
  <div class="col-md-2">
  <div class="progress-container mb-4 bs-stepper">
    <div class="step-container">
      <span style="color: #737373">Step {{ currentStep }}/3</span>
      <div class="progress-bars">
        <div
          class="progress-bar"
          [ngClass]="{
            'active-bar': currentStep >= 1,
            'inactive-bar': currentStep < 1,
          }"
          role="progressbar"
        ></div>
        <div
          class="progress-bar"
          [ngClass]="{
            'active-bar': currentStep >= 2,
            'inactive-bar': currentStep < 2,
          }"
          role="progressbar"
        ></div>
        <div
          class="progress-bar"
          [ngClass]="{
            'active-bar': currentStep === 3,
            'inactive-bar': currentStep < 3,
          }"
          role="progressbar"
        ></div>
      </div>
    </div>
  </div>
</div>
</div>


  <div class="mt-3">
    <div class="map-container">
      <div class="map" #infoCard #map></div>
    </div>
    <div>
      <div class="legend-container">
        <div class="legend-item">
          <span class="legends-text">Legends: </span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Compliant.svg"
            alt="location"
          />
          <span class="legend-text">Compliant</span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Exception.svg"
            alt="location"
          />
          <span class="legend-text">Exception</span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Delinquent.svg"
            alt="location"
          />
          <span class="legend-text">Delinquent</span>
        </div>
      </div>
      
      <div *ngIf="displayPreviewVisible" class="survey-preview">
        <div class="col-md-12 survey-preview-header-container">
          <div class="survey-preview-header">
            <div class="header-title-container">
              <div class="ellipsis">
                <kendo-icon
                  class="k-icon k-icon-inline text-muted me-2 elipse"
                  name="more-vertical"
                ></kendo-icon>
              </div>
              <span class="survey-preview-title">Survey Preview</span>
            </div>
            <span class="survey-preview-item date-created" style="margin-left:21px;">
              <span class="dot"></span>
              Created on {{ current_Date |customDate }}
            </span>
          </div>
          <div class="survey-preview-details">
            <span class="survey-preview-item">{{'SURVEY_TASK_PREVIEW.NAME' | translate}}: <span class="survey-value">{{ surveyNameVal }}</span></span>
            <span class="survey-preview-item">{{'SURVEY_TASK_PREVIEW.TYPE' | translate}}: <span class="survey-value">{{ surveyTypeVal }}</span></span>
            <span class="survey-preview-item">{{'SURVEY_TASK_PREVIEW.PRIORITY' | translate}}: <span class="survey-value">{{ surveyPriorityVal }}</span></span>
            <span class="survey-preview-item">{{'SURVEY_TASK_PREVIEW.DUE_DATE' | translate}}: <span class="survey-value">{{ dueDateVal | customDate }}</span></span>
            <span class="survey-preview-item">{{'SURVEY_TASK_PREVIEW.ASSIGNED_TO' | translate}}: <span class="survey-value">{{ assignedToName }}</span></span>
          </div>
        </div>
      </div>
  
      <div style="margin-top: 10px">
        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <kendo-grid [kendoGridBinding]="assets" style="width: 100%; border-radius: 10px">
            <kendo-grid-column
              field="type"
              title="Asset Type"
              [headerStyle]="{ background: '#F1F8FF' }"
            ></kendo-grid-column>
            <kendo-grid-column
              field="name"
              title="Asset Name"
              [headerStyle]="{ background: '#F1F8FF' }"
            ></kendo-grid-column>
            <kendo-grid-column
              field="lastInspectionDate"
              title="Last Inspection Date"
              [headerStyle]="{ background: '#F1F8FF' }"
            ></kendo-grid-column>
            <kendo-grid-column
              field="assetDelinquencyDate"
              title="Days Until Delinquent"
              [headerStyle]="{ background: '#F1F8FF' }"
            ></kendo-grid-column>
            <kendo-grid-column
              field="coordinates"
              title="Coordinates"
              [headerStyle]="{ background: '#F1F8FF' }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.latitude }}, {{ dataItem.longitude }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column *ngIf="!displayOrderSelection && !displayPreviewVisible" [width]="50">
              <ng-template kendoGridHeaderTemplate>
                <input
                  type="checkbox"
                  id="selectAllCheckbox"
                  (change)="onSelectAllChange($event)"
                  [checked]="allSelected"
                  [(ngModel)]="allSelected"
                />
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <input
                  type="checkbox"
                  [attr.data-asset-id]="dataItem.id"
                  (change)="onCheckboxChange($event, dataItem)"
                  [checked]="dataItem.selected"
                />
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column *ngIf="displayOrderSelection || displayPreviewVisible" [width]="80">
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
              <div class="inline-container">
                  <!-- Conditionally display the cancel icon if displayPreviewVisible is true -->
                  <img 
                    *ngIf="displayPreviewVisible" 
                    src="assets/icon/cancel.svg" 
                    alt="Remove Asset"
                    class="remove-asset"
                    (click)="removeAsset(dataItem)"
                  />
            
                  <div cdkDrag cdkDragHandle class="order-selection">
                    <div class="order-icon">
                      <mat-icon class="icon">menu</mat-icon>
                      <span class="order-number">{{ rowIndex + 1 }}</span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
        <div class="d-flex justify-content-end align-items-center mt-3 flex-wrap"  *ngIf="!displayOrderSelection && !displayPreviewVisible">
          <button class="circular-button me-2" (click)="backToCreateSurvey()">
            <mat-icon class="me-2">keyboard_arrow_left</mat-icon>
          </button>
          <button type="button" class="btn btn-secondary mtx-btn-secondary me-2" style="width: 150px; font-weight: 500;" (click)="cancel()">Cancel</button>
          <button [disabled]="!isOrderButtonEnabled" type="button" class="btn btn-primary" style="width: 150px; font-weight: 500;  background-color: #3498DB !important;" (click)="toggleOrderSelection()">Order Selection</button>
        </div>
        <div class="col-md-12 text-end" *ngIf="displayOrderSelection">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-8 text-end">
              <button class="circular-button" style="margin-right: 10px; margin-top: 10px;" (click)="backtoAssetSelection()">
                <mat-icon style="margin-right: 10px; justify-content: center; text-align: center;">keyboard_arrow_left</mat-icon>
              </button>
              <button type="button" class="btn btn-secondary mtx-btn-secondary" style="width: 150px; margin-right: 10px; margin-top: -10px; font-weight: 500;" (click)="cancel()">Cancel</button>
              <button type="button" class="btn btn-primary " style="width: 150px; margin-right: 10px;margin-top: -10px; font-weight: 500;  background-color: #3498DB !important;" (click)="displayPreview()">Preview</button>
              <button class="circular-button" style="margin-right: 10px; margin-top: 10px;" (click)="displayPreview()">
                <mat-icon style="margin-right: 10px; justify-content: center; text-align: center;">keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-12 text-end" *ngIf="displayPreviewVisible">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-8 text-end">
              <button class="circular-button" style="margin-right: 10px; margin-top: 10px;" (click)="backtoOrderSelection()">
                <mat-icon style="margin-right: 10px; justify-content: center; text-align: center;">keyboard_arrow_left</mat-icon>
              </button>
              <button type="button" class="btn btn-secondary mtx-btn-secondary" style="width: 150px; margin-right: 10px; margin-top: -10px; font-weight: 500;" (click)="cancelFromPreview()">Cancel</button>
              <button type="button"  [disabled]="isSubmitButtonDisabled" class="btn btn-primary " style="width: 150px; margin-right: 10px;margin-top: -10px; font-weight: 500; cursor: pointer;  background-color: #3498DB !important;" (click)="submitSurveyTask()">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>