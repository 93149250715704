import { Injectable } from '@angular/core';
import { FULLACCESS, NOACCESS, READACCESS } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  permissionsFromStore = [
    {
      screenName: 'Asset Overview',
      fullAccess: true,
      noAccess: false,
      readAccess: false,
    },
    {
      screenName: 'Survey Overview',
      fullAccess: true,
      noAccess: false,
      readAccess: false,
    },
    {
      screenName: 'Route Management',
      fullAccess: true,
      noAccess: false,
      readAccess: false,
    },
    {
      screenName: 'Company Overview',
      fullAccess: true,
      noAccess: false,
      readAccess: true,
    },
    {
      screenName: 'User Overview',
      fullAccess: true,
      noAccess: false,
      readAccess: false,
    },
    {
      screenName: 'Inspections',
      fullAccess: true,
      noAccess: false,
      readAccess: false,
    },
    {
      screenName: 'Exceptions',
      fullAccess: true,
      noAccess: false,
      readAccess: false,
    },
    {
      screenName: 'Asset History',
      fullAccess: true,
      noAccess: false,
      readAccess: false,
    },
  ];

  private permissions: Record<string, { fullAccess: boolean; readAccess: boolean; noAccess: boolean }> = {};

  constructor() {
    this.transformData();
  }

  transformData() {
    this.permissions = this.permissionsFromStore.reduce(
      (acc, curr) => {
        acc[curr.screenName] = {
          fullAccess: curr.fullAccess,
          readAccess: curr.readAccess,
          noAccess: curr.noAccess,
        };
        return acc;
      },
      {} as Record<string, { fullAccess: boolean; readAccess: boolean; noAccess: boolean }>,
    );
  }

  setPermissions(permissions: Record<string, { fullAccess: boolean; readAccess: boolean; noAccess: boolean }>): void {
    this.permissions = permissions;
  }

  hasAnyPermission(
    feature: string,
    requiredPermissions: ('fullAccess' | 'readAccess' | 'noAccess')[],
  ): boolean {
    const featurePermissions = this.permissions[feature];

    if (featurePermissions) {
      return requiredPermissions.some(
        (permission) => featurePermissions[permission],
      );
    }
    return false;
  }

  hasPermission(
    feature: string,
    requiredPermission: 'fullAccess' | 'readAccess' | 'noAccess',
  ): boolean {
    const featurePermissions = this.permissions[feature];

    if (featurePermissions) {
      if (requiredPermission === FULLACCESS) {
        return featurePermissions[FULLACCESS];
      } else if (requiredPermission === READACCESS) {
        return featurePermissions[READACCESS];
      } else {
        return featurePermissions[NOACCESS];
      }
    }
    return false;
  }
}
