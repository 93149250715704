<kendo-popup
  *ngIf="isFilterOpen"
  (open)="isFilterOpen"
  [anchor]="selectedFilter.anchor!"
  (anchorViewportLeave)="onClose()"
  class="kendo-popup HeirarchyFilter"
>
  <div class="popupcontainer">
    <div class="search-bar-Popup">
      <kendo-svgicon
        [icon]="svgSearch"
        style="margin-left: 2px; color: gray"
      ></kendo-svgicon>
      <input
        type="search"
        class="search-input"
        placeholder="Search"
        [(ngModel)]="_searchTerm"
        (ngModelChange)="filterData()"
      />
    </div>
    <kendo-listview
      [data]="_searchedFilteredItems"
      class="list-container"
      *ngIf="!IsSearchResultNull"
    >
      <ng-template kendoListViewItemTemplate let-dataItem>
        <div class="item-container">
          <kendo-checkbox
            #checkedState
            [checkedState]="dataItem.checked"
            [(ngModel)]="dataItem.checked"
            (ngModelChange)="onCheckboxChange(dataItem)"
          ></kendo-checkbox>
          <label>{{ dataItem.label }}</label>
        </div>
      </ng-template>
    </kendo-listview>
    <div *ngIf="IsSearchResultNull">
      <label style="text-align: center; margin: 15px; color: gray;"
        >No available values based on current selection</label
      >
    </div>
    <div class="action-buttons-div">
      <button
        kendoButton
        [ngClass]="{
          'disabled-button': IsSearchResultNull,
          'reset-button': !IsSearchResultNull,
        }"
        [disabled]="IsSearchResultNull"
        (click)="OnResetListClicked()"
      >
        Reset
      </button>
      <button
        kendoButton
        [ngClass]="{
          'disabled-button': IsSearchResultNull,
          'save-button': !IsSearchResultNull,
        }"
        [disabled]="IsSearchResultNull"
        (click)="OnSaveListClicked()"
      >
        Save
      </button>
    </div>
  </div>
</kendo-popup>
