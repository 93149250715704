import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from '../service/loader/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  
  loading$: Observable<boolean>;
  constructor(private loaderService: LoaderService) {
    this.loading$ = this.loaderService.loading$;
    loaderService.show();
    setTimeout(() => loaderService.hide(), 15000);
  }
}
