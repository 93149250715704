import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  CompanyOptionConnection,
  CreatePrimaryCompanyMutationVariables,
  FilePreSignedUrl,
  FileUpload,
  FileUploadInput,
  GetAllPrimaryCompanyQueryVariables,
  GetPreSignedURLForUploadedFileQueryVariables,
  GetPrimaryCompanyByIdQueryVariables,
  InitializeFileUploadMutationVariables,
  KendoGridInput,
  PrimaryCompany,
  PrimaryCompanyConnection,
  PrimaryCompanyCreateInput,
  PrimaryCompanyResponse,
  PrimaryCompanyUpdateInput,
  TimeZoneOptionConnection,
  UpdatePrimaryCompanyMutationVariables,
} from '../../../awsAppSync/API';
import {
  getAllCompanyOption,
  getAllPrimaryCompany,
  getAllTimeZoneOption,
  getPreSignedURLForUploadedFile,
  getPrimaryCompanyById,
} from '../../../awsAppSync/graphql/queries';
import {
  CREATE_PRIMARY_COMPANY_ERROR,
  EMPTY_STRING,
  ERROR_FETCHING_COMPANIES,
  ERROR_FETCHING_EXISTING_COMPANIES,
  ERROR_FETCHING_TIME_ZONE,
  FETCHING_PRESIGNED_IMGURL_ERROR,
  FETCHING_PRIMARY_COMPANY_BYID_ERROR,
  UPDATE_PRIMARY_COMPANY_ERROR,
} from '../../shared/constants';
import {
  createPrimaryCompany,
  initializeFileUpload,
  updatePrimaryCompany,
} from '../../../awsAppSync/graphql/mutations';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public primaryCompanyId: string = EMPTY_STRING;
  constructor(private awsService: BaseAWSRequestService) {}

  public async getAllCompanyOption(): Promise<CompanyOptionConnection | null> {
    try {
      const query = getAllCompanyOption;
      const result = await this.awsService.executeGetQuery<{
        getAllCompanyOption: CompanyOptionConnection;
      }>(query);
      return result.getAllCompanyOption || null;
    } catch (error) {
      console.error(ERROR_FETCHING_EXISTING_COMPANIES, error);
      return null;
    }
  }

  public async getAllTimeZoneOptions(): Promise<TimeZoneOptionConnection | null> {
    const query = getAllTimeZoneOption;
    try {
      const response = await this.awsService.executeGetQuery<{
        getAllTimeZoneOption: TimeZoneOptionConnection;
      }>(query);
      return response?.getAllTimeZoneOption;
    } catch (error) {
      console.error(ERROR_FETCHING_TIME_ZONE, error);
      return null;
    }
  }

  public async getAllPrimaryCompany(
    input :KendoGridInput
  ): Promise<PrimaryCompanyConnection | null> {
    const query = getAllPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllPrimaryCompanyQueryVariables = {
      input : input
    };

    try {
      const result = await this.awsService.executeGetQuery<{
        getAllPrimaryCompany: PrimaryCompanyConnection;
      }>(query, variables);
      return result.getAllPrimaryCompany || null;
    } catch (error) {
      console.error(ERROR_FETCHING_COMPANIES, error);
      return null;
    }
  }
  // createPrimaryCompany method using the generic executeMutation method
  async createPrimaryCompany(
    input: PrimaryCompanyCreateInput,
  ): Promise<PrimaryCompanyResponse | null> {
    const mutation = createPrimaryCompany;
    const variables: CreatePrimaryCompanyMutationVariables = { input };
    try {
      const primaryCompanyResult = await this.awsService.executeMutation<{
        createPrimaryCompany: PrimaryCompanyResponse;
      }>(mutation, variables);
      const primaryCompanyResponse =
        primaryCompanyResult.createPrimaryCompany || null;
      this.primaryCompanyId = primaryCompanyResponse?.item?.id || null;
      return primaryCompanyResult.createPrimaryCompany || null;
    } catch (error) {
      console.error(CREATE_PRIMARY_COMPANY_ERROR, error);
      return null;
    }
  }

  async updatePrimaryCompany(
    input: PrimaryCompanyUpdateInput,
  ): Promise<PrimaryCompanyResponse | null> {
    const mutation = updatePrimaryCompany;
    const variables: UpdatePrimaryCompanyMutationVariables = { input };
    try {
      const primaryCompanyResult = await this.awsService.executeMutation<{
        updatePrimaryCompany: PrimaryCompanyResponse;
      }>(mutation, variables);
      const primaryCompanyResponse =
        primaryCompanyResult.createPrimaryCompany || null;
      this.primaryCompanyId = primaryCompanyResponse?.item?.id || null;
      return primaryCompanyResult.updatePrimaryCompany || null;
    } catch (error) {
      console.error(UPDATE_PRIMARY_COMPANY_ERROR, error);
      return null;
    }
  }

  async uploadPrimaryCompanyImage(
    input: FileUploadInput,
  ): Promise<FileUpload | null> {
    const mutation = initializeFileUpload;
    const variables: InitializeFileUploadMutationVariables = { input };
    try {
      // Call the generic mutation method
      const uploadedImgResult = await this.awsService.executeMutation<{
        initializeFileUpload: FileUpload;
      }>(mutation, variables);
      // Check if the uploaded image result has a presignedURL
      if (uploadedImgResult && uploadedImgResult.initializeFileUpload) {
        return uploadedImgResult.initializeFileUpload || null;
      }

      return null;
    } catch (error) {
      console.error(CREATE_PRIMARY_COMPANY_ERROR, error);
      return null;
    }
  }
  async getPrimaryCompanyDetailsById(
    id: string,
  ): Promise<PrimaryCompany | null> {
    try {
      const variables: GetPrimaryCompanyByIdQueryVariables = { id };
      const query = getPrimaryCompanyById;
      const result = await this.awsService.executeGetQuery<{
        getPrimaryCompanyById: PrimaryCompany;
      }>(query, variables);
      return result.getPrimaryCompanyById || null;
    } catch (error) {
      console.error(FETCHING_PRIMARY_COMPANY_BYID_ERROR, error);
      return null;
    }
  }

  async getPreSignedURLForUploadedFile(
    fileURL: string,
  ): Promise<FilePreSignedUrl | null> {
    try {
      const variable: GetPreSignedURLForUploadedFileQueryVariables = {
        fileURL,
      };
      const query = getPreSignedURLForUploadedFile;
      const result = await this.awsService.executeGetQuery<{
        getPreSignedURLForUploadedFile: FilePreSignedUrl;
      }>(query, variable);
      return  result.getPreSignedURLForUploadedFile 
    } catch (error) {
      console.error(FETCHING_PRESIGNED_IMGURL_ERROR, error);
      return null;
    }
  }
}
