import {
  Component,
  Input,
  OnInit,
  ElementRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { AssetHistoryService } from '../../../core/services/asset-history.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AssetSetupDataState } from '../../../core/store/asset.state';

@Component({
  selector: 'app-asset-history-chart',
  templateUrl: './asset-history-chart.component.html',
  styleUrls: ['./asset-history-chart.component.css'],
})
export class AssetHistoryChartComponent implements OnInit, OnChanges {
  @Input() assetId?: string; // New input for filtering data by assetId
  @Input() data: any;
  @Input() inputStartDate: Date | null = new Date();
  @Input() inputEndDate: Date | null = new Date();
  @Input() reloadChart = false;
  measurementTypeArray: string[] = [];
  colors: string[] = ['#3498DB', '#FFA500', 'green', 'red'];
  isFilterOpen = false;
  selectedMeasurementsWithoutSegment: Measurement[] = [];
  graphData: any;
  measurements: Measurement[] = [];
  selectedMeasurements: Measurement[] = this.measurements.filter(
    (m) => !m.selected,
  );
  isDateChanged = false;

  startDate$: Observable<Date | null>;
  endDate$: Observable<Date | null>;
  storestartDate: Date = new Date();
  storeendDate: Date = new Date();
  minDateForGridData: Date | null = new Date();
  maxDateForGridData: Date | null = new Date();

  constructor(
    private elementRef: ElementRef,
    private store: Store,
    private assetHistoryService: AssetHistoryService,
  ) {
    this.startDate$ = this.store.select(AssetSetupDataState.getAssetStartDate);
    this.endDate$ = this.store.select(AssetSetupDataState.getAssetEndDate);
  }

  private parsedData: { date: Date; value: number }[] = [];

  ngOnInit(): void {
    setTimeout(() => {
      this.generateChartData();
    }, 5000);
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['inputStartDate'] ||
      changes['inputEndDate'] ||
      changes['reloadChart']
    ) {
      this.startDate$.subscribe((startDate) => {
        this.storestartDate = startDate!;
      });
      this.endDate$.subscribe((endDate) => {
        this.storeendDate = endDate!;
      });
      this.generateChartData();
      this.selectedMeasurements = [];
      this.graphData = [];
      this.parsedData = [];
      this.selectedMeasurementsWithoutSegment = [];
      this.createChart();
      this.ShowPopUp();
      this.isDateChanged = true;
    }
  }

  generateChartData() {
    this.graphData = this.assetHistoryService.graphData;
    this.minDateForGridData = this.assetHistoryService.minDateForGridData;
    this.minDateForGridData = this.assetHistoryService.minDateForGridData;
    this.measurementTypeArray = this.assetHistoryService.measurementTypeArray;
    this.measurements = this.measurementTypeArray.map((type) => ({
      name: type,
      selected: false,
    }));
    const dataForPlot = this.segregateDataByMeasurementType(this.graphData);
    const valueWithSegment = Object.keys(dataForPlot)[0];
    this.parsedData = dataForPlot[valueWithSegment].map((d) => ({
      date: new Date(d.dateTime),
      value: +d.value,
    }));
  }

  generateChartPlotting() {
    this.graphData = this.assetHistoryService.graphData;
    const dataForPlot = this.segregateDataByMeasurementType(this.graphData);
    const valueWithSegment = Object.keys(dataForPlot)[0];
    this.parsedData = dataForPlot[valueWithSegment].map((d) => ({
      date: new Date(d.dateTime),
      value: +d.value,
    }));
  }

  segregateDataByMeasurementType(data: any) {
    const segregatedData: Record<
      string,
      { dateTime: string; value: string }[]
    > = {};
    for (const item of data) {
      const { measurementType, dateTime, value } = item;
      if (!segregatedData[measurementType]) {
        segregatedData[measurementType] = [];
      }
      segregatedData[measurementType].push({ dateTime, value });
    }

    return segregatedData;
  }

  getDotColor(index: number): string {
    return this.colors[index % this.colors.length];
  }

  onMeasurementChange(measurement: Measurement) {
    if (this.isDateChanged) {
      this.generateChartPlotting();
    }
    this.graphData = this.assetHistoryService.graphData;
    this.minDateForGridData = this.assetHistoryService.minDateForGridData;
    this.minDateForGridData = this.assetHistoryService.minDateForGridData;
    measurement.selected = !measurement.selected;
    this.selectedMeasurements = this.measurements.filter((m) => m.selected);
    this.selectedMeasurementsWithoutSegment = this.selectedMeasurements.map(
      (item) => {
        const updatedName = item.name.split(' - ')[0];
        return { ...item, name: updatedName };
      },
    );
    this.createChart();
  }

  ShowPopUp() {
    if (this.isFilterOpen) {
      this.isFilterOpen = false;
    } else {
      this.isFilterOpen = true;
    }
  }

  calculateMonthsDifference(startDate: Date | null, endDate: Date | null): number {
    if(startDate && endDate){
      const startYear = startDate?.getFullYear();
      const startMonth = startDate?.getMonth(); // 0-based index for months
      const endYear = endDate?.getFullYear();
      const endMonth = endDate?.getMonth();
  
      // Calculate the total months between the two dates
      const monthsDifference =
        (endYear - startYear) * 12 + (endMonth - startMonth);
      return monthsDifference;
    }
    return 0;
   
  }

  createChart(): void {
    const svgWidth = '100%';
    const svgHeight = 300;

    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 1150 - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    // Remove any existing SVG elements before drawing a new chart (to avoid duplication)
    d3.select(this.elementRef.nativeElement).select('svg').remove();

    // Create SVG container
    const svg = d3
      .select(this.elementRef.nativeElement)
      .append('svg')
      .attr('width', svgWidth)
      .attr('height', svgHeight);

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Filter the data based on selectedMeasurements
    const dataForSelectedTypes = this.selectedMeasurementsWithoutSegment.map(
      (type) => {
        return {
          type,
          data: this.segregateDataByMeasurementType(this.graphData)[
            type.name
          ].map((d) => ({
            date: new Date(d.dateTime),
            value: +d.value, // Convert string to number
          })),
        };
      },
    );

    // Set the x and y scales
    const x = d3
      .scaleTime()
      .domain(d3.extent(this.parsedData, (d) => d.date) as [Date, Date])
      .range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(
          dataForSelectedTypes.flatMap((d) => d.data),
          (d) => d.value,
        ) as number,
      ])
      .range([height, 0]);

    // Calculate the difference between startDate and endDate
    const startDate = new Date(this.storestartDate); // assuming startDate is available
    const endDate = new Date(this.storeendDate); // assuming endDate is available
    const timeDiffInYears = d3.timeYear.count(startDate, endDate);
    const timeDiffInMonths = this.calculateMonthsDifference(
      this.minDateForGridData,
      this.maxDateForGridData,
    );
    let tickInterval;
    if (timeDiffInYears >= 1 && timeDiffInYears <= 2) {
      tickInterval = d3.timeMonth.every(1); // Show months for 1 year or less
    } else if (timeDiffInMonths > 2) {
      tickInterval = d3.timeMonth.every(1);
    } else if (timeDiffInYears < 1) {
      tickInterval = d3.timeDay.every(1);
    } else {
      tickInterval = d3.timeYear.every(0.5);
    }
    // Add the x-axis
    const xAxis = g
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(
            tickInterval, // For more than 2 years, show years
          )
          .tickFormat((d: Date | d3.NumberValue) => {
            // Format the ticks based on the time range
            if (timeDiffInYears >= 1 && timeDiffInYears <= 2) {
              return d3
                .timeFormat('%b %Y')(d as Date)
                .toUpperCase(); // Show month and year (e.g., Jan 2023)
            } else if (timeDiffInYears < 1) {
              return d3
                .timeFormat('%b %d')(d as Date)
                .toUpperCase();
            } else {
              return d3
                .timeFormat('%b %Y')(d as Date)
                .toUpperCase(); // Show year only (e.g., 2023)
            }
          }),
      );
    xAxis
      .select('.domain')
      .attr('stroke', '#8E8E8E') // Set the x-axis line color
      .attr('stroke-width', 1) // Adjust thickness
      .attr('stroke-dasharray', '5,5');
    xAxis.selectAll('.tick line').attr('stroke', 'none'); // Hide tick lines
    // Add the y-axis
    const yAxis = g.append('g').attr('class', 'y-axis').call(d3.axisLeft(y));
    yAxis
      .select('.domain')
      .attr('stroke', '#8E8E8E') // Set the x-axis line color
      .attr('stroke-width', 1) // Adjust thickness
      .attr('stroke-dasharray', '5,5');
    yAxis.selectAll('.tick line').attr('stroke', 'none'); // Hide tick lines

    xAxis.selectAll('.tick text').attr('fill', '#8E8E8E'); // Set tick label color for x-axis

    // Change y-axis tick label color
    yAxis.selectAll('.tick text').attr('fill', '#8E8E8E');
    // Line generator function
    const line = d3
      .line<{ date: Date; value: number }>()
      .x((d) => x(d.date))
      .y((d) => y(d.value));

    // Loop through each selected measurement type and plot the corresponding line
    dataForSelectedTypes.forEach((dataSet, index) => {
      g.append('path')
        .datum(dataSet.data)
        .attr('fill', 'none')
        .attr('stroke', this.getDotColor(index)) // Assign a unique color from the colors array
        .attr('stroke-width', 1.5)
        .attr('d', line);

      // Add labels for each line (optional)
      g.append('text')
        .attr('class', 'months-label')
        .attr('x', width / 2) // Center the text
        .attr('y', height + 30) // Position below the x-axis
        .attr('text-anchor', 'middle') // Center align the text
        .attr('fill', '#8E8E8E') // Set the color
        .text('MONTHS'); // The placeholder text
    });
  }
}
interface Measurement {
  name: string;
  selected: boolean;
}
