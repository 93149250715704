<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          {{ 'COMPANY_OVERVIEW_PAGE.TITLE' | translate }}
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px; margin-right: -10px">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            ></app-search>
          </div>
          <div *ngIf="permission">
            <button
              class="company-button"
              (click)="openCreatePrimaryCompangyPage()"
            >
              <div class="company-text">
                <span class="company-text">
                  <span class="button-text-alignment">{{
                    'COMPANY_OVERVIEW_PAGE.PRIMARY_COMPANY_BUTTON' | translate
                  }}</span>
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid-container mt-3">
    <kendo-grid
      [data]="gridData"
      #grid
      class="grid-shadow"
      [filterable]="'menu'"
      [resizable]="true"
      [skip]="state.skip"
      [pageable]="true"
      [pageSize]="state.take"
      [filter]="state.filter"
      (dataStateChange)="dataStateChange($event)"
    >
      <kendo-grid-column
        [resizable]="false"
        [headerClass]="'firstColGridHeader non-reorderable'"
        [width]="60"
        [filterable]="false"
        field="id"
        title=""
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex-container">
            <div class="icon-container">
              <span
                class="k-icon k-font-icon my-custom-icon-class"
                style="position: relative"
                (click)="openDropdownMenu($event, dataItem)"
              ></span>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [minResizableWidth]="80"
        field="primaryCompany"
        [title]="'COMPANY_GRID_COLUMNS.PRIMARY_COMPANY' | translate"
        [width]="140"
      >
      </kendo-grid-column>

      <kendo-grid-column
        [minResizableWidth]="80"
        field="phoneNumber"
        [title]="'COMPANY_GRID_COLUMNS.PHONE' | translate"
        [width]="120"
      ></kendo-grid-column>

      <kendo-grid-column
        field="officeNumber"
        [title]="'COMPANY_GRID_COLUMNS.CORPORATE_PHONE' | translate"
        [width]="120"
        [minResizableWidth]="80"
      >
      </kendo-grid-column>

      <kendo-grid-column
        field="address"
        [width]="170"
        [minResizableWidth]="80"
        [title]="'COMPANY_GRID_COLUMNS.ADDRESS' | translate"
      >
      </kendo-grid-column>

      <kendo-grid-column
        field="city"
        [minResizableWidth]="80"
        [title]="'COMPANY_GRID_COLUMNS.CITY' | translate"
        [width]="100"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="state"
        [minResizableWidth]="80"
        [title]="'COMPANY_GRID_COLUMNS.STATE' | translate"
        [width]="140"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
            fieldName="state"
            [inputElements]="stateFilterValues"
            [parentDiv]="customFilter"
            [state]="customstate"
            (selectedValues)="applyFilter($event, filterService, 'state')">
         </app-custom-grid-filter>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="zipCode"
        [minResizableWidth]="80"
        [title]="'COMPANY_GRID_COLUMNS.ZIPCODE' | translate"
        [width]="120"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="country"
        [minResizableWidth]="80"
        [title]="'COMPANY_GRID_COLUMNS.COUNTRY' | translate"
        [width]="120"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
            fieldName="country"
            [inputElements]="countryFilterValues"
            [parentDiv]="customFilter"
            [state]="customstate"
            (selectedValues)="applyFilter($event, filterService, 'country')">
         </app-custom-grid-filter>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="setupStatus"
        [minResizableWidth]="80"
        [title]="'COMPANY_GRID_COLUMNS.STATUS' | translate"
        [width]="130"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          Step {{ dataItem.setupStatus }}/4
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <app-row-actions
      class="row-action"
      [selectedRowData]="selectedRowData"
      [visible]="dropdownVisible"
      [positionX]="dropdownX"
      [width]="ellipsisWidth"
      [positionY]="dropdownY"
      [inputScreen]="inputScreen"
      (visibleChange)="handleDropdownVisibilityChange($event)"
      [rowId]="rowId"
      [isStickyRow]="false"
      [permission] = "permission"
      (optionSelected)="handleDropdownOption($event)"
    ></app-row-actions>
  </div>
</div>
